var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", width: "900px" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "v-modal-fixed style-2 overflow-x-hidden" },
        [
          _c(
            "v-card-title",
            {
              staticClass:
                "headline sticky-header v-modal-header d-flex justify-space-between px-9 py-3",
            },
            [
              _c("h5", [_vm._v(_vm._s(_vm.$t("editCampaign.editCampWizard")))]),
              _c(
                "v-icon",
                {
                  staticClass: "black--text",
                  attrs: { disabled: _vm.saving },
                  on: { click: _vm.close },
                },
                [_vm._v("mdi-close")]
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "px-5 pb-0" },
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-form",
                    {
                      ref: "editCampform",
                      attrs: { "lazy-validation": "" },
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.updateCampaign.apply(null, arguments)
                        },
                      },
                      model: {
                        value: _vm.valid,
                        callback: function ($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid",
                      },
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "12" } },
                            [
                              _c("v-label", { staticClass: "mb-4 d-flex" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("editCampaign.campName")) + " "
                                ),
                                _c("sup", { staticClass: "red--text" }, [
                                  _vm._v("*"),
                                ]),
                              ]),
                              _c("v-text-field", {
                                attrs: {
                                  outlined: "",
                                  dense: "",
                                  required: "",
                                  rules: _vm.nameRules,
                                },
                                model: {
                                  value: _vm.campaignDetails.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.campaignDetails, "name", $$v)
                                  },
                                  expression: "campaignDetails.name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "mt-0", attrs: { align: "center" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "pt-0", attrs: { cols: "12" } },
                            [
                              _c("v-label", { staticClass: "mb-4 d-flex" }, [
                                _vm._v(_vm._s(_vm.$t("editCampaign.app"))),
                              ]),
                              _c(
                                "div",
                                { staticClass: "app-field d-flex mb-0" },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      required: "",
                                      outlined: "",
                                      dense: "",
                                      disabled: "",
                                    },
                                    model: {
                                      value: _vm.campaignDetails.appName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.campaignDetails,
                                          "appName",
                                          $$v
                                        )
                                      },
                                      expression: "campaignDetails.appName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "mt-0", attrs: { align: "center" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-0 editCampaignChips",
                              attrs: { cols: "12" },
                            },
                            [
                              _c("v-label", { staticClass: "mb-4 d-flex" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "editCampaign.countriesOrRegionsPara"
                                    )
                                  ) + " "
                                ),
                                _c("sup", { staticClass: "red--text" }, [
                                  _vm._v("*"),
                                ]),
                              ]),
                              _c("v-select", {
                                staticClass: "shadow-0",
                                attrs: {
                                  items: _vm.appCountries,
                                  solo: "",
                                  "deletable-chips": "",
                                  multiple: "",
                                  outlined: "",
                                  chips: "",
                                  dense: "",
                                  "small-chips": "",
                                  "item-text": "Name",
                                  "item-value": "Code",
                                  rules: _vm.countryRules,
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "item",
                                    fn: function (data) {
                                      return [
                                        [
                                          _c("country-flag", {
                                            attrs: {
                                              country: data.item.Code.toLowerCase(),
                                            },
                                          }),
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v(_vm._s(data.item.Name)),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                      ]
                                    },
                                  },
                                ]),
                                model: {
                                  value: _vm.campaignDetails.countriesOrRegions,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.campaignDetails,
                                      "countriesOrRegions",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "campaignDetails.countriesOrRegions",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "mt-0", attrs: { align: "center" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "pt-0", attrs: { cols: "6" } },
                            [
                              _c(
                                "div",
                                { staticClass: "field left-icon-field-area" },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "black--text mb-4 d-flex" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("editCampaign.budget")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c("v-text-field", {
                                    staticClass: "icon-append",
                                    attrs: {
                                      outlined: "",
                                      dense: "",
                                      "prepend-icon": "mdi-currency-usd",
                                    },
                                    model: {
                                      value:
                                        _vm.campaignDetails.budgetAmount.amount,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.campaignDetails.budgetAmount,
                                          "amount",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "campaignDetails.budgetAmount.amount",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "v-col",
                            { staticClass: "pt-0", attrs: { cols: "6" } },
                            [
                              _c(
                                "div",
                                { staticClass: "field left-icon-field-area" },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "black--text mb-2 d-flex" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("editCampaign.dailyCap"))
                                      ),
                                    ]
                                  ),
                                  _c("v-text-field", {
                                    staticClass: "icon-append",
                                    attrs: {
                                      required: "",
                                      outlined: "",
                                      dense: "",
                                      "prepend-icon": "mdi-currency-usd",
                                    },
                                    model: {
                                      value: _vm.dailyBudgetAmount,
                                      callback: function ($$v) {
                                        _vm.dailyBudgetAmount = $$v
                                      },
                                      expression: "dailyBudgetAmount",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "bottom right sticky-footer" },
            [
              _c(
                "v-card-actions",
                { staticClass: "justify-end mt-0 px-8 py-4" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        width: "127",
                        color: "grey",
                        depressed: "",
                        outlined: "",
                        disabled: _vm.saving,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.close()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("editCampaign.cancel")) + " ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        loading: _vm.saving,
                        disabled: !_vm.valid || _vm.saving,
                        width: "127",
                        color: "primary",
                        depressed: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.updateCampaign()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("editCampaign.save")) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }