


























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import Vue from 'vue';
import Sortable from 'sortablejs';
import XLSX from 'xlsx';
import { LSS } from '@core/services';
import { SubscriptionPermissions } from '@shared/enums';
import { decodeToken } from '@shared/utils/commom.util';
import PermissionPopup from './PermissionPopup.vue';
import moment from 'moment';
import { $loading } from 'vue/types/umd';
import {
  CREATE_BULK_ACTION,
  UPSERT_STRATEGIC_VIEW,
} from '../utils/apollo-mutations';
import { numberFormat, numberDecimal } from 'src/utils/util';
import getSymbolFromCurrency from 'currency-symbol-map';
import {
  GET_INTEGRATION,
  GET_ALL_STRATEGIC_VIEW,
} from '../utils/apollo-queries';
import { error } from 'console';
function watchClass(targetNode, classToWatch) {
  let lastClassState = targetNode.classList.contains(classToWatch);
  const observer = new MutationObserver((mutationsList) => {
    for (let i = 0; i < mutationsList.length; i++) {
      const mutation = mutationsList[i];
      if (
        mutation.type === 'attributes' &&
        mutation.attributeName === 'class'
      ) {
        const currentClassState = mutation.target.classList.contains(
          classToWatch
        );
        if (lastClassState !== currentClassState) {
          lastClassState = currentClassState;
          if (!currentClassState) {
            mutation.target.classList.add('sortHandle');
          }
        }
      }
    }
  });
  observer.observe(targetNode, { attributes: true });
}

export default Vue.extend({
  name: 'DataTable',
  components: { PermissionPopup },
  props: {
    headers: {
      type: Array,
      default: function () {
        return [];
      },
    },
    items: {
      type: Array,
      default: function () {
        return [];
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    tab: {
      type: String,
      default: '',
    },
    tabInfo: {
      type: String,
      default: null,
    },
    tabindex: {
      type: Number,
    },
    customView: String,
    customTab: {
      type: String,
      default: null,
    },
    itemsLength: {
      type: Number,
      default: 5,
    },
    sortBy: {
      type: String,
      default: null,
    },
    sortDesc: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      /**
       * Currency to use when it's not directly accessible from campaign data in template.
       * e.g "totals" row at the bottom doesn't get to directly access campaign data in the template.
       */
      componentCurrency: '',
      custom: false,
      localItems: this.items,
      addToStrategyModal: false,
      existingStrategies: [],
      selectedStrategy: '',
      cautionMessage: [],
      addEntitiesPopup: false,
      prevHeaderValue: [],
      isUpdatingCustomDataset: false,
      goalAttr: [
        'calcGoalsRevenue',
        'calcEventCount',
        'calcCostPerGoal',
        'calcGoalRoas',
        'calcRevenuePerGoal',
        'calcARPU',
        'calcAMPU',
        'calcEventCountSec',
        'calcCostPerGoalSec',
      ],
      progressBar: false,
      searchInput: false,
      loadEnabled: 100,
      appsFilter: [],
      accountsFilter: [],
      searchTermFilter: [],
      //campaign
      campaignsSearchLiveFilter: [],
      campaignsSearchPausedFilter: [],
      campaignsSearchDeletedFilter: [],
      campaignsSearchAllFilter: [],
      //adgroup
      adgroupSearchLiveFilter: [],
      adgroupSearchPausedFilter: [],
      adgroupSearchDeletedFilter: [],
      adgroupSearchAllFilter: [],

      //negKeywords
      negKeywordSearchLiveFilter: [],
      negKeywordSearchPausedFilter: [],
      negKeywordSearchDeletedFilter: [],
      negKeywordSearchAllFilter: [],

      //ads
      adsdSearchLiveFilter: [],
      adsdSearchPausedFilter: [],
      adsdSearchDeletedFilter: [],
      adsdSearchAllFilter: [],
      //keywords
      liveKeywordSearchFilter: [],
      pausedKeywordSearchFilter: [],
      deletedKeywordSearchFilter: [],
      allKeywordSearchFilter: [],

      textBox: '',
      searchLoading: false,
      multiSearch: {},
      message: '',
      singleSelect: false,
      selectedItems: [],
      anIncreasingNumber: 1,
      finalHeaders: [],
      fileName: 'SAM',
      fileType: 'csv',
      sheetName: 'SAM',
      headerView: [{ text: 'Custom' }, { text: 'Default' }],
      selectedHeaderView: 'Custom',
      goalsHeaders: null,
      SubscriptionPermissionsEnums: SubscriptionPermissions,
      permissionMetadata: {},
      pagination: {},
      tableHeight: '18rem',

      accountsSearch: {
        total: {
          localSpend: {},
          avgCPA: {},
          avgCPT: {},
          avgCPM: {},
        },
      },
      appsSearch: {
        total: {
          localSpend: {},
          avgCPA: {},
          avgCPT: {},
          avgCPM: {},
        },
        metadata: {
          app: {},
        },
      },
      campaignsSearchLive: {
        total: {
          localSpend: {},
          avgCPA: {},
          avgCPT: {},
          avgCPM: {},
        },
        campaignMetadata: {
          dailyBudgetAmount: {},
          budgetAmount: {},
        },
        metadata: {
          app: {},
        },
      },
      campaignsSearchPaused: {
        total: {
          localSpend: {},
          avgCPA: {},
          avgCPT: {},
          avgCPM: {},
        },
        campaignMetadata: {
          dailyBudgetAmount: {},
          budgetAmount: {},
        },
        metadata: {
          app: {},
        },
      },
      campaignsSearchDeleted: {
        total: {
          localSpend: {},
          avgCPA: {},
          avgCPT: {},
          avgCPM: {},
        },
        campaignMetadata: {
          dailyBudgetAmount: {},
          budgetAmount: {},
        },
        metadata: {
          app: {},
        },
      },
      campaignsSearchAll: {
        total: {
          localSpend: {},
          avgCPA: {},
          avgCPT: {},
          avgCPM: {},
        },
        campaignMetadata: {
          dailyBudgetAmount: {},
          budgetAmount: {},
        },
        metadata: {
          app: {},
        },
      },
      campaignsSearchDraft: {},

      adgroupSearchLive: {
        adgroupMetadata: {},
        campaignMetadata: {},
        total: {
          localSpend: {},
          avgCPA: {},
          avgCPT: {},
          avgCPM: {},
        },
      },
      adgroupSearchPaused: {
        adgroupMetadata: {},
        campaignMetadata: {},
        total: {
          localSpend: {},
          avgCPA: {},
          avgCPT: {},
          avgCPM: {},
        },
      },
      adgroupSearchDeleted: {
        adgroupMetadata: {},
        campaignMetadata: {},
        total: {
          localSpend: {},
          avgCPA: {},
          avgCPT: {},
          avgCPM: {},
        },
      },
      adgroupSearchAll: {
        adgroupMetadata: {},
        campaignMetadata: {},
        total: {
          localSpend: {},
          avgCPA: {},
          avgCPT: {},
          avgCPM: {},
        },
      },

      searchTermSearch: {
        total: {
          localSpend: {},
          avgCPA: {},
          avgCPT: {},
          avgCPM: {},
        },
        metadata: {},
      },

      negKeywordSearchLive: {},
      negKeywordSearchPaused: {},
      negKeywordSearchDeleted: {},
      negKeywordSearchAll: {},

      adsdSearchLive: {
        total: {
          localSpend: {},
          avgCPA: {},
          avgCPT: {},
          avgCPM: {},
        },
        adsMetadata: {},
        adgroupMetadata: {},
        campaignMetadata: {},
      },

      adsdSearchPaused: {
        total: {
          localSpend: {},
          avgCPA: {},
          avgCPT: {},
          avgCPM: {},
        },
        adsMetadata: {},
        adgroupMetadata: {},
        campaignMetadata: {},
      },
      adsdSearchDeleted: {
        total: {
          localSpend: {},
          avgCPA: {},
          avgCPT: {},
          avgCPM: {},
        },
        adsMetadata: {},
        adgroupMetadata: {},
        campaignMetadata: {},
      },

      adsdSearchAll: {
        total: {
          localSpend: {},
          avgCPA: {},
          avgCPT: {},
          avgCPM: {},
        },
        adsMetadata: {},
        adgroupMetadata: {},
        campaignMetadata: {},
      },

      liveKeywordSearch: {
        total: {
          localSpend: {},
          avgCPA: {},
          avgCPT: {},
          avgCPM: {},
        },
        keywordMetadata: {
          bidAmount: {},
        },
        metadata: {},
        campaignMetadata: {},
      },
      pausedKeywordSearch: {
        total: {
          localSpend: {},
          avgCPA: {},
          avgCPT: {},
          avgCPM: {},
        },
        keywordMetadata: {
          bidAmount: {},
        },
        metadata: {},
        campaignMetadata: {},
      },
      deletedKeywordSearch: {
        total: {
          localSpend: {},
          avgCPA: {},
          avgCPT: {},
          avgCPM: {},
        },
        keywordMetadata: {
          bidAmount: {},
        },
        metadata: {},
        campaignMetadata: {},
      },
      allKeywordSearch: {
        total: {
          localSpend: {},
          avgCPA: {},
          avgCPT: {},
          avgCPM: {},
        },
        keywordMetadata: {
          bidAmount: {},
        },
        metadata: {},
        campaignMetadata: {},
      },
    };
  },

  computed: {
    strategyOptions() {
      const existingOpt = this.existingStrategies.map((strategy) => ({
        text: strategy.name,
        value: strategy.strategyId,
      }));
      const createNewOption = {
        text: 'Create a New Strategy',
        value: 'createNew',
      };
      return [createNewOption, ...existingOpt];
    },
    filteredData() {
      if (this.multiSearch) {
        return this.rowItems.filter((item) => {
          return Object.entries(this.multiSearch).every(([key, value]) => {
            return (item[key] || '')
              .toString()
              .toUpperCase()
              .includes(value.toString().toUpperCase());
          });
        });
      } else {
        return this.rowItems;
      }
    },
    permissions: {
      cache: false,
      get() {
        if (decodeToken(LSS.token)) {
          if (decodeToken(LSS.token).Role == 'SUPERADMIN') {
            return [];
          } else {
            return decodeToken(LSS.token).permissions
              ? decodeToken(LSS.token).permissions
              : [];
          }
        }
      },
    },
    isSuperAdmin: {
      cache: false,
      get() {
        if (
          decodeToken(LSS.token) &&
          decodeToken(LSS.token).Role == 'SUPERADMIN'
        ) {
          return true;
        }
        return false;
      },
    },
    selectedHeaders: {
      get() {
        if (LSS.isGoalSelected) {
          if (
            this.tabindex == 3 ||
            this.tabindex == 4 ||
            this.tabindex == 5 ||
            this.tabindex == 6
          ) {
            this.selectedHeaderView = 'Goals';
          }
          if (
            this.tabindex == 1 ||
            this.tabindex == 2 ||
            this.tabindex == 7 ||
            this.tabindex == 8 ||
            this.tabInfo == 'draftcampaign'
          ) {
            this.selectedHeaderView = LSS.selectedDefaultView
              ? LSS.selectedDefaultView
              : 'Default';
          }
        } else {
          this.selectedHeaderView = LSS.selectedDefaultView
            ? LSS.selectedDefaultView
            : 'Default';
        }
        const goalData = [{ text: 'Goals' }];
        if (
          this.tabindex == 3 ||
          this.tabindex == 4 ||
          this.tabindex == 5 ||
          this.tabindex == 6
        ) {
          this.headerView.push(...goalData);
        } else {
          this.headerView = this.headerView.splice(0, 2);
        }

        const goalsHead = [];
        this.headers.forEach((column) => {
          if (this.tabindex == 3 && column.text == 'Campaign') {
            goalsHead.push(column);
          } else if (this.tabindex == 4 && column.text == 'Ad Group') {
            goalsHead.push(column);
          } else if (this.tabindex == 5 && column.text == 'Keyword') {
            goalsHead.push(column);
          } else if (this.tabindex == 6 && column.text == 'Ad') {
            goalsHead.push(column);
          } else if (
            column.text.toLowerCase().includes('goal') ||
            column.text == 'Installs' ||
            column.text.toLowerCase().includes('spend')
          ) {
            goalsHead.push(column);
          }
        });
        this.goalsHeaders = goalsHead;
        if (this.selectedHeaderView == 'Goals') {
          return this.goalsHeaders;
        }

        if (this.selectedHeaderView == 'Default') {
          this.finalHeaders = this.headers.slice(0, 10);
          return this.headers.slice(0, 10);
        }

        // if (LSS.datatableDatasets) {
        //   return this.selectedDatasetsFromLSS();
        if (LSS.customViews) {
          this.selectedCustomViewFromLSS();
          return this.finalHeaders;
        } else {
          this.finalHeaders = this.headers.slice(0, 10);
          return this.headers.slice(0, 10);
        }
      },
      set(val) {
        this.prevHeaderValue = this.finalHeaders;
        if (LSS.persisteDataset == 'Goals') {
          if (
            this.tabindex == 3 ||
            this.tabindex == 4 ||
            this.tabindex == 5 ||
            this.tabindex == 6
          ) {
            this.selectedHeaderView = 'Goals';
            LSS.isGoalSelected = true;
          }
          if (
            this.tabindex == 1 ||
            this.tabindex == 2 ||
            this.tabindex == 7 ||
            this.tabindex == 8 ||
            this.tabInfo == 'draftcampaign'
          ) {
            this.selectedHeaderView = 'Default';
          }
        }

        if (this.selectedHeaderView == 'Goals') {
          if (this.goalsHeaders && val.length == this.goalsHeaders.length) {
            this.finalHeaders = val;
            const arr = [];
            this.finalHeaders.map((column) => {
              arr.push(column.value);
            });

            this.$emit('customViewsTable', {
              tabInfo: this.tabInfo,
              selectedDataTable: arr,
              customTab: this.customTab,
            });
          } else {
            LSS.selectedDefaultView = 'Custom';
            LSS.isGoalSelected = false;
            this.selectedHeaderView = 'Custom';
            this.finalHeaders = val;
            const arr = [];
            this.finalHeaders.map((column) => {
              arr.push(column.value);
            });

            this.$emit('persistDatatable', {
              tabInfo: this.tabInfo,
              selectedDataTable: arr,
            });

            this.$emit('customViewsTable', {
              tabInfo: this.tabInfo,
              selectedDataTable: arr,
              customTab: this.customTab,
            });
            if (this.prevHeaderValue.length != val.length) {
              this.isUpdatingCustomDataset = false;
            }
            if (
              this.selectedHeaderView === 'Custom' &&
              !this.isUpdatingCustomDataset
            ) {
              this.isUpdatingCustomDataset = true;
              this.$root.$emit('custom-dataset-update', val);
            }
          }
        } else if (this.selectedHeaderView == 'Default') {
          if (val.length == 10) {
            this.finalHeaders = val;
            const arr = [];
            this.finalHeaders.map((column) => {
              arr.push(column.value);
            });
          } else {
            this.selectedHeaderView = 'Custom';
            this.finalHeaders = val;
            const arr = [];
            this.finalHeaders.map((column) => {
              arr.push(column.value);
            });

            this.$emit('persistDatatable', {
              tabInfo: this.tabInfo,
              selectedDataTable: arr,
            });
            this.$emit('customViewsTable', {
              tabInfo: this.tabInfo,
              selectedDataTable: arr,
              customTab: this.customTab,
            });
            if (this.prevHeaderValue.length != val.length) {
              this.isUpdatingCustomDataset = false;
            }
            if (
              this.selectedHeaderView === 'Custom' &&
              !this.isUpdatingCustomDataset
            ) {
              this.isUpdatingCustomDataset = true;
              this.$root.$emit('custom-dataset-update', val);
            }
          }
        } else {
          this.selectedHeaderView = 'Custom';
          this.finalHeaders = val;
          const arr = [];
          this.finalHeaders.map((column) => {
            arr.push(column.value);
          });

          this.$emit('persistDatatable', {
            tabInfo: this.tabInfo,
            selectedDataTable: arr,
          });
          this.$emit('customViewsTable', {
            tabInfo: this.tabInfo,
            selectedDataTable: arr,
            customTab: this.customTab,
          });
          if (this.prevHeaderValue.length != val.length) {
            this.isUpdatingCustomDataset = false;
          }
          if (
            this.selectedHeaderView === 'Custom' &&
            !this.isUpdatingCustomDataset
          ) {
            this.isUpdatingCustomDataset = true;
            this.$root.$emit('custom-dataset-update', val);
          }
        }
      },
    },

    formattedItems() {
      if (this.loading == true) {
        this.localItems = [];

        return;
      }

      return this.items.map((item) => {
        const newItem = { ...item };
        newItem.isSelectable =
          (item.keywordMetadata?.deleted ??
            item.adgroupMetadata?.deleted ??
            item.campaignMetadata?.deleted) == true ||
          item.keywordMetadata?.text == 'null'
            ? false
            : true;
        if (item.total) {
          if(!this.componentCurrency) this.componentCurrency = item.orgMetadata?.currency;
        }
        return newItem;
      });
    },
  },

  watch: {
    selectedStrategy: 'checkForMatchingIds',
    pagination: {
      handler(value) {
        this.handleScroll(value);
        this.$emit('pageChange', value);
      },
    },
    finalHeaders(v) {
      const finalHeaders = v.map((e) => e.value);
      let getGoals = false;
      getGoals = this.goalAttr.some((el) => finalHeaders.includes(el));
      this.$root.$emit('update-api', {
        getGoals: getGoals,
        tabInfo: this.tabInfo,
      });
    },

    selectedHeaderView(val) {
      LSS.persisteDataset = val;

      if (val != 'Goals') {
        if (
          this.tabindex == 3 ||
          this.tabindex == 4 ||
          this.tabindex == 5 ||
          this.tabindex == 6
        ) {
          this.$root.$emit('datatable-view-update');
        }
      }
      if (val == 'Goals') {
        this.$root.$emit('datatable-view-update');
        this.finalHeaders = this.goalsHeaders;
        this.selectedHeaders = this.goalsHeaders.slice(0, 10);
      } else if (val == 'Custom') {
        LSS.selectedDefaultView = val;
        if (LSS.customViews) {
          return this.selectedCustomViewFromLSS();
        } else {
          this.finalHeaders = this.headers.slice(0, 10);
          return this.headers.slice(0, 10);
        }
      } else {
        LSS.selectedDefaultView = val;
        this.finalHeaders = this.headers.slice(0, 10);
        return this.headers.slice(0, 10);
      }
    },
  },

  created() {
    this.startedTyping = _.debounce(this.startedTyping, 3000, {
      leading: true,
      trailing: false,
    });
    this.stoppedTyping = _.debounce(this.stoppedTyping, 3000, {
      leading: false,
      trailing: true,
    });
  },
  methods: {
    numberDecimal,
    numberFormat,
    handleStrategyChange() {
      if (this.selectedStrategy === 'createNew') {
        this.createNewStrategy();
      }
    },
    createNewStrategy() {
      if (this.customTab == 'campaigns') {
        const extractedValues = this.selectedItems.map((item) => ({
          appId: item.metadata.app.adamId,
          type: 'CAMPAIGN',
          appName: item.metadata.app.appName,
          name: item.campaignMetadata.name,
          id: item.campaignMetadata.id,
        }));
        this.$router.push({
          path: '/strategies',
          query: { extractedValues: extractedValues },
        });
      }
      if (this.customTab == 'adgroups') {
        const extractedValues = this.selectedItems.map((item) => ({
          appId: item.metadata.app.adamId,
          type: 'ADGROUP',
          appName: item.metadata.app.appName,
          name: item.adgroupMetadata.name,
          id: item.adgroupMetadata.id,
        }));
        this.$router.push({
          path: '/strategies',
          query: { extractedValues: extractedValues },
        });
      }
      if (this.customTab == 'keywords') {
        const extractedValues = this.selectedItems.map((item) => ({
          appId: item.metadata.app.adamId,
          type: 'KEYWORD',
          appName: item.metadata.app.appName,
          name: item.keywordMetadata.text,
          id: item.keywordMetadata.id,
        }));
        this.$router.push({
          path: '/strategies',
          query: { extractedValues: extractedValues },
        });
      }
    },
    closeDialog() {
      this.addToStrategyModal = false;
      this.selectedStrategy = '';
      this.cautionMessage = [];
    },
    checkForMatchingIds() {
      if (this.customTab == 'campaigns') {
        let existingEntites = [];
        this.existingStrategies.map((strategy) => {
          strategy.targetEntities.map((TE) => {
            if (TE.type == 'CAMPAIGN') {
              this.selectedItems.map((item) => {
                if (item.campaignMetadata.id == TE.id) {
                  existingEntites.push({
                    strategy: strategy.name,
                    name: item.campaignMetadata.name,
                  });
                }
              });
            }
          });
        });
        this.cautionMessage = existingEntites
          .map((matchedItem) => {
            return `'${matchedItem.strategy}' contains  '${matchedItem.name}'`;
          })
          .join('\n');
      }
      if (this.customTab == 'adgroups') {
        let existingEntites = [];
        this.existingStrategies.map((strategy) => {
          strategy.targetEntities.map((TE) => {
            if (TE.type == 'ADGROUP') {
              this.selectedItems.map((item) => {
                if (item.adgroupMetadata.id == TE.id) {
                  existingEntites.push({
                    strategy: strategy.name,
                    name: item.adgroupMetadata.name,
                  });
                }
              });
            }
          });
        });
        this.cautionMessage = existingEntites
          .map((matchedItem) => {
            return `'${matchedItem.strategy}' contains  '${matchedItem.name}'`;
          })
          .join('\n');
      }
      if (this.customTab == 'keywords') {
        let existingEntites = [];
        this.existingStrategies.map((strategy) => {
          strategy.targetEntities.map((TE) => {
            if (TE.type == 'KEYWORD') {
              this.selectedItems.map((item) => {
                if (item.keywordMetadata.id == TE.id) {
                  existingEntites.push({
                    strategy: strategy.name,
                    name: item.keywordMetadata.text,
                  });
                }
              });
            }
          });
        });
        this.cautionMessage = existingEntites
          .map((matchedItem) => {
            return `'${matchedItem.strategy}' contains  '${matchedItem.name}'`;
          })
          .join('\n');
      }
    },
    async addEntities() {
      try {
        const selectedStrategy = this.existingStrategies.find(
          (strategy) => strategy.strategyId === this.selectedStrategy
        );
        if (this.customTab == 'campaigns') {
          const extractedValues = this.selectedItems.map((item) => ({
            appId: item.metadata.app.adamId,
            type: 'CAMPAIGN',
            appName: item.metadata.app.appName,
            name: item.campaignMetadata.name,
            id: item.campaignMetadata.id,
          }));

          await this.$apollo.mutate({
            mutation: UPSERT_STRATEGIC_VIEW,
            variables: {
              strategy: {
                strategyId: selectedStrategy ? selectedStrategy.strategyId : '',
                name: selectedStrategy ? selectedStrategy.name : '',
                createdBy: selectedStrategy ? selectedStrategy.createdBy : '',
                deleted: selectedStrategy ? selectedStrategy.deleted : false,
                description: selectedStrategy
                  ? selectedStrategy.description
                  : '',
                goals: selectedStrategy ? selectedStrategy.goals : [],
                targetEntities: [
                  // Keep existing targetEntities
                  ...(selectedStrategy
                    ? selectedStrategy.targetEntities.filter(
                        (existingEntity) =>
                          !extractedValues.some(
                            (newValue) => newValue.id === existingEntity.id
                          )
                      )
                    : []),
                  // Add new targetEntities
                  ...extractedValues.map((value) => ({
                    appId: value.appId,
                    type: value.type,
                    appName: value.appName,
                    name: value.name,
                    id: value.id,
                  })),
                ],
              },
            },
          });
          this.selectedStrategy = selectedStrategy;
          this.addEntitiesPopup = true;
          this.addToStrategyModal = false;
        }
        if (this.customTab == 'adgroups') {
          const extractedValues = this.selectedItems.map((item) => ({
            appId: item.metadata.app.adamId,
            type: 'ADGROUP',
            appName: item.metadata.app.appName,
            name: item.adgroupMetadata.name,
            id: item.adgroupMetadata.id,
          }));

          await this.$apollo.mutate({
            mutation: UPSERT_STRATEGIC_VIEW,
            variables: {
              strategy: {
                strategyId: selectedStrategy ? selectedStrategy.strategyId : '',
                name: selectedStrategy ? selectedStrategy.name : '',
                createdBy: selectedStrategy ? selectedStrategy.createdBy : '',
                deleted: selectedStrategy ? selectedStrategy.deleted : false,
                description: selectedStrategy
                  ? selectedStrategy.description
                  : '',
                goals: selectedStrategy ? selectedStrategy.goals : [],
                targetEntities: [
                  // Keep existing targetEntities
                  ...(selectedStrategy
                    ? selectedStrategy.targetEntities.filter(
                        (existingEntity) =>
                          !extractedValues.some(
                            (newValue) => newValue.id === existingEntity.id
                          )
                      )
                    : []),
                  // Add new targetEntities
                  ...extractedValues.map((value) => ({
                    appId: value.appId,
                    type: value.type,
                    appName: value.appName,
                    name: value.name,
                    id: value.id,
                  })),
                ],
              },
            },
          });
          this.selectedStrategy = selectedStrategy;
          this.addEntitiesPopup = true;
          this.addToStrategyModal = false;
        }
        if (this.customTab == 'keywords') {
          const extractedValues = this.selectedItems
            .map((item) => {
              if (
                item.metadata &&
                item.metadata.app &&
                item.metadata.app.adamId
              ) {
                return {
                  appId: item.metadata.app.adamId,
                  type: 'KEYWORD',
                  appName: item.metadata.app.appName,
                  name: item.keywordMetadata.text,
                  id: item.keywordMetadata.id,
                };
              } else {
                return null;
              }
            })
            .filter(Boolean);

          await this.$apollo.mutate({
            mutation: UPSERT_STRATEGIC_VIEW,
            variables: {
              strategy: {
                strategyId: selectedStrategy ? selectedStrategy.strategyId : '',
                name: selectedStrategy ? selectedStrategy.name : '',
                createdBy: selectedStrategy ? selectedStrategy.createdBy : '',
                deleted: selectedStrategy ? selectedStrategy.deleted : false,
                description: selectedStrategy
                  ? selectedStrategy.description
                  : '',
                goals: selectedStrategy ? selectedStrategy.goals : [],
                targetEntities: [
                  // Keep existing targetEntities
                  ...(selectedStrategy
                    ? selectedStrategy.targetEntities.filter(
                        (existingEntity) =>
                          !extractedValues.some(
                            (newValue) => newValue.id === existingEntity.id
                          )
                      )
                    : []),
                  // Add new targetEntities
                  ...extractedValues.map((value) => ({
                    appId: value.appId,
                    type: value.type,
                    appName: value.appName,
                    name: value.name,
                    id: value.id,
                  })),
                ],
              },
            },
          });
          this.selectedStrategy = selectedStrategy;
          this.addEntitiesPopup = true;
          this.addToStrategyModal = false;
        }
      } catch (error) {
        console.error('Error adding entities:', error);
      }
    },
    returnsToAdsManager() {
      this.addEntitiesPopup = false;
      this.addToStrategyModal = false;
      this.selectedStrategy = '';
      this.selectedItems = [];
    },
    viewStrategies() {
      const strategyId = this.selectedStrategy.strategyId;
      this.$router.push({
        path: '/strategies',
        query: {
          existingStrategies: this.existingStrategies,
          strategyId: strategyId,
        },
      });
    },
    async getStrategies() {
      this.fullscreenLoader = this.$loading.show();
      this.$apollo
        .query({
          query: GET_ALL_STRATEGIC_VIEW,
          fetchPolicy: 'network-only',
          variables: {},
        })
        .then((res) => {
          this.existingStrategies = res.data.getAllStrategies;
          this.addToStrategyModal = true;
          this.fullscreenLoader.hide();
        })
        .catch((error) => {
          console.log('error getting stratagies', error);
        });
    },
    headerViewChanged(val) {
      if (
        this.tabindex == 3 ||
        this.tabindex == 4 ||
        this.tabindex == 5 ||
        this.tabindex == 6
      ) {
        if (val != 'Goals') {
          LSS.isGoalSelected = false;
        }
      }
      if (
        this.tabindex == 1 ||
        this.tabindex == 2 ||
        this.tabindex == 7 ||
        this.tabindex == 8 ||
        this.tabInfo == 'draftcampaign'
      ) {
        LSS.isGoalSelected = false;
      }
    },
    addChips(headerValue, event) {
      if (event.target.value) {
        switch (this.tabInfo) {
          case 'accounts':
            this.accountsFilter[headerValue] = event.target.value;

          case 'apps':
            this.appsFilter[headerValue] = event.target.value;

          case 'searchterm':
            this.searchTermFilter[headerValue] = event.target.value;
          case 'livecampaign':
            this.campaignsSearchLiveFilter[headerValue] = event.target.value;
          case 'pausedcampaign':
            this.campaignsSearchPausedFilter[headerValue] = event.target.value;
          case 'deletedcampaign':
            this.campaignsSearchDeletedFilter[headerValue] = event.target.value;
          case 'allcampaign':
            this.campaignsSearchAllFilter[headerValue] = event.target.value;
          case 'liveadgroup':
            this.adgroupSearchLiveFilter[headerValue] = event.target.value;
          case 'pausedadgroup':
            this.adgroupSearchPausedFilter[headerValue] = event.target.value;
          case 'deletedadgroup':
            this.adgroupSearchDeletedFilter[headerValue] = event.target.value;
          case 'alladgroup':
            this.adgroupSearchAllFilter[headerValue] = event.target.value;
          case 'livekeyword':
            this.liveKeywordSearchFilter[headerValue] = event.target.value;
          case 'pausedkeyword':
            this.pausedKeywordSearchFilter[headerValue] = event.target.value;
          case 'deletedkeyword':
            this.deletedKeywordSearchFilter[headerValue] = event.target.value;
          case 'allkeyword':
            this.allKeywordSearchFilter[headerValue] = event.target.value;
          case 'ads':
            this.adsdSearchLiveFilter[headerValue] = event.target.value;
          case 'pausedads':
            this.adsdSearchPausedFilter[headerValue] = event.target.value;
          case 'deletedads':
            this.adsdSearchDeletedFilter[headerValue] = event.target.value;
          case 'allads':
            this.adsdSearchAllFilter[headerValue] = event.target.value;
          case 'livenegativekeyword':
            this.negKeywordSearchLiveFilter[headerValue] = event.target.value;
          case 'pausednegativekeyword':
            this.negKeywordSearchPausedFilter[headerValue] = event.target.value;
          case 'deletednegativekeyword':
            this.negKeywordSearchDeletedFilter[headerValue] =
              event.target.value;
          case 'allnegativekeyword':
            this.negKeywordSearchAllFilter[headerValue] = event.target.value;
        }
      }
    },
    debouncedInput(headerValue, event, key) {
      let filterinput = headerValue.split('.');
      switch (filterinput[0]) {
        case 'total':
          var ASCIICode = event.which ? event.which : event.keyCode;
          if (ASCIICode === 8 || ASCIICode === 37 || ASCIICode === 39) {
            // return true
          } else if (/[^0-9\.]/g.test(event.key)) {
            event.preventDefault();
          }
          break;
        case 'keywordMetadata':
          if (headerValue == 'keywordMetadata.bidAmount.amount') {
            var ASCIICode = event.which ? event.which : event.keyCode;
            if (ASCIICode === 8 || ASCIICode === 37 || ASCIICode === 39) {
              // return true
            } else if (/[^0-9\.]/g.test(event.key)) {
              event.preventDefault();
            }
          }

          break;
        case 'campaignMetadata':
          if (
            headerValue == 'campaignMetadata.budgetAmount.amount' ||
            headerValue == 'campaignMetadata.dailyBudgetAmount.amount'
          ) {
            var ASCIICode = event.which ? event.which : event.keyCode;
            if (ASCIICode === 8 || ASCIICode === 37 || ASCIICode === 39) {
              // return true
            } else if (/[^0-9\.]/g.test(event.key)) {
              event.preventDefault();
            }
          }
          break;
      }
      this.localItems = [];
      this.startedTyping(key);
      this.stoppedTyping(headerValue, event);
    },
    startedTyping(key) {
      this.loadEnabled = key;
    },
    stoppedTyping(headerValue, event) {
      this.loadEnabled = 99;
      this.addChips(headerValue, event);
      this.searchFilter(headerValue, event);
    },
    removedFilter(headerValue, event, key) {
      this.localItems = [];
      this.multiSearch[headerValue] = '';
      let splitVal = headerValue.split('.');
      switch (this.tabInfo) {
        case 'accounts':
          delete this.accountsFilter[headerValue];
          if (splitVal.length == 2 && splitVal[0] == 'total') {
            this.accountsSearch[splitVal[0]][splitVal[1]] = null;
          } else if (splitVal.length == 3 && splitVal[0] == 'total') {
            this.accountsSearch[splitVal[0]][splitVal[1]][splitVal[2]] = null;
          } else {
            this.accountsSearch[headerValue] = null;
          }
          return this.$emit('searchAccountCols', this.accountsSearch);
        case 'apps':
          delete this.appsFilter[headerValue];
          if (splitVal.length == 2 && splitVal[0] == 'total') {
            this.appsSearch[splitVal[0]][splitVal[1]] = null;
          } else if (splitVal.length == 3 && splitVal[0] == 'total') {
            this.appsSearch[splitVal[0]][splitVal[1]][splitVal[2]] = null;
          } else if (headerValue == 'metadata.app.appName')
            this.appsSearch['metadata']['app']['appName'] = '';
          else if (headerValue == 'metadata.app.adamId')
            this.appsSearch['metadata']['app']['adamId'] = '';
          else this.appsSearch[headerValue] = '';
          return this.$emit('searchAppsCols', this.appsSearch);

        case 'searchterm':
          delete this.searchTermFilter[headerValue];
          if (splitVal.length == 2 && splitVal[0] == 'total') {
            this.searchTermSearch[splitVal[0]][splitVal[1]] = null;
          } else if (splitVal.length == 3 && splitVal[0] == 'total') {
            this.searchTermSearch[splitVal[0]][splitVal[1]][splitVal[2]] = null;
          } else if (headerValue == 'metadata.keyword')
            this.searchTermSearch['metadata']['keyword'] = '';
          else if (headerValue == 'metadata.adGroupId')
            this.searchTermSearch['metadata']['adGroupId'] = '';
          else if (headerValue == 'metadata.searchTermText')
            this.searchTermSearch['metadata']['searchTermText'] = '';
          else this.searchTermSearch[headerValue] = '';
          return this.$emit('searchSearchTermCols', this.searchTermSearch);

        case 'livecampaign':
          delete this.campaignsSearchLiveFilter[headerValue];
          if (splitVal.length == 2 && splitVal[0] == 'total') {
            this.campaignsSearchLive[splitVal[0]][splitVal[1]] = null;
          } else if (splitVal.length == 3 && splitVal[0] == 'total') {
            this.campaignsSearchLive[splitVal[0]][splitVal[1]][
              splitVal[2]
            ] = null;
          } else if (headerValue == 'campaignMetadata.name')
            this.campaignsSearchLive['campaignMetadata']['name'] = '';
          else if (headerValue == 'campaignMetadata.supplySources')
            this.campaignsSearchLive['campaignMetadata']['supplySources'] = '';
          else if (headerValue == 'campaignMetadata.id')
            this.campaignsSearchLive['campaignMetadata']['id'] = '';
          else if (headerValue == 'metadata.app.appName')
            this.campaignsSearchLive['metadata']['app']['appName'] = '';
          else if (headerValue == 'metadata.app.adamId')
            this.campaignsSearchLive['metadata']['app']['adamId'] = '';
          else if (headerValue == 'campaignMetadata.budgetAmount.amount')
            this.campaignsSearchLive['campaignMetadata']['budgetAmount'][
              'amount'
            ] = '';
          else if (headerValue == 'campaignMetadata.dailyBudgetAmount.amount')
            this.campaignsSearchLive['campaignMetadata']['dailyBudgetAmount'][
              'amount'
            ] = '';
          return this.$emit('campSearchLiveCols', this.campaignsSearchLive);

        case 'pausedcampaign':
          delete this.campaignsSearchPausedFilter[headerValue];
          if (splitVal.length == 2 && splitVal[0] == 'total') {
            this.campaignsSearchPaused[splitVal[0]][splitVal[1]] = null;
          } else if (splitVal.length == 3 && splitVal[0] == 'total') {
            this.campaignsSearchPaused[splitVal[0]][splitVal[1]][
              splitVal[2]
            ] = null;
          } else if (headerValue == 'campaignMetadata.name')
            this.campaignsSearchPaused['campaignMetadata']['name'] = '';
          else if (headerValue == 'campaignMetadata.supplySources')
            this.campaignsSearchPaused['campaignMetadata']['supplySources'] =
              '';
          else if (headerValue == 'campaignMetadata.id')
            this.campaignsSearchPaused['campaignMetadata']['id'] = '';
          else if (headerValue == 'metadata.app.appName')
            this.campaignsSearchPaused['metadata']['app']['appName'] = '';
          else if (headerValue == 'metadata.app.adamId')
            this.campaignsSearchPaused['metadata']['app']['adamId'] = '';
          else if (headerValue == 'campaignMetadata.budgetAmount.amount')
            this.campaignsSearchPaused['campaignMetadata']['budgetAmount'][
              'amount'
            ] = '';
          else if (headerValue == 'campaignMetadata.dailyBudgetAmount.amount')
            this.campaignsSearchPaused['campaignMetadata']['dailyBudgetAmount'][
              'amount'
            ] = '';
          return this.$emit('campSearchPausedCols', this.campaignsSearchPaused);
        case 'deletedcampaign':
          delete this.campaignsSearchDeletedFilter[headerValue];
          if (splitVal.length == 2 && splitVal[0] == 'total') {
            this.campaignsSearchDeleted[splitVal[0]][splitVal[1]] = null;
          } else if (splitVal.length == 3 && splitVal[0] == 'total') {
            this.campaignsSearchDeleted[splitVal[0]][splitVal[1]][
              splitVal[2]
            ] = null;
          } else if (headerValue == 'campaignMetadata.name')
            this.campaignsSearchDeleted['campaignMetadata']['name'] = '';
          else if (headerValue == 'campaignMetadata.supplySources')
            this.campaignsSearchDeleted['campaignMetadata']['supplySources'] =
              '';
          else if (headerValue == 'campaignMetadata.id')
            this.campaignsSearchDeleted['campaignMetadata']['id'] = '';
          else if (headerValue == 'metadata.app.appName')
            this.campaignsSearchDeleted['metadata']['app']['appName'] = '';
          else if (headerValue == 'metadata.app.adamId')
            this.campaignsSearchDeleted['metadata']['app']['adamId'] = '';
          else if (headerValue == 'campaignMetadata.budgetAmount.amount')
            this.campaignsSearchDeleted['campaignMetadata']['budgetAmount'][
              'amount'
            ] = '';
          else if (headerValue == 'campaignMetadata.dailyBudgetAmount.amount')
            this.campaignsSearchDeleted['campaignMetadata'][
              'dailyBudgetAmount'
            ]['amount'] = '';

          return this.$emit(
            'campSearchDeletedCols',
            this.campaignsSearchDeleted
          );

        case 'allcampaign':
          delete this.campaignsSearchAllFilter[headerValue];
          if (splitVal.length == 2 && splitVal[0] == 'total') {
            this.campaignsSearchAll[splitVal[0]][splitVal[1]] = null;
          } else if (splitVal.length == 3 && splitVal[0] == 'total') {
            this.campaignsSearchAll[splitVal[0]][splitVal[1]][
              splitVal[2]
            ] = null;
          } else if (headerValue == 'campaignMetadata.name')
            this.campaignsSearchAll['campaignMetadata']['name'] = '';
          else if (headerValue == 'campaignMetadata.supplySources')
            this.campaignsSearchAll['campaignMetadata']['supplySources'] = '';
          else if (headerValue == 'campaignMetadata.id')
            this.campaignsSearchAll['campaignMetadata']['id'] = '';
          else if (headerValue == 'metadata.app.appName')
            this.campaignsSearchAll['metadata']['app']['appName'] = '';
          else if (headerValue == 'metadata.app.adamId')
            this.campaignsSearchAll['metadata']['app']['adamId'] = '';
          else if (headerValue == 'campaignMetadata.budgetAmount.amount')
            this.campaignsSearchAll['campaignMetadata']['budgetAmount'][
              'amount'
            ] = '';
          else if (headerValue == 'campaignMetadata.dailyBudgetAmount.amount')
            this.campaignsSearchAll['campaignMetadata']['dailyBudgetAmount'][
              'amount'
            ] = '';

          return this.$emit('campSearchAllCols', this.campaignsSearchAll);
        case 'draftcampaign':
          delete this.chipData[headerValue];
          this.campaignsSearchDraft[headerValue] = '';
          return this.$emit('campSearchDraftCols', this.campaignsSearchDraft);
        case 'liveadgroup':
          delete this.adgroupSearchLiveFilter[headerValue];
          if (splitVal.length == 2 && splitVal[0] == 'total') {
            this.adgroupSearchLive[splitVal[0]][splitVal[1]] = null;
          } else if (splitVal.length == 3 && splitVal[0] == 'total') {
            this.adgroupSearchLive[splitVal[0]][splitVal[1]][
              splitVal[2]
            ] = null;
          } else if (headerValue == 'adgroupMetadata.name')
            this.adgroupSearchLive['adgroupMetadata']['name'] = '';
          else if (headerValue == 'adgroupMetadata.campaignId')
            this.adgroupSearchLive['adgroupMetadata']['campaignId'] = '';
          else if (headerValue == 'campaignMetadata.adamId')
            this.adgroupSearchLive['campaignMetadata']['adamId'] = '';
          else if (headerValue == 'adgroupMetadata.id')
            this.adgroupSearchLive['adgroupMetadata']['id'] = '';
          return this.$emit('adgroupSearchLiveCols', this.adgroupSearchLive);
        case 'pausedadgroup':
          delete this.adgroupSearchPausedFilter[headerValue];
          if (splitVal.length == 2 && splitVal[0] == 'total') {
            this.adgroupSearchPaused[splitVal[0]][splitVal[1]] = null;
          } else if (splitVal.length == 3 && splitVal[0] == 'total') {
            this.adgroupSearchPaused[splitVal[0]][splitVal[1]][
              splitVal[2]
            ] = null;
          } else if (headerValue == 'adgroupMetadata.name')
            this.adgroupSearchPaused['adgroupMetadata']['name'] = '';
          else if (headerValue == 'adgroupMetadata.campaignId')
            this.adgroupSearchPaused['adgroupMetadata']['campaignId'] = '';
          else if (headerValue == 'campaignMetadata.adamId')
            this.adgroupSearchPaused['campaignMetadata']['adamId'] = '';
          else if (headerValue == 'adgroupMetadata.id')
            this.adgroupSearchPaused['adgroupMetadata']['id'] = '';

          return this.$emit(
            'adgroupSearchPausedCols',
            this.adgroupSearchPaused
          );
        case 'deletedadgroup':
          delete this.adgroupSearchDeletedFilter[headerValue];
          if (splitVal.length == 2 && splitVal[0] == 'total') {
            this.adgroupSearchDeleted[splitVal[0]][splitVal[1]] = null;
          } else if (splitVal.length == 3 && splitVal[0] == 'total') {
            this.adgroupSearchDeleted[splitVal[0]][splitVal[1]][
              splitVal[2]
            ] = null;
          } else if (headerValue == 'adgroupMetadata.name')
            this.adgroupSearchDeleted['adgroupMetadata']['name'] = '';
          else if (headerValue == 'adgroupMetadata.campaignId')
            this.adgroupSearchDeleted['adgroupMetadata']['campaignId'] = '';
          else if (headerValue == 'campaignMetadata.adamId')
            this.adgroupSearchDeleted['campaignMetadata']['adamId'] = '';
          else if (headerValue == 'adgroupMetadata.id')
            this.adgroupSearchDeleted['adgroupMetadata']['id'] = '';
          return this.$emit(
            'adgroupSearchDeletedCols',
            this.adgroupSearchDeleted
          );

        case 'alladgroup':
          delete this.adgroupSearchAllFilter[headerValue];
          if (splitVal.length == 2 && splitVal[0] == 'total') {
            this.adgroupSearchAll[splitVal[0]][splitVal[1]] = null;
          } else if (splitVal.length == 3 && splitVal[0] == 'total') {
            this.adgroupSearchAll[splitVal[0]][splitVal[1]][splitVal[2]] = null;
          } else if (headerValue == 'adgroupMetadata.name')
            this.adgroupSearchAll['adgroupMetadata']['name'] = '';
          else if (headerValue == 'adgroupMetadata.campaignId')
            this.adgroupSearchAll['adgroupMetadata']['campaignId'] = '';
          else if (headerValue == 'campaignMetadata.adamId')
            this.adgroupSearchAll['campaignMetadata']['adamId'] = '';
          else if (headerValue == 'adgroupMetadata.id')
            this.adgroupSearchAll['adgroupMetadata']['id'] = '';
          return this.$emit('adgroupSearchAllCols', this.adgroupSearchAll);
        case 'livekeyword':
          delete this.liveKeywordSearchFilter[headerValue];
          if (splitVal.length == 2 && splitVal[0] == 'total') {
            this.liveKeywordSearch[splitVal[0]][splitVal[1]] = null;
          } else if (splitVal.length == 3 && splitVal[0] == 'total') {
            this.liveKeywordSearch[splitVal[0]][splitVal[1]][
              splitVal[2]
            ] = null;
          } else if (headerValue == 'keywordMetadata.text')
            this.liveKeywordSearch['keywordMetadata']['text'] = '';
          else if (headerValue == 'keywordMetadata.id')
            this.liveKeywordSearch['keywordMetadata']['id'] = '';
          else if (headerValue == 'keywordMetadata.adGroupId')
            this.liveKeywordSearch['keywordMetadata']['adGroupId'] = '';
          else if (headerValue == 'metadata.campaignId')
            this.liveKeywordSearch['metadata']['campaignId'] = '';
          else if (headerValue == 'keywordMetadata.bidAmount.amount')
            this.liveKeywordSearch['keywordMetadata']['bidAmount']['amount'] =
              '';
          else if (headerValue == 'campaignMetadata.adamId')
            this.liveKeywordSearch['campaignMetadata']['adamId'] = '';

          return this.$emit('liveKeywordSearchCols', this.liveKeywordSearch);
        case 'pausedkeyword':
          delete this.pausedKeywordSearchFilter[headerValue];
          if (splitVal.length == 2 && splitVal[0] == 'total') {
            this.pausedKeywordSearch[splitVal[0]][splitVal[1]] = null;
          } else if (splitVal.length == 3 && splitVal[0] == 'total') {
            this.pausedKeywordSearch[splitVal[0]][splitVal[1]][
              splitVal[2]
            ] = null;
          } else if (headerValue == 'keywordMetadata.text')
            this.pausedKeywordSearch['keywordMetadata']['text'] = '';
          else if (headerValue == 'keywordMetadata.id')
            this.pausedKeywordSearch['keywordMetadata']['id'] = '';
          else if (headerValue == 'keywordMetadata.adGroupId')
            this.pausedKeywordSearch['keywordMetadata']['adGroupId'] = '';
          else if (headerValue == 'metadata.campaignId')
            this.pausedKeywordSearch['metadata']['campaignId'] = '';
          else if (headerValue == 'keywordMetadata.bidAmount.amount')
            this.pausedKeywordSearch['keywordMetadata']['bidAmount']['amount'] =
              '';
          else if (headerValue == 'campaignMetadata.adamId')
            this.pausedKeywordSearch['campaignMetadata']['adamId'] = '';

          return this.$emit(
            'pausedKeywordSearchCols',
            this.pausedKeywordSearch
          );

        case 'deletedkeyword':
          delete this.deletedKeywordSearchFilter[headerValue];
          if (splitVal.length == 2 && splitVal[0] == 'total') {
            this.deletedKeywordSearch[splitVal[0]][splitVal[1]] = null;
          } else if (splitVal.length == 3 && splitVal[0] == 'total') {
            this.deletedKeywordSearch[splitVal[0]][splitVal[1]][
              splitVal[2]
            ] = null;
          } else if (headerValue == 'keywordMetadata.text')
            this.deletedKeywordSearch['keywordMetadata']['text'] = '';
          else if (headerValue == 'keywordMetadata.id')
            this.deletedKeywordSearch['keywordMetadata']['id'] = '';
          else if (headerValue == 'keywordMetadata.adGroupId')
            this.deletedKeywordSearch['keywordMetadata']['adGroupId'] = '';
          else if (headerValue == 'metadata.campaignId')
            this.deletedKeywordSearch['metadata']['campaignId'] = '';
          else if (headerValue == 'keywordMetadata.bidAmount.amount')
            this.deletedKeywordSearch['keywordMetadata']['bidAmount'][
              'amount'
            ] = '';
          else if (headerValue == 'campaignMetadata.adamId')
            this.deletedKeywordSearch['campaignMetadata']['adamId'] = '';

          return this.$emit(
            'deletedKeywordSearchCols',
            this.deletedKeywordSearch
          );

        case 'allkeyword':
          delete this.allKeywordSearchFilter[headerValue];
          if (splitVal.length == 2 && splitVal[0] == 'total') {
            this.allKeywordSearch[splitVal[0]][splitVal[1]] = null;
          } else if (splitVal.length == 3 && splitVal[0] == 'total') {
            this.allKeywordSearch[splitVal[0]][splitVal[1]][splitVal[2]] = null;
          } else if (headerValue == 'keywordMetadata.text')
            this.allKeywordSearch['keywordMetadata']['text'] = '';
          else if (headerValue == 'keywordMetadata.id')
            this.allKeywordSearch['keywordMetadata']['id'] = '';
          else if (headerValue == 'keywordMetadata.adGroupId')
            this.allKeywordSearch['keywordMetadata']['adGroupId'] = '';
          else if (headerValue == 'metadata.campaignId')
            this.allKeywordSearch['metadata']['campaignId'] = '';
          else if (headerValue == 'keywordMetadata.bidAmount.amount')
            this.allKeywordSearch['keywordMetadata']['bidAmount']['amount'] =
              '';
          else if (headerValue == 'campaignMetadata.adamId')
            this.allKeywordSearch['campaignMetadata']['adamId'] = '';
          return this.$emit('allKeywordSearchCols', this.allKeywordSearch);
        case 'ads':
          delete this.adsdSearchLiveFilter[headerValue];
          if (splitVal.length == 2 && splitVal[0] == 'total') {
            this.adsdSearchLive[splitVal[0]][splitVal[1]] = null;
          } else if (splitVal.length == 3 && splitVal[0] == 'total') {
            this.adsdSearchLive[splitVal[0]][splitVal[1]][splitVal[2]] = null;
          } else if (headerValue == 'adsMetadata.name')
            this.adsdSearchLive['adsMetadata']['name'] = '';
          else if (headerValue == 'adsMetadata.creativeType')
            this.adsdSearchLive['adsMetadata']['creativeType'] = '';
          else if (headerValue == 'adgroupMetadata.id')
            this.adsdSearchLive['adgroupMetadata']['id'] = '';
          else if (headerValue == 'campaignMetadata.id')
            this.adsdSearchLive['campaignMetadata']['id'] = '';
          else if (headerValue == 'campaignMetadata.adamId')
            this.adsdSearchLive['campaignMetadata']['adamId'] = '';

          return this.$emit('adsSearchLiveCols', this.adsdSearchLive);
        case 'pausedads':
          delete this.adsdSearchPausedFilter[headerValue];
          if (splitVal.length == 2 && splitVal[0] == 'total') {
            this.adsdSearchPaused[splitVal[0]][splitVal[1]] = null;
          } else if (splitVal.length == 3 && splitVal[0] == 'total') {
            this.adsdSearchPaused[splitVal[0]][splitVal[1]][splitVal[2]] = null;
          } else if (headerValue == 'adsMetadata.name')
            this.adsdSearchPaused['adsMetadata']['name'] = '';
          else if (headerValue == 'adsMetadata.creativeType')
            this.adsdSearchPaused['adsMetadata']['creativeType'] = '';
          else if (headerValue == 'adgroupMetadata.id')
            this.adsdSearchPaused['adgroupMetadata']['id'] = '';
          else if (headerValue == 'campaignMetadata.id')
            this.adsdSearchPaused['campaignMetadata']['id'] = '';
          else if (headerValue == 'campaignMetadata.adamId')
            this.adsdSearchPaused['campaignMetadata']['adamId'] = '';

          return this.$emit('adsdSearchPausedCols', this.adsdSearchPaused);
        case 'deletedads':
          delete this.adsdSearchDeletedFilter[headerValue];
          if (splitVal.length == 2 && splitVal[0] == 'total') {
            this.adsdSearchDeleted[splitVal[0]][splitVal[1]] = null;
          } else if (splitVal.length == 3 && splitVal[0] == 'total') {
            this.adsdSearchDeleted[splitVal[0]][splitVal[1]][
              splitVal[2]
            ] = null;
          } else if (headerValue == 'adsMetadata.name')
            this.adsdSearchDeleted['adsMetadata']['name'] = '';
          else if (headerValue == 'adsMetadata.creativeType')
            this.adsdSearchDeleted['adsMetadata']['creativeType'] = '';
          else if (headerValue == 'adgroupMetadata.id')
            this.adsdSearchDeleted['adgroupMetadata']['id'] = '';
          else if (headerValue == 'campaignMetadata.id')
            this.adsdSearchDeleted['campaignMetadata']['id'] = '';
          else if (headerValue == 'campaignMetadata.adamId')
            this.adsdSearchDeleted['campaignMetadata']['adamId'] = '';

          return this.$emit('adsdSearchDeletedCols', this.adsdSearchDeleted);

        case 'allads':
          delete this.adsdSearchAllFilter[headerValue];
          if (splitVal.length == 2 && splitVal[0] == 'total') {
            this.adsdSearchAll[splitVal[0]][splitVal[1]] = null;
          } else if (splitVal.length == 3 && splitVal[0] == 'total') {
            this.adsdSearchAll[splitVal[0]][splitVal[1]][splitVal[2]] = null;
          } else if (headerValue == 'adsMetadata.name')
            this.adsdSearchAll['adsMetadata']['name'] = '';
          else if (headerValue == 'adsMetadata.creativeType')
            this.adsdSearchAll['adsMetadata']['creativeType'] = '';
          else if (headerValue == 'adgroupMetadata.id')
            this.adsdSearchAll['adgroupMetadata']['id'] = '';
          else if (headerValue == 'campaignMetadata.id')
            this.adsdSearchAll['campaignMetadata']['id'] = '';
          else if (headerValue == 'campaignMetadata.adamId')
            this.adsdSearchAll['campaignMetadata']['adamId'] = '';

          return this.$emit('adsdSearchAllCols', this.adsdSearchAll);

        case 'livenegativekeyword':
          delete this.negKeywordSearchLiveFilter[headerValue];
          this.negKeywordSearchLive[headerValue] = '';
          return this.$emit(
            'negKeywordSearchLiveCols',
            this.negKeywordSearchLive
          );
        case 'pausednegativekeyword':
          delete this.negKeywordSearchPausedFilter[headerValue];
          this.negKeywordSearchPaused[headerValue] = '';
          return this.$emit(
            'negKeywordSearchPausedCols',
            this.negKeywordSearchPaused
          );
        case 'deletednegativekeyword':
          delete this.negKeywordSearchDeletedFilter[headerValue];
          this.negKeywordSearchDeleted[headerValue] = '';
          return this.$emit(
            'negKeywordSearchDeletedCols',
            this.negKeywordSearchDeleted
          );
        case 'allnegativekeyword':
          delete this.negKeywordSearchAllFilter[headerValue];
          this.negKeywordSearchAll[headerValue] = '';
          return this.$emit(
            'negKeywordSearchAllCols',
            this.negKeywordSearchAll
          );
      }
    },
    getCurrencyIcon(value) {
      if (value !== undefined || value !== null) {
        return getSymbolFromCurrency(value) == undefined
          ? ''
          : getSymbolFromCurrency(value);
      }
      return '';
    },
    searchFilter(headerValue, event) {
      let splitVal = headerValue.split('.');
      switch (this.tabInfo) {
        case 'accounts':
          if (event.target.value === '') {
            if (this.accountsFilter.hasOwnProperty(headerValue)) {
              delete this.accountsFilter[headerValue];
            }
          }
          if (splitVal.length == 2 && splitVal[0] == 'total') {
            this.accountsSearch[splitVal[0]][splitVal[1]] = parseFloat(
              event.target.value
            );
          } else if (splitVal.length == 3 && splitVal[0] == 'total') {
            this.accountsSearch[splitVal[0]][splitVal[1]][
              splitVal[2]
            ] = parseFloat(event.target.value);
          } else {
            this.accountsSearch[headerValue] = event.target.value.trim();
          }
          return this.$emit('searchAccountCols', this.accountsSearch);

        case 'apps':
          if (event.target.value === '') {
            if (this.appsFilter.hasOwnProperty(headerValue)) {
              delete this.appsFilter[headerValue];
            }
          }
          if (splitVal.length == 2 && splitVal[0] == 'total') {
            this.appsSearch[splitVal[0]][splitVal[1]] = parseFloat(
              event.target.value
            );
          } else if (splitVal.length == 3 && splitVal[0] == 'total') {
            this.appsSearch[splitVal[0]][splitVal[1]][splitVal[2]] = parseFloat(
              event.target.value
            );
          } else if (headerValue == 'metadata.app.appName')
            this.appsSearch['metadata']['app'][
              'appName'
            ] = event.target.value.trim();
          else if (headerValue == 'metadata.app.adamId')
            this.appsSearch['metadata']['app'][
              'adamId'
            ] = event.target.value.trim();
          else this.appsSearch[headerValue] = event.target.value.trim();
          return this.$emit('searchAppsCols', this.appsSearch);

        case 'searchterm':
          if (event.target.value === '') {
            if (this.searchTermFilter.hasOwnProperty(headerValue)) {
              delete this.searchTermFilter[headerValue];
            }
          }
          if (splitVal.length == 2 && splitVal[0] == 'total') {
            this.searchTermSearch[splitVal[0]][splitVal[1]] = parseFloat(
              event.target.value
            );
          } else if (splitVal.length == 3 && splitVal[0] == 'total') {
            this.searchTermSearch[splitVal[0]][splitVal[1]][
              splitVal[2]
            ] = parseFloat(event.target.value);
          } else if (headerValue == 'metadata.keyword')
            this.searchTermSearch['metadata'][
              'keyword'
            ] = event.target.value.trim();
          else if (headerValue == 'metadata.adGroupId')
            this.searchTermSearch['metadata'][
              'adGroupId'
            ] = event.target.value.trim();
          else if (headerValue == 'metadata.searchTermText')
            this.searchTermSearch['metadata'][
              'searchTermText'
            ] = event.target.value.trim();
          else this.searchTermSearch[headerValue] = event.target.value.trim();
          return this.$emit('searchSearchTermCols', this.searchTermSearch);

        case 'livecampaign':
          if (event.target.value === '') {
            if (this.campaignsSearchLiveFilter.hasOwnProperty(headerValue)) {
              delete this.campaignsSearchLiveFilter[headerValue];
            }
          }
          if (splitVal.length == 2 && splitVal[0] == 'total') {
            this.campaignsSearchLive[splitVal[0]][splitVal[1]] = parseFloat(
              event.target.value
            );
          } else if (splitVal.length == 3 && splitVal[0] == 'total') {
            this.campaignsSearchLive[splitVal[0]][splitVal[1]][
              splitVal[2]
            ] = parseFloat(event.target.value);
          } else if (headerValue == 'campaignMetadata.name')
            this.campaignsSearchLive['campaignMetadata'][
              'name'
            ] = event.target.value.trim();
          else if (headerValue == 'campaignMetadata.supplySources')
            this.campaignsSearchLive['campaignMetadata'][
              'supplySources'
            ] = event.target.value.trim();
          else if (headerValue == 'campaignMetadata.id')
            this.campaignsSearchLive['campaignMetadata'][
              'id'
            ] = event.target.value.trim();
          else if (headerValue == 'metadata.app.appName')
            this.campaignsSearchLive['metadata']['app'][
              'appName'
            ] = event.target.value.trim();
          else if (headerValue == 'metadata.app.adamId')
            this.campaignsSearchLive['metadata']['app'][
              'adamId'
            ] = event.target.value.trim();
          else if (headerValue == 'campaignMetadata.budgetAmount.amount')
            this.campaignsSearchLive['campaignMetadata']['budgetAmount'][
              'amount'
            ] = event.target.value.trim();
          else if (headerValue == 'campaignMetadata.dailyBudgetAmount.amount')
            this.campaignsSearchLive['campaignMetadata']['dailyBudgetAmount'][
              'amount'
            ] = event.target.value.trim();
          return this.$emit('campSearchLiveCols', this.campaignsSearchLive);

        case 'pausedcampaign':
          if (event.target.value === '') {
            if (this.campaignsSearchPausedFilter.hasOwnProperty(headerValue)) {
              delete this.campaignsSearchPausedFilter[headerValue];
            }
          }
          if (splitVal.length == 2 && splitVal[0] == 'total') {
            this.campaignsSearchPaused[splitVal[0]][splitVal[1]] = parseFloat(
              event.target.value
            );
          } else if (splitVal.length == 3 && splitVal[0] == 'total') {
            this.campaignsSearchPaused[splitVal[0]][splitVal[1]][
              splitVal[2]
            ] = parseFloat(event.target.value);
          } else if (headerValue == 'campaignMetadata.name')
            this.campaignsSearchPaused['campaignMetadata'][
              'name'
            ] = event.target.value.trim();
          else if (headerValue == 'campaignMetadata.supplySources')
            this.campaignsSearchPaused['campaignMetadata'][
              'supplySources'
            ] = event.target.value.trim();
          else if (headerValue == 'campaignMetadata.id')
            this.campaignsSearchPaused['campaignMetadata'][
              'id'
            ] = event.target.value.trim();
          else if (headerValue == 'metadata.app.appName')
            this.campaignsSearchPaused['metadata']['app'][
              'appName'
            ] = event.target.value.trim();
          else if (headerValue == 'metadata.app.adamId')
            this.campaignsSearchPaused['metadata']['app'][
              'adamId'
            ] = event.target.value.trim();
          else if (headerValue == 'campaignMetadata.budgetAmount.amount')
            this.campaignsSearchPaused['campaignMetadata']['budgetAmount'][
              'amount'
            ] = event.target.value.trim();
          else if (headerValue == 'campaignMetadata.dailyBudgetAmount.amount')
            this.campaignsSearchPaused['campaignMetadata']['dailyBudgetAmount'][
              'amount'
            ] = event.target.value.trim();
          return this.$emit('campSearchPausedCols', this.campaignsSearchPaused);
        case 'deletedcampaign':
          if (event.target.value === '') {
            if (this.campaignsSearchDeletedFilter.hasOwnProperty(headerValue)) {
              delete this.campaignsSearchDeletedFilter[headerValue];
            }
          }
          if (splitVal.length == 2 && splitVal[0] == 'total') {
            this.campaignsSearchDeleted[splitVal[0]][splitVal[1]] = parseFloat(
              event.target.value
            );
          } else if (splitVal.length == 3 && splitVal[0] == 'total') {
            this.campaignsSearchDeleted[splitVal[0]][splitVal[1]][
              splitVal[2]
            ] = parseFloat(event.target.value);
          } else if (headerValue == 'campaignMetadata.name')
            this.campaignsSearchDeleted['campaignMetadata'][
              'name'
            ] = event.target.value.trim();
          else if (headerValue == 'campaignMetadata.supplySources')
            this.campaignsSearchDeleted['campaignMetadata'][
              'supplySources'
            ] = event.target.value.trim();
          else if (headerValue == 'campaignMetadata.id')
            this.campaignsSearchDeleted['campaignMetadata'][
              'id'
            ] = event.target.value.trim();
          else if (headerValue == 'metadata.app.appName')
            this.campaignsSearchDeleted['metadata']['app'][
              'appName'
            ] = event.target.value.trim();
          else if (headerValue == 'metadata.app.adamId')
            this.campaignsSearchDeleted['metadata']['app'][
              'adamId'
            ] = event.target.value.trim();
          else if (headerValue == 'campaignMetadata.budgetAmount.amount')
            this.campaignsSearchDeleted['campaignMetadata']['budgetAmount'][
              'amount'
            ] = event.target.value.trim();
          else if (headerValue == 'campaignMetadata.dailyBudgetAmount.amount')
            this.campaignsSearchDeleted['campaignMetadata'][
              'dailyBudgetAmount'
            ]['amount'] = event.target.value.trim();

          return this.$emit(
            'campSearchDeletedCols',
            this.campaignsSearchDeleted
          );

        case 'allcampaign':
          if (event.target.value === '') {
            if (this.campaignsSearchAllFilter.hasOwnProperty(headerValue)) {
              delete this.campaignsSearchAllFilter[headerValue];
            }
          }
          if (splitVal.length == 2 && splitVal[0] == 'total') {
            this.campaignsSearchAll[splitVal[0]][splitVal[1]] = parseFloat(
              event.target.value
            );
          } else if (splitVal.length == 3 && splitVal[0] == 'total') {
            this.campaignsSearchAll[splitVal[0]][splitVal[1]][
              splitVal[2]
            ] = parseFloat(event.target.value);
          } else if (headerValue == 'campaignMetadata.name')
            this.campaignsSearchAll['campaignMetadata'][
              'name'
            ] = event.target.value.trim();
          else if (headerValue == 'campaignMetadata.supplySources')
            this.campaignsSearchAll['campaignMetadata'][
              'supplySources'
            ] = event.target.value.trim();
          else if (headerValue == 'campaignMetadata.id')
            this.campaignsSearchAll['campaignMetadata'][
              'id'
            ] = event.target.value.trim();
          else if (headerValue == 'metadata.app.appName')
            this.campaignsSearchAll['metadata']['app'][
              'appName'
            ] = event.target.value.trim();
          else if (headerValue == 'metadata.app.adamId')
            this.campaignsSearchAll['metadata']['app'][
              'adamId'
            ] = event.target.value.trim();
          else if (headerValue == 'campaignMetadata.budgetAmount.amount')
            this.campaignsSearchAll['campaignMetadata']['budgetAmount'][
              'amount'
            ] = event.target.value.trim();
          else if (headerValue == 'campaignMetadata.dailyBudgetAmount.amount')
            this.campaignsSearchAll['campaignMetadata']['dailyBudgetAmount'][
              'amount'
            ] = event.target.value.trim();

          return this.$emit('campSearchAllCols', this.campaignsSearchAll);
        case 'draftcampaign':
          this.campaignsSearchDraft[headerValue] = event.target.value.trim();
          return this.$emit('campSearchDraftCols', this.campaignsSearchDraft);
        case 'liveadgroup':
          if (event.target.value === '') {
            if (this.adgroupSearchLiveFilter.hasOwnProperty(headerValue)) {
              delete this.adgroupSearchLiveFilter[headerValue];
            }
          }
          if (splitVal.length == 2 && splitVal[0] == 'total') {
            this.adgroupSearchLive[splitVal[0]][splitVal[1]] = parseFloat(
              event.target.value
            );
          } else if (splitVal.length == 3 && splitVal[0] == 'total') {
            this.adgroupSearchLive[splitVal[0]][splitVal[1]][
              splitVal[2]
            ] = parseFloat(event.target.value);
          } else if (headerValue == 'adgroupMetadata.name')
            this.adgroupSearchLive['adgroupMetadata'][
              'name'
            ] = event.target.value.trim();
          else if (headerValue == 'adgroupMetadata.campaignId')
            this.adgroupSearchLive['adgroupMetadata'][
              'campaignId'
            ] = event.target.value.trim();
          else if (headerValue == 'campaignMetadata.adamId')
            this.adgroupSearchLive['campaignMetadata'][
              'adamId'
            ] = event.target.value.trim();
          else if (headerValue == 'adgroupMetadata.id')
            this.adgroupSearchLive['adgroupMetadata'][
              'id'
            ] = event.target.value.trim();

          return this.$emit('adgroupSearchLiveCols', this.adgroupSearchLive);
        case 'pausedadgroup':
          if (event.target.value === '') {
            if (this.adgroupSearchPausedFilter.hasOwnProperty(headerValue)) {
              delete this.adgroupSearchPausedFilter[headerValue];
            }
          }
          if (splitVal.length == 2 && splitVal[0] == 'total') {
            this.adgroupSearchPaused[splitVal[0]][splitVal[1]] = parseFloat(
              event.target.value
            );
          } else if (splitVal.length == 3 && splitVal[0] == 'total') {
            this.adgroupSearchPaused[splitVal[0]][splitVal[1]][
              splitVal[2]
            ] = parseFloat(event.target.value);
          } else if (headerValue == 'adgroupMetadata.name')
            this.adgroupSearchPaused['adgroupMetadata'][
              'name'
            ] = event.target.value.trim();
          else if (headerValue == 'adgroupMetadata.campaignId')
            this.adgroupSearchPaused['adgroupMetadata'][
              'campaignId'
            ] = event.target.value.trim();
          else if (headerValue == 'campaignMetadata.adamId')
            this.adgroupSearchPaused['campaignMetadata'][
              'adamId'
            ] = event.target.value.trim();
          else if (headerValue == 'adgroupMetadata.id')
            this.adgroupSearchPaused['adgroupMetadata'][
              'id'
            ] = event.target.value.trim();

          // this.adgroupSearchPaused[headerValue] = event.target.value;
          return this.$emit(
            'adgroupSearchPausedCols',
            this.adgroupSearchPaused
          );
        case 'deletedadgroup':
          if (event.target.value === '') {
            if (this.adgroupSearchDeletedFilter.hasOwnProperty(headerValue)) {
              delete this.adgroupSearchDeletedFilter[headerValue];
            }
          }
          if (splitVal.length == 2 && splitVal[0] == 'total') {
            this.adgroupSearchDeleted[splitVal[0]][splitVal[1]] = parseFloat(
              event.target.value
            );
          } else if (splitVal.length == 3 && splitVal[0] == 'total') {
            this.adgroupSearchDeleted[splitVal[0]][splitVal[1]][
              splitVal[2]
            ] = parseFloat(event.target.value);
          } else if (headerValue == 'adgroupMetadata.name')
            this.adgroupSearchDeleted['adgroupMetadata'][
              'name'
            ] = event.target.value.trim();
          else if (headerValue == 'adgroupMetadata.campaignId')
            this.adgroupSearchDeleted['adgroupMetadata'][
              'campaignId'
            ] = event.target.value.trim();
          else if (headerValue == 'campaignMetadata.adamId')
            this.adgroupSearchDeleted['campaignMetadata'][
              'adamId'
            ] = event.target.value.trim();
          else if (headerValue == 'adgroupMetadata.id')
            this.adgroupSearchDeleted['adgroupMetadata'][
              'id'
            ] = event.target.value.trim();

          return this.$emit(
            'adgroupSearchDeletedCols',
            this.adgroupSearchDeleted
          );
        case 'alladgroup':
          if (event.target.value === '') {
            if (this.adgroupSearchAllFilter.hasOwnProperty(headerValue)) {
              delete this.adgroupSearchAllFilter[headerValue];
            }
          }
          if (splitVal.length == 2 && splitVal[0] == 'total') {
            this.adgroupSearchAll[splitVal[0]][splitVal[1]] = parseFloat(
              event.target.value
            );
          } else if (splitVal.length == 3 && splitVal[0] == 'total') {
            this.adgroupSearchAll[splitVal[0]][splitVal[1]][
              splitVal[2]
            ] = parseFloat(event.target.value);
          } else if (headerValue == 'adgroupMetadata.name')
            this.adgroupSearchAll['adgroupMetadata'][
              'name'
            ] = event.target.value.trim();
          else if (headerValue == 'adgroupMetadata.campaignId')
            this.adgroupSearchAl['adgroupMetadata'][
              'campaignId'
            ] = event.target.value.trim();
          else if (headerValue == 'campaignMetadata.adamId')
            this.adgroupSearchAll['campaignMetadata'][
              'adamId'
            ] = event.target.value.trim();
          else if (headerValue == 'adgroupMetadata.id')
            this.adgroupSearchAll['adgroupMetadata'][
              'id'
            ] = event.target.value.trim();

          return this.$emit('adgroupSearchAllCols', this.adgroupSearchAll);

        case 'livekeyword':
          if (event.target.value === '') {
            if (this.liveKeywordSearchFilter.hasOwnProperty(headerValue)) {
              delete this.liveKeywordSearchFilter[headerValue];
            }
          }
          if (splitVal.length == 2 && splitVal[0] == 'total') {
            this.liveKeywordSearch[splitVal[0]][splitVal[1]] = parseFloat(
              event.target.value
            );
          } else if (splitVal.length == 3 && splitVal[0] == 'total') {
            this.liveKeywordSearch[splitVal[0]][splitVal[1]][
              splitVal[2]
            ] = parseFloat(event.target.value);
          } else if (headerValue == 'keywordMetadata.text')
            this.liveKeywordSearch['keywordMetadata'][
              'text'
            ] = event.target.value.trim();
          else if (headerValue == 'keywordMetadata.id')
            this.liveKeywordSearch['keywordMetadata'][
              'id'
            ] = event.target.value.trim();
          else if (headerValue == 'keywordMetadata.adGroupId')
            this.liveKeywordSearch['keywordMetadata'][
              'adGroupId'
            ] = event.target.value.trim();
          else if (headerValue == 'metadata.campaignId')
            this.liveKeywordSearch['metadata'][
              'campaignId'
            ] = event.target.value.trim();
          else if (headerValue == 'keywordMetadata.bidAmount.amount')
            this.liveKeywordSearch['keywordMetadata']['bidAmount'][
              'amount'
            ] = event.target.value.trim();
          else if (headerValue == 'campaignMetadata.adamId')
            this.liveKeywordSearch['campaignMetadata'][
              'adamId'
            ] = event.target.value.trim();

          return this.$emit('liveKeywordSearchCols', this.liveKeywordSearch);
        case 'pausedkeyword':
          if (event.target.value === '') {
            if (this.pausedKeywordSearchFilter.hasOwnProperty(headerValue)) {
              delete this.pausedKeywordSearchFilter[headerValue];
            }
          }
          if (splitVal.length == 2 && splitVal[0] == 'total') {
            this.pausedKeywordSearch[splitVal[0]][splitVal[1]] = parseFloat(
              event.target.value
            );
          } else if (splitVal.length == 3 && splitVal[0] == 'total') {
            this.pausedKeywordSearch[splitVal[0]][splitVal[1]][
              splitVal[2]
            ] = parseFloat(event.target.value);
          } else if (headerValue == 'keywordMetadata.text')
            this.pausedKeywordSearch['keywordMetadata'][
              'text'
            ] = event.target.value.trim();
          else if (headerValue == 'keywordMetadata.id')
            this.pausedKeywordSearch['keywordMetadata'][
              'id'
            ] = event.target.value.trim();
          else if (headerValue == 'keywordMetadata.adGroupId')
            this.pausedKeywordSearch['keywordMetadata'][
              'adGroupId'
            ] = event.target.value.trim();
          else if (headerValue == 'metadata.campaignId')
            this.pausedKeywordSearch['metadata'][
              'campaignId'
            ] = event.target.value.trim();
          else if (headerValue == 'keywordMetadata.bidAmount.amount')
            this.pausedKeywordSearch['keywordMetadata']['bidAmount'][
              'amount'
            ] = event.target.value.trim();
          else if (headerValue == 'campaignMetadata.adamId')
            this.pausedKeywordSearch['campaignMetadata'][
              'adamId'
            ] = event.target.value.trim();

          return this.$emit(
            'pausedKeywordSearchCols',
            this.pausedKeywordSearch
          );

        case 'deletedkeyword':
          if (event.target.value === '') {
            if (this.deletedKeywordSearchFilter.hasOwnProperty(headerValue)) {
              delete this.deletedKeywordSearchFilter[headerValue];
            }
          }
          if (splitVal.length == 2 && splitVal[0] == 'total') {
            this.deletedKeywordSearch[splitVal[0]][splitVal[1]] = parseFloat(
              event.target.value
            );
          } else if (splitVal.length == 3 && splitVal[0] == 'total') {
            this.deletedKeywordSearch[splitVal[0]][splitVal[1]][
              splitVal[2]
            ] = parseFloat(event.target.value);
          } else if (headerValue == 'keywordMetadata.text')
            this.deletedKeywordSearch['keywordMetadata'][
              'text'
            ] = event.target.value.trim();
          else if (headerValue == 'keywordMetadata.id')
            this.deletedKeywordSearch['keywordMetadata'][
              'id'
            ] = event.target.value.trim();
          else if (headerValue == 'keywordMetadata.adGroupId')
            this.deletedKeywordSearch['keywordMetadata'][
              'adGroupId'
            ] = event.target.value.trim();
          else if (headerValue == 'metadata.campaignId')
            this.deletedKeywordSearch['metadata'][
              'campaignId'
            ] = event.target.value.trim();
          else if (headerValue == 'keywordMetadata.bidAmount.amount')
            this.deletedKeywordSearch['keywordMetadata']['bidAmount'][
              'amount'
            ] = event.target.value.trim();
          else if (headerValue == 'campaignMetadata.adamId')
            this.deletedKeywordSearch['campaignMetadata'][
              'adamId'
            ] = event.target.value.trim();

          return this.$emit(
            'deletedKeywordSearchCols',
            this.deletedKeywordSearch
          );

        case 'allkeyword':
          if (event.target.value === '') {
            if (this.allKeywordSearchFilter.hasOwnProperty(headerValue)) {
              delete this.allKeywordSearchFilter[headerValue];
            }
          }
          if (splitVal.length == 2 && splitVal[0] == 'total') {
            this.allKeywordSearch[splitVal[0]][splitVal[1]] = parseFloat(
              event.target.value
            );
          } else if (splitVal.length == 3 && splitVal[0] == 'total') {
            this.allKeywordSearch[splitVal[0]][splitVal[1]][
              splitVal[2]
            ] = parseFloat(event.target.value);
          } else if (headerValue == 'keywordMetadata.text')
            this.allKeywordSearch['keywordMetadata'][
              'text'
            ] = event.target.value.trim();
          else if (headerValue == 'keywordMetadata.id')
            this.allKeywordSearch['keywordMetadata'][
              'id'
            ] = event.target.value.trim();
          else if (headerValue == 'keywordMetadata.adGroupId')
            this.allKeywordSearch['keywordMetadata'][
              'adGroupId'
            ] = event.target.value.trim();
          else if (headerValue == 'metadata.campaignId')
            this.allKeywordSearch['metadata'][
              'campaignId'
            ] = event.target.value.trim();
          else if (headerValue == 'keywordMetadata.bidAmount.amount')
            this.allKeywordSearch['keywordMetadata']['bidAmount'][
              'amount'
            ] = event.target.value.trim();
          else if (headerValue == 'campaignMetadata.adamId')
            this.allKeywordSearch['campaignMetadata'][
              'adamId'
            ] = event.target.value.trim();

          return this.$emit('allKeywordSearchCols', this.allKeywordSearch);

        case 'ads':
          if (event.target.value === '') {
            if (this.adsdSearchLiveFilter.hasOwnProperty(headerValue)) {
              delete this.adsdSearchLiveFilter[headerValue];
            }
          }
          if (splitVal.length == 2 && splitVal[0] == 'total') {
            this.adsdSearchLive[splitVal[0]][splitVal[1]] = parseFloat(
              event.target.value
            );
          } else if (splitVal.length == 3 && splitVal[0] == 'total') {
            this.adsdSearchLive[splitVal[0]][splitVal[1]][
              splitVal[2]
            ] = parseFloat(event.target.value);
          } else if (headerValue == 'adsMetadata.name')
            this.adsdSearchLive['adsMetadata'][
              'name'
            ] = event.target.value.trim();
          else if (headerValue == 'adsMetadata.creativeType')
            this.adsdSearchLive['adsMetadata'][
              'creativeType'
            ] = event.target.value.trim();
          else if (headerValue == 'adgroupMetadata.id')
            this.adsdSearchLive['adgroupMetadata'][
              'id'
            ] = event.target.value.trim();
          else if (headerValue == 'campaignMetadata.id')
            this.adsdSearchLive['campaignMetadata'][
              'id'
            ] = event.target.value.trim();
          else if (headerValue == 'campaignMetadata.adamId')
            this.adsdSearchLive['campaignMetadata'][
              'adamId'
            ] = event.target.value.trim();

          return this.$emit('adsSearchLiveCols', this.adsdSearchLive);
        case 'pausedads':
          if (event.target.value === '') {
            if (this.adsdSearchPausedFilter.hasOwnProperty(headerValue)) {
              delete this.adsdSearchPausedFilter[headerValue];
            }
          }
          if (splitVal.length == 2 && splitVal[0] == 'total') {
            this.adsdSearchPaused[splitVal[0]][splitVal[1]] = parseFloat(
              event.target.value
            );
          } else if (splitVal.length == 3 && splitVal[0] == 'total') {
            this.adsdSearchPaused[splitVal[0]][splitVal[1]][
              splitVal[2]
            ] = parseFloat(event.target.value);
          } else if (headerValue == 'adsMetadata.name')
            this.adsdSearchPaused['adsMetadata'][
              'name'
            ] = event.target.value.trim();
          else if (headerValue == 'adsMetadata.creativeType')
            this.adsdSearchPaused['adsMetadata'][
              'creativeType'
            ] = event.target.value.trim();
          else if (headerValue == 'adgroupMetadata.id')
            this.adsdSearchPaused['adgroupMetadata'][
              'id'
            ] = event.target.value.trim();
          else if (headerValue == 'campaignMetadata.id')
            this.adsdSearchPaused['campaignMetadata'][
              'id'
            ] = event.target.value.trim();
          else if (headerValue == 'campaignMetadata.adamId')
            this.adsdSearchPaused['campaignMetadata'][
              'adamId'
            ] = event.target.value.trim();

          return this.$emit('adsdSearchPausedCols', this.adsdSearchPaused);
        case 'deletedads':
          if (event.target.value === '') {
            if (this.adsdSearchDeletedFilter.hasOwnProperty(headerValue)) {
              delete this.adsdSearchDeletedFilter[headerValue];
            }
          }
          if (splitVal.length == 2 && splitVal[0] == 'total') {
            this.adsdSearchDeleted[splitVal[0]][splitVal[1]] = parseFloat(
              event.target.value
            );
          } else if (splitVal.length == 3 && splitVal[0] == 'total') {
            this.adsdSearchDeleted[splitVal[0]][splitVal[1]][
              splitVal[2]
            ] = parseFloat(event.target.value);
          } else if (headerValue == 'adsMetadata.name')
            this.adsdSearchDeleted['adsMetadata'][
              'name'
            ] = event.target.value.trim();
          else if (headerValue == 'adsMetadata.creativeType')
            this.adsdSearchDeleted['adsMetadata'][
              'creativeType'
            ] = event.target.value.trim();
          else if (headerValue == 'adgroupMetadata.id')
            this.adsdSearchDeleted['adgroupMetadata'][
              'id'
            ] = event.target.value.trim();
          else if (headerValue == 'campaignMetadata.id')
            this.adsdSearchDeleted['campaignMetadata'][
              'id'
            ] = event.target.value.trim();
          else if (headerValue == 'campaignMetadata.adamId')
            this.adsdSearchDeleted['campaignMetadata'][
              'adamId'
            ] = event.target.value.trim();

          return this.$emit('adsdSearchDeletedCols', this.adsdSearchDeleted);

        case 'allads':
          if (event.target.value === '') {
            if (this.adsdSearchAllFilter.hasOwnProperty(headerValue)) {
              delete this.adsdSearchAllFilter[headerValue];
            }
          }
          if (splitVal.length == 2 && splitVal[0] == 'total') {
            this.adsdSearchAll[splitVal[0]][splitVal[1]] = parseFloat(
              event.target.value
            );
          } else if (splitVal.length == 3 && splitVal[0] == 'total') {
            this.adsdSearchAll[splitVal[0]][splitVal[1]][
              splitVal[2]
            ] = parseFloat(event.target.value);
          } else if (headerValue == 'adsMetadata.name')
            this.adsdSearchAll['adsMetadata'][
              'name'
            ] = event.target.value.trim();
          else if (headerValue == 'adsMetadata.creativeType')
            this.adsdSearchAll['adsMetadata'][
              'creativeType'
            ] = event.target.value.trim();
          else if (headerValue == 'adgroupMetadata.id')
            this.adsdSearchAll['adgroupMetadata'][
              'id'
            ] = event.target.value.trim();
          else if (headerValue == 'campaignMetadata.id')
            this.adsdSearchAll['campaignMetadata'][
              'id'
            ] = event.target.value.trim();
          else if (headerValue == 'campaignMetadata.adamId')
            this.adsdSearchAll['campaignMetadata'][
              'adamId'
            ] = event.target.value.trim();

          return this.$emit('adsdSearchAllCols', this.adsdSearchAll);

        case 'livenegativekeyword':
          if (event.target.value === '') {
            if (this.negKeywordSearchLiveFilter.hasOwnProperty(headerValue)) {
              delete this.negKeywordSearchLiveFilter[headerValue];
            }
          }
          this.negKeywordSearchLive[headerValue] = event.target.value.trim();
          return this.$emit(
            'negKeywordSearchLiveCols',
            this.negKeywordSearchLive
          );
        case 'pausednegativekeyword':
          if (event.target.value === '') {
            if (this.negKeywordSearchPausedFilter.hasOwnProperty(headerValue)) {
              delete this.negKeywordSearchPausedFilter[headerValue];
            }
          }
          this.negKeywordSearchPaused[headerValue] = event.target.value.trim();
          return this.$emit(
            'negKeywordSearchPausedCols',
            this.negKeywordSearchPaused
          );
        case 'deletednegativekeyword':
          if (event.target.value === '') {
            if (
              this.negKeywordSearchDeletedFilter.hasOwnProperty(headerValue)
            ) {
              delete this.negKeywordSearchDeletedFilter[headerValue];
            }
          }
          this.negKeywordSearchDeleted[headerValue] = event.target.value.trim();
          return this.$emit(
            'negKeywordSearchDeletedCols',
            this.negKeywordSearchDeleted
          );

        case 'allnegativekeyword':
          if (event.target.value === '') {
            if (this.negKeywordSearchAllFilter.hasOwnProperty(headerValue)) {
              delete this.negKeywordSearchAllFilter[headerValue];
            }
          }
          this.negKeywordSearchAll[headerValue] = event.target.value.trim();
          return this.$emit(
            'negKeywordSearchAllCols',
            this.negKeywordSearchAll
          );
      }
    },

    bulkAction(action) {
      let actionMsg = '';
      if (action == 'PAUSED') actionMsg = 'pausing';
      if (action == 'ENABLED') actionMsg = 'live';
      if (action == 'DELETE') actionMsg = 'deleting';
      this.fullscreenLoader = this.$loading.show();
      this.$apollo
        .query({
          query: GET_INTEGRATION,
          variables: {},
        })
        .then((res) => {
          const showApples = res.data.getIntegration.filter(
            (e) => e.type == 'APPLE_SEARCH_ADS' && e.invalid != true
          );
          if (showApples.length == 0) {
            this.fullscreenLoader.hide();
            this.$notify.error(' Invalid apple search ads integration');
            LSS.asaInt = false;
          } else {
            LSS.asaInt = true;
            if (this.customTab == 'campaigns') {
              const ids = this.selectedItems.map((el) => ({
                ['id']: el.campaignMetadata.id,
                ['name']: el.campaignMetadata.name,
                ['orgId']: el.campaignMetadata.orgId,
              }));
              const requestObj = {
                ids: ids,
                action: action,
                actionFor: 'CAMPAIGN',
              };
              this.$apollo
                .mutate({
                  mutation: CREATE_BULK_ACTION,
                  variables: {
                    bulkAction: requestObj,
                  },
                })
                .then((res) => {
                  this.fullscreenLoader.hide();
                  this.$notify.success(
                    `Your request for ${actionMsg} the ${this.customTab} is received, you will be notified once completed`
                  );
                  this.selectedItems = [];
                })
                .catch((res) => {
                  this.fullscreenLoader.hide();
                  this.$notify.error('something went wrong');
                });
            }
            if (this.customTab == 'adgroups') {
              const ids = this.selectedItems.map((el) => ({
                ['id']: el.adgroupMetadata.id,
                ['name']: el.adgroupMetadata.name,
                ['orgId']: el.adgroupMetadata.orgId,
                ['campaignId']: el.adgroupMetadata.campaignId,
              }));
              const requestObj = {
                ids: ids,
                action: action,
                actionFor: 'ADGROUP',
              };
              this.$apollo
                .mutate({
                  mutation: CREATE_BULK_ACTION,
                  variables: {
                    bulkAction: requestObj,
                  },
                })
                .then((res) => {
                  this.fullscreenLoader.hide();
                  this.$notify.success(
                    `Your request for ${actionMsg} the ${this.customTab} is received, you will be notified once completed`
                  );
                  this.selectedItems = [];
                })
                .catch((res) => {
                  this.fullscreenLoader.hide();
                  this.$notify.error('something went wrong');
                });
            }
            if (this.customTab == 'keywords') {
              const ids = this.selectedItems.map((el) => ({
                ['id']: el.keywordMetadata.id,
                ['name']: el.keywordMetadata.text,
                ['orgId']: el.adgroupMetadata.orgId,
                ['adgroupId']: el.keywordMetadata.adGroupId,
                ['campaignId']: el.adgroupMetadata.campaignId,
              }));
              const requestObj = {
                ids: ids,
                action: action,
                actionFor: 'TARGETINGKEYWORD',
              };
              this.$apollo
                .mutate({
                  mutation: CREATE_BULK_ACTION,
                  variables: {
                    bulkAction: requestObj,
                  },
                })
                .then((res) => {
                  this.fullscreenLoader.hide();
                  this.$notify.success(
                    `Your request for ${actionMsg} the ${this.customTab} is received, you will be notified once completed`
                  );
                  this.selectedItems = [];
                })
                .catch((res) => {
                  this.fullscreenLoader.hide();
                  this.$notify.error('something went wrong');
                });
            }
          }
        })
        .catch((err) => {
          this.fullscreenLoader.hide();
          this.$notify.error('Something went wrong');
        });
    },
    goToFirstPage() {
      this.pagination.page = 1;
    },
    downloadReportsEvent(data) {
      let createXLSLFormatObj = [];
      let newXlsHeader = [];
      if (
        this.tab === 'Live-Targeting-Keywords' ||
        this.tab == 'Disabled-Targeting-Keywords' ||
        this.tab == 'Deleted-Targeting-Keywords' ||
        this.tab == 'All-Targeting-Keywords'
      ) {
        if (
          !this.finalHeaders.some(
            (column) => column.value === 'campaignMetadata.name'
          )
        ) {
          this.finalHeaders.push({
            text: 'Campaign',
            align: 'start',
            value: 'campaignMetadata.name',
            sortable: false,
            class: 'sortHandle',
          });
        }
      }
      if (
        this.tab === 'Live-Targeting-Keywords' ||
        this.tab == 'Disabled-Targeting-Keywords' ||
        this.tab == 'Deleted-Targeting-Keywords' ||
        this.tab == 'All-Targeting-Keywords'
      ) {
        if (
          !this.finalHeaders.some(
            (column) => column.value === 'metadata.adGroupName'
          )
        ) {
          this.finalHeaders.push({
            text: 'Ad Group',
            align: 'start',
            value: 'metadata.adGroupName',
            sortable: false,
            class: 'sortHandle',
          });
        }
      }
      if (
        this.tab === 'Live-Targeting-Keywords' ||
        this.tab == 'Disabled-Targeting-Keywords' ||
        this.tab == 'Deleted-Targeting-Keywords' ||
        this.tab == 'All-Targeting-Keywords'
      ) {
        if (
          !this.finalHeaders.some(
            (column) => column.value === 'keywordMetadata.id'
          )
        ) {
          this.finalHeaders.push({
            text: 'Keyword ID',
            align: 'start',
            value: 'keywordMetadata.id',
            sortable: false,
            class: 'sortHandle',
          });
        }
      }

      if (
        this.tab === 'Live-Adgroups' ||
        this.tab == 'Disabled-Adgroups' ||
        this.tab == 'Deleted-Adgroups' ||
        this.tab == 'All-Adgroups'
      ) {
        if (
          !this.finalHeaders.some(
            (column) => column.value === 'adgroupMetadata.id'
          )
        ) {
          this.finalHeaders.push({
            text: 'AdGroup ID',
            align: 'start',
            value: 'adgroupMetadata.id',
            sortable: false,
            class: 'sortHandle',
          });
        }
      }

      if (
        this.tab === 'Live-Campaigns' ||
        this.tab == 'Disabled-Campaigns' ||
        this.tab == 'Deleted-Campaigns' ||
        this.tab == 'All-Campaigns'
      ) {
        if (
          !this.finalHeaders.some(
            (column) => column.value === 'campaignMetadata.id'
          )
        ) {
          this.finalHeaders.push({
            text: 'Campaign ID',
            align: 'start',
            value: 'campaignMetadata.id',
            sortable: false,
            class: 'sortHandle',
          });
        }
      }
      this.finalHeaders.map((column) => {
        if (
          [
            'total.localSpend.amount',
            'total.avgCPA.amount',
            'total.avgCPT.amount',
            'total.avgCPM.amount',
            'campaignMetadata.dailyBudgetAmount.amount',
            'campaignMetadata.budgetAmount.amount',
            'calcGoalsRevenue',
            'calcRevenuePerGoal',
            'calcCostPerGoal',
            'calcGoalRoas',
            'calcARPU',
            'calcAMPU',
            'keywordMetadata.bidAmount.amount',
            'insights.bidRecommendation.bidMin.amount',
            'insights.bidRecommendation.bidMax.amount',
          ].includes(column.value)
        ) {
          newXlsHeader.push(column.text + '( $ )');
        } else if (
          ['total.ttr', 'total.conversionRate'].includes(column.value)
        ) {
          newXlsHeader.push(column.text + '( % )');
        } else {
          newXlsHeader.push(column.text);
        }
      });
      createXLSLFormatObj.push(newXlsHeader);
      data.map((value) => {
        let innerRowData = [];

        this.finalHeaders.map((val) => {
          //--start- to find attribution field
          const attrResponseKeys = [
            'appAttributionData',
            'campaignAttributionData',
            'adgroupAttributionData',
            'keywordAttributionData',
            'productPageAttributionData',
            'searchTermAttributionData',
          ];

          const responseItem = data[0];
          const field = attrResponseKeys?.find((key) => key in responseItem);
          //--end

          let fieldValue = value[val.value];
          if (val.value.split('.').length > 1) {
            if (
              val.value == 'orgMetadata.orgName' ||
              val.value == 'total.impressions' ||
              val.value == 'total.taps' ||
              val.value == 'total.installs' ||
              val.value == 'total.latOnInstalls' ||
              val.value == 'total.latOffInstalls' ||
              val.value == 'total.redownloads' ||
              val.value == 'total.newDownloads' ||
              val.value == 'metadata.app.appName' ||
              val.value == 'campaignMetadata.name' ||
              val.value == 'campaignMetadata.supplySources' ||
              val.value == 'campaignMetadata.status' ||
              val.value == 'campaignMetadata.countriesOrRegions' ||
              val.value == 'campaignMetadata.displayStatus' ||
              val.value == 'campaignMetadata.deleted' ||
              val.value == 'campaignMetadata.dailyBudgetAmount.amount' ||
              val.value == 'adgroupMetadata.name' ||
              val.value == 'adgroupMetadata.status' ||
              val.value == 'metadata.adGroupDisplayStatus' ||
              val.value == 'adgroupMetadata.deleted' ||
              val.value == 'keywordMetadata.text' ||
              val.value == 'keywordMetadata.status' ||
              val.value == 'insights.bidRecommendation.bidMax.amount' ||
              val.value == 'insights.bidRecommendation.bidMin.amount' ||
              val.value == 'keywordMetadata.deleted' ||
              val.value == 'adsMetadata.name' ||
              val.value == 'adsMetadata.creativeType' ||
              val.value == 'adsMetadata.status' ||
              val.value == 'adsMetadata.deleted' ||
              val.value == 'metadata.searchTermText' ||
              val.value == 'metadata.keyword' ||
              val.value == 'Action' ||
              val.value == 'modificationTime' ||
              val.value == 'campaignMetadataNeg.name' ||
              val.value == 'adgroupMetadataNeg.name' ||
              val.value == 'metadata.adGroupName' ||
              val.value == 'campaignMetadata.id' ||
              val.value == 'adgroupMetadata.id' ||
              val.value == 'keywordMetadata.id' ||
              val.value == 'keywordMetadata.adGroupId' ||
              val.value == 'metadata.campaignId' ||
              val.value == 'metadata.adGroupId' ||
              val.value == 'metadata.app.adamId' ||
              val.value == 'campaignMetadata.adamId' ||
              val.value == 'adgroupMetadata.campaignId' ||
              val.value == 'keywordMetadata.matchType'
            ) {
              fieldValue = this.getNestedValue(value, val.value);
            } else {
              fieldValue = numberDecimal(this.getNestedValue(value, val.value));
            }
          }

          //--Start- To calculate attribution data
          if (val.value == 'calcGoalsRevenue') {
            fieldValue = this.calcGoalsRevenue(value, field);
          }
          if (val.value == 'calcEventCount') {
            fieldValue = this.calcEventCount(value, field);
          }
          if (val.value == 'calcEventCountSec') {
            fieldValue = this.calcEventCountSec(value, field);
          }
          if (val.value == 'calcGoalRoas') {
            fieldValue = this.calcGoalRoas(value, field);
          }
          if (val.value == 'calcCostPerGoal') {
            fieldValue = this.calcCostPerGoal(value, field);
          }
          if (val.value == 'calcCostPerGoalSec') {
            fieldValue = this.calcCostPerGoalSec(value, field);
          }
          if (val.value == 'calcRevenuePerGoal') {
            fieldValue = this.calcRevenuePerGoal(value, field);
          }
          if (val.value == 'calcARPU') {
            fieldValue = this.calcARPU(value, field);
          }
          if (val.value == 'calcAMPU') {
            fieldValue = this.calcAMPU(value, field);
          }
          //--end

          if (val.value == 'keywordMetadata.status' && fieldValue == 'PAUSED') {
            fieldValue = 'Campaign is disabled';
          }

          if (val.dataFormat && typeof val.dataFormat === 'function') {
            innerRowData.push(val.dataFormat(fieldValue));
          } else {
            innerRowData.push(fieldValue);
          }
        });
        createXLSLFormatObj.push(innerRowData);
      });
      let fileName = this.fileName + '-' + this.tab + '.' + this.fileType;
      let ws_name = this.sheetName;
      let wb = XLSX.utils.book_new(),
        ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);
      XLSX.utils.book_append_sheet(wb, ws, ws_name);
      XLSX.writeFile(wb, fileName);
      this.progressBar = false;
      this.$notify.success('Downloaded reports successfully .');
    },
    handleScroll(value) {
      const tableRows = value.itemsPerPage;
      let tabRowHeight;
      let tabWinHeight;
      const windowHeight = window.innerHeight;

      tabRowHeight = 55 * tableRows;

      tabWinHeight = windowHeight - 250;
      if (tabRowHeight < tabWinHeight) {
        this.tableHeight = tabRowHeight;
      } else {
        this.tableHeight = tabWinHeight;
      }
    },
    openPermissionPopup() {
      this.permissionMetadata = {
        date: moment.now(),
      };
    },
    sortTheHeadersAndUpdateTheKey(evt) {
      const headersTmp = this.finalHeaders;
      let oldIndex = evt.oldIndex;
      let newIndex = evt.newIndex;
      if (
        (this.customTab == 'keywords' ||
          this.customTab == 'adgroups' ||
          this.customTab == 'campaigns') &&
        this.tabInfo != 'draftcampaign' &&
        this.tabInfo != 'deletedcampaign' &&
        this.tabInfo != 'deletedadgroup' &&
        this.tabInfo != 'deletedkeyword'
      ) {
        if (evt.newIndex < evt.oldIndex) {
          newIndex = evt.newIndex - 1;
        }
        oldIndex = evt.oldIndex - 1;
      }

      if (newIndex >= headersTmp.length) {
        let k = newIndex - headersTmp.length + 1;
        while (k--) {
          headersTmp.push(undefined);
        }
      }
      headersTmp.splice(newIndex, 0, headersTmp.splice(oldIndex, 1)[0]);
      this.table = headersTmp;
      this.anIncreasingNumber += 1;

      const arr = [];
      this.finalHeaders.map((column) => {
        arr.push(column.value);
      });

      this.$emit('persistDatatable', {
        tabInfo: this.tabInfo,
        selectedDataTable: arr,
      });
      this.$emit('customViewsTable', {
        tabInfo: this.tabInfo,
        selectedDataTable: arr,
        customTab: this.customTab,
      });
    },
    selectedDatasetsFromLSS() {
      switch (this.tabInfo) {
        case 'accounts': {
          const presets = LSS.datatableDatasets;
          if (presets.accounts) {
            const presetLSS = presets.accounts.split(',');
            if (presetLSS.length) {
              let tmpArr = (this.finalHeaders = this.headers.filter((set) =>
                presetLSS.includes(set.value)
              ));
              const ordered_array = this.mapOrder(tmpArr, presetLSS, 'value');
              return ordered_array;
            } else {
              this.finalHeaders = this.headers.slice(0, 10);
              return this.headers.slice(0, 10);
            }
          } else {
            this.finalHeaders = this.headers.slice(0, 10);
            return this.headers.slice(0, 10);
          }
        }
        case 'apps': {
          const presets = LSS.datatableDatasets;
          if (presets.apps) {
            const presetLSS = presets.apps.split(',');
            if (presetLSS.length) {
              let tmpArr = (this.finalHeaders = this.headers.filter((set) =>
                presetLSS.includes(set.value)
              ));
              const ordered_array = this.mapOrder(tmpArr, presetLSS, 'value');
              return ordered_array;
            } else {
              this.finalHeaders = this.headers.slice(0, 10);
              return this.headers.slice(0, 10);
            }
          } else {
            this.finalHeaders = this.headers.slice(0, 10);
            return this.headers.slice(0, 10);
          }
        }

        case 'livecampaign': {
          const presets = LSS.datatableDatasets;
          if (presets.livecampaign) {
            const presetLSS = presets.livecampaign.split(',');
            if (presetLSS.length) {
              let tmpArr = (this.finalHeaders = this.headers.filter((set) =>
                presetLSS.includes(set.value)
              ));
              const ordered_array = this.mapOrder(tmpArr, presetLSS, 'value');
              return ordered_array;
            } else {
              this.finalHeaders = this.headers.slice(0, 10);
              return this.headers.slice(0, 10);
            }
          } else {
            this.finalHeaders = this.headers.slice(0, 10);
            return this.headers.slice(0, 10);
          }
        }
        case 'pausedcampaign': {
          const presets = LSS.datatableDatasets;
          if (presets.pausedcampaign) {
            const presetLSS = presets.pausedcampaign.split(',');
            if (presetLSS.length) {
              let tmpArr = (this.finalHeaders = this.headers.filter((set) =>
                presetLSS.includes(set.value)
              ));
              const ordered_array = this.mapOrder(tmpArr, presetLSS, 'value');
              return ordered_array;
            } else {
              this.finalHeaders = this.headers.slice(0, 10);
              return this.headers.slice(0, 10);
            }
          } else {
            this.finalHeaders = this.headers.slice(0, 10);
            return this.headers.slice(0, 10);
          }
        }
        case 'deletedcampaign': {
          const presets = LSS.datatableDatasets;
          if (presets.deletedcampaign) {
            const presetLSS = presets.deletedcampaign.split(',');
            if (presetLSS.length) {
              let tmpArr = (this.finalHeaders = this.headers.filter((set) =>
                presetLSS.includes(set.value)
              ));
              const ordered_array = this.mapOrder(tmpArr, presetLSS, 'value');
              return ordered_array;
            } else {
              this.finalHeaders = this.headers.slice(0, 10);
              return this.headers.slice(0, 10);
            }
          } else {
            this.finalHeaders = this.headers.slice(0, 10);
            return this.headers.slice(0, 10);
          }
        }
        case 'allcampaign': {
          const presets = LSS.datatableDatasets;
          if (presets.allcampaign) {
            const presetLSS = presets.allcampaign.split(',');
            if (presetLSS.length) {
              let tmpArr = (this.finalHeaders = this.headers.filter((set) =>
                presetLSS.includes(set.value)
              ));
              const ordered_array = this.mapOrder(tmpArr, presetLSS, 'value');
              return ordered_array;
            } else {
              this.finalHeaders = this.headers.slice(0, 10);
              return this.headers.slice(0, 10);
            }
          } else {
            this.finalHeaders = this.headers.slice(0, 10);
            return this.headers.slice(0, 10);
          }
        }

        case 'draftcampaign': {
          const presets = LSS.datatableDatasets;
          if (presets.draftcampaign) {
            const presetLSS = presets.draftcampaign.split(',');
            if (presetLSS.length) {
              let tmpArr = (this.finalHeaders = this.headers.filter((set) =>
                presetLSS.includes(set.value)
              ));
              const ordered_array = this.mapOrder(tmpArr, presetLSS, 'value');
              return ordered_array;
            } else {
              this.finalHeaders = this.headers.slice(0, 10);
              return this.headers.slice(0, 10);
            }
          } else {
            this.finalHeaders = this.headers.slice(0, 10);
            return this.headers.slice(0, 10);
          }
        }
        case 'liveadgroup': {
          const presets = LSS.datatableDatasets;
          if (presets.liveadgroup) {
            const presetLSS = presets.liveadgroup.split(',');
            if (presetLSS.length) {
              let tmpArr = (this.finalHeaders = this.headers.filter((set) =>
                presetLSS.includes(set.value)
              ));
              const ordered_array = this.mapOrder(tmpArr, presetLSS, 'value');
              return ordered_array;
            } else {
              this.finalHeaders = this.headers.slice(0, 10);
              return this.headers.slice(0, 10);
            }
          } else {
            this.finalHeaders = this.headers.slice(0, 10);
            return this.headers.slice(0, 10);
          }
        }
        case 'pausedadgroup': {
          const presets = LSS.datatableDatasets;
          if (presets.pausedadgroup) {
            const presetLSS = presets.pausedadgroup.split(',');
            if (presetLSS.length) {
              let tmpArr = (this.finalHeaders = this.headers.filter((set) =>
                presetLSS.includes(set.value)
              ));
              const ordered_array = this.mapOrder(tmpArr, presetLSS, 'value');
              return ordered_array;
            } else {
              this.finalHeaders = this.headers.slice(0, 10);
              return this.headers.slice(0, 10);
            }
          } else {
            this.finalHeaders = this.headers.slice(0, 10);
            return this.headers.slice(0, 10);
          }
        }
        case 'deletedadgroup': {
          const presets = LSS.datatableDatasets;
          if (presets.deletedadgroup) {
            const presetLSS = presets.deletedadgroup.split(',');
            if (presetLSS.length) {
              let tmpArr = (this.finalHeaders = this.headers.filter((set) =>
                presetLSS.includes(set.value)
              ));
              const ordered_array = this.mapOrder(tmpArr, presetLSS, 'value');
              return ordered_array;
            } else {
              this.finalHeaders = this.headers.slice(0, 10);
              return this.headers.slice(0, 10);
            }
          } else {
            this.finalHeaders = this.headers.slice(0, 10);
            return this.headers.slice(0, 10);
          }
        }
        case 'alladgroup': {
          const presets = LSS.datatableDatasets;
          if (presets.alladgroup) {
            const presetLSS = presets.alladgroup.split(',');
            if (presetLSS.length) {
              let tmpArr = (this.finalHeaders = this.headers.filter((set) =>
                presetLSS.includes(set.value)
              ));
              const ordered_array = this.mapOrder(tmpArr, presetLSS, 'value');
              return ordered_array;
            } else {
              this.finalHeaders = this.headers.slice(0, 10);
              return this.headers.slice(0, 10);
            }
          } else {
            this.finalHeaders = this.headers.slice(0, 10);
            return this.headers.slice(0, 10);
          }
        }

        case 'livekeyword': {
          const presets = LSS.datatableDatasets;
          if (presets.livekeyword) {
            const presetLSS = presets.livekeyword.split(',');
            if (presetLSS.length) {
              let tmpArr = (this.finalHeaders = this.headers.filter((set) =>
                presetLSS.includes(set.value)
              ));
              const ordered_array = this.mapOrder(tmpArr, presetLSS, 'value');
              return ordered_array;
            } else {
              this.finalHeaders = this.headers.slice(0, 10);
              return this.headers.slice(0, 10);
            }
          } else {
            this.finalHeaders = this.headers.slice(0, 10);
            return this.headers.slice(0, 10);
          }
        }
        case 'pausedkeyword': {
          const presets = LSS.datatableDatasets;
          if (presets.pausedkeyword) {
            const presetLSS = presets.pausedkeyword.split(',');
            if (presetLSS.length) {
              let tmpArr = (this.finalHeaders = this.headers.filter((set) =>
                presetLSS.includes(set.value)
              ));
              const ordered_array = this.mapOrder(tmpArr, presetLSS, 'value');
              return ordered_array;
            } else {
              this.finalHeaders = this.headers.slice(0, 10);
              return this.headers.slice(0, 10);
            }
          } else {
            this.finalHeaders = this.headers.slice(0, 10);
            return this.headers.slice(0, 10);
          }
        }
        case 'deletedkeyword': {
          const presets = LSS.datatableDatasets;
          if (presets.deletedkeyword) {
            const presetLSS = presets.deletedkeyword.split(',');
            if (presetLSS.length) {
              let tmpArr = (this.finalHeaders = this.headers.filter((set) =>
                presetLSS.includes(set.value)
              ));
              const ordered_array = this.mapOrder(tmpArr, presetLSS, 'value');
              return ordered_array;
            } else {
              this.finalHeaders = this.headers.slice(0, 10);
              return this.headers.slice(0, 10);
            }
          } else {
            this.finalHeaders = this.headers.slice(0, 10);
            return this.headers.slice(0, 10);
          }
        }
        case 'allkeyword': {
          const presets = LSS.datatableDatasets;
          if (presets.allkeyword) {
            const presetLSS = presets.allkeyword.split(',');
            if (presetLSS.length) {
              let tmpArr = (this.finalHeaders = this.headers.filter((set) =>
                presetLSS.includes(set.value)
              ));
              const ordered_array = this.mapOrder(tmpArr, presetLSS, 'value');
              return ordered_array;
            } else {
              this.finalHeaders = this.headers.slice(0, 10);
              return this.headers.slice(0, 10);
            }
          } else {
            this.finalHeaders = this.headers.slice(0, 10);
            return this.headers.slice(0, 10);
          }
        }
        case 'ads': {
          const presets = LSS.datatableDatasets;
          if (presets.ads) {
            const presetLSS = presets.ads.split(',');
            if (presetLSS.length) {
              let tmpArr = (this.finalHeaders = this.headers.filter((set) =>
                presetLSS.includes(set.value)
              ));
              const ordered_array = this.mapOrder(tmpArr, presetLSS, 'value');
              return ordered_array;
            } else {
              this.finalHeaders = this.headers.slice(0, 10);
              return this.headers.slice(0, 10);
            }
          } else {
            this.finalHeaders = this.headers.slice(0, 10);
            return this.headers.slice(0, 10);
          }
        }
        case 'pausedads': {
          const presets = LSS.datatableDatasets;
          if (presets.pausedads) {
            const presetLSS = presets.pausedads.split(',');
            if (presetLSS.length) {
              let tmpArr = (this.finalHeaders = this.headers.filter((set) =>
                presetLSS.includes(set.value)
              ));
              const ordered_array = this.mapOrder(tmpArr, presetLSS, 'value');
              return ordered_array;
            } else {
              this.finalHeaders = this.headers.slice(0, 10);
              return this.headers.slice(0, 10);
            }
          } else {
            this.finalHeaders = this.headers.slice(0, 10);
            return this.headers.slice(0, 10);
          }
        }
        case 'deletedads': {
          const presets = LSS.datatableDatasets;
          if (presets.deletedads) {
            const presetLSS = presets.deletedads.split(',');
            if (presetLSS.length) {
              let tmpArr = (this.finalHeaders = this.headers.filter((set) =>
                presetLSS.includes(set.value)
              ));
              const ordered_array = this.mapOrder(tmpArr, presetLSS, 'value');
              return ordered_array;
            } else {
              this.finalHeaders = this.headers.slice(0, 10);
              return this.headers.slice(0, 10);
            }
          } else {
            this.finalHeaders = this.headers.slice(0, 10);
            return this.headers.slice(0, 10);
          }
        }
        case 'allads': {
          const presets = LSS.datatableDatasets;
          if (presets.allads) {
            const presetLSS = presets.allads.split(',');
            if (presetLSS.length) {
              let tmpArr = (this.finalHeaders = this.headers.filter((set) =>
                presetLSS.includes(set.value)
              ));
              const ordered_array = this.mapOrder(tmpArr, presetLSS, 'value');
              return ordered_array;
            } else {
              this.finalHeaders = this.headers.slice(0, 10);
              return this.headers.slice(0, 10);
            }
          } else {
            this.finalHeaders = this.headers.slice(0, 10);
            return this.headers.slice(0, 10);
          }
        }
        case 'livecreativeset': {
          const presets = LSS.datatableDatasets;
          if (presets.livecreativeset) {
            const presetLSS = presets.livecreativeset.split(',');
            if (presetLSS.length) {
              let tmpArr = (this.finalHeaders = this.headers.filter((set) =>
                presetLSS.includes(set.value)
              ));
              const ordered_array = this.mapOrder(tmpArr, presetLSS, 'value');
              return ordered_array;
            } else {
              this.finalHeaders = this.headers.slice(0, 10);
              return this.headers.slice(0, 10);
            }
          } else {
            this.finalHeaders = this.headers.slice(0, 10);
            return this.headers.slice(0, 10);
          }
        }
        case 'pausedcreativeset': {
          const presets = LSS.datatableDatasets;
          if (presets.pausedcreativeset) {
            const presetLSS = presets.pausedcreativeset.split(',');
            if (presetLSS.length) {
              let tmpArr = (this.finalHeaders = this.headers.filter((set) =>
                presetLSS.includes(set.value)
              ));
              const ordered_array = this.mapOrder(tmpArr, presetLSS, 'value');
              return ordered_array;
            } else {
              this.finalHeaders = this.headers.slice(0, 10);
              return this.headers.slice(0, 10);
            }
          } else {
            this.finalHeaders = this.headers.slice(0, 10);
            return this.headers.slice(0, 10);
          }
        }
        case 'deletedcreativeset': {
          const presets = LSS.datatableDatasets;
          if (presets.deletedcreativeset) {
            const presetLSS = presets.deletedcreativeset.split(',');
            if (presetLSS.length) {
              let tmpArr = (this.finalHeaders = this.headers.filter((set) =>
                presetLSS.includes(set.value)
              ));
              const ordered_array = this.mapOrder(tmpArr, presetLSS, 'value');
              return ordered_array;
            } else {
              this.finalHeaders = this.headers.slice(0, 10);
              return this.headers.slice(0, 10);
            }
          } else {
            this.finalHeaders = this.headers.slice(0, 10);
            return this.headers.slice(0, 10);
          }
        }
        case 'searchterm': {
          const presets = LSS.datatableDatasets;
          if (presets.searchterm) {
            const presetLSS = presets.searchterm.split(',');
            if (presetLSS.length) {
              let tmpArr = (this.finalHeaders = this.headers.filter((set) =>
                presetLSS.includes(set.value)
              ));
              const ordered_array = this.mapOrder(tmpArr, presetLSS, 'value');
              return ordered_array;
            } else {
              this.finalHeaders = this.headers.slice(0, 10);
              return this.headers.slice(0, 10);
            }
          } else {
            this.finalHeaders = this.headers.slice(0, 10);
            return this.headers.slice(0, 10);
          }
        }
        case 'livenegativekeyword': {
          const presets = LSS.datatableDatasets;
          if (presets.livenegativekeyword) {
            const presetLSS = presets.livenegativekeyword.split(',');
            if (presetLSS.length) {
              let tmpArr = (this.finalHeaders = this.headers.filter((set) =>
                presetLSS.includes(set.value)
              ));
              const ordered_array = this.mapOrder(tmpArr, presetLSS, 'value');
              return ordered_array;
            } else {
              this.finalHeaders = this.headers.slice(0, 10);
              return this.headers.slice(0, 10);
            }
          } else {
            this.finalHeaders = this.headers.slice(0, 10);
            return this.headers.slice(0, 10);
          }
        }
        case 'pausednegativekeyword': {
          const presets = LSS.datatableDatasets;
          if (presets.pausednegativekeyword) {
            const presetLSS = presets.pausednegativekeyword.split(',');
            if (presetLSS.length) {
              let tmpArr = (this.finalHeaders = this.headers.filter((set) =>
                presetLSS.includes(set.value)
              ));
              const ordered_array = this.mapOrder(tmpArr, presetLSS, 'value');
              return ordered_array;
            } else {
              this.finalHeaders = this.headers.slice(0, 10);
              return this.headers.slice(0, 10);
            }
          } else {
            this.finalHeaders = this.headers.slice(0, 10);
            return this.headers.slice(0, 10);
          }
        }
        case 'deletednegativekeyword': {
          const presets = LSS.datatableDatasets;
          if (presets.deletednegativekeyword) {
            const presetLSS = presets.deletednegativekeyword.split(',');
            if (presetLSS.length) {
              let tmpArr = (this.finalHeaders = this.headers.filter((set) =>
                presetLSS.includes(set.value)
              ));
              const ordered_array = this.mapOrder(tmpArr, presetLSS, 'value');
              return ordered_array;
            } else {
              this.finalHeaders = this.headers.slice(0, 10);
              return this.headers.slice(0, 10);
            }
          } else {
            this.finalHeaders = this.headers.slice(0, 10);
            return this.headers.slice(0, 10);
          }
        }
        case 'allnegativekeyword': {
          const presets = LSS.datatableDatasets;
          if (presets.allnegativekeyword) {
            const presetLSS = presets.allnegativekeyword.split(',');
            if (presetLSS.length) {
              let tmpArr = (this.finalHeaders = this.headers.filter((set) =>
                presetLSS.includes(set.value)
              ));
              const ordered_array = this.mapOrder(tmpArr, presetLSS, 'value');
              return ordered_array;
            } else {
              this.finalHeaders = this.headers.slice(0, 10);
              return this.headers.slice(0, 10);
            }
          } else {
            this.finalHeaders = this.headers.slice(0, 10);
            return this.headers.slice(0, 10);
          }
        }
        default: {
          this.finalHeaders = this.headers;
        }
      }
    },
    selectedCustomViewFromLSS() {
      const presets = LSS.customViews;
      switch (this.tabInfo) {
        case 'accounts': {
          if (presets) {
            let preset;
            if (this.customView == '0')
              preset = presets?.find(
                (o) => o._id == '1' && o.tab == 'accounts'
              );
            else
              preset = presets?.find(
                (o) => o._id == this.customView && o.tab == 'accounts'
              );
            if (!preset || !preset.DatatableDatasets?.accounts) {
              this.selectedHeaders = this.finalHeaders = this.headers.slice(
                0,
                10
              );
              return this.headers.slice(0, 10);
            }
            const presetLSS = preset.DatatableDatasets?.accounts.split(',');
            if (presetLSS.length) {
              let tmpArr = (this.finalHeaders = this.headers.filter((set) =>
                presetLSS.includes(set.value)
              ));
              const ordered_array = this.mapOrder(tmpArr, presetLSS, 'value');
              return ordered_array;
            } else {
              this.finalHeaders = this.headers.slice(0, 10);
              return this.headers.slice(0, 10);
            }
          } else {
            this.finalHeaders = this.headers.slice(0, 10);
            return this.headers.slice(0, 10);
          }
        }
        case 'apps': {
          if (presets) {
            let preset;
            if (this.customView == '0')
              preset = presets?.find((o) => o._id == '1' && o.tab == 'apps');
            else
              preset = presets?.find(
                (o) => o._id == this.customView && o.tab == 'apps'
              );
            if (!preset || !preset.DatatableDatasets?.apps) {
              this.selectedHeaders = this.finalHeaders = this.headers.slice(
                0,
                10
              );
              return this.headers.slice(0, 10);
            }

            const presetLSS = preset.DatatableDatasets?.apps.split(',');
            if (presetLSS.length) {
              let tmpArr = (this.finalHeaders = this.headers.filter((set) =>
                presetLSS.includes(set.value)
              ));
              const ordered_array = this.mapOrder(tmpArr, presetLSS, 'value');
              return ordered_array;
            } else {
              this.finalHeaders = this.headers.slice(0, 10);
              return this.headers.slice(0, 10);
            }
          } else {
            this.finalHeaders = this.headers.slice(0, 10);
            return this.headers.slice(0, 10);
          }
        }

        case 'livecampaign': {
          if (presets) {
            let preset;
            if (this.customView == '0')
              preset = presets?.find(
                (o) => o._id == '1' && o.tab == 'campaigns'
              );
            else
              preset = presets?.find(
                (o) => o._id == this.customView && o.tab == 'campaigns'
              );
            if (!preset || !preset.DatatableDatasets?.livecampaign) {
              this.selectedHeaders = this.finalHeaders = this.headers.slice(
                0,
                10
              );
              return this.headers.slice(0, 10);
            }
            const presetLSS = preset.DatatableDatasets?.livecampaign?.split(
              ','
            );
            if (presetLSS && presetLSS.length) {
              let tmpArr = (this.finalHeaders = this.headers.filter((set) =>
                presetLSS.includes(set.value)
              ));
              const ordered_array = this.mapOrder(tmpArr, presetLSS, 'value');
              return ordered_array;
            } else {
              this.finalHeaders = this.headers.slice(0, 10);
              return this.headers.slice(0, 10);
            }
          } else {
            this.finalHeaders = this.headers.slice(0, 10);
            return this.headers.slice(0, 10);
          }
        }
        case 'pausedcampaign': {
          if (presets) {
            let preset;
            if (this.customView == '0')
              preset = presets?.find(
                (o) => o._id == '1' && o.tab == 'campaigns'
              );
            else
              preset = presets?.find(
                (o) => o._id == this.customView && o.tab == 'campaigns'
              );
            if (!preset || !preset.DatatableDatasets?.pausedcampaign) {
              this.selectedHeaders = this.finalHeaders = this.headers.slice(
                0,
                10
              );
              return this.headers.slice(0, 10);
            }
            const presetLSS = preset.DatatableDatasets?.pausedcampaign?.split(
              ','
            );
            if (presetLSS && presetLSS.length) {
              let tmpArr = (this.finalHeaders = this.headers.filter((set) =>
                presetLSS.includes(set.value)
              ));
              const ordered_array = this.mapOrder(tmpArr, presetLSS, 'value');
              return ordered_array;
            } else {
              this.finalHeaders = this.headers.slice(0, 10);
              return this.headers.slice(0, 10);
            }
          } else {
            this.finalHeaders = this.headers.slice(0, 10);
            return this.headers.slice(0, 10);
          }
        }
        case 'deletedcampaign': {
          if (presets) {
            let preset;
            if (this.customView == '0')
              preset = presets?.find(
                (o) => o._id == '1' && o.tab == 'campaigns'
              );
            else
              preset = presets?.find(
                (o) => o._id == this.customView && o.tab == 'campaigns'
              );
            if (!preset || !preset.DatatableDatasets?.deletedcampaign) {
              this.selectedHeaders = this.finalHeaders = this.headers.slice(
                0,
                10
              );
              return this.headers.slice(0, 10);
            }
            const presetLSS = preset.DatatableDatasets?.deletedcampaign?.split(
              ','
            );
            if (presetLSS && presetLSS.length) {
              let tmpArr = (this.finalHeaders = this.headers.filter((set) =>
                presetLSS.includes(set.value)
              ));
              const ordered_array = this.mapOrder(tmpArr, presetLSS, 'value');
              return ordered_array;
            } else {
              this.finalHeaders = this.headers.slice(0, 10);
              return this.headers.slice(0, 10);
            }
          } else {
            this.finalHeaders = this.headers.slice(0, 10);
            return this.headers.slice(0, 10);
          }
        }

        case 'allcampaign': {
          if (presets) {
            let preset;
            if (this.customView == '0')
              preset = presets?.find(
                (o) => o._id == '1' && o.tab == 'campaigns'
              );
            else
              preset = presets?.find(
                (o) => o._id == this.customView && o.tab == 'campaigns'
              );
            if (!preset || !preset.DatatableDatasets?.allcampaign) {
              this.selectedHeaders = this.finalHeaders = this.headers.slice(
                0,
                10
              );
              return this.headers.slice(0, 10);
            }
            const presetLSS = preset.DatatableDatasets?.allcampaign?.split(',');
            if (presetLSS && presetLSS.length) {
              let tmpArr = (this.finalHeaders = this.headers.filter((set) =>
                presetLSS.includes(set.value)
              ));
              const ordered_array = this.mapOrder(tmpArr, presetLSS, 'value');
              return ordered_array;
            } else {
              this.finalHeaders = this.headers.slice(0, 10);
              return this.headers.slice(0, 10);
            }
          } else {
            this.finalHeaders = this.headers.slice(0, 10);
            return this.headers.slice(0, 10);
          }
        }
        case 'draftcampaign': {
          if (presets) {
            let preset;
            if (this.customView == '0')
              preset = presets?.find(
                (o) => o._id == '1' && o.tab == 'campaigns'
              );
            else
              preset = presets?.find(
                (o) => o._id == this.customView && o.tab == 'campaigns'
              );
            if (!preset || !preset.DatatableDatasets?.draftcampaign) {
              this.selectedHeaders = this.finalHeaders = this.headers.slice(
                0,
                10
              );
              return this.headers.slice(0, 10);
            }
            const presetLSS = preset.DatatableDatasets?.draftcampaign?.split(
              ','
            );
            if (presetLSS && presetLSS.length) {
              let tmpArr = (this.finalHeaders = this.headers.filter((set) =>
                presetLSS.includes(set.value)
              ));
              const ordered_array = this.mapOrder(tmpArr, presetLSS, 'value');
              return ordered_array;
            } else {
              this.finalHeaders = this.headers.slice(0, 10);
              return this.headers.slice(0, 10);
            }
          } else {
            this.finalHeaders = this.headers.slice(0, 10);
            return this.headers.slice(0, 10);
          }
        }
        case 'liveadgroup': {
          if (presets) {
            let preset;
            if (this.customView == '0')
              preset = presets?.find(
                (o) => o._id == '1' && o.tab == 'adgroups'
              );
            else
              preset = presets?.find(
                (o) => o._id == this.customView && o.tab == 'adgroups'
              );
            if (!preset || !preset.DatatableDatasets?.liveadgroup) {
              this.selectedHeaders = this.finalHeaders = this.headers.slice(
                0,
                10
              );
              return this.headers.slice(0, 10);
            }
            const presetLSS = preset.DatatableDatasets?.liveadgroup?.split(',');
            if (presetLSS && presetLSS.length) {
              let tmpArr = (this.finalHeaders = this.headers.filter((set) =>
                presetLSS.includes(set.value)
              ));
              const ordered_array = this.mapOrder(tmpArr, presetLSS, 'value');
              return ordered_array;
            } else {
              this.finalHeaders = this.headers.slice(0, 10);
              return this.headers.slice(0, 10);
            }
          } else {
            this.finalHeaders = this.headers.slice(0, 10);
            return this.headers.slice(0, 10);
          }
        }
        case 'pausedadgroup': {
          if (presets) {
            let preset;
            if (this.customView == '0')
              preset = presets?.find(
                (o) => o._id == '1' && o.tab == 'adgroups'
              );
            else
              preset = presets?.find(
                (o) => o._id == this.customView && o.tab == 'adgroups'
              );
            if (!preset || !preset.DatatableDatasets?.pausedadgroup) {
              this.selectedHeaders = this.finalHeaders = this.headers.slice(
                0,
                10
              );
              return this.headers.slice(0, 10);
            }
            const presetLSS = preset.DatatableDatasets?.pausedadgroup?.split(
              ','
            );
            if (presetLSS && presetLSS.length) {
              let tmpArr = (this.finalHeaders = this.headers.filter((set) =>
                presetLSS.includes(set.value)
              ));
              const ordered_array = this.mapOrder(tmpArr, presetLSS, 'value');
              return ordered_array;
            } else {
              this.finalHeaders = this.headers.slice(0, 10);
              return this.headers.slice(0, 10);
            }
          } else {
            this.finalHeaders = this.headers.slice(0, 10);
            return this.headers.slice(0, 10);
          }
        }
        case 'deletedadgroup': {
          if (presets) {
            let preset;
            if (this.customView == '0')
              preset = presets?.find(
                (o) => o._id == '1' && o.tab == 'adgroups'
              );
            else
              preset = presets?.find(
                (o) => o._id == this.customView && o.tab == 'adgroups'
              );
            if (!preset || !preset.DatatableDatasets?.deletedadgroup) {
              this.selectedHeaders = this.finalHeaders = this.headers.slice(
                0,
                10
              );
              return this.headers.slice(0, 10);
            }
            const presetLSS = preset.DatatableDatasets?.deletedadgroup?.split(
              ','
            );
            if (presetLSS && presetLSS.length) {
              let tmpArr = (this.finalHeaders = this.headers.filter((set) =>
                presetLSS.includes(set.value)
              ));
              const ordered_array = this.mapOrder(tmpArr, presetLSS, 'value');
              return ordered_array;
            } else {
              this.finalHeaders = this.headers.slice(0, 10);
              return this.headers.slice(0, 10);
            }
          } else {
            this.finalHeaders = this.headers.slice(0, 10);
            return this.headers.slice(0, 10);
          }
        }
        case 'alladgroup': {
          if (presets) {
            let preset;
            if (this.customView == '0')
              preset = presets?.find(
                (o) => o._id == '1' && o.tab == 'adgroups'
              );
            else
              preset = presets?.find(
                (o) => o._id == this.customView && o.tab == 'adgroups'
              );
            if (!preset || !preset.DatatableDatasets?.alladgroup) {
              this.selectedHeaders = this.finalHeaders = this.headers.slice(
                0,
                10
              );
              return this.headers.slice(0, 10);
            }
            const presetLSS = preset.DatatableDatasets?.alladgroup?.split(',');
            if (presetLSS && presetLSS.length) {
              let tmpArr = (this.finalHeaders = this.headers.filter((set) =>
                presetLSS.includes(set.value)
              ));
              const ordered_array = this.mapOrder(tmpArr, presetLSS, 'value');
              return ordered_array;
            } else {
              this.finalHeaders = this.headers.slice(0, 10);
              return this.headers.slice(0, 10);
            }
          } else {
            this.finalHeaders = this.headers.slice(0, 10);
            return this.headers.slice(0, 10);
          }
        }

        case 'livekeyword': {
          if (presets) {
            let preset;
            if (this.customView == '0')
              preset = presets?.find(
                (o) => o._id == '1' && o.tab == 'keywords'
              );
            else
              preset = presets?.find(
                (o) => o._id == this.customView && o.tab == 'keywords'
              );
            if (!preset || !preset.DatatableDatasets?.livekeyword) {
              this.selectedHeaders = this.finalHeaders = this.headers.slice(
                0,
                10
              );
              return this.headers.slice(0, 10);
            }
            const presetLSS = preset.DatatableDatasets?.livekeyword?.split(',');
            if (presetLSS && presetLSS.length) {
              let tmpArr = (this.finalHeaders = this.headers.filter((set) =>
                presetLSS.includes(set.value)
              ));
              const ordered_array = this.mapOrder(tmpArr, presetLSS, 'value');
              return ordered_array;
            } else {
              this.finalHeaders = this.headers.slice(0, 10);
              return this.headers.slice(0, 10);
            }
          } else {
            this.finalHeaders = this.headers.slice(0, 10);
            return this.headers.slice(0, 10);
          }
        }
        case 'pausedkeyword': {
          if (presets) {
            let preset;
            if (this.customView == '0')
              preset = presets?.find(
                (o) => o._id == '1' && o.tab == 'keywords'
              );
            else
              preset = presets?.find(
                (o) => o._id == this.customView && o.tab == 'keywords'
              );
            if (!preset || !preset.DatatableDatasets?.pausedkeyword) {
              this.selectedHeaders = this.finalHeaders = this.headers.slice(
                0,
                10
              );
              return this.headers.slice(0, 10);
            }
            const presetLSS = preset.DatatableDatasets?.pausedkeyword?.split(
              ','
            );
            if (presetLSS && presetLSS.length) {
              let tmpArr = (this.finalHeaders = this.headers.filter((set) =>
                presetLSS.includes(set.value)
              ));
              const ordered_array = this.mapOrder(tmpArr, presetLSS, 'value');
              return ordered_array;
            } else {
              this.finalHeaders = this.headers.slice(0, 10);
              return this.headers.slice(0, 10);
            }
          } else {
            this.finalHeaders = this.headers.slice(0, 10);
            return this.headers.slice(0, 10);
          }
        }
        case 'deletedkeyword': {
          if (presets) {
            let preset;
            if (this.customView == '0')
              preset = presets?.find(
                (o) => o._id == '1' && o.tab == 'keywords'
              );
            else
              preset = presets?.find(
                (o) => o._id == this.customView && o.tab == 'keywords'
              );
            if (!preset || !preset.DatatableDatasets?.deletedkeyword) {
              this.selectedHeaders = this.finalHeaders = this.headers.slice(
                0,
                10
              );
              return this.headers.slice(0, 10);
            }
            const presetLSS = preset.DatatableDatasets?.deletedkeyword?.split(
              ','
            );
            if (presetLSS && presetLSS.length) {
              let tmpArr = (this.finalHeaders = this.headers.filter((set) =>
                presetLSS.includes(set.value)
              ));
              const ordered_array = this.mapOrder(tmpArr, presetLSS, 'value');
              return ordered_array;
            } else {
              this.finalHeaders = this.headers.slice(0, 10);
              return this.headers.slice(0, 10);
            }
          } else {
            this.finalHeaders = this.headers.slice(0, 10);
            return this.headers.slice(0, 10);
          }
        }
        case 'allkeyword': {
          if (presets) {
            let preset;
            if (this.customView == '0')
              preset = presets?.find(
                (o) => o._id == '1' && o.tab == 'keywords'
              );
            else
              preset = presets?.find(
                (o) => o._id == this.customView && o.tab == 'keywords'
              );
            if (!preset || !preset.DatatableDatasets?.allkeyword) {
              this.selectedHeaders = this.finalHeaders = this.headers.slice(
                0,
                10
              );
              return this.headers.slice(0, 10);
            }
            const presetLSS = preset.DatatableDatasets?.allkeyword?.split(',');
            if (presetLSS && presetLSS.length) {
              let tmpArr = (this.finalHeaders = this.headers.filter((set) =>
                presetLSS.includes(set.value)
              ));
              const ordered_array = this.mapOrder(tmpArr, presetLSS, 'value');
              return ordered_array;
            } else {
              this.finalHeaders = this.headers.slice(0, 10);
              return this.headers.slice(0, 10);
            }
          } else {
            this.finalHeaders = this.headers.slice(0, 10);
            return this.headers.slice(0, 10);
          }
        }
        case 'ads': {
          if (presets) {
            let preset;
            if (this.customView == '0')
              preset = presets?.find((o) => o._id == '1' && o.tab == 'ads');
            else
              preset = presets?.find(
                (o) => o._id == this.customView && o.tab == 'ads'
              );
            if (!preset || !preset.DatatableDatasets?.ads) {
              this.selectedHeaders = this.finalHeaders = this.headers.slice(
                0,
                10
              );
              return this.headers.slice(0, 10);
            }
            const presetLSS = preset.DatatableDatasets?.ads.split(',');
            if (presetLSS && presetLSS.length) {
              let tmpArr = (this.finalHeaders = this.headers.filter((set) =>
                presetLSS.includes(set.value)
              ));
              const ordered_array = this.mapOrder(tmpArr, presetLSS, 'value');
              return ordered_array;
            } else {
              this.finalHeaders = this.headers.slice(0, 10);
              return this.headers.slice(0, 10);
            }
          } else {
            this.finalHeaders = this.headers.slice(0, 10);
            return this.headers.slice(0, 10);
          }
        }
        case 'pausedads': {
          if (presets) {
            let preset;
            if (this.customView == '0')
              preset = presets?.find((o) => o._id == '1' && o.tab == 'ads');
            else
              preset = presets?.find(
                (o) => o._id == this.customView && o.tab == 'ads'
              );
            if (!preset || !preset.DatatableDatasets?.pausedads) {
              this.selectedHeaders = this.finalHeaders = this.headers.slice(
                0,
                10
              );
              return this.headers.slice(0, 10);
            }
            const presetLSS = preset.DatatableDatasets?.pausedads.split(',');
            if (presetLSS && presetLSS.length) {
              let tmpArr = (this.finalHeaders = this.headers.filter((set) =>
                presetLSS.includes(set.value)
              ));
              const ordered_array = this.mapOrder(tmpArr, presetLSS, 'value');
              return ordered_array;
            } else {
              this.finalHeaders = this.headers.slice(0, 10);
              return this.headers.slice(0, 10);
            }
          } else {
            this.finalHeaders = this.headers.slice(0, 10);
            return this.headers.slice(0, 10);
          }
        }
        case 'deletedads': {
          if (presets) {
            let preset;
            if (this.customView == '0')
              preset = presets?.find((o) => o._id == '1' && o.tab == 'ads');
            else
              preset = presets?.find(
                (o) => o._id == this.customView && o.tab == 'ads'
              );
            if (!preset || !preset.DatatableDatasets?.deletedads) {
              this.selectedHeaders = this.finalHeaders = this.headers.slice(
                0,
                10
              );
              return this.headers.slice(0, 10);
            }
            const presetLSS = preset.DatatableDatasets?.deletedads.split(',');
            if (presetLSS && presetLSS.length) {
              let tmpArr = (this.finalHeaders = this.headers.filter((set) =>
                presetLSS.includes(set.value)
              ));
              const ordered_array = this.mapOrder(tmpArr, presetLSS, 'value');
              return ordered_array;
            } else {
              this.finalHeaders = this.headers.slice(0, 10);
              return this.headers.slice(0, 10);
            }
          } else {
            this.finalHeaders = this.headers.slice(0, 10);
            return this.headers.slice(0, 10);
          }
        }
        case 'allads': {
          if (presets) {
            let preset;
            if (this.customView == '0')
              preset = presets?.find((o) => o._id == '1' && o.tab == 'ads');
            else
              preset = presets?.find(
                (o) => o._id == this.customView && o.tab == 'ads'
              );
            if (!preset || !preset.DatatableDatasets?.allads) {
              this.selectedHeaders = this.finalHeaders = this.headers.slice(
                0,
                10
              );
              return this.headers.slice(0, 10);
            }
            const presetLSS = preset.DatatableDatasets?.allads.split(',');
            if (presetLSS && presetLSS.length) {
              let tmpArr = (this.finalHeaders = this.headers.filter((set) =>
                presetLSS.includes(set.value)
              ));
              const ordered_array = this.mapOrder(tmpArr, presetLSS, 'value');
              return ordered_array;
            } else {
              this.finalHeaders = this.headers.slice(0, 10);
              return this.headers.slice(0, 10);
            }
          } else {
            this.finalHeaders = this.headers.slice(0, 10);
            return this.headers.slice(0, 10);
          }
        }
        case 'searchterm': {
          if (presets) {
            let preset;
            if (this.customView == '0')
              preset = presets?.find(
                (o) => o._id == '1' && o.tab == 'searchterm'
              );
            else
              preset = presets?.find(
                (o) => o._id == this.customView && o.tab == 'searchterm'
              );
            if (!preset || !preset.DatatableDatasets?.searchterm) {
              this.selectedHeaders = this.finalHeaders = this.headers.slice(
                0,
                10
              );
              return this.headers.slice(0, 10);
            }
            const presetLSS = preset.DatatableDatasets?.searchterm.split(',');
            if (presetLSS && presetLSS.length) {
              let tmpArr = (this.finalHeaders = this.headers.filter((set) =>
                presetLSS.includes(set.value)
              ));
              const ordered_array = this.mapOrder(tmpArr, presetLSS, 'value');
              return ordered_array;
            } else {
              this.finalHeaders = this.headers.slice(0, 10);
              return this.headers.slice(0, 10);
            }
          } else {
            this.finalHeaders = this.headers.slice(0, 10);
            return this.headers.slice(0, 10);
          }
        }
        case 'livenegativekeyword': {
          if (presets) {
            let preset;
            if (this.customView == '0')
              preset = presets?.find(
                (o) => o._id == '1' && o.tab == 'negativekeywords'
              );
            else
              preset = presets?.find(
                (o) => o._id == this.customView && o.tab == 'negativekeywords'
              );
            if (!preset || !preset.DatatableDatasets?.livenegativekeyword) {
              this.selectedHeaders = this.finalHeaders = this.headers.slice(
                0,
                10
              );
              return this.headers.slice(0, 10);
            }
            const presetLSS = preset.DatatableDatasets?.livenegativekeyword?.split(
              ','
            );
            if (presetLSS && presetLSS.length) {
              let tmpArr = (this.finalHeaders = this.headers.filter((set) =>
                presetLSS.includes(set.value)
              ));
              const ordered_array = this.mapOrder(tmpArr, presetLSS, 'value');
              return ordered_array;
            } else {
              this.finalHeaders = this.headers.slice(0, 10);
              return this.headers.slice(0, 10);
            }
          } else {
            this.finalHeaders = this.headers.slice(0, 10);
            return this.headers.slice(0, 10);
          }
        }
        case 'pausednegativekeyword': {
          if (presets) {
            let preset;
            if (this.customView == '0')
              preset = presets?.find(
                (o) => o._id == '1' && o.tab == 'negativekeywords'
              );
            else
              preset = presets?.find(
                (o) => o._id == this.customView && o.tab == 'negativekeywords'
              );
            if (!preset || !preset.DatatableDatasets?.pausednegativekeyword) {
              this.selectedHeaders = this.finalHeaders = this.headers.slice(
                0,
                10
              );
              return this.headers.slice(0, 10);
            }
            const presetLSS = preset.DatatableDatasets?.pausednegativekeyword?.split(
              ','
            );
            if (presetLSS && presetLSS.length) {
              let tmpArr = (this.finalHeaders = this.headers.filter((set) =>
                presetLSS.includes(set.value)
              ));
              const ordered_array = this.mapOrder(tmpArr, presetLSS, 'value');
              return ordered_array;
            } else {
              this.finalHeaders = this.headers.slice(0, 10);
              return this.headers.slice(0, 10);
            }
          } else {
            this.finalHeaders = this.headers.slice(0, 10);
            return this.headers.slice(0, 10);
          }
        }
        case 'deletednegativekeyword': {
          if (presets) {
            let preset;
            if (this.customView == '0')
              preset = presets?.find(
                (o) => o._id == '1' && o.tab == 'negativekeywords'
              );
            else
              preset = presets?.find(
                (o) => o._id == this.customView && o.tab == 'negativekeywords'
              );
            if (!preset || !preset.DatatableDatasets?.deletednegativekeyword) {
              this.selectedHeaders = this.finalHeaders = this.headers.slice(
                0,
                10
              );
              return this.headers.slice(0, 10);
            }
            const presetLSS = preset.DatatableDatasets?.deletednegativekeyword?.split(
              ','
            );
            if (presetLSS && presetLSS.length) {
              let tmpArr = (this.finalHeaders = this.headers.filter((set) =>
                presetLSS.includes(set.value)
              ));
              const ordered_array = this.mapOrder(tmpArr, presetLSS, 'value');
              return ordered_array;
            } else {
              this.finalHeaders = this.headers.slice(0, 10);
              return this.headers.slice(0, 10);
            }
          } else {
            this.finalHeaders = this.headers.slice(0, 10);
            return this.headers.slice(0, 10);
          }
        }
        case 'allnegativekeyword': {
          if (presets) {
            let preset;
            if (this.customView == '0')
              preset = presets?.find(
                (o) => o._id == '1' && o.tab == 'negativekeywords'
              );
            else
              preset = presets?.find(
                (o) => o._id == this.customView && o.tab == 'negativekeywords'
              );
            if (!preset || !preset.DatatableDatasets?.allnegativekeyword) {
              this.selectedHeaders = this.finalHeaders = this.headers.slice(
                0,
                10
              );
              return this.headers.slice(0, 10);
            }
            const presetLSS = preset.DatatableDatasets?.allnegativekeyword?.split(
              ','
            );
            if (presetLSS && presetLSS.length) {
              let tmpArr = (this.finalHeaders = this.headers.filter((set) =>
                presetLSS.includes(set.value)
              ));
              const ordered_array = this.mapOrder(tmpArr, presetLSS, 'value');
              return ordered_array;
            } else {
              this.finalHeaders = this.headers.slice(0, 10);
              return this.headers.slice(0, 10);
            }
          } else {
            this.finalHeaders = this.headers.slice(0, 10);
            return this.headers.slice(0, 10);
          }
        }
        default: {
          this.finalHeaders = this.headers;
        }
      }
    },
    mapOrder(array, order, key) {
      array.sort(function (a, b) {
        var A = a[key],
          B = b[key];

        if (order.indexOf(A) > order.indexOf(B)) {
          return 1;
        } else {
          return -1;
        }
      });

      return array;
    },

    calculateSum(key) {
      if (
        ![
          'impressions',
          'taps',
          'installs',
          'localSpend',
          'latOnInstalls',
          'latOffInstalls',
          'redownloads',
          'newDownloads',
        ].includes(key)
      )
        return '-';

      let subArray = this.items.map((item) => ({
        avgCPA: numberDecimal(item.total?.avgCPA.amount, true),
        avgCPT: numberDecimal(item.total?.avgCPT.amount, true),
        avgCPM: numberDecimal(item.total?.avgCPM.amount, true),
        conversionRate: numberDecimal(item.total?.conversionRate, true),
        localSpend: numberDecimal(item.total?.localSpend.amount, true),
        ttr: numberDecimal(item.total?.ttr, true),

        impressions: numberFormat(item.total?.impressions, true),
        installs: numberFormat(item.total?.installs, true),
        latOffInstalls: numberFormat(item.total?.latOffInstalls, true),
        latOnInstalls: numberFormat(item.total?.latOnInstalls, true),
        newDownloads: numberFormat(item.total?.newDownloads, true),
        redownloads: numberFormat(item.total?.redownloads, true),
        taps: numberFormat(item.total?.taps, true),
      }));

      const total = subArray.reduce(
        (a, b) => Number(a) + (Number(b[key]) || 0),
        0
      );
      
      return numberFormat(total);
    },

    calcAttrSum(key, isMoney) {
      if (
        !['calcEventCount', 'calcGoalsRevenue', 'calcEventCountSec'].includes(
          key
        )
      )
        return '-';

      const attrResponseKeys = [
        'appAttributionData',
        'campaignAttributionData',
        'adgroupAttributionData',
        'keywordAttributionData',
        'productPageAttributionData',
        'searchTermAttributionData',
      ];

      const responseItem = this.items[0];

      const field = attrResponseKeys?.find((key) => key in responseItem);

      if (field) {
        let subArray = this.items.map((item) => ({
          calcCostPerGoal: this.calcCostPerGoal(item, field),
          calcEventCount: this.calcEventCount(item, field),
          calcCostPerGoalSec: this.calcCostPerGoalSec(item, field),
          calcEventCountSec: this.calcEventCountSec(item, field),
          calcGoalRoas: this.calcGoalRoas(item, field),
          calcGoalsRevenue: this.calcGoalsRevenue(item, field),
          calcRevenuePerGoal: this.calcRevenuePerGoal(item, field),
          calcARPU: this.calcARPU(item, field),
          calcAMPU: this.calcAMPU(item, field),
        }));
        subArray.map((item) => {
          for (const key in item) {
            item[key] = Number(String(item[key]).split(',').join(''));
          }
        });
        const total = subArray.reduce(
          (a, b) => Number(a) + (Number(b[key]) || 0),
          0
        );
        if (key == 'calcGoalsRevenue') {
          let currency = '';
          if (key == 'localSpend') {
            currency = this.getCurrencyIcon(item?.orgMetadata?.currency);
          }
          return currency + numberDecimal(total);
        } else {
          return numberFormat(total);
        }
      }
    },

    calcGoalsRevenue(item, field) {
      return numberDecimal(Number(item[field]?.content?.revenue?.total || 0));
    },

    calcGoalRoas(item, field) {
      if (!Number(item.total?.localSpend?.amount) || 0) return '0';
      return numberDecimal(
        Number(item[field]?.content?.revenue?.total || 0) /
          Number(item.total?.localSpend?.amount)
      );
    },

    calcEventCount(item, field) {
      return numberFormat(Number(item[field]?.content?.eventCount?.count || 0));
    },

    calcEventCountSec(item, field) {
      return numberFormat(
        Number(item[field]?.secondaryContent?.eventCount?.count || 0)
      );
    },

    calcCostPerGoal(item, field) {
      if (!Number(item[field]?.content?.eventCount?.count || 0)) return '0';
      return numberDecimal(
        Number(item.total?.localSpend?.amount) /
          Number(item[field]?.content?.eventCount?.count)
      );
    },

    calcCostPerGoalSec(item, field) {
      if (!Number(item[field]?.secondaryContent?.eventCount?.count || 0))
        return '0';
      return numberDecimal(
        Number(item.total?.localSpend?.amount) /
          Number(item[field]?.secondaryContent?.eventCount?.count)
      );
    },

    calcRevenuePerGoal(item, field) {
      if (!Number(item[field]?.content?.eventCount?.count || 0)) return '0';
      return numberDecimal(
        Number(item[field]?.content?.revenue?.total || 0) /
          Number(item[field]?.content?.eventCount?.count)
      );
    },

    calcARPU(item, field) {
      if (!Number(item.total?.installs || 0)) return '0';
      return numberDecimal(
        Number(item[field]?.content?.revenue?.total || 0) /
          Number(item.total?.installs)
      );
    },

    calcAMPU(item, field) {
      const installs = String(item.total?.installs || 0)
        .split(',')
        .join('');
      const amount = String(item.total?.localSpend?.amount || 0)
        .replace(/[$]/g, '')
        .split(',')
        .join('');
      if (!Number(installs || 0)) return '0.00';
      const rev = String(item[field]?.content?.revenue?.total || 0)
        .replace(/[$]/g, '')
        .split(',')
        .join('');
      const goalRoas = String(numberDecimal(Number(rev || 0) / Number(amount)))
        .replace(/[$]/g, '')
        .split(',')
        .join('');
      return numberDecimal(Number(goalRoas || 0) / Number(installs));
    },

    downloadReport() {
      if (this.tabInfo == 'draftcampaign') {
        this.$notify.success('Downloading reports.');
        this.exportExcel();
        return;
      }
      this.$emit('downloadReport', {
        reportFor: this.tabInfo,
      });
      this.progressBar = true;
    },

    exportExcel() {
      let createXLSLFormatObj = [];
      let newXlsHeader = [];

      this.finalHeaders.map((column) => {
        newXlsHeader.push(column.text);
      });
      createXLSLFormatObj.push(newXlsHeader);
      this.items.map((value) => {
        let innerRowData = [];
        this.finalHeaders.map((val) => {
          //--start- to find attribution field
          const attrResponseKeys = [
            'appAttributionData',
            'campaignAttributionData',
            'adgroupAttributionData',
            'keywordAttributionData',
            'productPageAttributionData',
            'searchTermAttributionData',
          ];

          const responseItem = this.items[0];
          const field = attrResponseKeys?.find((key) => key in responseItem);
          //--end

          let fieldValue = value[val.value];
          if (val.value.split('.').length > 1) {
            fieldValue = this.getNestedValue(value, val.value);
          }

          //--Start- To calculate attribution data
          if (val.value == 'calcGoalsRevenue') {
            fieldValue = this.calcGoalsRevenue(value, field);
          }
          if (val.value == 'calcEventCount') {
            fieldValue = this.calcEventCount(value, field);
          }
          if (val.value == 'calcEventCountSec') {
            fieldValue = this.calcEventCountSec(value, field);
          }
          if (val.value == 'calcGoalRoas') {
            fieldValue = this.calcGoalRoas(value, field);
          }
          if (val.value == 'calcCostPerGoal') {
            fieldValue = this.calcCostPerGoal(value, field);
          }
          if (val.value == 'calcCostPerGoalSec') {
            fieldValue = this.calcCostPerGoalSec(value, field);
          }
          if (val.value == 'calcRevenuePerGoal') {
            fieldValue = this.calcRevenuePerGoal(value, field);
          }
          if (val.value == 'calcARPU') {
            fieldValue = this.calcARPU(value, field);
          }
          if (val.value == 'calcAMPU') {
            fieldValue = this.calcAMPU(value, field);
          }

          //--end

          if (val.dataFormat && typeof val.dataFormat === 'function') {
            innerRowData.push(val.dataFormat(fieldValue));
          } else {
            innerRowData.push(fieldValue);
          }
        });
        createXLSLFormatObj.push(innerRowData);
      });
      let fileName = this.fileName + '-' + this.tab + '.' + this.fileType;
      let ws_name = this.sheetName;
      let wb = XLSX.utils.book_new(),
        ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);
      XLSX.utils.book_append_sheet(wb, ws, ws_name);
      XLSX.writeFile(wb, fileName);
    },

    getNestedValue(object, string) {
      string = string.replace(/\[(\w+)\]/g, '.$1');
      string = string.replace(/^\./, '');
      let a = string.split('.');
      for (let i = 0, n = a.length; i < n; ++i) {
        let k = a[i];
        if (object === null) {
          return;
        }
        if (k in object) {
          object = object[k];
        } else {
          return;
        }
      }
      return object;
    },
  },
  directives: {
    'sortable-table': {
      inserted: (el, binding) => {
        el.querySelectorAll('th').forEach((draggableEl) => {
          // Need a class watcher because sorting v-data-table rows asc/desc removes the sortHandle class
          watchClass(draggableEl, 'sortHandle');
          draggableEl.classList.add('sortHandle');
        });
        Sortable.create(
          el.querySelector('tr'),
          binding.value ? { ...binding.value, handle: '.sortHandle' } : {}
        );
      },
    },
  },
  beforeMount() {
    const presets = LSS.customViews;
    switch (this.tabInfo) {
      case 'ads': {
        if (presets?.find((o) => o.PinnedStatus && o.tab == 'ads')) return;
        let preset = presets?.find((o) => o._id == '1' && o.tab == 'ads');
        if (!preset) {
          this.selectedHeaders = this.finalHeaders = this.headers.slice(0, 10);
          return this.headers.slice(0, 10);
        }
        break;
      }
      case 'pausedads': {
        if (presets?.find((o) => o.PinnedStatus && o.tab == 'ads')) return;
        let preset = presets?.find((o) => o._id == '1' && o.tab == 'ads');
        if (!preset) {
          this.selectedHeaders = this.finalHeaders = this.headers.slice(0, 10);
          return this.headers.slice(0, 10);
        }
        break;
      }
      case 'deletedads': {
        if (presets?.find((o) => o.PinnedStatus && o.tab == 'ads')) return;
        let preset = presets?.find((o) => o._id == '1' && o.tab == 'ads');
        if (!preset) {
          this.selectedHeaders = this.finalHeaders = this.headers.slice(0, 10);
          return this.headers.slice(0, 10);
        }
        break;
      }
      case 'allads': {
        if (presets?.find((o) => o.PinnedStatus && o.tab == 'ads')) return;
        let preset = presets?.find((o) => o._id == '1' && o.tab == 'ads');
        if (!preset) {
          this.selectedHeaders = this.finalHeaders = this.headers.slice(0, 10);
          return this.headers.slice(0, 10);
        }
        break;
      }
      case 'searchterm': {
        if (presets?.find((o) => o.PinnedStatus && o.tab == 'searchterm'))
          return;
        let preset = presets?.find(
          (o) => o._id == '1' && o.tab == 'searchterm'
        );
        if (!preset) {
          this.selectedHeaders = this.finalHeaders = this.headers.slice(0, 10);
          return this.headers.slice(0, 10);
        }
        break;
      }
      case 'accounts': {
        if (presets?.find((o) => o.PinnedStatus && o.tab == 'accounts')) return;
        let preset = presets?.find((o) => o._id == '1' && o.tab == 'accounts');
        if (!preset) {
          this.selectedHeaders = this.finalHeaders = this.headers.slice(0, 10);
          return this.headers.slice(0, 10);
        }
        break;
      }
      case 'apps': {
        if (presets?.find((o) => o.PinnedStatus && o.tab == 'apps')) return;
        let preset = presets?.find((o) => o._id == '1' && o.tab == 'apps');
        if (!preset) {
          this.selectedHeaders = this.finalHeaders = this.headers.slice(0, 10);
          return this.headers.slice(0, 10);
        }
        break;
      }
      case 'livecampaign': {
        if (presets?.find((o) => o.PinnedStatus && o.tab == 'campaigns'))
          return;
        let preset = presets?.find((o) => o._id == '1' && o.tab == 'campaigns');
        if (!preset) {
          this.selectedHeaders = this.finalHeaders = this.headers.slice(0, 10);
          return this.headers.slice(0, 10);
        }
        break;
      }
      case 'pausedcampaign': {
        if (presets?.find((o) => o.PinnedStatus && o.tab == 'campaigns'))
          return;
        let preset = presets?.find((o) => o._id == '1' && o.tab == 'campaigns');
        if (!preset) {
          this.selectedHeaders = this.finalHeaders = this.headers.slice(0, 10);
          return this.headers.slice(0, 10);
        }
        break;
      }
      case 'deletedcampaign': {
        if (presets?.find((o) => o.PinnedStatus && o.tab == 'campaigns'))
          return;
        let preset = presets?.find((o) => o._id == '1' && o.tab == 'campaigns');
        if (!preset) {
          this.selectedHeaders = this.finalHeaders = this.headers.slice(0, 10);
          return this.headers.slice(0, 10);
        }
        break;
      }

      case 'allcampaign': {
        if (presets?.find((o) => o.PinnedStatus && o.tab == 'campaigns'))
          return;
        let preset = presets?.find((o) => o._id == '1' && o.tab == 'campaigns');
        if (!preset) {
          this.selectedHeaders = this.finalHeaders = this.headers.slice(0, 10);
          return this.headers.slice(0, 10);
        }
        break;
      }
      case 'draftcampaign': {
        if (presets?.find((o) => o.PinnedStatus && o.tab == 'campaigns'))
          return;
        let preset = presets?.find((o) => o._id == '1' && o.tab == 'campaigns');
        if (!preset) {
          this.selectedHeaders = this.finalHeaders = this.headers.slice(0, 10);
          return this.headers.slice(0, 10);
        }
        break;
      }
      case 'liveadgroup': {
        if (presets?.find((o) => o.PinnedStatus && o.tab == 'adgroups')) return;
        let preset = presets?.find((o) => o._id == '1' && o.tab == 'adgroups');
        if (!preset) {
          this.selectedHeaders = this.finalHeaders = this.headers.slice(0, 10);
          return this.headers.slice(0, 10);
        }
        break;
      }
      case 'pausedadgroup': {
        if (presets?.find((o) => o.PinnedStatus && o.tab == 'adgroups')) return;
        let preset = presets?.find((o) => o._id == '1' && o.tab == 'adgroups');
        if (!preset) {
          this.selectedHeaders = this.finalHeaders = this.headers.slice(0, 10);
          return this.headers.slice(0, 10);
        }
        break;
      }
      case 'deletedadgroup': {
        if (presets?.find((o) => o.PinnedStatus && o.tab == 'adgroups')) return;
        let preset = presets?.find((o) => o._id == '1' && o.tab == 'adgroups');
        if (!preset) {
          this.selectedHeaders = this.finalHeaders = this.headers.slice(0, 10);
          return this.headers.slice(0, 10);
        }
        break;
      }
      case 'alladgroup': {
        if (presets?.find((o) => o.PinnedStatus && o.tab == 'adgroups')) return;
        let preset = presets?.find((o) => o._id == '1' && o.tab == 'adgroups');
        if (!preset) {
          this.selectedHeaders = this.finalHeaders = this.headers.slice(0, 10);
          return this.headers.slice(0, 10);
        }
        break;
      }
      case 'livekeyword': {
        if (presets?.find((o) => o.PinnedStatus && o.tab == 'keywords')) return;
        let preset = presets?.find((o) => o._id == '1' && o.tab == 'keywords');
        if (!preset) {
          this.selectedHeaders = this.finalHeaders = this.headers.slice(0, 10);
          return this.headers.slice(0, 10);
        }
        break;
      }
      case 'pausedkeyword': {
        if (presets?.find((o) => o.PinnedStatus && o.tab == 'keywords')) return;
        let preset = presets?.find((o) => o._id == '1' && o.tab == 'keywords');
        if (!preset) {
          this.selectedHeaders = this.finalHeaders = this.headers.slice(0, 10);
          return this.headers.slice(0, 10);
        }
        break;
      }
      case 'deletedkeyword': {
        if (presets?.find((o) => o.PinnedStatus && o.tab == 'keywords')) return;
        let preset = presets?.find((o) => o._id == '1' && o.tab == 'keywords');
        if (!preset) {
          this.selectedHeaders = this.finalHeaders = this.headers.slice(0, 10);
          return this.headers.slice(0, 10);
        }
        break;
      }
      case 'allkeyword': {
        if (presets?.find((o) => o.PinnedStatus && o.tab == 'keywords')) return;
        let preset = presets?.find((o) => o._id == '1' && o.tab == 'keywords');
        if (!preset) {
          this.selectedHeaders = this.finalHeaders = this.headers.slice(0, 10);
          return this.headers.slice(0, 10);
        }
        break;
      }
      case 'livenegativekeyword': {
        if (presets?.find((o) => o.PinnedStatus && o.tab == 'negativekeywords'))
          return;
        let preset = presets?.find(
          (o) => o._id == '1' && o.tab == 'negativekeywords'
        );
        if (!preset) {
          this.selectedHeaders = this.finalHeaders = this.headers.slice(0, 10);
          return this.headers.slice(0, 10);
        }
        break;
      }
      case 'pausednegativekeyword': {
        if (presets?.find((o) => o.PinnedStatus && o.tab == 'negativekeywords'))
          return;
        let preset = presets?.find(
          (o) => o._id == '1' && o.tab == 'negativekeywords'
        );
        if (!preset) {
          this.selectedHeaders = this.finalHeaders = this.headers.slice(0, 10);
          return this.headers.slice(0, 10);
        }
        break;
      }
      case 'deletednegativekeyword': {
        if (presets?.find((o) => o.PinnedStatus && o.tab == 'negativekeywords'))
          return;
        let preset = presets?.find(
          (o) => o._id == '1' && o.tab == 'negativekeywords'
        );
        if (!preset) {
          this.selectedHeaders = this.finalHeaders = this.headers.slice(0, 10);
          return this.headers.slice(0, 10);
        }
        break;
      }
      case 'allnegativekeyword': {
        if (presets?.find((o) => o.PinnedStatus && o.tab == 'negativekeywords'))
          return;
        let preset = presets?.find(
          (o) => o._id == '1' && o.tab == 'negativekeywords'
        );
        if (!preset) {
          this.selectedHeaders = this.finalHeaders = this.headers.slice(0, 10);
          return this.headers.slice(0, 10);
        }
        break;
      }
    }
  },
  mounted() {
    this.pagination['sortBy'] = [this.sortBy];
    this.pagination['sortDesc'] = [this.sortDesc];

    this.$root.$on('datatable-view-update', () => {
      if (LSS.persisteDataset !== this.selectedHeaderView) {
        this.selectedHeaderView = LSS.persisteDataset;
      }
    });

    this.$root.$on('custom-dataset-update', (val) => {
      this.selectedCustomViewFromLSS();
    });
  },
  updated() {
    this.pagination['sortBy'] = [this.sortBy];
    this.pagination['sortDesc'] = [this.sortDesc];
  },
  beforeDestroy() {
    this.$root.$off('datatable-view-update');
    this.$root.$off('custom-dataset-update');
    this.localItems = null;
    this.rowItems = null;
    this.goalsHeaders = null;
    this.SubscriptionPermissionsEnums = null;
    this.prevHeaderValue = null;
    this.goalAttr = null;
    this.appsFilter = null;
    this.accountsFilter = null;
    this.campaignsSearchLiveFilter = null;
    this.campaignsSearchPausedFilter = null;
    this.campaignsSearchDeletedFilter = null;
    this.campaignsSearchAllFilter = null;
    this.adgroupSearchLiveFilter = null;
    this.adgroupSearchPausedFilter = null;
    this.adgroupSearchDeletedFilter = null;
    this.adgroupSearchAllFilter = null;
    this.negKeywordSearchLiveFilter = null;
    this.negKeywordSearchPausedFilter = null;
    this.negKeywordSearchDeletedFilter = null;
    this.negKeywordSearchAllFilter = null;
    this.adsdSearchLiveFilter = null;
    this.adsdSearchPausedFilter = null;
    this.adsdSearchDeletedFilter = null;
    this.adsdSearchAllFilter = null;
    this.liveKeywordSearchFilter = null;
    this.pausedKeywordSearchFilter = null;
    this.deletedKeywordSearchFilter = null;
    this.allKeywordSearchFilter = null;
    this.multiSearch = null;
    this.selectedItems = null;
    this.headerView = null;
    this.permissionMetadata = null;
    this.accountsSearch = null;
    this.appsSearch = null;
    this.campaignsSearchLive = null;
    this.campaignsSearchPaused = null;
    this.campaignsSearchDeleted = null;
    this.campaignsSearchAll = null;
    this.campaignsSearchDraft = null;
    this.adgroupSearchLive = null;
    this.adgroupSearchPaused = null;
    this.adgroupSearchDeleted = null;
    this.adgroupSearchAll = null;
    this.searchTermSearch = null;
    this.negKeywordSearchLive = null;
    this.negKeywordSearchPaused = null;
    this.negKeywordSearchDeleted = null;
    this.negKeywordSearchAll = null;
    this.adsdSearchLive = null;
    this.adsdSearchPaused = null;
    this.adsdSearchDeleted = null;
    this.adsdSearchAll = null;
    this.liveKeywordSearch = null;
    this.pausedKeywordSearch = null;
    this.deletedKeywordSearch = null;
    this.allKeywordSearch = null;
  },
});
