

















































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { DELETE_KEYWORD } from 'src/utils/apollo-mutations';
import Vue from 'vue';
export default Vue.extend({
  props: {
    keywordDetailsForDeletion: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      deleting: false,
    };
  },
  watch: {
    keywordDetailsForDeletion() {
      this.dialog = true;
    },
  },
  methods: {
    async deleteKeyword() {
      this.deleting = true;
      try {
        const result = await this.$apollo.mutate({
          mutation: DELETE_KEYWORD,
          variables: {
            orgId: this.keywordDetailsForDeletion.orgId,
            campaignId: this.keywordDetailsForDeletion.campaignId,
            adgroupId: this.keywordDetailsForDeletion.adgroupId,
            keywordId: this.keywordDetailsForDeletion.keywordId
          },
        });

        if (!result.data.deleteKeyword.successMessage) {
          this.$notify.error(this.$t('successMsg.deleteKwFailMsg'));
        } else {
          this.$notify.success(this.$t('successMsg.kwDeletedMsg'));
          this.$emit('onDeleteKeyword');
        }
        this.deleting = false;
        this.dialog = false;
      } catch (error) {
        this.deleting = false;
        console.log(error);
      }
    },
  },
});
