import gql from 'graphql-tag';

export const ADS_MANAGER_KEYWORD_LIVE = gql`
  query targetingKeywordReportsLive(
    $filter: Filters!
    $offset: Float!
    $limit: Float!
    $sortBy: String!
    $sortDesc: Boolean!
    $keywordSearch: KeywordSearchReq!
    $getGoals: Boolean!
  ) {
    targetingKeywordReportsLive(
      filter: $filter
      offset: $offset
      limit: $limit
      sortBy: $sortBy
      sortDesc: $sortDesc
      keywordSearch: $keywordSearch
    ) {
      counts {
        allkeywords
        livekeywords
        pausedkeywords
        deletedkeywords
      }
      filterCount
      grandTotals {
        total {
          installs
          newDownloads
          redownloads
          ttr
          taps
          impressions
          localSpend {
            amount
          }
        }
      }
      row {
        dirty
        recentBidUpdates
        total {
          localSpend {
            amount
          }
          avgCPA {
            amount
          }
          avgCPT {
            amount
          }
          avgCPM {
            amount
          }
          impressions
          taps
          installs
          ttr
          conversionRate
          newDownloads
          redownloads
          latOnInstalls
          latOffInstalls
        }
        metadata {
          # campaignStatus,
          # displayStatus,
          app {
            adamId
            appName
          }
          campaignId
          # orgId,
          # campaignName,
          # countriesOrRegions,
          adGroupName
        }
        insights {
          bidRecommendation {
            bidMin {
              amount
              currency
            }
            bidMax {
              amount
              currency
            }
          }
        }
        campaignMetadata {
          name
          adamId
          status
          countriesOrRegions
          deleted
        }
        adgroupMetadata {
          campaignId
          orgId
          status
        }
        keywordAttributionData(filter: $filter, getGoals: $getGoals) {
          successMessage
          content
          secondaryContent
        }
        keywordMetadata {
          id
          text
          status
          adGroupId
          matchType
          bidAmount {
            amount
            currency
          }
          deleted
        }
        orgMetadata {
          orgId
          orgName
          currency
        }
      }
    }
  }
`;

export const ADS_MANAGER_KEYWORD_FILTER_LIVE = gql`
  query targetingKeywordReportsLive($filter: Filters!, $offset: Float!, $limit: Float!, $sortBy: String!, $sortDesc: Boolean!) {
    keywordReportsForFilter: targetingKeywordReportsLive(
      filter: $filter
      offset: $offset
      limit: $limit
      sortBy: $sortBy
      sortDesc: $sortDesc
    ) {
      counts {
        allkeywords
        livekeywords
        pausedkeywords
        deletedkeywords
      }
      row {
        keywordMetadata {
          id
        }
      }
    }
  }
`;

export const ADS_MANAGER_KEYWORD_ALL = gql`
  query targetingKeywordReportsAll(
    $filter: Filters!
    $offset: Float!
    $limit: Float!
    $sortBy: String!
    $sortDesc: Boolean!
    $keywordSearch: KeywordSearchReq!
    $getGoals: Boolean!
  ) {
    targetingKeywordReportsAll(
      filter: $filter
      offset: $offset
      limit: $limit
      sortBy: $sortBy
      sortDesc: $sortDesc
      keywordSearch: $keywordSearch
    ) {
      counts {
        allkeywords
        livekeywords
        pausedkeywords
        deletedkeywords
      }
      filterCount
      grandTotals {
        total {
          installs
          newDownloads
          redownloads
          ttr
          taps
          impressions
          localSpend {
            amount
          }
        }
      }
      row {
        total {
          localSpend {
            amount
          }
          avgCPA {
            amount
          }
          avgCPT {
            amount
          }
          avgCPM {
            amount
          }
          impressions
          taps
          installs
          ttr
          conversionRate
          newDownloads
          redownloads
          latOnInstalls
          latOffInstalls
        }
        metadata {
          # campaignStatus,
          # displayStatus,
          # app {
          #   adamId,
          #   appName
          # },
          campaignId
          # orgId,
          # campaignName,
          # countriesOrRegions,
          adGroupName
        }
        insights {
          bidRecommendation {
            bidMin {
              amount
              currency
            }
            bidMax {
              amount
              currency
            }
          }
        }
        campaignMetadata {
          name
          adamId
          status
          countriesOrRegions
          deleted
        }
        adgroupMetadata {
          campaignId
          orgId
          status
        }
        keywordAttributionData(filter: $filter, getGoals: $getGoals) {
          successMessage
          content
          secondaryContent
        }
        keywordMetadata {
          id
          text
          status
          adGroupId
          matchType
          bidAmount {
            amount
            currency
          }
          deleted
        }
        orgMetadata {
          orgId
          orgName
          currency
        }
      }
    }
  }
`;

export const ADS_MANAGER_KEYWORD_FILTER_ALL = gql`
  query targetingKeywordReportsAll($filter: Filters!, $offset: Float!, $limit: Float!, $sortBy: String!, $sortDesc: Boolean!) {
    keywordReportsForFilter: targetingKeywordReportsAll(
      filter: $filter
      offset: $offset
      limit: $limit
      sortBy: $sortBy
      sortDesc: $sortDesc
    ) {
      counts {
        allkeywords
        livekeywords
        pausedkeywords
        deletedkeywords
      }
      row {
        keywordMetadata {
          id
        }
      }
    }
  }
`;

export const ADS_MANAGER_KEYWORD_PAUSED = gql`
  query targetingKeywordReportsPaused(
    $filter: Filters!
    $offset: Float!
    $limit: Float!
    $sortBy: String!
    $sortDesc: Boolean!
    $keywordSearch: KeywordSearchReq!
    $getGoals: Boolean!
  ) {
    targetingKeywordReportsPaused(
      filter: $filter
      offset: $offset
      limit: $limit
      sortBy: $sortBy
      sortDesc: $sortDesc
      keywordSearch: $keywordSearch
    ) {
      counts {
        allkeywords
        livekeywords
        pausedkeywords
        deletedkeywords
      }
      filterCount
      grandTotals {
        total {
          installs
          newDownloads
          redownloads
          ttr
          taps
          impressions
          localSpend {
            amount
          }
        }
      }
      row {
        total {
          localSpend {
            amount
          }
          avgCPA {
            amount
          }
          avgCPT {
            amount
          }
          avgCPM {
            amount
          }
          impressions
          taps
          installs
          ttr
          conversionRate
          newDownloads
          redownloads
          latOnInstalls
          latOffInstalls
        }
        metadata {
          # campaignStatus,
          # displayStatus,
          # app {
          #   adamId,
          #   appName
          # },
          campaignId
          # orgId,
          # campaignName,
          # countriesOrRegions,
          adGroupName
        }
        insights {
          bidRecommendation {
            bidMin {
              amount
              currency
            }
            bidMax {
              amount
              currency
            }
          }
        }
        campaignMetadata {
          name
          adamId
          status
          countriesOrRegions
          deleted
        }
        adgroupMetadata {
          campaignId
          orgId
          status
        }
        keywordAttributionData(filter: $filter, getGoals: $getGoals) {
          successMessage
          content
          secondaryContent
        }
        keywordMetadata {
          id
          text
          status
          adGroupId
          matchType
          bidAmount {
            amount
            currency
          }
          deleted
        }
        orgMetadata {
          orgId
          orgName
          currency
        }
      }
    }
  }
`;

export const ADS_MANAGER_KEYWORD_FILTER_PAUSED = gql`
  query targetingKeywordReportsPaused($filter: Filters!, $offset: Float!, $limit: Float!, $sortBy: String!, $sortDesc: Boolean!) {
    keywordReportsForFilter: targetingKeywordReportsPaused(
      filter: $filter
      offset: $offset
      limit: $limit
      sortBy: $sortBy
      sortDesc: $sortDesc
    ) {
      counts {
        allkeywords
        livekeywords
        pausedkeywords
        deletedkeywords
      }
      row {
        keywordMetadata {
          id
        }
      }
    }
  }
`;

export const ADS_MANAGER_KEYWORD_DELETED = gql`
  query targetingKeywordReportsDeleted(
    $filter: Filters!
    $offset: Float!
    $limit: Float!
    $sortBy: String!
    $sortDesc: Boolean!
    $keywordSearch: KeywordSearchReq!
    $getGoals: Boolean!
  ) {
    targetingKeywordReportsDeleted(
      filter: $filter
      offset: $offset
      limit: $limit
      sortBy: $sortBy
      sortDesc: $sortDesc
      keywordSearch: $keywordSearch
    ) {
      counts {
        allkeywords
        livekeywords
        pausedkeywords
        deletedkeywords
      }
      filterCount
      grandTotals {
        total {
          installs
          newDownloads
          redownloads
          ttr
          taps
          impressions
          localSpend {
            amount
          }
        }
      }
      row {
        total {
          localSpend {
            amount
          }
          avgCPA {
            amount
          }
          avgCPT {
            amount
          }
          avgCPM {
            amount
          }
          impressions
          taps
          installs
          ttr
          conversionRate
          newDownloads
          redownloads
          latOnInstalls
          latOffInstalls
        }
        metadata {
          # campaignStatus,
          # displayStatus,
          # app {
          #   adamId,
          #   appName
          # },
          campaignId
          # orgId,
          # campaignName,
          # countriesOrRegions,
          adGroupName
        }
        insights {
          bidRecommendation {
            bidMin {
              amount
              currency
            }
            bidMax {
              amount
              currency
            }
          }
        }
        campaignMetadata {
          name
          adamId
          status
          countriesOrRegions
          deleted
        }
        adgroupMetadata {
          campaignId
          orgId
          status
        }
        keywordAttributionData(filter: $filter, getGoals: $getGoals) {
          successMessage
          content
          secondaryContent
        }
        keywordMetadata {
          id
          text
          status
          adGroupId
          matchType
          bidAmount {
            amount
            currency
          }
          deleted
        }
        orgMetadata {
          orgId
          orgName
          currency
        }
      }
    }
  }
`;

export const ADS_MANAGER_KEYWORD_FILTER_DELETED = gql`
  query targetingKeywordReportsDeleted($filter: Filters!, $offset: Float!, $limit: Float!, $sortBy: String!, $sortDesc: Boolean!) {
    keywordReportsForFilter: targetingKeywordReportsDeleted(
      filter: $filter
      offset: $offset
      limit: $limit
      sortBy: $sortBy
      sortDesc: $sortDesc
    ) {
      counts {
        allkeywords
        livekeywords
        pausedkeywords
        deletedkeywords
      }
      row {
        keywordMetadata {
          id
        }
      }
    }
  }
`;

export const KEYWORDS_TOTAL_SUMMARY = gql`
  query keyTotalSummary($filter: Filters!) {
    keyTotalSummary(filter: $filter) {
      installs
      newDownloads
      redownloads
      ttr
      taps
      impressions
      localSpend {
        amount
      }
    }
  }
`;
