




















































  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-nocheck
import { CHANGE_KEYWORD_BID_AMOUNT } from '../../utils/apollo-mutations';
import Vue from 'vue';

export default Vue.extend({
  name: 'EditBidAmount',
  props: {
    keywordDetails: {
      type: Object,
    },
  },
  data() {
    return {
      saving: false,
      bidAmountDialog: false,
      amountRules: [
        (v) => !!v || 'Bid Amount is required',
        v => !!/^[0.1-9]*$/gi.test(v) || 'Please enter a valid Bid Amount',
      ],
      bidAmount: null
    };
  },
  watch: {
    keywordDetails(data) {
      this.bidAmountDialog = true;
      this.bidAmount = data.bidAmount.amount
    },
  },
  methods: {
    async changeKeywordBidAmount() {
      this.saving = true;
      const changeKeywordBidAmountReq = {
        orgId: this.keywordDetails.orgId,
        campaignId: this.keywordDetails.campaignId,
        adGroupId: this.keywordDetails.adGroupId,
        keywordBidAmount: {
          id: this.keywordDetails.keywordId,
          bidAmount: {
            amount: this.bidAmount,
            currency: this.keywordDetails.bidAmount.currency
          },
        },
      };

      try {
        const result = await this.$apollo.mutate({
          mutation: CHANGE_KEYWORD_BID_AMOUNT,
          variables: changeKeywordBidAmountReq,
        });

        if(result.data.changeKeywordBidAmount.searchAdsErrors) {
          this.$notify.error(result.data.changeKeywordBidAmount.searchAdsErrors);
        } else {
          this.$emit('bidUpdated');
          this.$notify.success(this.$t('successMsg.bidChangeSuccessMsg'));
          this.bidAmountDialog = false;
        }
      } catch {}

      this.saving = false;
    },

    bidAmountDialogClose() {
      this.bidAmountDialog = false;
    },
  },
});
