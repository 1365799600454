var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard" },
    [
      _c(
        "v-app",
        [
          _c(
            "v-navigation-drawer",
            {
              staticClass: "v-fixed-right",
              attrs: { fixed: "", temporary: "", right: "", width: "450" },
              model: {
                value: _vm.drawer,
                callback: function ($$v) {
                  _vm.drawer = $$v
                },
                expression: "drawer",
              },
            },
            [
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    { staticClass: "v-filter-header" },
                    [
                      _c(
                        "v-flex",
                        {
                          staticClass: "w-100",
                          attrs: {
                            "d-flex": "",
                            "align-center": "",
                            "justify-space-between": "",
                          },
                        },
                        [
                          _c("v-list-item-title", [
                            _vm._v(_vm._s(_vm.$t("buttons.filter"))),
                          ]),
                          _c(
                            "v-icon",
                            {
                              staticClass: "black--text v-close-ic",
                              attrs: { left: "" },
                              on: {
                                click: function ($event) {
                                  _vm.drawer = false
                                },
                              },
                            },
                            [_vm._v("close")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-list",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-form",
                            [
                              _c(
                                "div",
                                { staticClass: "mt-2 v-search-box style-2" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "no-field-bottom-space" },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.$t("labels.search"),
                                          outlined: "",
                                          dense: "",
                                          required: "",
                                        },
                                        model: {
                                          value: _vm.keyFilter,
                                          callback: function ($$v) {
                                            _vm.keyFilter = $$v
                                          },
                                          expression: "keyFilter",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "rounded-lg mr-0 v-search-icon-btn no-ripple-effect",
                                      attrs: { depressed: "", medium: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.applySearchFilter()
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "mr-0",
                                          attrs: { color: "primary" },
                                        },
                                        [_vm._v("mdi-magnify")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.tab == 0
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "rounded-lg",
                                          attrs: {
                                            depressed: "",
                                            dense: "",
                                            medium: "",
                                          },
                                          on: { click: _vm.resetFilters },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("buttons.reset"))
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm.shouldFilterAppear(
                                _vm.adsManagerFilters.ACCOUNT
                              )
                                ? [
                                    _c("v-label", { attrs: { block: "" } }, [
                                      _vm._v(_vm._s(_vm.$t("filter.account"))),
                                    ]),
                                    _c("v-select", {
                                      staticClass: "mt-2 wrap-text-filter",
                                      attrs: {
                                        items:
                                          (_vm.accountsReportsForFilter &&
                                            _vm.accountsReportsForFilter.row) ||
                                          [],
                                        placeholder: "Select",
                                        "item-text": "orgMetadata.orgName",
                                        "item-value": "orgMetadata",
                                        required: "",
                                        outlined: "",
                                        dense: "",
                                        loading:
                                          _vm.$apollo.queries
                                            .accountsReportsForFilter.loading,
                                      },
                                      model: {
                                        value: _vm.selectedAccount,
                                        callback: function ($$v) {
                                          _vm.selectedAccount = $$v
                                        },
                                        expression: "selectedAccount",
                                      },
                                    }),
                                  ]
                                : _vm._e(),
                              _vm.shouldFilterAppear(_vm.adsManagerFilters.APPS)
                                ? [
                                    _c("v-label", { attrs: { block: "" } }, [
                                      _vm._v(_vm._s(_vm.$t("filter.app"))),
                                    ]),
                                    _c("v-select", {
                                      staticClass: "mt-2 wrap-text-filter",
                                      attrs: {
                                        items:
                                          (_vm.appFilterData &&
                                            _vm.appFilterData.row) ||
                                          [],
                                        placeholder: "Select",
                                        "item-text": "metadata.app.appName",
                                        "item-value": "metadata.app",
                                        required: "",
                                        outlined: "",
                                        dense: "",
                                        loading:
                                          _vm.$apollo.queries
                                            .appsReportsForFilter.loading,
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "item",
                                            fn: function (data) {
                                              return [
                                                [
                                                  _c("v-img", {
                                                    staticClass: "rounded-lg",
                                                    attrs: {
                                                      color: "primary",
                                                      "lazy-src":
                                                        data.item.metadata.app
                                                          .appIcon,
                                                      "max-width": "30",
                                                      "max-height": "30",
                                                      "min-width": "20",
                                                      "min-height": "20",
                                                      src:
                                                        data.item.metadata.app
                                                          .appIcon,
                                                    },
                                                  }),
                                                  _c(
                                                    "v-list-item-content",
                                                    { staticClass: "ml-2" },
                                                    [
                                                      _c("v-list-item-title", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              data.item.metadata
                                                                .app.appName
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        3029780782
                                      ),
                                      model: {
                                        value: _vm.selectedApp,
                                        callback: function ($$v) {
                                          _vm.selectedApp = $$v
                                        },
                                        expression: "selectedApp",
                                      },
                                    }),
                                  ]
                                : _vm._e(),
                              _vm.shouldFilterAppear(_vm.adsManagerFilters.APPS)
                                ? [
                                    _c("v-label", { attrs: { block: "" } }, [
                                      _vm._v(_vm._s(_vm.$t("filter.goal"))),
                                    ]),
                                    _c("v-select", {
                                      staticClass: "mt-2 wrap-text-filter",
                                      attrs: {
                                        items: _vm.getGoals,
                                        placeholder: "Select",
                                        "item-text": "name",
                                        required: "",
                                        outlined: "",
                                        dense: "",
                                        "return-object": "",
                                        disabled: _vm.disableGoalFilter,
                                      },
                                      model: {
                                        value: _vm.selectedGoal,
                                        callback: function ($$v) {
                                          _vm.selectedGoal = $$v
                                        },
                                        expression: "selectedGoal",
                                      },
                                    }),
                                  ]
                                : _vm._e(),
                              _vm.shouldFilterAppear(_vm.adsManagerFilters.APPS)
                                ? [
                                    _c("v-label", { attrs: { block: "" } }, [
                                      _vm._v(_vm._s(_vm.$t("filter.secGoal"))),
                                    ]),
                                    _c("v-select", {
                                      staticClass: "mt-2",
                                      attrs: {
                                        items: _vm.secondaryGoals,
                                        placeholder: "Select",
                                        "item-text": "name",
                                        required: "",
                                        outlined: "",
                                        dense: "",
                                        "return-object": "",
                                        disabled:
                                          _vm.disableGoalFilter ||
                                          !_vm.selectedGoal,
                                      },
                                      model: {
                                        value: _vm.selectedSecondaryGoal,
                                        callback: function ($$v) {
                                          _vm.selectedSecondaryGoal = $$v
                                        },
                                        expression: "selectedSecondaryGoal",
                                      },
                                    }),
                                  ]
                                : _vm._e(),
                              _vm.shouldFilterAppear(_vm.adsManagerFilters.APPS)
                                ? [
                                    _c("v-label", { attrs: { block: "" } }, [
                                      _vm._v("Select Campaign Type"),
                                    ]),
                                    _c("v-select", {
                                      staticClass: "mt-2 wrap-text-filter",
                                      attrs: {
                                        "item-text": "name",
                                        "item-value": "supplySources",
                                        items: _vm.supplySourcesFilters,
                                        placeholder: "Select",
                                        required: "",
                                        outlined: "",
                                        dense: "",
                                        "return-object": "",
                                        loading:
                                          _vm.$apollo.queries
                                            .campaignReportsForFilter.loading,
                                      },
                                      model: {
                                        value: _vm.selectedCampaignSupplySource,
                                        callback: function ($$v) {
                                          _vm.selectedCampaignSupplySource = $$v
                                        },
                                        expression:
                                          "selectedCampaignSupplySource",
                                      },
                                    }),
                                  ]
                                : _vm._e(),
                              _vm.shouldFilterAppear(
                                _vm.adsManagerFilters.CAMPAIGN
                              )
                                ? [
                                    _c("v-label", { attrs: { block: "" } }, [
                                      _vm._v(_vm._s(_vm.$t("filter.campaign"))),
                                    ]),
                                    _c("v-select", {
                                      staticClass: "mt-2 wrap-text-filter",
                                      attrs: {
                                        items:
                                          (_vm.campaignReportsForFilter &&
                                            _vm.campaignReportsForFilter.row) ||
                                          [],
                                        placeholder: "Select",
                                        "item-text": "campaignMetadata.name",
                                        "item-value": "campaignMetadata",
                                        required: "",
                                        outlined: "",
                                        dense: "",
                                        loading:
                                          _vm.$apollo.queries
                                            .campaignReportsForFilter.loading,
                                      },
                                      model: {
                                        value: _vm.selectedCampaign,
                                        callback: function ($$v) {
                                          _vm.selectedCampaign = $$v
                                        },
                                        expression: "selectedCampaign",
                                      },
                                    }),
                                  ]
                                : _vm._e(),
                              _vm.shouldFilterAppear(
                                _vm.adsManagerFilters.ADGROUP
                              )
                                ? [
                                    _c("v-label", { attrs: { block: "" } }, [
                                      _vm._v(_vm._s(_vm.$t("filter.adgroup"))),
                                    ]),
                                    _c("v-select", {
                                      staticClass: "mt-2 wrap-text-filter",
                                      attrs: {
                                        items:
                                          _vm.adgroupReportsForFilter &&
                                          _vm.adgroupReportsForFilter.row,
                                        placeholder: "Select",
                                        "item-text": "adgroupMetadata.name",
                                        "item-value": "adgroupMetadata",
                                        required: "",
                                        outlined: "",
                                        dense: "",
                                        loading:
                                          _vm.$apollo.queries
                                            .adgroupReportsForFilter.loading,
                                      },
                                      model: {
                                        value: _vm.selectedAdGroup,
                                        callback: function ($$v) {
                                          _vm.selectedAdGroup = $$v
                                        },
                                        expression: "selectedAdGroup",
                                      },
                                    }),
                                  ]
                                : _vm._e(),
                              _vm.shouldFilterAppear(_vm.adsManagerFilters.AD)
                                ? [
                                    _c("v-label", { attrs: { block: "" } }, [
                                      _vm._v(_vm._s(_vm.$t("filter.ad"))),
                                    ]),
                                    _c("v-select", {
                                      staticClass: "mt-2 wrap-text-filter",
                                      attrs: {
                                        items:
                                          _vm.productPageReportsForFilter &&
                                          _vm.productPageReportsForFilter.row,
                                        placeholder: "Select",
                                        "item-text": "adsMetadata.name",
                                        "item-value": "adsMetadata",
                                        required: "",
                                        outlined: "",
                                        dense: "",
                                        loading:
                                          _vm.$apollo.queries
                                            .productPageReportsForFilter
                                            .loading,
                                      },
                                      model: {
                                        value: _vm.selectedAd,
                                        callback: function ($$v) {
                                          _vm.selectedAd = $$v
                                        },
                                        expression: "selectedAd",
                                      },
                                    }),
                                  ]
                                : _vm._e(),
                              _c(
                                "div",
                                [
                                  _vm.tab != 0
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "rounded-lg mr-3",
                                          attrs: {
                                            disabled:
                                              !_vm.applyButtonDisabled &&
                                              this.currentTab ==
                                                "neg_keywords_tab",
                                            color: "primary",
                                            depressed: "",
                                            dense: "",
                                            medium: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.runFilterQueries(
                                                "click"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("buttons.apply"))
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.tab != 0
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "rounded-lg",
                                          attrs: {
                                            depressed: "",
                                            dense: "",
                                            medium: "",
                                          },
                                          on: { click: _vm.resetFilters },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("buttons.reset"))
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { width: "650px", persistent: "" },
              model: {
                value: _vm.keywordBulkBidUpdateModal,
                callback: function ($$v) {
                  _vm.keywordBulkBidUpdateModal = $$v
                },
                expression: "keywordBulkBidUpdateModal",
              },
            },
            [
              _c(
                "v-card",
                {
                  staticClass: "ad-groupKeywordStep",
                  attrs: { elevation: "0" },
                },
                [
                  _c(
                    "v-card-title",
                    {
                      staticClass:
                        "d-flex justify-space-between align-center w-100 primary white--text px-6 py-3",
                    },
                    [
                      _vm._v(" Keyword Bulk Bid Update "),
                      _c(
                        "v-icon",
                        {
                          staticClass: "white--text",
                          on: {
                            click: function ($event) {
                              _vm.keywordBulkBidUpdateModal = false
                            },
                          },
                        },
                        [_vm._v("mdi-close")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    { staticClass: "pa-0" },
                    [
                      _c(
                        "v-row",
                        [
                          _vm.KBBUPreview.length == 0
                            ? _c(
                                "v-col",
                                { attrs: { lg: "12", md: "12", sm: "12" } },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: {
                                        bottom: "",
                                        color: "primarydark",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function (ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  "div",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        staticClass:
                                                          "upload-file text-center",
                                                      },
                                                      "div",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c("v-file-input", {
                                                      attrs: {
                                                        "hide-input": "",
                                                        solo: "",
                                                        accept:
                                                          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.csv",
                                                      },
                                                      on: {
                                                        change:
                                                          _vm.KBBUFileChanged,
                                                      },
                                                      model: {
                                                        value: _vm.KBBUFile,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.KBBUFile = $$v
                                                        },
                                                        expression: "KBBUFile",
                                                      },
                                                    }),
                                                    _c("p", [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "primary--text",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "createCampaign.upload"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1261281645
                                      ),
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("createCampaign.suppFormat")
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.KBBUPreview.length > 0
                            ? _c(
                                "v-col",
                                { attrs: { lg: "12", md: "12", sm: "12" } },
                                [
                                  _c(
                                    "div",
                                    _vm._g(
                                      _vm._b(
                                        { staticClass: "text-center" },
                                        "div",
                                        _vm.attrs,
                                        false
                                      ),
                                      _vm.on
                                    ),
                                    [
                                      _c("v-data-table", {
                                        attrs: {
                                          headers: _vm.KBBUPreviewheaders,
                                          items: _vm.KBBUPreview,
                                          "items-per-page": 5,
                                          dense: "",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-card-actions", { attrs: { right: "" } }, [
                    _c(
                      "div",
                      {
                        staticClass: "w-100",
                        staticStyle: { "text-align": "start" },
                      },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "rounded-md info--text mx-3",
                            attrs: { depressed: "", outlined: "" },
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "text-right body-2",
                                attrs: {
                                  href:
                                    "data:text/csv;base64,a2V5d29yZF9pZCxrZXl3b3JkLG5ld19iaWRfYW1vdW50CjEyNTEyMzk3NSxzcGFjZSwwLjAxCg==",
                                  download:
                                    "keyword-bulk-bid-update-sample.csv",
                                },
                              },
                              [_vm._v("Example Sheet")]
                            ),
                          ]
                        ),
                        _vm.KBBUPreview.length > 0
                          ? _c(
                              "v-btn",
                              {
                                staticClass: "rounded-md error--text mx-3",
                                attrs: { depressed: "", outlined: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.clearKBBUPreview()
                                  },
                                },
                              },
                              [_vm._v("Clear")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "w-100",
                        staticStyle: { "text-align": "end" },
                      },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "rounded-md error--text mx-3",
                            attrs: { depressed: "", outlined: "" },
                            on: {
                              click: function ($event) {
                                _vm.keywordBulkBidUpdateModal = false
                              },
                            },
                          },
                          [_vm._v("Close")]
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "rounded-md success--text mr-3",
                            attrs: {
                              depressed: "",
                              outlined: "",
                              disabled:
                                _vm.KBBUUpdating ||
                                _vm.KBBUFileContents.length === 0,
                              loading: _vm.KBBUUpdating,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.updateKBBU()
                              },
                            },
                          },
                          [_vm._v("Update Bids")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inner-container v-ads-manage-style" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "top-page-header with-right-two-input page-header-with-banner",
                  attrs: { lang: _vm.locale },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "head-title withBreadcrumb top-space mobile-header",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "custom-breadcrum" },
                        [
                          _vm.selectedStatestrategy
                            ? _c(
                                "v-chip",
                                {
                                  staticClass: "ma-2 orange-chip",
                                  attrs: { color: "orange", small: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.navstrategyFilters()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.selectedStatestrategy) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.selectedAccount
                            ? _c(
                                "v-chip",
                                {
                                  staticClass: "breadcrumb-chip my-2",
                                  attrs: { color: "default", small: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.navAccountFilter()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function (ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  "span",
                                                  _vm._g(
                                                    _vm._b(
                                                      {},
                                                      "span",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.selectedAccount
                                                            .orgName
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        3958961641
                                      ),
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-white tooltip-name",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.selectedAccount.orgName)
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.selectedApp
                            ? _c(
                                "v-chip",
                                {
                                  staticClass: "breadcrumb-chip my-2",
                                  attrs: { color: "default", small: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.navAppFilter()
                                    },
                                  },
                                },
                                [
                                  _vm.selectedApp
                                    ? _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function (ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "span",
                                                      _vm._g(
                                                        _vm._b(
                                                          {},
                                                          "span",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.selectedApp
                                                              .appName
                                                          ) + " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            3431801529
                                          ),
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "text-white tooltip-name",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.selectedApp.appName)
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.selectedGoal
                            ? _c(
                                "v-chip",
                                {
                                  staticClass: "breadcrumb-chip my-2",
                                  attrs: { color: "default", small: "" },
                                },
                                [
                                  _vm.selectedGoal
                                    ? _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function (ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "span",
                                                      _vm._g(
                                                        _vm._b(
                                                          {},
                                                          "span",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.selectedGoal
                                                              .name
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            3253870135
                                          ),
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "text-white tooltip-name",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.selectedGoal.name)
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.selectedSecondaryGoal
                            ? _c(
                                "v-chip",
                                {
                                  staticClass: "breadcrumb-chip my-2",
                                  attrs: { color: "default", small: "" },
                                },
                                [_vm._v(_vm._s(_vm.selectedSecondaryGoal.name))]
                              )
                            : _vm._e(),
                          _vm.selectedCampaignSupplySource
                            ? _c(
                                "v-chip",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass: "breadcrumb-chip my-2",
                                      attrs: { color: "default", small: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function (ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  "span",
                                                  _vm._g(
                                                    _vm._b(
                                                      {},
                                                      "span",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm
                                                          .selectedCampaignSupplySource
                                                          .name
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        4025726594
                                      ),
                                    },
                                    "v-chip",
                                    _vm.attrs,
                                    false
                                  ),
                                  _vm.on
                                ),
                                [
                                  _c(
                                    "span",
                                    { staticClass: "text-white tooltip-name" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.selectedCampaignSupplySource.name
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.selectedCampaign
                            ? _c(
                                "v-chip",
                                {
                                  staticClass: "breadcrumb-chip my-2",
                                  attrs: { color: "default", small: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.navCampaignFilter()
                                    },
                                  },
                                },
                                [
                                  _vm.selectedCampaign
                                    ? _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function (ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "span",
                                                      _vm._g(
                                                        _vm._b(
                                                          {},
                                                          "span",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.selectedCampaign
                                                              .name
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            592492300
                                          ),
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "text-white tooltip-name",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.selectedCampaign.name
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.selectedAdGroup
                            ? _c(
                                "v-chip",
                                {
                                  staticClass: "breadcrumb-chip my-2",
                                  attrs: { color: "default", small: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.navAdgroupFilter()
                                    },
                                  },
                                },
                                [
                                  _vm.selectedAdGroup
                                    ? _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function (ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "span",
                                                      _vm._g(
                                                        _vm._b(
                                                          {},
                                                          "span",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.selectedAdGroup
                                                              .name
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            553505352
                                          ),
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "text-white tooltip-name",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.selectedAdGroup.name)
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.selectedTargetingKeyword
                            ? _c(
                                "v-chip",
                                {
                                  staticClass: "breadcrumb-chip my-2",
                                  attrs: { color: "default", small: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.navKeywordFilter()
                                    },
                                  },
                                },
                                [
                                  _vm.selectedTargetingKeyword
                                    ? _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function (ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "span",
                                                      _vm._g(
                                                        _vm._b(
                                                          {},
                                                          "span",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm
                                                              .selectedTargetingKeyword
                                                              .text
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            1509597856
                                          ),
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "text-white tooltip-name",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.selectedTargetingKeyword
                                                    .text
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.selectedAd
                            ? _c(
                                "v-chip",
                                {
                                  staticClass:
                                    "breadcrumb-chip mr-2 active my-2",
                                  attrs: { color: "primary", small: "" },
                                },
                                [
                                  _vm.selectedAd
                                    ? _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function (ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "span",
                                                      _vm._g(
                                                        _vm._b(
                                                          {},
                                                          "span",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.selectedAd.name
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            1328077335
                                          ),
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "text-white tooltip-name",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.selectedAd.name)
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          this.selectedStatestrategy !== 0 ||
                          this.selectedAccount !== 0 ||
                          this.selectedApp !== 0 ||
                          this.selectedCampaign !== 0 ||
                          this.selectedCampaignSupplySource !== 0 ||
                          this.selectedAdGroup !== 0 ||
                          this.selectedTargetingKeyword !== 0 ||
                          this.selectedAd !== 0 ||
                          this.keyFilter !== ""
                            ? _c(
                                "v-chip",
                                {
                                  staticClass: "clear-filter primary--text",
                                  attrs: {
                                    depressed: "",
                                    text: "",
                                    small: "",
                                    color: " ",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.resetFilters()
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v("mdi-window-close"),
                                  ]),
                                  _vm._v("Clear"),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "v-row",
                    { staticClass: " " },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "py-0",
                          attrs: {
                            cols: "12",
                            xl: "8",
                            lg: "8",
                            md: "6",
                            sm: "12",
                          },
                        },
                        [
                          _c(
                            "v-tabs",
                            {
                              staticClass: "pb-4",
                              attrs: {
                                "center-active": "",
                                "show-arrows": "",
                                "background-color": "transparent",
                              },
                              model: {
                                value: _vm.tab,
                                callback: function ($$v) {
                                  _vm.tab = $$v
                                },
                                expression: "tab",
                              },
                            },
                            _vm._l(_vm.tabItems, function (item) {
                              return _c(
                                "v-tab",
                                {
                                  key: item.tab,
                                  on: {
                                    change: function ($event) {
                                      return _vm.onTabChange(item)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(item.tab))]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-0 d-flex justify-end px-0",
                          attrs: {
                            cols: "12",
                            xl: "4",
                            lg: "4",
                            md: "6",
                            sm: "12",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "top-heading-right d-flex flex-wrap",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "cal-filter d-flex align-center justify-end flex-wrap",
                                },
                                [
                                  _c(
                                    "v-menu",
                                    {
                                      ref: "menu",
                                      staticClass: "mt-2 date-picker",
                                      attrs: {
                                        "close-on-content-click": false,
                                        "return-value": _vm.rangePicker,
                                        transition: "scale-transition",
                                        "offset-y": "",
                                        "min-width": "auto",
                                        left: "",
                                      },
                                      on: {
                                        "update:returnValue": function (
                                          $event
                                        ) {
                                          _vm.rangePicker = $event
                                        },
                                        "update:return-value": function (
                                          $event
                                        ) {
                                          _vm.rangePicker = $event
                                        },
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function (ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "v-text-field",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass:
                                                        "v-date-picker-cs w-sm-100 mx-2",
                                                      staticStyle: {
                                                        "font-size": "revert",
                                                      },
                                                      attrs: {
                                                        "prepend-icon":
                                                          "mdi-calendar",
                                                        readonly: "",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.filterDateRangeText,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.filterDateRangeText = $$v
                                                        },
                                                        expression:
                                                          "filterDateRangeText",
                                                      },
                                                    },
                                                    "v-text-field",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                )
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                      model: {
                                        value: _vm.rangePicker,
                                        callback: function ($$v) {
                                          _vm.rangePicker = $$v
                                        },
                                        expression: "rangePicker",
                                      },
                                    },
                                    [
                                      [
                                        _c(
                                          "div",
                                          { staticStyle: { display: "flex" } },
                                          [
                                            _c(
                                              "div",
                                              { staticStyle: { flex: "auto" } },
                                              [
                                                _c(
                                                  "ul",
                                                  {
                                                    staticClass:
                                                      "flex-container column",
                                                    staticStyle: {
                                                      "list-style-type": "none",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "flex-item",
                                                      },
                                                      [
                                                        _c(
                                                          "v-chip",
                                                          {
                                                            staticClass: "ma-1",
                                                            attrs: {
                                                              label: "",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.selectPreset(
                                                                  "TODAY"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "dateRangePresets.today"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "flex-item",
                                                      },
                                                      [
                                                        _c(
                                                          "v-chip",
                                                          {
                                                            staticClass: "ma-1",
                                                            attrs: {
                                                              label: "",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.selectPreset(
                                                                  "YESTERDAY"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "dateRangePresets.yesterday"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "flex-item",
                                                      },
                                                      [
                                                        _c(
                                                          "v-chip",
                                                          {
                                                            staticClass: "ma-1",
                                                            attrs: {
                                                              label: "",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.selectPreset(
                                                                  "WEEKTODATE"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "dateRangePresets.weekToDate"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "flex-item",
                                                      },
                                                      [
                                                        _c(
                                                          "v-chip",
                                                          {
                                                            staticClass: "ma-1",
                                                            attrs: {
                                                              label: "",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.selectPreset(
                                                                  "LASTWEEK"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "dateRangePresets.lastWeek"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "flex-item",
                                                      },
                                                      [
                                                        _c(
                                                          "v-chip",
                                                          {
                                                            staticClass: "ma-1",
                                                            attrs: {
                                                              label: "",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.selectPreset(
                                                                  "LASTSEVENDAYS"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "dateRangePresets.lastSevenDays"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "flex-item",
                                                      },
                                                      [
                                                        _c(
                                                          "v-chip",
                                                          {
                                                            staticClass: "ma-1",
                                                            attrs: {
                                                              label: "",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.selectPreset(
                                                                  "MONTHTODATE"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "dateRangePresets.monthToDate"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "flex-item",
                                                      },
                                                      [
                                                        _c(
                                                          "v-chip",
                                                          {
                                                            staticClass: "ma-1",
                                                            attrs: {
                                                              label: "",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.selectPreset(
                                                                  "LASTMONTH"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "dateRangePresets.lastMonth"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "flex-item",
                                                      },
                                                      [
                                                        _c(
                                                          "v-chip",
                                                          {
                                                            staticClass: "ma-1",
                                                            attrs: {
                                                              label: "",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.selectPreset(
                                                                  "LASTTHIRTYDAY"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "dateRangePresets.lastThirtyDays"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticStyle: { flex: "auto" } },
                                              [
                                                _c(
                                                  "v-date-picker",
                                                  {
                                                    attrs: {
                                                      max: _vm.maxDate,
                                                      "no-title": "",
                                                      scrollable: "",
                                                      range: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.filterDateRangeModel,
                                                      callback: function ($$v) {
                                                        _vm.filterDateRangeModel = $$v
                                                      },
                                                      expression:
                                                        "filterDateRangeModel",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          text: "",
                                                          color: "grey",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            _vm.rangePicker = false
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("Cancel")]
                                                    ),
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          disabled:
                                                            _vm.filterDateRangeModel &&
                                                            _vm
                                                              .filterDateRangeModel
                                                              .length !== 2,
                                                          text: "",
                                                          color: "primary",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.applyDateRange()
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("OK")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                    ],
                                    2
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "filter-btn-area mb-1 mt-1 ml-2",
                                    },
                                    [
                                      !_vm.isIconDisabled
                                        ? _c(
                                            "v-tooltip",
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function (ref) {
                                                      var on = ref.on
                                                      var attrs = ref.attrs
                                                      return [
                                                        _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "mr-2 pa-2 rounded-border primary white--text",
                                                                attrs: {
                                                                  disabled:
                                                                    _vm.isIconDisabled,
                                                                },
                                                                on: {
                                                                  click: function (
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return _vm.toggleDrawer.apply(
                                                                      null,
                                                                      arguments
                                                                    )
                                                                  },
                                                                },
                                                              },
                                                              "v-icon",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _vm._v(
                                                              "mdi-filter-variant"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                714297068
                                              ),
                                            },
                                            [_c("span", [_vm._v("Filters")])]
                                          )
                                        : _c(
                                            "v-tooltip",
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "activator",
                                                  fn: function (ref) {
                                                    var on = ref.on
                                                    var attrs = ref.attrs
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              staticClass:
                                                                "mr-2 pa-2 rounded-border primary grey--text gray-btn-bg",
                                                              on: {
                                                                click: function (
                                                                  $event
                                                                ) {
                                                                  $event.stopPropagation()
                                                                  !_vm.toggleDrawer
                                                                },
                                                              },
                                                            },
                                                            "v-icon",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [
                                                          _vm._v(
                                                            "mdi-filter-variant"
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ]),
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  "Filters are disabled while viewing a Strategy"
                                                ),
                                              ]),
                                            ]
                                          ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-container",
                { staticClass: "v-main-content", attrs: { fluid: "" } },
                [
                  [
                    _c(
                      "v-card",
                      {
                        staticClass: "pa-4 rounded-lg",
                        attrs: { elevation: "3" },
                      },
                      [
                        _c(
                          "v-tabs-items",
                          {
                            model: {
                              value: _vm.tab,
                              callback: function ($$v) {
                                _vm.tab = $$v
                              },
                              expression: "tab",
                            },
                          },
                          [
                            _c(
                              "v-tab-item",
                              [
                                _c(
                                  "v-card",
                                  {
                                    staticClass: "pa-0",
                                    attrs: { flat: "", elevation: "0" },
                                  },
                                  [
                                    _c("DataTable", {
                                      ref: "strategiesDatatable",
                                      attrs: {
                                        items: _vm.strategyitems || [],
                                        headers: _vm.strategyHeaders,
                                        tab: "Strategies",
                                        tabInfo: "strategies",
                                        tabindex: _vm.tab,
                                        customView: _vm.customView._id,
                                        customTab: "strategies",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "item.strategyitems.name",
                                          fn: function (ref) {
                                            var item = ref.item
                                            return [
                                              _c(
                                                "a",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.strategyFilters(
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v(_vm._s(item.name))]
                                              ),
                                            ]
                                          },
                                        },
                                        {
                                          key: "item.targetEntities",
                                          fn: function (ref) {
                                            var item = ref.item
                                            return _vm._l(
                                              item.combinedEntities,
                                              function (app, index) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: index,
                                                    staticClass:
                                                      "mr-1 d-flex flex-v-center",
                                                  },
                                                  [
                                                    _c("v-img", {
                                                      staticClass:
                                                        "ma-1 rounded-lg",
                                                      attrs: {
                                                        src: app.appIcon,
                                                        "lazy-src": app.appIcon,
                                                        "max-width": "30",
                                                        height: "30",
                                                      },
                                                    }),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "flex-v-center",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              app.appName
                                                            ) +
                                                            " "
                                                        ),
                                                        _c("br"),
                                                        app.remainingApps > 0
                                                          ? _c("span", [
                                                              _vm._v(
                                                                "+" +
                                                                  _vm._s(
                                                                    app.remainingApps
                                                                  ) +
                                                                  " More"
                                                              ),
                                                            ])
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              }
                                            )
                                          },
                                        },
                                        {
                                          key: "item.stratCampaigns",
                                          fn: function (ref) {
                                            var item = ref.item
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    item.stratCampaignsVal
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          },
                                        },
                                        {
                                          key: "item.stratKeywords",
                                          fn: function (ref) {
                                            var item = ref.item
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    item.stratKeywordsVal
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          },
                                        },
                                        {
                                          key: "item.stratAdGroups",
                                          fn: function (ref) {
                                            var item = ref.item
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    item.stratAdGroupsVal
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-tab-item",
                              [
                                _c(
                                  "v-row",
                                  { staticClass: "d-flex align-start" },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        attrs: {
                                          cols: "12",
                                          lg: "5",
                                          md: "6",
                                          sm: "8",
                                        },
                                      },
                                      [
                                        !_vm.editMode
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "box-header left-0 chart-header-area d-flex align-baseline pl-6 mb-4 max-400",
                                              },
                                              [
                                                _c("v-select", {
                                                  staticClass: "max-275",
                                                  attrs: {
                                                    "item-text": "CustomView",
                                                    "item-value": "_id ",
                                                    items:
                                                      _vm.customViews || [],
                                                    attach: "",
                                                    "menu-props": "auto",
                                                    label: "Select",
                                                    "hide-details": "",
                                                    "single-line": "",
                                                    "return-object": "",
                                                    disabled: _vm.isSuperAdmin
                                                      ? false
                                                      : _vm.permissions.includes(
                                                          _vm
                                                            .SubscriptionPermissionsEnums
                                                            .CUSTOMVIEWS
                                                        )
                                                      ? false
                                                      : true,
                                                  },
                                                  on: {
                                                    change:
                                                      _vm.changeCustomView,
                                                  },
                                                  model: {
                                                    value: _vm.customView,
                                                    callback: function ($$v) {
                                                      _vm.customView = $$v
                                                    },
                                                    expression: "customView",
                                                  },
                                                }),
                                                _vm.customView._id != "0"
                                                  ? _c(
                                                      "v-icon",
                                                      {
                                                        staticClass:
                                                          "ml-2 primary--text",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            _vm.editMode = true
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "mdi-pencil-outline"
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.customView._id != "0"
                                                  ? _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "ml-2",
                                                        attrs: {
                                                          color: "primary",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.deleteCustomView(
                                                              _vm.customView
                                                                ._id,
                                                              "accounts"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "mdi-delete-outline"
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.customView._id != "0" &&
                                                _vm.customViews.find(function (
                                                  el
                                                ) {
                                                  return (
                                                    el._id == _vm.customView._id
                                                  )
                                                }).PinnedStatus != true
                                                  ? _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: {
                                                          bottom: "",
                                                          color: "primarydark",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                var attrs =
                                                                  ref.attrs
                                                                return [
                                                                  _c(
                                                                    "v-icon",
                                                                    _vm._g(
                                                                      _vm._b(
                                                                        {
                                                                          staticClass:
                                                                            "ml-2 primary--text",
                                                                          on: {
                                                                            click: function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.pinnedCustomView(
                                                                                true
                                                                              )
                                                                            },
                                                                          },
                                                                        },
                                                                        "v-icon",
                                                                        attrs,
                                                                        false
                                                                      ),
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-pin-outline"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          2813756920
                                                        ),
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v("Pin View"),
                                                        ]),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.customView._id != "0" &&
                                                _vm.customViews.find(function (
                                                  el
                                                ) {
                                                  return (
                                                    el._id == _vm.customView._id
                                                  )
                                                }).PinnedStatus == true
                                                  ? _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: {
                                                          bottom: "",
                                                          color: "primarydark",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                var attrs =
                                                                  ref.attrs
                                                                return [
                                                                  _c(
                                                                    "v-icon",
                                                                    _vm._g(
                                                                      _vm._b(
                                                                        {
                                                                          staticClass:
                                                                            "ml-2 primary--text",
                                                                          on: {
                                                                            click: function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.pinnedCustomView(
                                                                                false
                                                                              )
                                                                            },
                                                                          },
                                                                        },
                                                                        "v-icon",
                                                                        attrs,
                                                                        false
                                                                      ),
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-pin-off-outline"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          1877317105
                                                        ),
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v("Unpin View"),
                                                        ]),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.editMode
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "box-header chart-header-area edit-field d-flex align-baseline pl-6 mt-3",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "max-275" },
                                                  [
                                                    _c("v-text-field", {
                                                      staticClass: "max-275",
                                                      class: {
                                                        "error--text":
                                                          _vm.errorEditViewMessage,
                                                      },
                                                      attrs: {
                                                        outlined: "",
                                                        dense: "",
                                                        placeholder: "value",
                                                      },
                                                      on: {
                                                        input:
                                                          _vm.validateInput,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.customView
                                                            .CustomView,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.customView,
                                                            "CustomView",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "customView.CustomView",
                                                      },
                                                    }),
                                                    _vm.errorEditViewMessage
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "error--text text-caption ml-3 v-messages__message",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.errorEditViewMessage
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: {
                                                      bottom: "",
                                                      color: "primarydark",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function (ref) {
                                                            var on = ref.on
                                                            var attrs =
                                                              ref.attrs
                                                            return [
                                                              _c(
                                                                "v-icon",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      staticClass:
                                                                        "ml-2 primary--text",
                                                                      class: {
                                                                        "disabled-icon":
                                                                          _vm.errorEditViewMessage,
                                                                      },
                                                                      on: {
                                                                        click: function (
                                                                          $event
                                                                        ) {
                                                                          _vm.errorEditViewMessage
                                                                            ? null
                                                                            : _vm.updateCustomView()
                                                                        },
                                                                      },
                                                                    },
                                                                    "v-icon",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _vm._v(
                                                                    "mdi-checkbox-marked-circle"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      2788561454
                                                    ),
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v("Cancel"),
                                                    ]),
                                                  ]
                                                ),
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: {
                                                      bottom: "",
                                                      color: "primarydark",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function (ref) {
                                                            var on = ref.on
                                                            var attrs =
                                                              ref.attrs
                                                            return [
                                                              _c(
                                                                "v-icon",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      staticClass:
                                                                        "ml-2 primary--text",
                                                                      on: {
                                                                        click:
                                                                          _vm.cancelEditView,
                                                                      },
                                                                    },
                                                                    "v-icon",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _vm._v(
                                                                    "mdi-close-circle"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      2384401514
                                                    ),
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v("Cancel"),
                                                    ]),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                    !_vm.editMode
                                      ? _c(
                                          "v-col",
                                          {
                                            staticClass:
                                              "mt-2 d-flex justify-end align-center",
                                            attrs: {
                                              cols: "12",
                                              lg: "7",
                                              md: "6",
                                              sm: "4",
                                            },
                                          },
                                          [
                                            _vm.customView.CustomView !=
                                            "Default View"
                                              ? _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    {
                                                      class: [
                                                        _vm.isSuperAdmin
                                                          ? "primary"
                                                          : _vm.permissions.includes(
                                                              _vm
                                                                .SubscriptionPermissionsEnums
                                                                .CUSTOMVIEWS
                                                            )
                                                          ? "primary"
                                                          : "disabled-button",
                                                      ],
                                                      attrs: { dark: "" },
                                                    },
                                                    _vm.isSuperAdmin
                                                      ? {
                                                          click: function () {
                                                            return _vm.updateCustomView()
                                                          },
                                                        }
                                                      : _vm.permissions.includes(
                                                          _vm
                                                            .SubscriptionPermissionsEnums
                                                            .CUSTOMVIEWS
                                                        ) == false
                                                      ? {
                                                          click: function () {
                                                            return _vm.openPermissionPopup()
                                                          },
                                                        }
                                                      : {
                                                          click: function () {
                                                            return _vm.updateCustomView()
                                                          },
                                                        }
                                                  ),
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { staticClass: "mr-1" },
                                                      [
                                                        _vm._v(
                                                          "mdi-arrow-right"
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" Update View "),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  class: [
                                                    "ml-3",
                                                    _vm.isSuperAdmin
                                                      ? "primary"
                                                      : _vm.permissions.includes(
                                                          _vm
                                                            .SubscriptionPermissionsEnums
                                                            .CUSTOMVIEWS
                                                        )
                                                      ? "primary"
                                                      : "disabled-button",
                                                  ],
                                                  attrs: {
                                                    dark: "",
                                                    position: "relative",
                                                  },
                                                },
                                                _vm.isSuperAdmin
                                                  ? {
                                                      click: function () {
                                                        return _vm.openViewForm()
                                                      },
                                                    }
                                                  : _vm.permissions.includes(
                                                      _vm
                                                        .SubscriptionPermissionsEnums
                                                        .CUSTOMVIEWS
                                                    ) == false
                                                  ? {
                                                      click: function () {
                                                        return _vm.openPermissionPopup()
                                                      },
                                                    }
                                                  : {
                                                      click: function () {
                                                        return _vm.openViewForm()
                                                      },
                                                    }
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  { staticClass: "mr-1" },
                                                  [_vm._v("mdi-plus")]
                                                ),
                                                _vm._v(" Add View "),
                                              ],
                                              1
                                            ),
                                            _vm.showViewForm
                                              ? [
                                                  _c(
                                                    "v-card",
                                                    {
                                                      staticClass:
                                                        "mx-auto d-flex align-baseline px-6 py-5 add-view",
                                                      attrs: { outlined: "" },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "field add-view-field",
                                                        },
                                                        [
                                                          _c("v-text-field", {
                                                            staticClass:
                                                              "w-100",
                                                            class: {
                                                              "error-border":
                                                                _vm.errorViewMessage,
                                                            },
                                                            attrs: {
                                                              required: "",
                                                              outlined: "",
                                                              dense: "",
                                                              placeholder:
                                                                "Enter View name",
                                                            },
                                                            on: {
                                                              input:
                                                                _vm.validateInput,
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.newCustomView,
                                                              callback: function (
                                                                $$v
                                                              ) {
                                                                _vm.newCustomView = $$v
                                                              },
                                                              expression:
                                                                "newCustomView",
                                                            },
                                                          }),
                                                          _vm.errorViewMessage
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "error--text text-caption ml-3 v-messages__message",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.errorViewMessage
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-card-actions",
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                color: _vm.newCustomView
                                                                  ? "primary"
                                                                  : "grey",
                                                                disabled:
                                                                  !_vm.newCustomView ||
                                                                  !_vm.isInputValid,
                                                              },
                                                              on: {
                                                                click: function (
                                                                  $event
                                                                ) {
                                                                  return _vm.saveCustomView(
                                                                    "accounts"
                                                                  )
                                                                },
                                                              },
                                                            },
                                                            [_vm._v(" Save ")]
                                                          ),
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                color: "grey",
                                                                depressed: "",
                                                                outlined: "",
                                                              },
                                                              on: {
                                                                click: function (
                                                                  $event
                                                                ) {
                                                                  _vm.showViewForm = false
                                                                },
                                                              },
                                                            },
                                                            [_vm._v(" Cancel ")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              : _vm._e(),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-card",
                                  {
                                    staticClass: "pa-0",
                                    attrs: { flat: "", elevation: "0" },
                                  },
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              lg: "6",
                                              md: "12",
                                              sm: "12",
                                            },
                                          },
                                          [
                                            _c(
                                              "KeepAlive",
                                              { attrs: { max: "1" } },
                                              [
                                                _vm.currentTab ===
                                                "accounts_tab"
                                                  ? _c("Chart", {
                                                      attrs: {
                                                        timeLineEntries:
                                                          _vm.timeLineEntries,
                                                        chartdata:
                                                          _vm.accountsChartData,
                                                        options:
                                                          _vm.chartOptions,
                                                        tabInfo: "accounts",
                                                        customView:
                                                          _vm.customView._id,
                                                        granularity:
                                                          _vm.chartGranularity,
                                                        loading:
                                                          _vm.$apollo.queries
                                                            .accountsGraph
                                                            .loading,
                                                      },
                                                      on: {
                                                        granularityChanged:
                                                          _vm.handleGranularityChange,
                                                        persistDatasets:
                                                          _vm.persistDatasets,
                                                        customViews:
                                                          _vm.saveCustomViews,
                                                        granularityWithTab:
                                                          _vm.saveGranularityToCustomView,
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              lg: "6",
                                              md: "12",
                                              sm: "12",
                                            },
                                          },
                                          [
                                            _c(
                                              "KeepAlive",
                                              { attrs: { max: "1" } },
                                              [
                                                _vm.currentTab ===
                                                "accounts_tab"
                                                  ? _c("TotalSummary", {
                                                      attrs: {
                                                        tab: _vm.tab,
                                                        values:
                                                          (_vm.accountsReport &&
                                                            _vm.accountsReport
                                                              .grandTotals &&
                                                            _vm.accountsReport
                                                              .grandTotals
                                                              .total) ||
                                                          _vm.accountsReports,
                                                        rowItems:
                                                          (_vm.accountsReport &&
                                                            _vm.accountsReport
                                                              .row) ||
                                                          [],
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("div", {
                                      staticClass: "actions-col mt-8",
                                    }),
                                    _c(
                                      "KeepAlive",
                                      { attrs: { max: "1" } },
                                      [
                                        _c("DataTable", {
                                          ref: "accountDatatable",
                                          attrs: {
                                            loading:
                                              _vm.$apollo.queries
                                                .accountsReports.loading,
                                            items:
                                              (_vm.filteredAccountReports &&
                                                _vm.filteredAccountReports
                                                  .row) ||
                                              [],
                                            headers: _vm.accountHeaders,
                                            tab: "Accounts",
                                            tabInfo: "accounts",
                                            tabindex: _vm.tab,
                                            customView: _vm.customView._id,
                                            customTab: "accounts",
                                            itemsLength:
                                              _vm.filteredAccountsReportsCount,
                                            sortBy: _vm.AccountSortBy,
                                            sortDesc: _vm.AccountSortDesc,
                                          },
                                          on: {
                                            persistDatatable:
                                              _vm.persistDatatable,
                                            customViewsTable:
                                              _vm.saveCustomTableViews,
                                            pageChange: function ($event) {
                                              return _vm.fetchRowsPerPageAccounts(
                                                $event
                                              )
                                            },
                                            searchAccountCols: function (
                                              $event
                                            ) {
                                              return _vm.fetchSearchAccounts(
                                                $event
                                              )
                                            },
                                            downloadReport: _vm.downloadReport,
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "item.orgMetadata.orgName",
                                              fn: function (ref) {
                                                var item = ref.item
                                                return [
                                                  _c(
                                                    "a",
                                                    {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.accountFilters(
                                                            item.orgMetadata
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.orgMetadata
                                                              .orgName
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-tab-item",
                              [
                                _c(
                                  "v-row",
                                  { staticClass: "d-flex align-start" },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        attrs: {
                                          cols: "12",
                                          lg: "5",
                                          md: "6",
                                          sm: "8",
                                        },
                                      },
                                      [
                                        !_vm.editMode
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "box-header left-0 chart-header-area d-flex align-baseline pl-6 mb-4 max-400",
                                              },
                                              [
                                                _c("v-select", {
                                                  staticClass: "max-275",
                                                  attrs: {
                                                    "item-text": "CustomView",
                                                    "item-value": "_id",
                                                    items:
                                                      _vm.customViews || [],
                                                    attach: "",
                                                    "menu-props": "auto",
                                                    label: "Select",
                                                    "hide-details": "",
                                                    "single-line": "",
                                                    "return-object": "",
                                                    disabled: _vm.isSuperAdmin
                                                      ? false
                                                      : _vm.permissions.includes(
                                                          _vm
                                                            .SubscriptionPermissionsEnums
                                                            .CUSTOMVIEWS
                                                        )
                                                      ? false
                                                      : true,
                                                  },
                                                  on: {
                                                    change:
                                                      _vm.changeCustomView,
                                                  },
                                                  model: {
                                                    value: _vm.customView,
                                                    callback: function ($$v) {
                                                      _vm.customView = $$v
                                                    },
                                                    expression: "customView",
                                                  },
                                                }),
                                                _vm.customView._id != "0"
                                                  ? _c(
                                                      "v-icon",
                                                      {
                                                        staticClass:
                                                          "ml-2 primary--text",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            _vm.editMode = true
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "mdi-pencil-outline"
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.customView._id != "0"
                                                  ? _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "ml-2",
                                                        attrs: {
                                                          color: "primary",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.deleteCustomView(
                                                              _vm.customView
                                                                ._id,
                                                              "apps"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "mdi-delete-outline"
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.customView._id != "0" &&
                                                _vm.customViews.find(function (
                                                  el
                                                ) {
                                                  return (
                                                    el._id == _vm.customView._id
                                                  )
                                                }).PinnedStatus != true
                                                  ? _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: {
                                                          bottom: "",
                                                          color: "primarydark",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                var attrs =
                                                                  ref.attrs
                                                                return [
                                                                  _c(
                                                                    "v-icon",
                                                                    _vm._g(
                                                                      _vm._b(
                                                                        {
                                                                          staticClass:
                                                                            "ml-2 primary--text",
                                                                          on: {
                                                                            click: function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.pinnedCustomView(
                                                                                true
                                                                              )
                                                                            },
                                                                          },
                                                                        },
                                                                        "v-icon",
                                                                        attrs,
                                                                        false
                                                                      ),
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-pin-outline"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          2813756920
                                                        ),
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v("Pin View"),
                                                        ]),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.customView._id != "0" &&
                                                _vm.customViews.find(function (
                                                  el
                                                ) {
                                                  return (
                                                    el._id == _vm.customView._id
                                                  )
                                                }).PinnedStatus == true
                                                  ? _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: {
                                                          bottom: "",
                                                          color: "primarydark",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                var attrs =
                                                                  ref.attrs
                                                                return [
                                                                  _c(
                                                                    "v-icon",
                                                                    _vm._g(
                                                                      _vm._b(
                                                                        {
                                                                          staticClass:
                                                                            "ml-2 primary--text",
                                                                          on: {
                                                                            click: function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.pinnedCustomView(
                                                                                false
                                                                              )
                                                                            },
                                                                          },
                                                                        },
                                                                        "v-icon",
                                                                        attrs,
                                                                        false
                                                                      ),
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-pin-off-outline"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          1877317105
                                                        ),
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v("Unpin View"),
                                                        ]),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.editMode
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "box-header chart-header-area edit-field d-flex align-baseline pl-6 mt-3",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "max-275" },
                                                  [
                                                    _c("v-text-field", {
                                                      staticClass: "max-275",
                                                      class: {
                                                        "error--text":
                                                          _vm.errorEditViewMessage,
                                                      },
                                                      attrs: {
                                                        outlined: "",
                                                        dense: "",
                                                        placeholder: "value",
                                                      },
                                                      on: {
                                                        input:
                                                          _vm.validateInput,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.customView
                                                            .CustomView,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.customView,
                                                            "CustomView",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "customView.CustomView",
                                                      },
                                                    }),
                                                    _vm.errorEditViewMessage
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "error--text text-caption ml-3 v-messages__message",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.errorEditViewMessage
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: {
                                                      bottom: "",
                                                      color: "primarydark",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function (ref) {
                                                            var on = ref.on
                                                            var attrs =
                                                              ref.attrs
                                                            return [
                                                              _c(
                                                                "v-icon",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      staticClass:
                                                                        "ml-2 primary--text",
                                                                      class: {
                                                                        "disabled-icon":
                                                                          _vm.errorEditViewMessage,
                                                                      },
                                                                      on: {
                                                                        click: function (
                                                                          $event
                                                                        ) {
                                                                          _vm.errorEditViewMessage
                                                                            ? null
                                                                            : _vm.updateCustomView()
                                                                        },
                                                                      },
                                                                    },
                                                                    "v-icon",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _vm._v(
                                                                    "mdi-checkbox-marked-circle"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      2788561454
                                                    ),
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v("Confirm"),
                                                    ]),
                                                  ]
                                                ),
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: {
                                                      bottom: "",
                                                      color: "primarydark",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function (ref) {
                                                            var on = ref.on
                                                            var attrs =
                                                              ref.attrs
                                                            return [
                                                              _c(
                                                                "v-icon",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      staticClass:
                                                                        "ml-2 primary--text",
                                                                      on: {
                                                                        click:
                                                                          _vm.cancelEditView,
                                                                      },
                                                                    },
                                                                    "v-icon",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _vm._v(
                                                                    "mdi-close-circle"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      2384401514
                                                    ),
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v("Cancel"),
                                                    ]),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                    !_vm.editMode
                                      ? _c(
                                          "v-col",
                                          {
                                            staticClass:
                                              "mt-2 d-flex justify-end align-center",
                                            attrs: {
                                              cols: "12",
                                              lg: "7",
                                              md: "6",
                                              sm: "4",
                                            },
                                          },
                                          [
                                            _vm.customView.CustomView !=
                                            "Default View"
                                              ? _c(
                                                  "v-btn",
                                                  {
                                                    staticClass: " ",
                                                    attrs: {
                                                      color: "primary",
                                                      dark: "",
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.updateCustomView,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { staticClass: "mr-1" },
                                                      [
                                                        _vm._v(
                                                          "mdi-arrow-right"
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" Update View "),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  class: [
                                                    "ml-3",
                                                    _vm.isSuperAdmin
                                                      ? "primary"
                                                      : _vm.permissions.includes(
                                                          _vm
                                                            .SubscriptionPermissionsEnums
                                                            .CUSTOMVIEWS
                                                        )
                                                      ? "primary"
                                                      : "disabled-button",
                                                  ],
                                                  attrs: {
                                                    dark: "",
                                                    position: "relative",
                                                  },
                                                },
                                                _vm.isSuperAdmin
                                                  ? {
                                                      click: function () {
                                                        return _vm.openViewForm()
                                                      },
                                                    }
                                                  : _vm.permissions.includes(
                                                      _vm
                                                        .SubscriptionPermissionsEnums
                                                        .CUSTOMVIEWS
                                                    ) == false
                                                  ? {
                                                      click: function () {
                                                        return _vm.openPermissionPopup()
                                                      },
                                                    }
                                                  : {
                                                      click: function () {
                                                        return _vm.openViewForm()
                                                      },
                                                    }
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  { staticClass: "mr-1" },
                                                  [_vm._v("mdi-plus")]
                                                ),
                                                _vm._v(" Add View "),
                                              ],
                                              1
                                            ),
                                            _vm.showViewForm
                                              ? [
                                                  _c(
                                                    "v-card",
                                                    {
                                                      staticClass:
                                                        "mx-auto d-flex align-baseline px-6 py-5 add-view",
                                                      attrs: { outlined: "" },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "field add-view-field",
                                                        },
                                                        [
                                                          _c("v-text-field", {
                                                            staticClass:
                                                              "w-100",
                                                            class: {
                                                              "error-border":
                                                                _vm.errorViewMessage,
                                                            },
                                                            attrs: {
                                                              required: "",
                                                              outlined: "",
                                                              dense: "",
                                                              placeholder:
                                                                "Enter View name",
                                                            },
                                                            on: {
                                                              input:
                                                                _vm.validateInput,
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.newCustomView,
                                                              callback: function (
                                                                $$v
                                                              ) {
                                                                _vm.newCustomView = $$v
                                                              },
                                                              expression:
                                                                "newCustomView",
                                                            },
                                                          }),
                                                          _vm.errorViewMessage
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "error--text text-caption ml-3 v-messages__message",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.errorViewMessage
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-card-actions",
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                color: _vm.newCustomView
                                                                  ? "primary"
                                                                  : "grey",
                                                                disabled:
                                                                  !_vm.newCustomView ||
                                                                  !_vm.isInputValid,
                                                              },
                                                              on: {
                                                                click: function (
                                                                  $event
                                                                ) {
                                                                  return _vm.saveCustomView(
                                                                    "apps"
                                                                  )
                                                                },
                                                              },
                                                            },
                                                            [_vm._v(" Save ")]
                                                          ),
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                color: "grey",
                                                                depressed: "",
                                                                outlined: "",
                                                              },
                                                              on: {
                                                                click: function (
                                                                  $event
                                                                ) {
                                                                  _vm.showViewForm = false
                                                                },
                                                              },
                                                            },
                                                            [_vm._v(" Cancel ")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              : _vm._e(),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-card",
                                  { attrs: { flat: "" } },
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              lg: "6",
                                              md: "12",
                                              sm: "12",
                                            },
                                          },
                                          [
                                            _c(
                                              "KeepAlive",
                                              { attrs: { max: "1" } },
                                              [
                                                _vm.currentTab === "apps_tab"
                                                  ? _c("Chart", {
                                                      attrs: {
                                                        timeLineEntries:
                                                          _vm.timeLineEntries,
                                                        chartdata:
                                                          _vm.appsChartData,
                                                        options:
                                                          _vm.chartOptions,
                                                        tabInfo: "apps",
                                                        customView:
                                                          _vm.customView._id,
                                                        granularity:
                                                          _vm.chartGranularity,
                                                        loading:
                                                          _vm.$apollo.queries
                                                            .appsChart.loading,
                                                      },
                                                      on: {
                                                        granularityChanged:
                                                          _vm.handleGranularityChange,
                                                        persistDatasets:
                                                          _vm.persistDatasets,
                                                        customViews:
                                                          _vm.saveCustomViews,
                                                        granularityWithTab:
                                                          _vm.saveGranularityToCustomView,
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              lg: "6",
                                              md: "12",
                                              sm: "12",
                                            },
                                          },
                                          [
                                            _c(
                                              "KeepAlive",
                                              { attrs: { max: "1" } },
                                              [
                                                _vm.currentTab === "apps_tab"
                                                  ? _c("TotalSummary", {
                                                      attrs: {
                                                        tab: _vm.tab,
                                                        values:
                                                          (_vm.appsReport &&
                                                            _vm.appsReport
                                                              .grandTotals &&
                                                            _vm.appsReport
                                                              .grandTotals
                                                              .total) ||
                                                          _vm.appsReports,
                                                        rowItems:
                                                          (_vm.appsReport &&
                                                            _vm.appsReport
                                                              .row) ||
                                                          [],
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "actions-col pt-5" },
                                      [
                                        _c(
                                          "v-item-group",
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c("v-col", {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "6",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "KeepAlive",
                                      { attrs: { max: "1" } },
                                      [
                                        _c("DataTable", {
                                          ref: "appsDatatable",
                                          attrs: {
                                            loading:
                                              _vm.$apollo.queries.appsReports
                                                .loading,
                                            items:
                                              (_vm.filteredAppReports &&
                                                _vm.filteredAppReports.row) ||
                                              [],
                                            headers: _vm.appsHeaders,
                                            tab: "Apps",
                                            tabInfo: "apps",
                                            tabindex: _vm.tab,
                                            customView: _vm.customView._id,
                                            customTab: "apps",
                                            itemsLength:
                                              _vm.filteredAppReportsCount,
                                            sortBy: _vm.appsReportSortBy,
                                            sortDesc: _vm.appsReportSortDesc,
                                          },
                                          on: {
                                            persistDatatable:
                                              _vm.persistDatatable,
                                            customViewsTable:
                                              _vm.saveCustomTableViews,
                                            pageChange: function ($event) {
                                              return _vm.fetchMoreApps($event)
                                            },
                                            searchAppsCols: function ($event) {
                                              return _vm.fetchSearchApps($event)
                                            },
                                            downloadReport: _vm.downloadReport,
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "item.orgMetadata.orgName",
                                              fn: function (ref) {
                                                var item = ref.item
                                                return [
                                                  _c("a", [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.orgMetadata.orgName
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              },
                                            },
                                            {
                                              key: "item.metadata.app.appName",
                                              fn: function (ref) {
                                                var item = ref.item
                                                return [
                                                  _c(
                                                    "a",
                                                    {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.appFilters(
                                                            item.orgMetadata,
                                                            item.metadata
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.metadata.app
                                                            .appName
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                            {
                                              key: "item.calcGoalsRevenue",
                                              fn: function (ref) {
                                                var item = ref.item
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.calcGoalsRevenue(
                                                          item,
                                                          "appAttributionData"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                            },
                                            {
                                              key: "item.calcGoalRoas",
                                              fn: function (ref) {
                                                var item = ref.item
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.calcGoalRoas(
                                                          item,
                                                          "appAttributionData"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                            },
                                            {
                                              key: "item.calcEventCount",
                                              fn: function (ref) {
                                                var item = ref.item
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.calcEventCount(
                                                          item,
                                                          "appAttributionData"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                            },
                                            {
                                              key: "item.calcCostPerGoal",
                                              fn: function (ref) {
                                                var item = ref.item
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.calcCostPerGoal(
                                                          item,
                                                          "appAttributionData"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                            },
                                            {
                                              key: "item.calcRevenuePerGoal",
                                              fn: function (ref) {
                                                var item = ref.item
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.calcRevenuePerGoal(
                                                          item,
                                                          "appAttributionData"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                            },
                                            {
                                              key: "item.calcARPU",
                                              fn: function (ref) {
                                                var item = ref.item
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.calcARPU(
                                                          item,
                                                          "appAttributionData"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                            },
                                            {
                                              key: "item.calcAMPU",
                                              fn: function (ref) {
                                                var item = ref.item
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.calcAMPU(
                                                          item,
                                                          "appAttributionData"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                            },
                                            {
                                              key: "item.calcEventCountSec",
                                              fn: function (ref) {
                                                var item = ref.item
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.calcEventCountSec(
                                                          item,
                                                          "appAttributionData"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                            },
                                            {
                                              key: "item.calcCostPerGoalSec",
                                              fn: function (ref) {
                                                var item = ref.item
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.calcCostPerGoalSec(
                                                          item,
                                                          "appAttributionData"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-tab-item",
                              [
                                _c(
                                  "v-row",
                                  { staticClass: "d-flex align-start" },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        attrs: {
                                          cols: "12",
                                          lg: "5",
                                          md: "6",
                                          sm: "8",
                                        },
                                      },
                                      [
                                        !_vm.editMode
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "box-header left-0 chart-header-area d-flex align-baseline pl-6 mb-4 max-400",
                                              },
                                              [
                                                _c("v-select", {
                                                  staticClass: "max-275",
                                                  attrs: {
                                                    "item-text": "CustomView",
                                                    "item-value": "_id",
                                                    items:
                                                      _vm.customViews || [],
                                                    attach: "",
                                                    "menu-props": "auto",
                                                    label: "Select",
                                                    "hide-details": "",
                                                    "single-line": "",
                                                    "return-object": "",
                                                    disabled: _vm.isSuperAdmin
                                                      ? false
                                                      : _vm.permissions.includes(
                                                          _vm
                                                            .SubscriptionPermissionsEnums
                                                            .CUSTOMVIEWS
                                                        )
                                                      ? false
                                                      : true,
                                                  },
                                                  on: {
                                                    change:
                                                      _vm.changeCustomView,
                                                  },
                                                  model: {
                                                    value: _vm.customView,
                                                    callback: function ($$v) {
                                                      _vm.customView = $$v
                                                    },
                                                    expression: "customView",
                                                  },
                                                }),
                                                _vm.customView._id != "0"
                                                  ? _c(
                                                      "v-icon",
                                                      {
                                                        staticClass:
                                                          "ml-2 primary--text",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            _vm.editMode = true
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "mdi-pencil-outline"
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.customView._id != "0"
                                                  ? _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "ml-2",
                                                        attrs: {
                                                          color: "primary",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.deleteCustomView(
                                                              _vm.customView
                                                                ._id,
                                                              "campaigns"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "mdi-delete-outline"
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.customView._id != "0" &&
                                                _vm.customViews.find(function (
                                                  el
                                                ) {
                                                  return (
                                                    el._id == _vm.customView._id
                                                  )
                                                }).PinnedStatus != true
                                                  ? _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: {
                                                          bottom: "",
                                                          color: "primarydark",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                var attrs =
                                                                  ref.attrs
                                                                return [
                                                                  _c(
                                                                    "v-icon",
                                                                    _vm._g(
                                                                      _vm._b(
                                                                        {
                                                                          staticClass:
                                                                            "ml-2 primary--text",
                                                                          on: {
                                                                            click: function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.pinnedCustomView(
                                                                                true
                                                                              )
                                                                            },
                                                                          },
                                                                        },
                                                                        "v-icon",
                                                                        attrs,
                                                                        false
                                                                      ),
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-pin-outline"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          2813756920
                                                        ),
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v("Pin View"),
                                                        ]),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.customView._id != "0" &&
                                                _vm.customViews.find(function (
                                                  el
                                                ) {
                                                  return (
                                                    el._id == _vm.customView._id
                                                  )
                                                }).PinnedStatus == true
                                                  ? _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: {
                                                          bottom: "",
                                                          color: "primarydark",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                var attrs =
                                                                  ref.attrs
                                                                return [
                                                                  _c(
                                                                    "v-icon",
                                                                    _vm._g(
                                                                      _vm._b(
                                                                        {
                                                                          staticClass:
                                                                            "ml-2 primary--text",
                                                                          on: {
                                                                            click: function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.pinnedCustomView(
                                                                                false
                                                                              )
                                                                            },
                                                                          },
                                                                        },
                                                                        "v-icon",
                                                                        attrs,
                                                                        false
                                                                      ),
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-pin-off-outline"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          1877317105
                                                        ),
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v("Unpin View"),
                                                        ]),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.editMode
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "box-header chart-header-area edit-field d-flex align-baseline pl-6 mt-3",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "max-275" },
                                                  [
                                                    _c("v-text-field", {
                                                      staticClass: "max-275",
                                                      class: {
                                                        "error--text":
                                                          _vm.errorEditViewMessage,
                                                      },
                                                      attrs: {
                                                        outlined: "",
                                                        dense: "",
                                                        placeholder: "value",
                                                      },
                                                      on: {
                                                        input:
                                                          _vm.validateInput,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.customView
                                                            .CustomView,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.customView,
                                                            "CustomView",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "customView.CustomView",
                                                      },
                                                    }),
                                                    _vm.errorEditViewMessage
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "error--text text-caption ml-3 v-messages__message",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.errorEditViewMessage
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: {
                                                      bottom: "",
                                                      color: "primarydark",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function (ref) {
                                                            var on = ref.on
                                                            var attrs =
                                                              ref.attrs
                                                            return [
                                                              _c(
                                                                "v-icon",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      staticClass:
                                                                        "ml-2 primary--text",
                                                                      class: {
                                                                        "disabled-icon":
                                                                          _vm.errorEditViewMessage,
                                                                      },
                                                                      on: {
                                                                        click: function (
                                                                          $event
                                                                        ) {
                                                                          _vm.errorEditViewMessage
                                                                            ? null
                                                                            : _vm.updateCustomView()
                                                                        },
                                                                      },
                                                                    },
                                                                    "v-icon",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _vm._v(
                                                                    "mdi-checkbox-marked-circle"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      2788561454
                                                    ),
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v("Confirm"),
                                                    ]),
                                                  ]
                                                ),
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: {
                                                      bottom: "",
                                                      color: "primarydark",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function (ref) {
                                                            var on = ref.on
                                                            var attrs =
                                                              ref.attrs
                                                            return [
                                                              _c(
                                                                "v-icon",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      staticClass:
                                                                        "ml-2 primary--text",
                                                                      on: {
                                                                        click:
                                                                          _vm.cancelEditView,
                                                                      },
                                                                    },
                                                                    "v-icon",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _vm._v(
                                                                    "mdi-close-circle"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      2384401514
                                                    ),
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v("Cancel"),
                                                    ]),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                    !_vm.editMode
                                      ? _c(
                                          "v-col",
                                          {
                                            staticClass:
                                              "mt-2 d-flex justify-end align-center",
                                            attrs: {
                                              cols: "12",
                                              lg: "7",
                                              md: "6",
                                              sm: "4",
                                            },
                                          },
                                          [
                                            _vm.customView.CustomView !=
                                            "Default View"
                                              ? _c(
                                                  "v-btn",
                                                  {
                                                    staticClass: " ",
                                                    attrs: {
                                                      color: "primary",
                                                      dark: "",
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.updateCustomView,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { staticClass: "mr-1" },
                                                      [
                                                        _vm._v(
                                                          "mdi-arrow-right"
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" Update View "),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  class: [
                                                    "ml-3",
                                                    _vm.isSuperAdmin
                                                      ? "primary"
                                                      : _vm.permissions.includes(
                                                          _vm
                                                            .SubscriptionPermissionsEnums
                                                            .CUSTOMVIEWS
                                                        )
                                                      ? "primary"
                                                      : "disabled-button",
                                                  ],
                                                  attrs: {
                                                    dark: "",
                                                    position: "relative",
                                                  },
                                                },
                                                _vm.isSuperAdmin
                                                  ? {
                                                      click: function () {
                                                        return _vm.openViewForm()
                                                      },
                                                    }
                                                  : _vm.permissions.includes(
                                                      _vm
                                                        .SubscriptionPermissionsEnums
                                                        .CUSTOMVIEWS
                                                    ) == false
                                                  ? {
                                                      click: function () {
                                                        return _vm.openPermissionPopup()
                                                      },
                                                    }
                                                  : {
                                                      click: function () {
                                                        return _vm.openViewForm()
                                                      },
                                                    }
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  { staticClass: "mr-1" },
                                                  [_vm._v("mdi-plus")]
                                                ),
                                                _vm._v(" Add View "),
                                              ],
                                              1
                                            ),
                                            _vm.showViewForm
                                              ? [
                                                  _c(
                                                    "v-card",
                                                    {
                                                      staticClass:
                                                        "mx-auto d-flex align-baseline px-6 py-5 add-view",
                                                      attrs: { outlined: "" },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "field add-view-field",
                                                        },
                                                        [
                                                          _c("v-text-field", {
                                                            staticClass:
                                                              "w-100",
                                                            class: {
                                                              "error--text":
                                                                _vm.errorViewMessage,
                                                            },
                                                            attrs: {
                                                              required: "",
                                                              outlined: "",
                                                              dense: "",
                                                              placeholder:
                                                                "Enter View name",
                                                            },
                                                            on: {
                                                              input:
                                                                _vm.validateInput,
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.newCustomView,
                                                              callback: function (
                                                                $$v
                                                              ) {
                                                                _vm.newCustomView = $$v
                                                              },
                                                              expression:
                                                                "newCustomView",
                                                            },
                                                          }),
                                                          _vm.errorViewMessage
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "error--text text-caption ml-3 v-messages__message",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.errorViewMessage
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-card-actions",
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                color: _vm.newCustomView
                                                                  ? "primary"
                                                                  : "grey",
                                                                disabled:
                                                                  !_vm.newCustomView ||
                                                                  !_vm.isInputValid,
                                                              },
                                                              on: {
                                                                click: function (
                                                                  $event
                                                                ) {
                                                                  return _vm.saveCustomView(
                                                                    "campaigns"
                                                                  )
                                                                },
                                                              },
                                                            },
                                                            [_vm._v(" Save ")]
                                                          ),
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                color: "grey",
                                                                depressed: "",
                                                                outlined: "",
                                                              },
                                                              on: {
                                                                click: function (
                                                                  $event
                                                                ) {
                                                                  _vm.showViewForm = false
                                                                },
                                                              },
                                                            },
                                                            [_vm._v(" Cancel ")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              : _vm._e(),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-card",
                                  { attrs: { flat: "" } },
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              lg: "6",
                                              md: "12",
                                              sm: "12",
                                            },
                                          },
                                          [
                                            _c(
                                              "KeepAlive",
                                              { attrs: { max: "1" } },
                                              [
                                                _vm.currentTab ===
                                                "campaigns_tab"
                                                  ? _c("Chart", {
                                                      attrs: {
                                                        timeLineEntries:
                                                          _vm.timeLineEntries,
                                                        chartdata:
                                                          _vm.campaignChartData,
                                                        options:
                                                          _vm.chartOptions,
                                                        tabInfo: "campaigns",
                                                        strategySelection:
                                                          _vm.selectedStatestrategy,
                                                        customView:
                                                          _vm.customView._id,
                                                        granularity:
                                                          _vm.chartGranularity,
                                                        loading:
                                                          _vm.$apollo.queries
                                                            .campaignChart
                                                            .loading,
                                                      },
                                                      on: {
                                                        granularityChanged:
                                                          _vm.handleGranularityChange,
                                                        persistDatasets:
                                                          _vm.persistDatasets,
                                                        customViews:
                                                          _vm.saveCustomViews,
                                                        granularityWithTab:
                                                          _vm.saveGranularityToCustomView,
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              lg: "6",
                                              md: "12",
                                              sm: "12",
                                            },
                                          },
                                          [
                                            _c(
                                              "KeepAlive",
                                              { attrs: { max: "1" } },
                                              [
                                                _vm.currentTab ===
                                                "campaigns_tab"
                                                  ? _c(
                                                      "TotalSummaryCampaigns",
                                                      {
                                                        attrs: {
                                                          tab: _vm.tab,
                                                          values:
                                                            _vm.campaignTotalSummaryData,
                                                          rowItems:
                                                            (_vm.campaignReport &&
                                                              _vm.campaignReport
                                                                .row) ||
                                                            [],
                                                        },
                                                      }
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "KeepAlive",
                                      { attrs: { max: "1" } },
                                      [
                                        _c("EditCampaign", {
                                          attrs: {
                                            campaignData: _vm.campaignMetadata,
                                          },
                                          on: {
                                            campaignUpdated: function ($event) {
                                              return _vm.onCampaignUpdate()
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "KeepAlive",
                                      { attrs: { max: "1" } },
                                      [
                                        _c("DeleteCampaign", {
                                          attrs: {
                                            campaignDetailsForDeletion:
                                              _vm.campaignDeletionMetadata,
                                          },
                                          on: {
                                            onDeleteCampaign: function (
                                              $event
                                            ) {
                                              return _vm.onUpdate()
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      {
                                        staticClass:
                                          "d-flex align-center mt-8 mb-4 custom-tab-outer",
                                      },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              lg: "9",
                                              md: "9",
                                              sm: "8",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-tabs",
                                              {
                                                staticClass: "custom-tab",
                                                attrs: {
                                                  "center-active": "",
                                                  "show-arrows": "",
                                                  "background-color":
                                                    "transparent",
                                                },
                                                model: {
                                                  value: _vm.campaignListingTab,
                                                  callback: function ($$v) {
                                                    _vm.campaignListingTab = $$v
                                                  },
                                                  expression:
                                                    "campaignListingTab",
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-tab",
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "subTabs.live"
                                                          ) +
                                                            " " +
                                                            _vm.$t(
                                                              "subTabs.campaigns"
                                                            )
                                                        ) +
                                                        " "
                                                    ),
                                                    _c(
                                                      "v-avatar",
                                                      {
                                                        staticClass: "mx-3",
                                                        attrs: {
                                                          color: "primary",
                                                          size: "26",
                                                        },
                                                      },
                                                      [
                                                        !_vm.$apollo.queries
                                                          .campaignReportsLive
                                                          .loading
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "white--text caption",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.liveReportsCount
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm.$apollo.queries
                                                          .campaignReportsLive
                                                          .loading
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "white--text caption",
                                                              },
                                                              [_vm._v("...")]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-tab",
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "subTabs.paused"
                                                          ) +
                                                            " " +
                                                            _vm.$t(
                                                              "subTabs.campaigns"
                                                            )
                                                        ) +
                                                        " "
                                                    ),
                                                    _c(
                                                      "v-avatar",
                                                      {
                                                        staticClass: "mx-3",
                                                        attrs: {
                                                          color: "primary",
                                                          size: "26",
                                                        },
                                                      },
                                                      [
                                                        !_vm.$apollo.queries
                                                          .campaignReportsPaused
                                                          .loading
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "white--text caption",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.disabledReportsCount
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm.$apollo.queries
                                                          .campaignReportsPaused
                                                          .loading
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "white--text caption",
                                                              },
                                                              [_vm._v("...")]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-tab",
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "subTabs.deleted"
                                                          ) +
                                                            " " +
                                                            _vm.$t(
                                                              "subTabs.campaigns"
                                                            )
                                                        ) +
                                                        " "
                                                    ),
                                                    _c(
                                                      "v-avatar",
                                                      {
                                                        staticClass: "mx-3",
                                                        attrs: {
                                                          color: "primary",
                                                          size: "26",
                                                        },
                                                      },
                                                      [
                                                        !_vm.$apollo.queries
                                                          .campaignReportsDeleted
                                                          .loading
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "white--text caption",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.deletedReportsCount
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm.$apollo.queries
                                                          .campaignReportsDeleted
                                                          .loading
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "white--text caption",
                                                              },
                                                              [_vm._v("...")]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-tab",
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "subTabs.draft"
                                                          ) +
                                                            " " +
                                                            _vm.$t(
                                                              "subTabs.campaigns"
                                                            )
                                                        ) +
                                                        " "
                                                    ),
                                                    _c(
                                                      "v-avatar",
                                                      {
                                                        staticClass: "mx-3",
                                                        attrs: {
                                                          color: "primary",
                                                          size: "26",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "white--text caption",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  (_vm.getCampaignDrafts &&
                                                                    _vm
                                                                      .getCampaignDrafts
                                                                      .length) ||
                                                                    0
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-tab",
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "subTabs.all"
                                                          ) +
                                                            " " +
                                                            _vm.$t(
                                                              "subTabs.campaigns"
                                                            )
                                                        ) +
                                                        " "
                                                    ),
                                                    _c(
                                                      "v-avatar",
                                                      {
                                                        staticClass: "mx-3",
                                                        attrs: {
                                                          color: "primary",
                                                          size: "26",
                                                        },
                                                      },
                                                      [
                                                        !_vm.$apollo.queries
                                                          .campaignReportsAll
                                                          .loading
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "white--text caption",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.allReportsCount
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm.$apollo.queries
                                                          .campaignReportsAll
                                                          .loading
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "white--text caption",
                                                              },
                                                              [_vm._v("...")]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "d-flex justify-end",
                                            attrs: {
                                              cols: "12",
                                              lg: "3",
                                              md: "3",
                                              sm: "4",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  staticClass:
                                                    "w-sm-100 min-width-156",
                                                  attrs: {
                                                    depressed: "",
                                                    color: "primary",
                                                  },
                                                },
                                                !_vm.isReadOnly()
                                                  ? {
                                                      click: function () {
                                                        return _vm.openCampaignViewDialog()
                                                      },
                                                    }
                                                  : {
                                                      click: function () {
                                                        return _vm.openPermissionReadOnlyPopup()
                                                      },
                                                    }
                                              ),
                                              [
                                                _c("v-icon", [
                                                  _vm._v("mdi-plus"),
                                                ]),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("popups.add") +
                                                        " " +
                                                        _vm.$t(
                                                          "popups.campaigns"
                                                        )
                                                    ) +
                                                    " "
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "KeepAlive",
                                              { attrs: { max: "1" } },
                                              [
                                                _c("CampaginViewDialog", {
                                                  attrs: {
                                                    campaignViewMetadata:
                                                      _vm.campaignViewMetadata,
                                                  },
                                                  on: {
                                                    emitNextStep: function (
                                                      $event
                                                    ) {
                                                      return _vm.openSearchTabDialog(
                                                        $event
                                                      )
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "KeepAlive",
                                              { attrs: { max: "1" } },
                                              [
                                                _c("SearchTabDialog", {
                                                  attrs: {
                                                    searchTabMetadata:
                                                      _vm.searchTabMetadata,
                                                  },
                                                  on: {
                                                    emitBackStep:
                                                      _vm.openCampaignViewDialog,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "KeepAlive",
                                              { attrs: { max: "1" } },
                                              [
                                                _c("SearchResultDialog", {
                                                  attrs: {
                                                    searchResultMetadata:
                                                      _vm.searchResultMetadata,
                                                  },
                                                  on: {
                                                    emitBackStep:
                                                      _vm.openCampaignViewDialog,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "KeepAlive",
                                              { attrs: { max: "1" } },
                                              [
                                                _c("TodayTabDialog", {
                                                  attrs: {
                                                    todayTabMetadata:
                                                      _vm.todayTabMetadata,
                                                  },
                                                  on: {
                                                    emitBackStep:
                                                      _vm.openCampaignViewDialog,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "KeepAlive",
                                              { attrs: { max: "1" } },
                                              [
                                                _c("ProductPageDialog", {
                                                  attrs: {
                                                    productPageMetadata:
                                                      _vm.productPageMetadata,
                                                  },
                                                  on: {
                                                    emitBackStep:
                                                      _vm.openCampaignViewDialog,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-tabs-items",
                                      {
                                        staticClass: "show-full-content",
                                        model: {
                                          value: _vm.campaignListingTab,
                                          callback: function ($$v) {
                                            _vm.campaignListingTab = $$v
                                          },
                                          expression: "campaignListingTab",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-tab-item",
                                          [
                                            _c(
                                              "KeepAlive",
                                              { attrs: { max: "1" } },
                                              [
                                                _c("DataTable", {
                                                  ref: "liveCampaignDatatable",
                                                  attrs: {
                                                    loading:
                                                      _vm.$apollo.queries
                                                        .campaignReportsLive
                                                        .loading,
                                                    items:
                                                      (_vm.campaignReport &&
                                                        _vm.campaignReport
                                                          .row) ||
                                                      [],
                                                    headers:
                                                      _vm.campaignHeaders,
                                                    tab: "Live-Campaigns",
                                                    tabInfo: "livecampaign",
                                                    tabindex: _vm.tab,
                                                    customTab: "campaigns",
                                                    customView:
                                                      _vm.customView._id,
                                                    itemsLength:
                                                      _vm.liveReportsCount,
                                                    sortBy:
                                                      _vm.LiveCampaignSortBy,
                                                    sortDesc:
                                                      _vm.LiveCampaignSortDesc,
                                                  },
                                                  on: {
                                                    persistDatatable:
                                                      _vm.persistDatatable,
                                                    customViewsTable:
                                                      _vm.saveCustomTableViews,
                                                    pageChange: function (
                                                      $event
                                                    ) {
                                                      return _vm.fetchMoreCampaignsLive(
                                                        $event
                                                      )
                                                    },
                                                    campSearchLiveCols: function (
                                                      $event
                                                    ) {
                                                      return _vm.searchCampaignsLive(
                                                        $event
                                                      )
                                                    },
                                                    downloadReport:
                                                      _vm.downloadReport,
                                                  },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key:
                                                        "item.campaignMetadata.name",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _c(
                                                            "p",
                                                            [
                                                              !item
                                                                .campaignMetadata
                                                                .deleted
                                                                ? _c(
                                                                    "v-icon",
                                                                    _vm._g(
                                                                      {
                                                                        staticClass:
                                                                          "mr-2",
                                                                        attrs: {
                                                                          color:
                                                                            "primary",
                                                                        },
                                                                      },
                                                                      !_vm.isReadOnly()
                                                                        ? {
                                                                            click: function () {
                                                                              return _vm.openEditCampaignWizard(
                                                                                item
                                                                              )
                                                                            },
                                                                          }
                                                                        : {
                                                                            click: function () {
                                                                              return _vm.openPermissionReadOnlyPopup()
                                                                            },
                                                                          }
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-pencil-outline"
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              _c(
                                                                "a",
                                                                {
                                                                  on: {
                                                                    click: function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.campaignFilters(
                                                                        item.orgMetadata,
                                                                        item.metadata,
                                                                        item.campaignMetadata
                                                                      )
                                                                    },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item
                                                                        .campaignMetadata
                                                                        .name
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.campaignMetadata.supplySources",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          item.campaignMetadata
                                                            .supplySources ==
                                                          "APPSTORE_SEARCH_RESULTS"
                                                            ? _c("div", [
                                                                _vm._v(
                                                                  "Search Results"
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                          item.campaignMetadata
                                                            .supplySources ==
                                                          "APPSTORE_TODAY_TAB"
                                                            ? _c("div", [
                                                                _vm._v(
                                                                  "Todays Tab"
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                          item.campaignMetadata
                                                            .supplySources ==
                                                          "APPSTORE_SEARCH_TAB"
                                                            ? _c("div", [
                                                                _vm._v(
                                                                  "Search Tab"
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                          item.campaignMetadata
                                                            .supplySources ==
                                                          "APPSTORE_PRODUCT_PAGES_BROWSE"
                                                            ? _c("div", [
                                                                _vm._v(
                                                                  "Product Pages"
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.metadata.countriesOrRegions",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return _vm._l(
                                                          item.metadata
                                                            .countriesOrRegions,
                                                          function (country) {
                                                            return _c("p", [
                                                              _vm._v(
                                                                _vm._s(country)
                                                              ),
                                                            ])
                                                          }
                                                        )
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.campaignMetadata.deleted",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          item.campaignMetadata
                                                            .deleted
                                                            ? _c(
                                                                "v-chip",
                                                                {
                                                                  staticClass:
                                                                    "ma-2",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "negativeHeaders.deleted"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          !item.campaignMetadata
                                                            .deleted
                                                            ? _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "mx-2",
                                                                    attrs: {
                                                                      icon: "",
                                                                      dark: "",
                                                                      elevation:
                                                                        "0",
                                                                      small: "",
                                                                      color:
                                                                        "primary",
                                                                    },
                                                                  },
                                                                  !_vm.isReadOnly()
                                                                    ? {
                                                                        click: function () {
                                                                          return _vm.openDeleteCampaignDialog(
                                                                            item
                                                                          )
                                                                        },
                                                                      }
                                                                    : {
                                                                        click: function () {
                                                                          return _vm.openPermissionReadOnlyPopup()
                                                                        },
                                                                      }
                                                                ),
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        dark:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-delete-outline"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.campaignMetadata.status",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                bottom: "",
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "activator",
                                                                    fn: function (
                                                                      ref
                                                                    ) {
                                                                      var on =
                                                                        ref.on
                                                                      var attrs =
                                                                        ref.attrs
                                                                      return [
                                                                        _c(
                                                                          "div",
                                                                          _vm._g(
                                                                            _vm._b(
                                                                              {
                                                                                staticStyle: {
                                                                                  width:
                                                                                    "100%",
                                                                                },
                                                                              },
                                                                              "div",
                                                                              attrs,
                                                                              false
                                                                            ),
                                                                            on
                                                                          ),
                                                                          [
                                                                            item
                                                                              .campaignMetadata
                                                                              .deleted
                                                                              ? _c(
                                                                                  "v-switch",
                                                                                  {
                                                                                    staticClass:
                                                                                      "borderedSwitch table-switch",
                                                                                    attrs: {
                                                                                      color:
                                                                                        "primary",
                                                                                      "true-value":
                                                                                        "ENABLED",
                                                                                      "false-value":
                                                                                        "PAUSED",
                                                                                      inset:
                                                                                        "",
                                                                                      outlined:
                                                                                        "",
                                                                                      disabled:
                                                                                        "",
                                                                                    },
                                                                                    model: {
                                                                                      value:
                                                                                        item
                                                                                          .campaignMetadata
                                                                                          .status,
                                                                                      callback: function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          item.campaignMetadata,
                                                                                          "status",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                      expression:
                                                                                        "item.campaignMetadata.status",
                                                                                    },
                                                                                  }
                                                                                )
                                                                              : _vm._e(),
                                                                            !item
                                                                              .campaignMetadata
                                                                              .deleted &&
                                                                            item
                                                                              .campaignMetadata
                                                                              .displayStatus !==
                                                                              "ON_HOLD"
                                                                              ? _c(
                                                                                  "v-switch",
                                                                                  _vm._g(
                                                                                    {
                                                                                      staticClass:
                                                                                        "borderedSwitch table-switch",
                                                                                      attrs: {
                                                                                        color:
                                                                                          "primary",
                                                                                        "true-value":
                                                                                          "ENABLED",
                                                                                        "false-value":
                                                                                          "PAUSED",
                                                                                        outlined:
                                                                                          "",
                                                                                        inset:
                                                                                          "",
                                                                                        readonly:
                                                                                          "",
                                                                                      },
                                                                                      model: {
                                                                                        value:
                                                                                          item
                                                                                            .campaignMetadata
                                                                                            .status,
                                                                                        callback: function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            item.campaignMetadata,
                                                                                            "status",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                        expression:
                                                                                          "item.campaignMetadata.status",
                                                                                      },
                                                                                    },
                                                                                    !_vm.isReadOnly()
                                                                                      ? {
                                                                                          click: function () {
                                                                                            return _vm.askConfirm(
                                                                                              item
                                                                                                .campaignMetadata
                                                                                                .status ==
                                                                                                "ENABLED"
                                                                                                ? "PAUSED"
                                                                                                : "ENABLED",
                                                                                              item,
                                                                                              "campaign"
                                                                                            )
                                                                                          },
                                                                                        }
                                                                                      : {
                                                                                          click: function () {
                                                                                            return _vm.openPermissionReadOnlyPopup()
                                                                                          },
                                                                                        }
                                                                                  )
                                                                                )
                                                                              : _vm._e(),
                                                                            !item
                                                                              .campaignMetadata
                                                                              .deleted &&
                                                                            item
                                                                              .campaignMetadata
                                                                              .displayStatus ===
                                                                              "ON_HOLD"
                                                                              ? _c(
                                                                                  "v-switch",
                                                                                  _vm._g(
                                                                                    {
                                                                                      staticClass:
                                                                                        "borderedSwitch table-switch hold-switch",
                                                                                      attrs: {
                                                                                        color:
                                                                                          "primary lighten-1 ",
                                                                                        "true-value":
                                                                                          "ENABLED",
                                                                                        "false-value":
                                                                                          "PAUSED",
                                                                                        outlined:
                                                                                          "",
                                                                                        inset:
                                                                                          "",
                                                                                        readonly:
                                                                                          "",
                                                                                      },
                                                                                      model: {
                                                                                        value:
                                                                                          item
                                                                                            .campaignMetadata
                                                                                            .status,
                                                                                        callback: function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            item.campaignMetadata,
                                                                                            "status",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                        expression:
                                                                                          "item.campaignMetadata.status",
                                                                                      },
                                                                                    },
                                                                                    !_vm.isReadOnly()
                                                                                      ? {
                                                                                          click: function () {
                                                                                            return _vm.askConfirm(
                                                                                              item
                                                                                                .campaignMetadata
                                                                                                .status ==
                                                                                                "ENABLED"
                                                                                                ? "PAUSED"
                                                                                                : "ENABLED",
                                                                                              item,
                                                                                              "campaign"
                                                                                            )
                                                                                          },
                                                                                        }
                                                                                      : {
                                                                                          click: function () {
                                                                                            return _vm.openPermissionReadOnlyPopup()
                                                                                          },
                                                                                        }
                                                                                  )
                                                                                )
                                                                              : _vm._e(),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ]
                                                                    },
                                                                  },
                                                                ],
                                                                null,
                                                                true
                                                              ),
                                                            },
                                                            [
                                                              item
                                                                .campaignMetadata
                                                                .displayStatus !==
                                                                "ON_HOLD" &&
                                                              item
                                                                .campaignMetadata
                                                                .status ==
                                                                "ENABLED"
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "aRules.disable"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ])
                                                                : _vm._e(),
                                                              item
                                                                .campaignMetadata
                                                                .displayStatus !==
                                                                "ON_HOLD" &&
                                                              item
                                                                .campaignMetadata
                                                                .status ==
                                                                "PAUSED"
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "aRules.enable"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ])
                                                                : _vm._e(),
                                                              item
                                                                .campaignMetadata
                                                                .displayStatus ===
                                                              "ON_HOLD"
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.formatReasons(
                                                                          item
                                                                            .campaignMetadata
                                                                            .servingStateReasons
                                                                        )
                                                                      )
                                                                    ),
                                                                  ])
                                                                : _vm._e(),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcGoalsRevenue",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcGoalsRevenue(
                                                                    item,
                                                                    "campaignAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "item.calcGoalRoas",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.calcGoalRoas(
                                                                  item,
                                                                  "campaignAttributionData"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcEventCount",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.calcEventCount(
                                                                  item,
                                                                  "campaignAttributionData"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcCostPerGoal",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcCostPerGoal(
                                                                    item,
                                                                    "campaignAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcRevenuePerGoal",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcRevenuePerGoal(
                                                                    item,
                                                                    "campaignAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "item.calcARPU",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcARPU(
                                                                    item,
                                                                    "campaignAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "item.calcAMPU",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcAMPU(
                                                                    item,
                                                                    "campaignAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcEventCountSec",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.calcEventCountSec(
                                                                  item,
                                                                  "campaignAttributionData"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcCostPerGoalSec",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcCostPerGoalSec(
                                                                    item,
                                                                    "campaignAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.campaignMetadata.budgetAmount.amount",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          item.campaignMetadata
                                                            .budgetAmount &&
                                                          item.campaignMetadata
                                                            .budgetAmount.amount
                                                            ? _c("div", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.getCurrencyIcon(
                                                                        item
                                                                          .orgMetadata
                                                                          .currency
                                                                      ) +
                                                                        "" +
                                                                        _vm.numDecimalFormat(
                                                                          Number(
                                                                            item
                                                                              .campaignMetadata
                                                                              .budgetAmount
                                                                              .amount
                                                                          )
                                                                        )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.campaignMetadata.dailyBudgetAmount.amount",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          item.campaignMetadata
                                                            .dailyBudgetAmount &&
                                                          item.campaignMetadata
                                                            .dailyBudgetAmount
                                                            .amount
                                                            ? _c("div", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.getCurrencyIcon(
                                                                        item
                                                                          .orgMetadata
                                                                          .currency
                                                                      ) +
                                                                        "" +
                                                                        _vm.numDecimalFormat(
                                                                          Number(
                                                                            item
                                                                              .campaignMetadata
                                                                              .dailyBudgetAmount
                                                                              .amount
                                                                          )
                                                                        )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                  ]),
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-tab-item",
                                          [
                                            _c(
                                              "KeepAlive",
                                              { attrs: { max: "1" } },
                                              [
                                                _c("DataTable", {
                                                  ref:
                                                    "pausedCampaignDatatable",
                                                  attrs: {
                                                    items:
                                                      (_vm.disabledCampaignReports &&
                                                        _vm
                                                          .disabledCampaignReports
                                                          .row) ||
                                                      [],
                                                    headers:
                                                      _vm.campaignHeaders,
                                                    loading:
                                                      _vm.$apollo.queries
                                                        .campaignReportsPaused
                                                        .loading,
                                                    tab: "Disabled-Campaigns",
                                                    tabInfo: "pausedcampaign",
                                                    tabindex: _vm.tab,
                                                    customView:
                                                      _vm.customView._id,
                                                    customTab: "campaigns",
                                                    itemsLength:
                                                      _vm.disabledReportsCount,
                                                    sortBy:
                                                      _vm.PausedCampaignSortBy,
                                                    sortDesc:
                                                      _vm.PausedCampaignSortDesc,
                                                  },
                                                  on: {
                                                    persistDatatable:
                                                      _vm.persistDatatable,
                                                    customViewsTable:
                                                      _vm.saveCustomTableViews,
                                                    pageChange: function (
                                                      $event
                                                    ) {
                                                      return _vm.fetchMoreCampaignsPaused(
                                                        $event
                                                      )
                                                    },
                                                    campSearchPausedCols: function (
                                                      $event
                                                    ) {
                                                      return _vm.searchCampaignsPaused(
                                                        $event
                                                      )
                                                    },
                                                    downloadReport:
                                                      _vm.downloadReport,
                                                  },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key:
                                                        "item.campaignMetadata.name",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _c(
                                                            "p",
                                                            [
                                                              !item
                                                                .campaignMetadata
                                                                .deleted
                                                                ? _c(
                                                                    "v-icon",
                                                                    _vm._g(
                                                                      {
                                                                        staticClass:
                                                                          "mr-2",
                                                                        attrs: {
                                                                          color:
                                                                            "primary",
                                                                        },
                                                                      },
                                                                      !_vm.isReadOnly()
                                                                        ? {
                                                                            click: function () {
                                                                              return _vm.openEditCampaignWizard(
                                                                                item
                                                                              )
                                                                            },
                                                                          }
                                                                        : {
                                                                            click: function () {
                                                                              return _vm.openPermissionReadOnlyPopup()
                                                                            },
                                                                          }
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-pencil-outline"
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              _c(
                                                                "a",
                                                                {
                                                                  on: {
                                                                    click: function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.campaignFilters(
                                                                        item.orgMetadata,
                                                                        item.metadata,
                                                                        item.campaignMetadata
                                                                      )
                                                                    },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item
                                                                        .campaignMetadata
                                                                        .name
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.campaignMetadata.supplySources",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          item.campaignMetadata
                                                            .supplySources ==
                                                          "APPSTORE_SEARCH_RESULTS"
                                                            ? _c("div", [
                                                                _vm._v(
                                                                  "Search Results"
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                          item.campaignMetadata
                                                            .supplySources ==
                                                          "APPSTORE_TODAY_TAB"
                                                            ? _c("div", [
                                                                _vm._v(
                                                                  "Todays Tab"
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                          item.campaignMetadata
                                                            .supplySources ==
                                                          "APPSTORE_SEARCH_TAB"
                                                            ? _c("div", [
                                                                _vm._v(
                                                                  "Search Tab"
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                          item.campaignMetadata
                                                            .supplySources ==
                                                          "APPSTORE_PRODUCT_PAGES_BROWSE"
                                                            ? _c("div", [
                                                                _vm._v(
                                                                  "Product Pages"
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.metadata.countriesOrRegions",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return _vm._l(
                                                          item.metadata
                                                            .countriesOrRegions,
                                                          function (country) {
                                                            return _c("p", [
                                                              _vm._v(
                                                                _vm._s(country)
                                                              ),
                                                            ])
                                                          }
                                                        )
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.campaignMetadata.deleted",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          item.campaignMetadata
                                                            .deleted
                                                            ? _c(
                                                                "v-chip",
                                                                {
                                                                  staticClass:
                                                                    "ma-2",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "negativeHeaders.deleted"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          !item.campaignMetadata
                                                            .deleted
                                                            ? _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "mx-2 delete-btn",
                                                                    attrs: {
                                                                      icon: "",
                                                                      dark: "",
                                                                      small: "",
                                                                      color:
                                                                        "primary",
                                                                    },
                                                                  },
                                                                  !_vm.isReadOnly()
                                                                    ? {
                                                                        click: function () {
                                                                          return _vm.openDeleteCampaignDialog(
                                                                            item
                                                                          )
                                                                        },
                                                                      }
                                                                    : {
                                                                        click: function () {
                                                                          return _vm.openPermissionReadOnlyPopup()
                                                                        },
                                                                      }
                                                                ),
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        dark:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-delete-outline"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.campaignMetadata.status",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                bottom: "",
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "activator",
                                                                    fn: function (
                                                                      ref
                                                                    ) {
                                                                      var on =
                                                                        ref.on
                                                                      var attrs =
                                                                        ref.attrs
                                                                      return [
                                                                        _c(
                                                                          "div",
                                                                          _vm._g(
                                                                            _vm._b(
                                                                              {
                                                                                staticStyle: {
                                                                                  width:
                                                                                    "100%",
                                                                                },
                                                                              },
                                                                              "div",
                                                                              attrs,
                                                                              false
                                                                            ),
                                                                            on
                                                                          ),
                                                                          [
                                                                            item
                                                                              .campaignMetadata
                                                                              .deleted
                                                                              ? _c(
                                                                                  "v-switch",
                                                                                  {
                                                                                    staticClass:
                                                                                      "borderedSwitch table-switch",
                                                                                    attrs: {
                                                                                      color:
                                                                                        "primary",
                                                                                      "true-value":
                                                                                        "ENABLED",
                                                                                      "false-value":
                                                                                        "PAUSED",
                                                                                      inset:
                                                                                        "",
                                                                                      outlined:
                                                                                        "",
                                                                                      disabled:
                                                                                        "",
                                                                                    },
                                                                                    model: {
                                                                                      value:
                                                                                        item
                                                                                          .campaignMetadata
                                                                                          .status,
                                                                                      callback: function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          item.campaignMetadata,
                                                                                          "status",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                      expression:
                                                                                        "item.campaignMetadata.status",
                                                                                    },
                                                                                  }
                                                                                )
                                                                              : _vm._e(),
                                                                            !item
                                                                              .campaignMetadata
                                                                              .deleted
                                                                              ? _c(
                                                                                  "v-switch",
                                                                                  _vm._g(
                                                                                    {
                                                                                      staticClass:
                                                                                        "borderedSwitch table-switch",
                                                                                      attrs: {
                                                                                        color:
                                                                                          "primary",
                                                                                        "true-value":
                                                                                          "ENABLED",
                                                                                        "false-value":
                                                                                          "PAUSED",
                                                                                        inset:
                                                                                          "",
                                                                                        outlined:
                                                                                          "",
                                                                                        readonly:
                                                                                          "",
                                                                                      },
                                                                                      model: {
                                                                                        value:
                                                                                          item
                                                                                            .campaignMetadata
                                                                                            .status,
                                                                                        callback: function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            item.campaignMetadata,
                                                                                            "status",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                        expression:
                                                                                          "item.campaignMetadata.status",
                                                                                      },
                                                                                    },
                                                                                    !_vm.isReadOnly()
                                                                                      ? {
                                                                                          click: function () {
                                                                                            return _vm.askConfirm(
                                                                                              item
                                                                                                .campaignMetadata
                                                                                                .status ==
                                                                                                "ENABLED"
                                                                                                ? "PAUSED"
                                                                                                : "ENABLED",
                                                                                              item,
                                                                                              "campaign"
                                                                                            )
                                                                                          },
                                                                                        }
                                                                                      : {
                                                                                          click: function () {
                                                                                            return _vm.openPermissionReadOnlyPopup()
                                                                                          },
                                                                                        }
                                                                                  )
                                                                                )
                                                                              : _vm._e(),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ]
                                                                    },
                                                                  },
                                                                ],
                                                                null,
                                                                true
                                                              ),
                                                            },
                                                            [
                                                              item
                                                                .campaignMetadata
                                                                .status ==
                                                              "ENABLED"
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "aRules.disable"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ])
                                                                : _vm._e(),
                                                              item
                                                                .campaignMetadata
                                                                .status ==
                                                              "PAUSED"
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "aRules.enable"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ])
                                                                : _vm._e(),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcGoalsRevenue",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcGoalsRevenue(
                                                                    item,
                                                                    "campaignAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "item.calcGoalRoas",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.calcGoalRoas(
                                                                  item,
                                                                  "campaignAttributionData"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcEventCount",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.calcEventCount(
                                                                  item,
                                                                  "campaignAttributionData"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcCostPerGoal",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcCostPerGoal(
                                                                    item,
                                                                    "campaignAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcRevenuePerGoal",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcRevenuePerGoal(
                                                                    item,
                                                                    "campaignAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "item.calcARPU",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcARPU(
                                                                    item,
                                                                    "campaignAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "item.calcAMPU",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcAMPU(
                                                                    item,
                                                                    "campaignAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcEventCountSec",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.calcEventCountSec(
                                                                  item,
                                                                  "campaignAttributionData"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcCostPerGoalSec",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcCostPerGoalSec(
                                                                    item,
                                                                    "campaignAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.campaignMetadata.budgetAmount.amount",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          item.campaignMetadata
                                                            .budgetAmount &&
                                                          item.campaignMetadata
                                                            .budgetAmount.amount
                                                            ? _c("div", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.getCurrencyIcon(
                                                                        item
                                                                          .orgMetadata
                                                                          .currency
                                                                      ) +
                                                                        "" +
                                                                        _vm.numDecimalFormat(
                                                                          Number(
                                                                            item
                                                                              .campaignMetadata
                                                                              .budgetAmount
                                                                              .amount
                                                                          )
                                                                        )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.campaignMetadata.dailyBudgetAmount.amount",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          item.campaignMetadata
                                                            .dailyBudgetAmount &&
                                                          item.campaignMetadata
                                                            .dailyBudgetAmount
                                                            .amount
                                                            ? _c("div", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.getCurrencyIcon(
                                                                        item
                                                                          .orgMetadata
                                                                          .currency
                                                                      ) +
                                                                        "" +
                                                                        _vm.numDecimalFormat(
                                                                          Number(
                                                                            item
                                                                              .campaignMetadata
                                                                              .dailyBudgetAmount
                                                                              .amount
                                                                          )
                                                                        )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                  ]),
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-tab-item",
                                          [
                                            _c(
                                              "KeepAlive",
                                              { attrs: { max: "1" } },
                                              [
                                                _c("DataTable", {
                                                  ref:
                                                    "deletedCampaignDatatable",
                                                  attrs: {
                                                    items:
                                                      (_vm.deletedCampaignReports &&
                                                        _vm
                                                          .deletedCampaignReports
                                                          .row) ||
                                                      [],
                                                    headers:
                                                      _vm.campaignHeaders,
                                                    loading:
                                                      _vm.$apollo.queries
                                                        .campaignReportsDeleted
                                                        .loading,
                                                    tab: "Deleted-Campaigns",
                                                    tabInfo: "deletedcampaign",
                                                    tabindex: _vm.tab,
                                                    customView:
                                                      _vm.customView._id,
                                                    customTab: "campaigns",
                                                    itemsLength:
                                                      _vm.deletedReportsCount,
                                                    sortBy:
                                                      _vm.DeletedCampaignSortBy,
                                                    sortDesc:
                                                      _vm.DeletedCampaignSortDesc,
                                                  },
                                                  on: {
                                                    persistDatatable:
                                                      _vm.persistDatatable,
                                                    customViewsTable:
                                                      _vm.saveCustomTableViews,
                                                    pageChange: function (
                                                      $event
                                                    ) {
                                                      return _vm.fetchMoreCampaignsDeleted(
                                                        $event
                                                      )
                                                    },
                                                    campSearchDeletedCols: function (
                                                      $event
                                                    ) {
                                                      return _vm.searchCampaignsDeleted(
                                                        $event
                                                      )
                                                    },
                                                    downloadReport:
                                                      _vm.downloadReport,
                                                  },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key:
                                                        "item.campaignMetadata.name",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _c("p", [
                                                            _c(
                                                              "a",
                                                              {
                                                                on: {
                                                                  click: function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.campaignFilters(
                                                                      item.orgMetadata,
                                                                      item.metadata,
                                                                      item.campaignMetadata
                                                                    )
                                                                  },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item
                                                                      .campaignMetadata
                                                                      .name
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.campaignMetadata.supplySources",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          item.campaignMetadata
                                                            .supplySources ==
                                                          "APPSTORE_SEARCH_RESULTS"
                                                            ? _c("div", [
                                                                _vm._v(
                                                                  "Search Results"
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                          item.campaignMetadata
                                                            .supplySources ==
                                                          "APPSTORE_TODAY_TAB"
                                                            ? _c("div", [
                                                                _vm._v(
                                                                  "Todays Tab"
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                          item.campaignMetadata
                                                            .supplySources ==
                                                          "APPSTORE_SEARCH_TAB"
                                                            ? _c("div", [
                                                                _vm._v(
                                                                  "Search Tab"
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                          item.campaignMetadata
                                                            .supplySources ==
                                                          "APPSTORE_PRODUCT_PAGES_BROWSE"
                                                            ? _c("div", [
                                                                _vm._v(
                                                                  "Product Pages"
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.metadata.countriesOrRegions",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return _vm._l(
                                                          item.metadata
                                                            .countriesOrRegions,
                                                          function (country) {
                                                            return _c("p", [
                                                              _vm._v(
                                                                _vm._s(country)
                                                              ),
                                                            ])
                                                          }
                                                        )
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.campaignMetadata.deleted",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _c(
                                                            "v-chip",
                                                            {
                                                              staticClass:
                                                                "ma-2 mr-0",
                                                            },
                                                            [_vm._v("Deleted")]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.campaignMetadata.status",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _c("v-switch", {
                                                            staticClass:
                                                              "borderedSwitch table-switch",
                                                            attrs: {
                                                              color: "primary",
                                                              "true-value":
                                                                "ENABLED",
                                                              "false-value":
                                                                "PAUSED",
                                                              inset: "",
                                                              outlined: "",
                                                              disabled: "",
                                                            },
                                                            model: {
                                                              value:
                                                                item
                                                                  .campaignMetadata
                                                                  .status,
                                                              callback: function (
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  item.campaignMetadata,
                                                                  "status",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "item.campaignMetadata.status",
                                                            },
                                                          }),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcGoalsRevenue",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcGoalsRevenue(
                                                                    item,
                                                                    "campaignAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "item.calcGoalRoas",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.calcGoalRoas(
                                                                  item,
                                                                  "campaignAttributionData"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcEventCount",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.calcEventCount(
                                                                  item,
                                                                  "campaignAttributionData"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcCostPerGoal",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcCostPerGoal(
                                                                    item,
                                                                    "campaignAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcRevenuePerGoal",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcRevenuePerGoal(
                                                                    item,
                                                                    "campaignAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "item.calcARPU",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcARPU(
                                                                    item,
                                                                    "campaignAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "item.calcAMPU",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcAMPU(
                                                                    item,
                                                                    "campaignAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcEventCountSec",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.calcEventCountSec(
                                                                  item,
                                                                  "campaignAttributionData"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcCostPerGoalSec",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcCostPerGoalSec(
                                                                    item,
                                                                    "campaignAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.campaignMetadata.budgetAmount.amount",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          item.campaignMetadata
                                                            .budgetAmount &&
                                                          item.campaignMetadata
                                                            .budgetAmount.amount
                                                            ? _c("div", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.getCurrencyIcon(
                                                                        item
                                                                          .orgMetadata
                                                                          .currency
                                                                      ) +
                                                                        "" +
                                                                        _vm.numDecimalFormat(
                                                                          Number(
                                                                            item
                                                                              .campaignMetadata
                                                                              .budgetAmount
                                                                              .amount
                                                                          )
                                                                        )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.campaignMetadata.dailyBudgetAmount.amount",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          item.campaignMetadata
                                                            .dailyBudgetAmount &&
                                                          item.campaignMetadata
                                                            .dailyBudgetAmount
                                                            .amount
                                                            ? _c("div", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.getCurrencyIcon(
                                                                        item
                                                                          .orgMetadata
                                                                          .currency
                                                                      ) +
                                                                        "" +
                                                                        _vm.numDecimalFormat(
                                                                          Number(
                                                                            item
                                                                              .campaignMetadata
                                                                              .dailyBudgetAmount
                                                                              .amount
                                                                          )
                                                                        )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                  ]),
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-tab-item",
                                          [
                                            _c(
                                              "KeepAlive",
                                              { attrs: { max: "1" } },
                                              [
                                                _c("DataTable", {
                                                  attrs: {
                                                    headers:
                                                      _vm.campaignDraftHeaders,
                                                    items:
                                                      _vm.getCampaignDrafts,
                                                    tabInfo: "draftcampaign",
                                                    loading:
                                                      _vm.$apollo.queries
                                                        .getCampaignDrafts
                                                        .loading,
                                                    customView:
                                                      _vm.customView._id,
                                                    sortBy:
                                                      _vm.DraftCampaignSortBy,
                                                    sortDesc:
                                                      _vm.DraftCampaignSortDesc,
                                                    customTab: "campaigns",
                                                  },
                                                  on: {
                                                    persistDatatable:
                                                      _vm.persistDatatable,
                                                    customViewsTable:
                                                      _vm.saveCustomTableViews,
                                                  },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key: "item.resume",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "my-2",
                                                                attrs: {
                                                                  depressed: "",
                                                                  dense: "",
                                                                  color:
                                                                    "primary",
                                                                },
                                                              },
                                                              !_vm.isReadOnly()
                                                                ? {
                                                                    click: function () {
                                                                      return _vm.searchdialog1(
                                                                        item
                                                                      )
                                                                    },
                                                                  }
                                                                : {
                                                                    click: function () {
                                                                      return _vm.openPermissionReadOnlyPopup()
                                                                    },
                                                                  }
                                                            ),
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    small: "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "arrow_forward"
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v("Resume "),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ]),
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-tab-item",
                                          [
                                            _c(
                                              "KeepAlive",
                                              { attrs: { max: "1" } },
                                              [
                                                _c("DataTable", {
                                                  ref: "allCampaignDatatable",
                                                  attrs: {
                                                    items:
                                                      (_vm.allCampaignReports &&
                                                        _vm.allCampaignReports
                                                          .row) ||
                                                      [],
                                                    headers:
                                                      _vm.campaignHeaders,
                                                    loading:
                                                      _vm.$apollo.queries
                                                        .campaignReportsAll
                                                        .loading,
                                                    tab: "All-Campaigns",
                                                    tabInfo: "allcampaign",
                                                    tabindex: _vm.tab,
                                                    customView:
                                                      _vm.customView._id,
                                                    customTab: "campaigns",
                                                    itemsLength:
                                                      _vm.allReportsCount,
                                                    sortBy:
                                                      _vm.AllCampaignSortBy,
                                                    sortDesc:
                                                      _vm.AllCampaignSortDesc,
                                                  },
                                                  on: {
                                                    persistDatatable:
                                                      _vm.persistDatatable,
                                                    customViewsTable:
                                                      _vm.saveCustomTableViews,
                                                    pageChange: function (
                                                      $event
                                                    ) {
                                                      return _vm.fetchMoreCampaignsAll(
                                                        $event
                                                      )
                                                    },
                                                    campSearchAllCols: function (
                                                      $event
                                                    ) {
                                                      return _vm.searchCampaignsAll(
                                                        $event
                                                      )
                                                    },
                                                    downloadReport:
                                                      _vm.downloadReport,
                                                  },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key:
                                                        "item.campaignMetadata.name",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _c(
                                                            "p",
                                                            [
                                                              !item
                                                                .campaignMetadata
                                                                .deleted
                                                                ? _c(
                                                                    "v-icon",
                                                                    _vm._g(
                                                                      {
                                                                        staticClass:
                                                                          "mr-2",
                                                                        attrs: {
                                                                          color:
                                                                            "primary",
                                                                        },
                                                                      },
                                                                      !_vm.isReadOnly()
                                                                        ? {
                                                                            click: function () {
                                                                              return _vm.openEditCampaignWizard(
                                                                                item
                                                                              )
                                                                            },
                                                                          }
                                                                        : {
                                                                            click: function () {
                                                                              return _vm.openPermissionReadOnlyPopup()
                                                                            },
                                                                          }
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-pencil-outline"
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              _c(
                                                                "a",
                                                                {
                                                                  on: {
                                                                    click: function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.campaignFilters(
                                                                        item.orgMetadata,
                                                                        item.metadata,
                                                                        item.campaignMetadata
                                                                      )
                                                                    },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item
                                                                        .campaignMetadata
                                                                        .name
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.campaignMetadata.supplySources",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          item.campaignMetadata
                                                            .supplySources ==
                                                          "APPSTORE_SEARCH_RESULTS"
                                                            ? _c("div", [
                                                                _vm._v(
                                                                  "Search Results"
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                          item.campaignMetadata
                                                            .supplySources ==
                                                          "APPSTORE_TODAY_TAB"
                                                            ? _c("div", [
                                                                _vm._v(
                                                                  "Todays Tab"
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                          item.campaignMetadata
                                                            .supplySources ==
                                                          "APPSTORE_SEARCH_TAB"
                                                            ? _c("div", [
                                                                _vm._v(
                                                                  "Search Tab"
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                          item.campaignMetadata
                                                            .supplySources ==
                                                          "APPSTORE_PRODUCT_PAGES_BROWSE"
                                                            ? _c("div", [
                                                                _vm._v(
                                                                  "Product Pages"
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.metadata.countriesOrRegions",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return _vm._l(
                                                          item.metadata
                                                            .countriesOrRegions,
                                                          function (country) {
                                                            return _c("p", [
                                                              _vm._v(
                                                                _vm._s(country)
                                                              ),
                                                            ])
                                                          }
                                                        )
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.campaignMetadata.deleted",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          item.campaignMetadata
                                                            .deleted
                                                            ? _c(
                                                                "v-chip",
                                                                {
                                                                  staticClass:
                                                                    "ma-2",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "negativeHeaders.deleted"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          !item.campaignMetadata
                                                            .deleted
                                                            ? _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "mx-2 delete-btn",
                                                                    attrs: {
                                                                      icon: "",
                                                                      dark: "",
                                                                      small: "",
                                                                      color:
                                                                        "primary",
                                                                    },
                                                                  },
                                                                  !_vm.isReadOnly()
                                                                    ? {
                                                                        click: function () {
                                                                          return _vm.openDeleteCampaignDialog(
                                                                            item
                                                                          )
                                                                        },
                                                                      }
                                                                    : {
                                                                        click: function () {
                                                                          return _vm.openPermissionReadOnlyPopup()
                                                                        },
                                                                      }
                                                                ),
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        dark:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-delete-outline"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.campaignMetadata.status",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                bottom: "",
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "activator",
                                                                    fn: function (
                                                                      ref
                                                                    ) {
                                                                      var on =
                                                                        ref.on
                                                                      var attrs =
                                                                        ref.attrs
                                                                      return [
                                                                        _c(
                                                                          "div",
                                                                          _vm._g(
                                                                            _vm._b(
                                                                              {
                                                                                staticStyle: {
                                                                                  width:
                                                                                    "100%",
                                                                                },
                                                                              },
                                                                              "div",
                                                                              attrs,
                                                                              false
                                                                            ),
                                                                            on
                                                                          ),
                                                                          [
                                                                            item
                                                                              .campaignMetadata
                                                                              .deleted
                                                                              ? _c(
                                                                                  "v-switch",
                                                                                  {
                                                                                    staticClass:
                                                                                      "borderedSwitch table-switch",
                                                                                    attrs: {
                                                                                      color:
                                                                                        "primary",
                                                                                      "true-value":
                                                                                        "ENABLED",
                                                                                      "false-value":
                                                                                        "PAUSED",
                                                                                      inset:
                                                                                        "",
                                                                                      outlined:
                                                                                        "",
                                                                                      disabled:
                                                                                        "",
                                                                                    },
                                                                                    model: {
                                                                                      value:
                                                                                        item
                                                                                          .campaignMetadata
                                                                                          .status,
                                                                                      callback: function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          item.campaignMetadata,
                                                                                          "status",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                      expression:
                                                                                        "item.campaignMetadata.status",
                                                                                    },
                                                                                  }
                                                                                )
                                                                              : _vm._e(),
                                                                            !item
                                                                              .campaignMetadata
                                                                              .deleted
                                                                              ? _c(
                                                                                  "v-switch",
                                                                                  _vm._g(
                                                                                    {
                                                                                      staticClass:
                                                                                        "borderedSwitch table-switch",
                                                                                      attrs: {
                                                                                        color:
                                                                                          "primary",
                                                                                        "true-value":
                                                                                          "ENABLED",
                                                                                        "false-value":
                                                                                          "PAUSED",
                                                                                        inset:
                                                                                          "",
                                                                                        outlined:
                                                                                          "",
                                                                                        readonly:
                                                                                          "",
                                                                                      },
                                                                                      model: {
                                                                                        value:
                                                                                          item
                                                                                            .campaignMetadata
                                                                                            .status,
                                                                                        callback: function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            item.campaignMetadata,
                                                                                            "status",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                        expression:
                                                                                          "item.campaignMetadata.status",
                                                                                      },
                                                                                    },
                                                                                    !_vm.isReadOnly()
                                                                                      ? {
                                                                                          click: function () {
                                                                                            return _vm.askConfirm(
                                                                                              item
                                                                                                .campaignMetadata
                                                                                                .status ==
                                                                                                "ENABLED"
                                                                                                ? "PAUSED"
                                                                                                : "ENABLED",
                                                                                              item,
                                                                                              "campaign"
                                                                                            )
                                                                                          },
                                                                                        }
                                                                                      : {
                                                                                          click: function () {
                                                                                            return _vm.openPermissionReadOnlyPopup()
                                                                                          },
                                                                                        }
                                                                                  )
                                                                                )
                                                                              : _vm._e(),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ]
                                                                    },
                                                                  },
                                                                ],
                                                                null,
                                                                true
                                                              ),
                                                            },
                                                            [
                                                              item
                                                                .campaignMetadata
                                                                .status ==
                                                              "ENABLED"
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "aRules.disable"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ])
                                                                : _vm._e(),
                                                              item
                                                                .campaignMetadata
                                                                .status ==
                                                              "PAUSED"
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "aRules.enable"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ])
                                                                : _vm._e(),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcGoalsRevenue",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcGoalsRevenue(
                                                                    item,
                                                                    "campaignAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "item.calcGoalRoas",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.calcGoalRoas(
                                                                  item,
                                                                  "campaignAttributionData"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcEventCount",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.calcEventCount(
                                                                  item,
                                                                  "campaignAttributionData"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcCostPerGoal",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcCostPerGoal(
                                                                    item,
                                                                    "campaignAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcRevenuePerGoal",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcRevenuePerGoal(
                                                                    item,
                                                                    "campaignAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "item.calcARPU",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcARPU(
                                                                    item,
                                                                    "campaignAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "item.calcAMPU",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcAMPU(
                                                                    item,
                                                                    "campaignAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcEventCountSec",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.calcEventCountSec(
                                                                  item,
                                                                  "campaignAttributionData"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcCostPerGoalSec",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcCostPerGoalSec(
                                                                    item,
                                                                    "campaignAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.campaignMetadata.budgetAmount.amount",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          item.campaignMetadata
                                                            .budgetAmount &&
                                                          item.campaignMetadata
                                                            .budgetAmount.amount
                                                            ? _c("div", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.getCurrencyIcon(
                                                                        item
                                                                          .orgMetadata
                                                                          .currency
                                                                      ) +
                                                                        "" +
                                                                        _vm.numDecimalFormat(
                                                                          Number(
                                                                            item
                                                                              .campaignMetadata
                                                                              .budgetAmount
                                                                              .amount
                                                                          )
                                                                        )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.campaignMetadata.dailyBudgetAmount.amount",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          item.campaignMetadata
                                                            .dailyBudgetAmount &&
                                                          item.campaignMetadata
                                                            .dailyBudgetAmount
                                                            .amount
                                                            ? _c("div", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.getCurrencyIcon(
                                                                        item
                                                                          .orgMetadata
                                                                          .currency
                                                                      ) +
                                                                        "" +
                                                                        _vm.numDecimalFormat(
                                                                          Number(
                                                                            item
                                                                              .campaignMetadata
                                                                              .dailyBudgetAmount
                                                                              .amount
                                                                          )
                                                                        )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                  ]),
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-tab-item",
                              [
                                _c(
                                  "v-row",
                                  { staticClass: "d-flex align-start" },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        attrs: {
                                          cols: "12",
                                          lg: "5",
                                          md: "6",
                                          sm: "8",
                                        },
                                      },
                                      [
                                        !_vm.editMode
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "box-header left-0 chart-header-area d-flex align-baseline pl-6 mb-4 max-400",
                                              },
                                              [
                                                _c("v-select", {
                                                  staticClass: "max-275",
                                                  attrs: {
                                                    "item-text": "CustomView",
                                                    "item-value": "_id",
                                                    items:
                                                      _vm.customViews || [],
                                                    attach: "",
                                                    "menu-props": "auto",
                                                    label: "Select",
                                                    "hide-details": "",
                                                    "single-line": "",
                                                    "return-object": "",
                                                    disabled: _vm.isSuperAdmin
                                                      ? false
                                                      : _vm.permissions.includes(
                                                          _vm
                                                            .SubscriptionPermissionsEnums
                                                            .CUSTOMVIEWS
                                                        )
                                                      ? false
                                                      : true,
                                                  },
                                                  on: {
                                                    change:
                                                      _vm.changeCustomView,
                                                  },
                                                  model: {
                                                    value: _vm.customView,
                                                    callback: function ($$v) {
                                                      _vm.customView = $$v
                                                    },
                                                    expression: "customView",
                                                  },
                                                }),
                                                _vm.customView._id != "0"
                                                  ? _c(
                                                      "v-icon",
                                                      {
                                                        staticClass:
                                                          "ml-2 primary--text",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            _vm.editMode = true
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "mdi-pencil-outline"
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.customView._id != "0"
                                                  ? _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "ml-2",
                                                        attrs: {
                                                          color: "primary",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.deleteCustomView(
                                                              _vm.customView
                                                                ._id,
                                                              "adgroups"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "mdi-delete-outline"
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.customView._id != "0" &&
                                                _vm.customViews.find(function (
                                                  el
                                                ) {
                                                  return (
                                                    el._id == _vm.customView._id
                                                  )
                                                }).PinnedStatus != true
                                                  ? _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: {
                                                          bottom: "",
                                                          color: "primarydark",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                var attrs =
                                                                  ref.attrs
                                                                return [
                                                                  _c(
                                                                    "v-icon",
                                                                    _vm._g(
                                                                      _vm._b(
                                                                        {
                                                                          staticClass:
                                                                            "ml-2 primary--text",
                                                                          on: {
                                                                            click: function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.pinnedCustomView(
                                                                                true
                                                                              )
                                                                            },
                                                                          },
                                                                        },
                                                                        "v-icon",
                                                                        attrs,
                                                                        false
                                                                      ),
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-pin-outline"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          2813756920
                                                        ),
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v("Pin View"),
                                                        ]),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.customView._id != "0" &&
                                                _vm.customViews.find(function (
                                                  el
                                                ) {
                                                  return (
                                                    el._id == _vm.customView._id
                                                  )
                                                }).PinnedStatus == true
                                                  ? _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: {
                                                          bottom: "",
                                                          color: "primarydark",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                var attrs =
                                                                  ref.attrs
                                                                return [
                                                                  _c(
                                                                    "v-icon",
                                                                    _vm._g(
                                                                      _vm._b(
                                                                        {
                                                                          staticClass:
                                                                            "ml-2 primary--text",
                                                                          on: {
                                                                            click: function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.pinnedCustomView(
                                                                                false
                                                                              )
                                                                            },
                                                                          },
                                                                        },
                                                                        "v-icon",
                                                                        attrs,
                                                                        false
                                                                      ),
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-pin-off-outline"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          1877317105
                                                        ),
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v("Unpin View"),
                                                        ]),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.editMode
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "box-header chart-header-area edit-field d-flex align-baseline pl-6 mt-3",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "max-275" },
                                                  [
                                                    _c("v-text-field", {
                                                      staticClass: "max-275",
                                                      class: {
                                                        "error--text":
                                                          _vm.errorEditViewMessage,
                                                      },
                                                      attrs: {
                                                        outlined: "",
                                                        dense: "",
                                                        placeholder: "value",
                                                      },
                                                      on: {
                                                        input:
                                                          _vm.validateInput,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.customView
                                                            .CustomView,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.customView,
                                                            "CustomView",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "customView.CustomView",
                                                      },
                                                    }),
                                                    _vm.errorEditViewMessage
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "error--text text-caption ml-3 v-messages__message",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.errorEditViewMessage
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: {
                                                      bottom: "",
                                                      color: "primarydark",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function (ref) {
                                                            var on = ref.on
                                                            var attrs =
                                                              ref.attrs
                                                            return [
                                                              _c(
                                                                "v-icon",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      staticClass:
                                                                        "ml-2 primary--text",
                                                                      class: {
                                                                        "disabled-icon":
                                                                          _vm.errorEditViewMessage,
                                                                      },
                                                                      on: {
                                                                        click: function (
                                                                          $event
                                                                        ) {
                                                                          _vm.errorEditViewMessage
                                                                            ? null
                                                                            : _vm.updateCustomView()
                                                                        },
                                                                      },
                                                                    },
                                                                    "v-icon",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _vm._v(
                                                                    "mdi-checkbox-marked-circle"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      2788561454
                                                    ),
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v("Confirm"),
                                                    ]),
                                                  ]
                                                ),
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: {
                                                      bottom: "",
                                                      color: "primarydark",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function (ref) {
                                                            var on = ref.on
                                                            var attrs =
                                                              ref.attrs
                                                            return [
                                                              _c(
                                                                "v-icon",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      staticClass:
                                                                        "ml-2 primary--text",
                                                                      on: {
                                                                        click:
                                                                          _vm.cancelEditView,
                                                                      },
                                                                    },
                                                                    "v-icon",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _vm._v(
                                                                    "mdi-close-circle"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      2384401514
                                                    ),
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v("Cancel"),
                                                    ]),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                    !_vm.editMode
                                      ? _c(
                                          "v-col",
                                          {
                                            staticClass:
                                              "mt-2 d-flex justify-end align-center",
                                            attrs: {
                                              cols: "12",
                                              lg: "7",
                                              md: "6",
                                              sm: "4",
                                            },
                                          },
                                          [
                                            _vm.customView.CustomView !=
                                            "Default View"
                                              ? _c(
                                                  "v-btn",
                                                  {
                                                    staticClass: " ",
                                                    attrs: {
                                                      color: "primary",
                                                      dark: "",
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.updateCustomView,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { staticClass: "mr-1" },
                                                      [
                                                        _vm._v(
                                                          "mdi-arrow-right"
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" Update View "),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  class: [
                                                    "ml-3",
                                                    _vm.isSuperAdmin
                                                      ? "primary"
                                                      : _vm.permissions.includes(
                                                          _vm
                                                            .SubscriptionPermissionsEnums
                                                            .CUSTOMVIEWS
                                                        )
                                                      ? "primary"
                                                      : "disabled-button",
                                                  ],
                                                  attrs: {
                                                    dark: "",
                                                    position: "relative",
                                                  },
                                                },
                                                _vm.isSuperAdmin
                                                  ? {
                                                      click: function () {
                                                        return _vm.openViewForm()
                                                      },
                                                    }
                                                  : _vm.permissions.includes(
                                                      _vm
                                                        .SubscriptionPermissionsEnums
                                                        .CUSTOMVIEWS
                                                    ) == false
                                                  ? {
                                                      click: function () {
                                                        return _vm.openPermissionPopup()
                                                      },
                                                    }
                                                  : {
                                                      click: function () {
                                                        return _vm.openViewForm()
                                                      },
                                                    }
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  { staticClass: "mr-1" },
                                                  [_vm._v("mdi-plus")]
                                                ),
                                                _vm._v(" Add View "),
                                              ],
                                              1
                                            ),
                                            _vm.showViewForm
                                              ? [
                                                  _c(
                                                    "v-card",
                                                    {
                                                      staticClass:
                                                        "mx-auto d-flex align-baseline px-6 py-5 add-view",
                                                      attrs: { outlined: "" },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "field add-view-field",
                                                        },
                                                        [
                                                          _c("v-text-field", {
                                                            staticClass:
                                                              "w-100",
                                                            class: {
                                                              "error--text":
                                                                _vm.errorViewMessage,
                                                            },
                                                            attrs: {
                                                              required: "",
                                                              outlined: "",
                                                              dense: "",
                                                              placeholder:
                                                                "Enter View name",
                                                            },
                                                            on: {
                                                              input:
                                                                _vm.validateInput,
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.newCustomView,
                                                              callback: function (
                                                                $$v
                                                              ) {
                                                                _vm.newCustomView = $$v
                                                              },
                                                              expression:
                                                                "newCustomView",
                                                            },
                                                          }),
                                                          _vm.errorViewMessage
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "error--text text-caption ml-3 v-messages__message",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.errorViewMessage
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-card-actions",
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                color: _vm.newCustomView
                                                                  ? "primary"
                                                                  : "grey",
                                                                disabled:
                                                                  !_vm.newCustomView ||
                                                                  !_vm.isInputValid,
                                                              },
                                                              on: {
                                                                click: function (
                                                                  $event
                                                                ) {
                                                                  return _vm.saveCustomView(
                                                                    "adgroups"
                                                                  )
                                                                },
                                                              },
                                                            },
                                                            [_vm._v(" Save ")]
                                                          ),
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                color: "grey",
                                                                depressed: "",
                                                                outlined: "",
                                                              },
                                                              on: {
                                                                click: function (
                                                                  $event
                                                                ) {
                                                                  _vm.showViewForm = false
                                                                },
                                                              },
                                                            },
                                                            [_vm._v(" Cancel ")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              : _vm._e(),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-card",
                                  { attrs: { flat: "" } },
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              lg: "6",
                                              md: "12",
                                              sm: "12",
                                            },
                                          },
                                          [
                                            _c(
                                              "KeepAlive",
                                              { attrs: { max: "1" } },
                                              [
                                                _vm.currentTab ===
                                                "ad_groups_tab"
                                                  ? _c("Chart", {
                                                      attrs: {
                                                        timeLineEntries:
                                                          _vm.timeLineEntries,
                                                        chartdata:
                                                          _vm.adGroupChartData,
                                                        options:
                                                          _vm.chartOptions,
                                                        tabInfo: "adgroups",
                                                        strategySelection:
                                                          _vm.selectedStatestrategy,
                                                        customView:
                                                          _vm.customView._id,
                                                        granularity:
                                                          _vm.chartGranularity,
                                                        loading:
                                                          _vm.$apollo.queries
                                                            .adGroupGraph
                                                            .loading,
                                                      },
                                                      on: {
                                                        granularityChanged:
                                                          _vm.handleGranularityChange,
                                                        persistDatasets:
                                                          _vm.persistDatasets,
                                                        customViews:
                                                          _vm.saveCustomViews,
                                                        granularityWithTab:
                                                          _vm.saveGranularityToCustomView,
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              lg: "6",
                                              md: "12",
                                              sm: "12",
                                            },
                                          },
                                          [
                                            _c(
                                              "KeepAlive",
                                              { attrs: { max: "1" } },
                                              [
                                                _vm.currentTab ===
                                                "ad_groups_tab"
                                                  ? _c("TotalSummaryAdGroups", {
                                                      attrs: {
                                                        tab: _vm.tab,
                                                        values:
                                                          _vm.adgroupsTotalSummaryData,
                                                        rowItems:
                                                          (_vm.adgroupReport &&
                                                            _vm.adgroupReport
                                                              .row) ||
                                                          [],
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("EditAdGroup", {
                                      attrs: {
                                        adgroupData: _vm.editAdgroupMetadata,
                                      },
                                      on: {
                                        adgroupUpdated: function ($event) {
                                          return _vm.onUpdate()
                                        },
                                      },
                                    }),
                                    _c("AddAdGroup", {
                                      attrs: {
                                        openAddAdGroup: _vm.openAddAdgroup,
                                      },
                                      on: {
                                        adgroupAdded: function ($event) {
                                          return _vm.onUpdate()
                                        },
                                      },
                                    }),
                                    _c("DeleteAdgroup", {
                                      attrs: {
                                        adgroupDetailsForDeletion:
                                          _vm.adgroupDeletionMetadata,
                                      },
                                      on: {
                                        onDeleteAdgroup: function ($event) {
                                          return _vm.onUpdate()
                                        },
                                      },
                                    }),
                                    _c(
                                      "v-row",
                                      {
                                        staticClass:
                                          "d-flex align-center mt-8 mb-4 custom-tab-outer",
                                      },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              lg: "9",
                                              md: "9",
                                              sm: "8",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-tabs",
                                              {
                                                staticClass: "custom-tab",
                                                attrs: {
                                                  "center-active": "",
                                                  "show-arrows": "",
                                                  "background-color":
                                                    "transparent",
                                                },
                                                model: {
                                                  value: _vm.adgroupListingTab,
                                                  callback: function ($$v) {
                                                    _vm.adgroupListingTab = $$v
                                                  },
                                                  expression:
                                                    "adgroupListingTab",
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-tab",
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "subTabs.live"
                                                          ) +
                                                            " " +
                                                            _vm.$t(
                                                              "subTabs.adgroups"
                                                            )
                                                        ) +
                                                        " "
                                                    ),
                                                    _c(
                                                      "v-avatar",
                                                      {
                                                        staticClass: "mx-3",
                                                        attrs: {
                                                          color: "primary",
                                                          size: "26",
                                                        },
                                                      },
                                                      [
                                                        !_vm.$apollo.queries
                                                          .adGroupReportsLive
                                                          .loading
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "white--text caption",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.filteredAdgroupReportsCount
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm.$apollo.queries
                                                          .adGroupReportsLive
                                                          .loading
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "white--text caption",
                                                              },
                                                              [_vm._v("...")]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-tab",
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "subTabs.paused"
                                                          ) +
                                                            " " +
                                                            _vm.$t(
                                                              "subTabs.adgroups"
                                                            )
                                                        ) +
                                                        " "
                                                    ),
                                                    _c(
                                                      "v-avatar",
                                                      {
                                                        staticClass: "mx-3",
                                                        attrs: {
                                                          color: "primary",
                                                          size: "26",
                                                        },
                                                      },
                                                      [
                                                        !_vm.$apollo.queries
                                                          .adGroupReportsPaused
                                                          .loading
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "white--text caption",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.disabledAdgroupReportsCount
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm.$apollo.queries
                                                          .adGroupReportsPaused
                                                          .loading
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "white--text caption",
                                                              },
                                                              [_vm._v("...")]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-tab",
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "subTabs.deleted"
                                                          ) +
                                                            " " +
                                                            _vm.$t(
                                                              "subTabs.adgroups"
                                                            )
                                                        ) +
                                                        " "
                                                    ),
                                                    _c(
                                                      "v-avatar",
                                                      {
                                                        staticClass: "mx-3",
                                                        attrs: {
                                                          color: "primary",
                                                          size: "26",
                                                        },
                                                      },
                                                      [
                                                        !_vm.$apollo.queries
                                                          .adGroupReportsDeleted
                                                          .loading
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "white--text caption",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.deletedAdgroupReportsCount
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm.$apollo.queries
                                                          .adGroupReportsDeleted
                                                          .loading
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "white--text caption",
                                                              },
                                                              [_vm._v("...")]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-tab",
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "subTabs.all"
                                                          ) +
                                                            " " +
                                                            _vm.$t(
                                                              "subTabs.adgroups"
                                                            )
                                                        ) +
                                                        " "
                                                    ),
                                                    _c(
                                                      "v-avatar",
                                                      {
                                                        staticClass: "mx-3",
                                                        attrs: {
                                                          color: "primary",
                                                          size: "26",
                                                        },
                                                      },
                                                      [
                                                        !_vm.$apollo.queries
                                                          .adGroupReportsAll
                                                          .loading
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "white--text caption",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.allAdgroupReportsCount
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm.$apollo.queries
                                                          .adGroupReportsAll
                                                          .loading
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "white--text caption",
                                                              },
                                                              [_vm._v("...")]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "d-flex justify-end",
                                            attrs: {
                                              cols: "12",
                                              lg: "3",
                                              md: "3",
                                              sm: "4",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  staticClass:
                                                    "w-sm-100 min-width-156",
                                                  attrs: {
                                                    depressed: "",
                                                    color: "primary",
                                                  },
                                                },
                                                !_vm.isReadOnly()
                                                  ? {
                                                      click: function () {
                                                        return _vm.openAddAdGroup()
                                                      },
                                                    }
                                                  : {
                                                      click: function () {
                                                        return _vm.openPermissionReadOnlyPopup()
                                                      },
                                                    }
                                              ),
                                              [
                                                _c("v-icon", [
                                                  _vm._v("mdi-plus"),
                                                ]),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("popups.add") +
                                                        " " +
                                                        _vm.$t("popups.adgroup")
                                                    ) +
                                                    " "
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-tabs-items",
                                      {
                                        staticClass: "show-full-content",
                                        model: {
                                          value: _vm.adgroupListingTab,
                                          callback: function ($$v) {
                                            _vm.adgroupListingTab = $$v
                                          },
                                          expression: "adgroupListingTab",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-tab-item",
                                          [
                                            _c(
                                              "KeepAlive",
                                              { attrs: { max: "1" } },
                                              [
                                                _c("DataTable", {
                                                  ref: "liveAdgroupDatatable",
                                                  attrs: {
                                                    loading:
                                                      _vm.$apollo.queries
                                                        .adGroupReportsLive
                                                        .loading,
                                                    items:
                                                      (_vm.adgroupReport &&
                                                        _vm.adgroupReport
                                                          .row) ||
                                                      [],
                                                    headers: _vm.adGroupHeaders,
                                                    tab: "Live-Adgroups",
                                                    tabInfo: "liveadgroup",
                                                    tabindex: _vm.tab,
                                                    customView:
                                                      _vm.customView._id,
                                                    customTab: "adgroups",
                                                    itemsLength:
                                                      _vm.filteredAdgroupReportsCount,
                                                    sortBy:
                                                      _vm.LiveAdGroupSortBy,
                                                    sortDesc:
                                                      _vm.LiveAdGroupSortDesc,
                                                  },
                                                  on: {
                                                    persistDatatable:
                                                      _vm.persistDatatable,
                                                    customViewsTable:
                                                      _vm.saveCustomTableViews,
                                                    pageChange: function (
                                                      $event
                                                    ) {
                                                      return _vm.fetchMoreAdgroupsLive(
                                                        $event
                                                      )
                                                    },
                                                    adgroupSearchLiveCols: function (
                                                      $event
                                                    ) {
                                                      return _vm.searchAdgroupsLive(
                                                        $event
                                                      )
                                                    },
                                                    downloadReport:
                                                      _vm.downloadReport,
                                                  },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key:
                                                        "item.adgroupMetadata.name",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _c(
                                                            "p",
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "mr-2",
                                                                    attrs: {
                                                                      color:
                                                                        "primary",
                                                                    },
                                                                  },
                                                                  !_vm.isReadOnly()
                                                                    ? {
                                                                        click: function () {
                                                                          return _vm.openEditAddgroupWizard(
                                                                            item
                                                                          )
                                                                        },
                                                                      }
                                                                    : {
                                                                        click: function () {
                                                                          return _vm.openPermissionReadOnlyPopup()
                                                                        },
                                                                      }
                                                                ),
                                                                [
                                                                  _vm._v(
                                                                    "mdi-pencil-outline"
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "a",
                                                                {
                                                                  on: {
                                                                    click: function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.adgroupFilters(
                                                                        item.orgMetadata,
                                                                        item
                                                                          .metadata
                                                                          .app,
                                                                        item.campaignMetadata,
                                                                        item.adgroupMetadata
                                                                      )
                                                                    },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item
                                                                        .adgroupMetadata
                                                                        .name
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.adgroupMetadata.status",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _c(
                                                            "v-switch",
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "borderedSwitch table-switch",
                                                                attrs: {
                                                                  color:
                                                                    "primary",
                                                                  "true-value":
                                                                    "ENABLED",
                                                                  "false-value":
                                                                    "PAUSED",
                                                                  inset: "",
                                                                  outlined: "",
                                                                  readonly: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    item
                                                                      .adgroupMetadata
                                                                      .status,
                                                                  callback: function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item.adgroupMetadata,
                                                                      "status",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "item.adgroupMetadata.status",
                                                                },
                                                              },
                                                              !_vm.isReadOnly()
                                                                ? {
                                                                    click: function () {
                                                                      return _vm.askConfirm(
                                                                        item
                                                                          .adgroupMetadata
                                                                          .status ==
                                                                          "ENABLED"
                                                                          ? "PAUSED"
                                                                          : "ENABLED",
                                                                        item,
                                                                        "adgroup"
                                                                      )
                                                                    },
                                                                  }
                                                                : {
                                                                    click: function () {
                                                                      return _vm.openPermissionReadOnlyPopup()
                                                                    },
                                                                  }
                                                            )
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.adgroupMetadata.deleted",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          item.adgroupMetadata
                                                            .deleted
                                                            ? _c(
                                                                "v-chip",
                                                                {
                                                                  staticClass:
                                                                    "ma-2",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "negativeHeaders.deleted"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          !item.adgroupMetadata
                                                            .deleted
                                                            ? _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "mx-2",
                                                                    attrs: {
                                                                      icon: "",
                                                                      dark: "",
                                                                      elevation:
                                                                        "0",
                                                                      small: "",
                                                                      color:
                                                                        "primary",
                                                                    },
                                                                  },
                                                                  !_vm.isReadOnly()
                                                                    ? {
                                                                        click: function () {
                                                                          return _vm.openDeleteAdgroupDialog(
                                                                            item
                                                                          )
                                                                        },
                                                                      }
                                                                    : {
                                                                        click: function () {
                                                                          return _vm.openPermissionReadOnlyPopup()
                                                                        },
                                                                      }
                                                                ),
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        dark:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-delete-outline"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.campaignMetadata.countriesOrRegions",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return _vm._l(
                                                          item.campaignMetadata
                                                            .countriesOrRegions,
                                                          function (country) {
                                                            return _c("p", [
                                                              _vm._v(
                                                                _vm._s(country)
                                                              ),
                                                            ])
                                                          }
                                                        )
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcGoalsRevenue",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcGoalsRevenue(
                                                                    item,
                                                                    "adgroupAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "item.calcGoalRoas",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.calcGoalRoas(
                                                                  item,
                                                                  "adgroupAttributionData"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcEventCount",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.calcEventCount(
                                                                  item,
                                                                  "adgroupAttributionData"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcCostPerGoal",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcCostPerGoal(
                                                                    item,
                                                                    "adgroupAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcRevenuePerGoal",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcRevenuePerGoal(
                                                                    item,
                                                                    "adgroupAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "item.calcARPU",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcARPU(
                                                                    item,
                                                                    "adgroupAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "item.calcAMPU",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcAMPU(
                                                                    item,
                                                                    "adgroupAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcEventCountSec",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.calcEventCountSec(
                                                                  item,
                                                                  "adgroupAttributionData"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcCostPerGoalSec",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcCostPerGoalSec(
                                                                    item,
                                                                    "adgroupAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ]),
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-tab-item",
                                          [
                                            _c(
                                              "KeepAlive",
                                              { attrs: { max: "1" } },
                                              [
                                                _c("DataTable", {
                                                  ref: "pausedAdgroupDatatable",
                                                  attrs: {
                                                    loading:
                                                      _vm.$apollo.queries
                                                        .adGroupReportsPaused
                                                        .loading,
                                                    items:
                                                      (_vm.disabledAdgroupReports &&
                                                        _vm
                                                          .disabledAdgroupReports
                                                          .row) ||
                                                      [],
                                                    headers: _vm.adGroupHeaders,
                                                    tab: "Disabled-Adgroups",
                                                    tabInfo: "pausedadgroup",
                                                    tabindex: _vm.tab,
                                                    customView:
                                                      _vm.customView._id,
                                                    customTab: "adgroups",
                                                    itemsLength:
                                                      _vm.disabledAdgroupReportsCount,
                                                    sortBy:
                                                      _vm.PausedAdGroupSortBy,
                                                    sortDesc:
                                                      _vm.PausedAdGroupSortDesc,
                                                  },
                                                  on: {
                                                    persistDatatable:
                                                      _vm.persistDatatable,
                                                    customViewsTable:
                                                      _vm.saveCustomTableViews,
                                                    pageChange: function (
                                                      $event
                                                    ) {
                                                      return _vm.fetchMoreAdgroupsPaused(
                                                        $event
                                                      )
                                                    },
                                                    adgroupSearchPausedCols: function (
                                                      $event
                                                    ) {
                                                      return _vm.searchAdgroupsPaused(
                                                        $event
                                                      )
                                                    },
                                                    downloadReport:
                                                      _vm.downloadReport,
                                                  },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key:
                                                        "item.adgroupMetadata.name",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _c(
                                                            "p",
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "mr-2",
                                                                    attrs: {
                                                                      color:
                                                                        "primary",
                                                                    },
                                                                  },
                                                                  !_vm.isReadOnly()
                                                                    ? {
                                                                        click: function () {
                                                                          return _vm.openEditAddgroupWizard(
                                                                            item
                                                                          )
                                                                        },
                                                                      }
                                                                    : {
                                                                        click: function () {
                                                                          return _vm.openPermissionReadOnlyPopup()
                                                                        },
                                                                      }
                                                                ),
                                                                [
                                                                  _vm._v(
                                                                    "mdi-pencil-outline"
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "a",
                                                                {
                                                                  on: {
                                                                    click: function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.adgroupFilters(
                                                                        item.orgMetadata,
                                                                        item
                                                                          .metadata
                                                                          .app,
                                                                        item.campaignMetadata,
                                                                        item.adgroupMetadata
                                                                      )
                                                                    },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item
                                                                        .adgroupMetadata
                                                                        .name
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.adgroupMetadata.status",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          item.adgroupMetadata
                                                            .status == "PAUSED"
                                                            ? _c(
                                                                "v-switch",
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "borderedSwitch table-switch",
                                                                    attrs: {
                                                                      color:
                                                                        "primary",
                                                                      "true-value":
                                                                        "ENABLED",
                                                                      "false-value":
                                                                        "PAUSED",
                                                                      inset: "",
                                                                      outlined:
                                                                        "",
                                                                      readonly:
                                                                        "",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        item
                                                                          .adgroupMetadata
                                                                          .status,
                                                                      callback: function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          item.adgroupMetadata,
                                                                          "status",
                                                                          $$v
                                                                        )
                                                                      },
                                                                      expression:
                                                                        "item.adgroupMetadata.status",
                                                                    },
                                                                  },
                                                                  !_vm.isReadOnly()
                                                                    ? {
                                                                        click: function () {
                                                                          return _vm.askConfirm(
                                                                            item
                                                                              .adgroupMetadata
                                                                              .status ==
                                                                              "ENABLED"
                                                                              ? "PAUSED"
                                                                              : "ENABLED",
                                                                            item,
                                                                            "adgroup"
                                                                          )
                                                                        },
                                                                      }
                                                                    : {
                                                                        click: function () {
                                                                          return _vm.openPermissionReadOnlyPopup()
                                                                        },
                                                                      }
                                                                )
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.adgroupMetadata.deleted",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          item.adgroupMetadata
                                                            .deleted
                                                            ? _c(
                                                                "v-chip",
                                                                {
                                                                  staticClass:
                                                                    "ma-2",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "negativeHeaders.deleted"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          !item.adgroupMetadata
                                                            .deleted
                                                            ? _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "mx-2",
                                                                    attrs: {
                                                                      icon: "",
                                                                      dark: "",
                                                                      elevation:
                                                                        "0",
                                                                      small: "",
                                                                      color:
                                                                        "primary",
                                                                    },
                                                                  },
                                                                  !_vm.isReadOnly()
                                                                    ? {
                                                                        click: function () {
                                                                          return _vm.openDeleteAdgroupDialog(
                                                                            item
                                                                          )
                                                                        },
                                                                      }
                                                                    : {
                                                                        click: function () {
                                                                          return _vm.openPermissionReadOnlyPopup()
                                                                        },
                                                                      }
                                                                ),
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        dark:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-delete-outline"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.campaignMetadata.countriesOrRegions",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return _vm._l(
                                                          item.campaignMetadata
                                                            .countriesOrRegions,
                                                          function (country) {
                                                            return _c("p", [
                                                              _vm._v(
                                                                _vm._s(country)
                                                              ),
                                                            ])
                                                          }
                                                        )
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcGoalsRevenue",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcGoalsRevenue(
                                                                    item,
                                                                    "adgroupAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "item.calcGoalRoas",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.calcGoalRoas(
                                                                  item,
                                                                  "adgroupAttributionData"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcEventCount",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.calcEventCount(
                                                                  item,
                                                                  "adgroupAttributionData"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcCostPerGoal",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcCostPerGoal(
                                                                    item,
                                                                    "adgroupAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcRevenuePerGoal",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcRevenuePerGoal(
                                                                    item,
                                                                    "adgroupAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "item.calcARPU",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcARPU(
                                                                    item,
                                                                    "adgroupAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "item.calcAMPU",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcAMPU(
                                                                    item,
                                                                    "adgroupAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcEventCountSec",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.calcEventCountSec(
                                                                  item,
                                                                  "adgroupAttributionData"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcCostPerGoalSec",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcCostPerGoalSec(
                                                                    item,
                                                                    "adgroupAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ]),
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-tab-item",
                                          [
                                            _c(
                                              "KeepAlive",
                                              { attrs: { max: "1" } },
                                              [
                                                _c("DataTable", {
                                                  ref:
                                                    "deletedAdgroupDatatable",
                                                  attrs: {
                                                    loading:
                                                      _vm.$apollo.queries
                                                        .adGroupReportsDeleted
                                                        .loading,
                                                    items:
                                                      (_vm.deletedAdgroupReports &&
                                                        _vm
                                                          .deletedAdgroupReports
                                                          .row) ||
                                                      [],
                                                    headers: _vm.adGroupHeaders,
                                                    tab: "Deleted-Adgroups",
                                                    tabInfo: "deletedadgroup",
                                                    tabindex: _vm.tab,
                                                    customView:
                                                      _vm.customView._id,
                                                    customTab: "adgroups",
                                                    itemsLength:
                                                      _vm.deletedAdgroupReportsCount,
                                                    sortBy:
                                                      _vm.DeletedAdGroupSortBy,
                                                    sortDesc:
                                                      _vm.DeletedAdGroupSortDesc,
                                                  },
                                                  on: {
                                                    persistDatatable:
                                                      _vm.persistDatatable,
                                                    customViewsTable:
                                                      _vm.saveCustomTableViews,
                                                    pageChange: function (
                                                      $event
                                                    ) {
                                                      return _vm.fetchMoreAdgroupsDeleted(
                                                        $event
                                                      )
                                                    },
                                                    adgroupSearchDeletedCols: function (
                                                      $event
                                                    ) {
                                                      return _vm.searchAdgroupsDeleted(
                                                        $event
                                                      )
                                                    },
                                                    downloadReport:
                                                      _vm.downloadReport,
                                                  },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key:
                                                        "item.adgroupMetadata.name",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _c("p", [
                                                            _c(
                                                              "a",
                                                              {
                                                                on: {
                                                                  click: function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.adgroupFilters(
                                                                      item.orgMetadata,
                                                                      item
                                                                        .metadata
                                                                        .app,
                                                                      item.campaignMetadata,
                                                                      item.adgroupMetadata
                                                                    )
                                                                  },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item
                                                                      .adgroupMetadata
                                                                      .name
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.adgroupMetadata.status",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _c("v-switch", {
                                                            staticClass:
                                                              "borderedSwitch table-switch",
                                                            attrs: {
                                                              color: "primary",
                                                              "true-value":
                                                                "ENABLED",
                                                              "false-value":
                                                                "PAUSED",
                                                              inset: "",
                                                              outlined: "",
                                                              disabled: "",
                                                            },
                                                            model: {
                                                              value:
                                                                item
                                                                  .adgroupMetadata
                                                                  .status,
                                                              callback: function (
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  item.adgroupMetadata,
                                                                  "status",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "item.adgroupMetadata.status",
                                                            },
                                                          }),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.campaignMetadata.countriesOrRegions",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return _vm._l(
                                                          item.campaignMetadata
                                                            .countriesOrRegions,
                                                          function (country) {
                                                            return _c("p", [
                                                              _vm._v(
                                                                _vm._s(country)
                                                              ),
                                                            ])
                                                          }
                                                        )
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.adgroupMetadata.deleted",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          item.adgroupMetadata
                                                            .deleted
                                                            ? _c(
                                                                "v-chip",
                                                                {
                                                                  staticClass:
                                                                    "ma-2",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "negativeHeaders.deleted"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcGoalsRevenue",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcGoalsRevenue(
                                                                    item,
                                                                    "adgroupAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "item.calcGoalRoas",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.calcGoalRoas(
                                                                  item,
                                                                  "adgroupAttributionData"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcEventCount",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.calcEventCount(
                                                                  item,
                                                                  "adgroupAttributionData"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcCostPerGoal",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcCostPerGoal(
                                                                    item,
                                                                    "adgroupAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcRevenuePerGoal",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcRevenuePerGoal(
                                                                    item,
                                                                    "adgroupAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "item.calcARPU",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcARPU(
                                                                    item,
                                                                    "adgroupAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "item.calcAMPU",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcAMPU(
                                                                    item,
                                                                    "adgroupAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcEventCountSec",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.calcEventCountSec(
                                                                  item,
                                                                  "adgroupAttributionData"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcCostPerGoalSec",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcCostPerGoalSec(
                                                                    item,
                                                                    "adgroupAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ]),
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-tab-item",
                                          [
                                            _c(
                                              "KeepAlive",
                                              { attrs: { max: "1" } },
                                              [
                                                _c("DataTable", {
                                                  ref: "allAdgroupDatatable",
                                                  attrs: {
                                                    loading:
                                                      _vm.$apollo.queries
                                                        .adGroupReportsAll
                                                        .loading,
                                                    items:
                                                      (_vm.allAdgroupReports &&
                                                        _vm.allAdgroupReports
                                                          .row) ||
                                                      [],
                                                    headers: _vm.adGroupHeaders,
                                                    tab: "All-Adgroups",
                                                    tabInfo: "alladgroup",
                                                    tabindex: _vm.tab,
                                                    customView:
                                                      _vm.customView._id,
                                                    customTab: "adgroups",
                                                    itemsLength:
                                                      _vm.allAdgroupReportsCount,
                                                    sortBy:
                                                      _vm.AllAdGroupSortBy,
                                                    sortDesc:
                                                      _vm.AllAdGroupSortDesc,
                                                  },
                                                  on: {
                                                    persistDatatable:
                                                      _vm.persistDatatable,
                                                    customViewsTable:
                                                      _vm.saveCustomTableViews,
                                                    pageChange: function (
                                                      $event
                                                    ) {
                                                      return _vm.fetchMoreAdgroupsAll(
                                                        $event
                                                      )
                                                    },
                                                    adgroupSearchAllCols: function (
                                                      $event
                                                    ) {
                                                      return _vm.searchAdgroupsAll(
                                                        $event
                                                      )
                                                    },
                                                    downloadReport:
                                                      _vm.downloadReport,
                                                  },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key:
                                                        "item.adgroupMetadata.name",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _c(
                                                            "p",
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "mr-2",
                                                                    attrs: {
                                                                      color:
                                                                        "primary",
                                                                    },
                                                                  },
                                                                  !_vm.isReadOnly()
                                                                    ? {
                                                                        click: function () {
                                                                          return _vm.openEditAddgroupWizard(
                                                                            item
                                                                          )
                                                                        },
                                                                      }
                                                                    : {
                                                                        click: function () {
                                                                          return _vm.openPermissionReadOnlyPopup()
                                                                        },
                                                                      }
                                                                ),
                                                                [
                                                                  _vm._v(
                                                                    "mdi-pencil-outline"
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "a",
                                                                {
                                                                  on: {
                                                                    click: function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.adgroupFilters(
                                                                        item.orgMetadata,
                                                                        item
                                                                          .metadata
                                                                          .app,
                                                                        item.campaignMetadata,
                                                                        item.adgroupMetadata
                                                                      )
                                                                    },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item
                                                                        .adgroupMetadata
                                                                        .name
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.adgroupMetadata.status",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          item.adgroupMetadata
                                                            .deleted ||
                                                          item.campaignMetadata
                                                            .deleted
                                                            ? _c("v-switch", {
                                                                staticClass:
                                                                  "borderedSwitch table-switch",
                                                                attrs: {
                                                                  color:
                                                                    "primary",
                                                                  "true-value":
                                                                    "ENABLED",
                                                                  "false-value":
                                                                    "PAUSED",
                                                                  inset: "",
                                                                  outlined: "",
                                                                  disabled: "",
                                                                  readonly: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    item
                                                                      .adgroupMetadata
                                                                      .status,
                                                                  callback: function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item.adgroupMetadata,
                                                                      "status",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "item.adgroupMetadata.status",
                                                                },
                                                              })
                                                            : _vm._e(),
                                                          !item.adgroupMetadata
                                                            .deleted &&
                                                          !item.campaignMetadata
                                                            .deleted
                                                            ? _c(
                                                                "v-switch",
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "borderedSwitch table-switch",
                                                                    attrs: {
                                                                      color:
                                                                        "primary",
                                                                      "true-value":
                                                                        "ENABLED",
                                                                      "false-value":
                                                                        "PAUSED",
                                                                      inset: "",
                                                                      outlined:
                                                                        "",
                                                                      readonly:
                                                                        "",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        item
                                                                          .adgroupMetadata
                                                                          .status,
                                                                      callback: function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          item.adgroupMetadata,
                                                                          "status",
                                                                          $$v
                                                                        )
                                                                      },
                                                                      expression:
                                                                        "item.adgroupMetadata.status",
                                                                    },
                                                                  },
                                                                  !_vm.isReadOnly()
                                                                    ? {
                                                                        click: function () {
                                                                          return _vm.askConfirm(
                                                                            item
                                                                              .adgroupMetadata
                                                                              .status ==
                                                                              "ENABLED"
                                                                              ? "PAUSED"
                                                                              : "ENABLED",
                                                                            item,
                                                                            "adgroup"
                                                                          )
                                                                        },
                                                                      }
                                                                    : {
                                                                        click: function () {
                                                                          return _vm.openPermissionReadOnlyPopup()
                                                                        },
                                                                      }
                                                                )
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.adgroupMetadata.deleted",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          item.adgroupMetadata
                                                            .deleted
                                                            ? _c(
                                                                "v-chip",
                                                                {
                                                                  staticClass:
                                                                    "ma-2",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "negativeHeaders.deleted"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          !item.adgroupMetadata
                                                            .deleted
                                                            ? _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "mx-2",
                                                                    attrs: {
                                                                      icon: "",
                                                                      dark: "",
                                                                      elevation:
                                                                        "0",
                                                                      small: "",
                                                                      color:
                                                                        "primary",
                                                                    },
                                                                  },
                                                                  !_vm.isReadOnly()
                                                                    ? {
                                                                        click: function () {
                                                                          return _vm.openDeleteAdgroupDialog(
                                                                            item
                                                                          )
                                                                        },
                                                                      }
                                                                    : {
                                                                        click: function () {
                                                                          return _vm.openPermissionReadOnlyPopup()
                                                                        },
                                                                      }
                                                                ),
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        dark:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-delete-outline"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.campaignMetadata.countriesOrRegions",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return _vm._l(
                                                          item.campaignMetadata
                                                            .countriesOrRegions,
                                                          function (country) {
                                                            return _c("p", [
                                                              _vm._v(
                                                                _vm._s(country)
                                                              ),
                                                            ])
                                                          }
                                                        )
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcGoalsRevenue",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcGoalsRevenue(
                                                                    item,
                                                                    "adgroupAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "item.calcGoalRoas",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.calcGoalRoas(
                                                                  item,
                                                                  "adgroupAttributionData"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcEventCount",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.calcEventCount(
                                                                  item,
                                                                  "adgroupAttributionData"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcCostPerGoal",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcCostPerGoal(
                                                                    item,
                                                                    "adgroupAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcRevenuePerGoal",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcRevenuePerGoal(
                                                                    item,
                                                                    "adgroupAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "item.calcARPU",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcARPU(
                                                                    item,
                                                                    "adgroupAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "item.calcAMPU",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcAMPU(
                                                                    item,
                                                                    "adgroupAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcEventCountSec",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.calcEventCountSec(
                                                                  item,
                                                                  "adgroupAttributionData"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcCostPerGoalSec",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcCostPerGoalSec(
                                                                    item,
                                                                    "adgroupAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ]),
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-tab-item",
                              [
                                _c(
                                  "v-row",
                                  { staticClass: "d-flex align-start" },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        attrs: {
                                          cols: "12",
                                          lg: "5",
                                          md: "6",
                                          sm: "8",
                                        },
                                      },
                                      [
                                        !_vm.editMode
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "box-header left-0 chart-header-area d-flex align-baseline pl-6 mb-4 max-400",
                                              },
                                              [
                                                _c("v-select", {
                                                  staticClass: "max-275",
                                                  attrs: {
                                                    "item-text": "CustomView",
                                                    "item-value": "_id",
                                                    items:
                                                      _vm.customViews || [],
                                                    attach: "",
                                                    "menu-props": "auto",
                                                    label: "Select",
                                                    "hide-details": "",
                                                    "single-line": "",
                                                    "return-object": "",
                                                    disabled: _vm.isSuperAdmin
                                                      ? false
                                                      : _vm.permissions.includes(
                                                          _vm
                                                            .SubscriptionPermissionsEnums
                                                            .CUSTOMVIEWS
                                                        )
                                                      ? false
                                                      : true,
                                                  },
                                                  on: {
                                                    change:
                                                      _vm.changeCustomView,
                                                  },
                                                  model: {
                                                    value: _vm.customView,
                                                    callback: function ($$v) {
                                                      _vm.customView = $$v
                                                    },
                                                    expression: "customView",
                                                  },
                                                }),
                                                _vm.customView._id != "0"
                                                  ? _c(
                                                      "v-icon",
                                                      {
                                                        staticClass:
                                                          "ml-2 primary--text",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            _vm.editMode = true
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "mdi-pencil-outline"
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.customView._id != "0"
                                                  ? _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "ml-2",
                                                        attrs: {
                                                          color: "primary",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.deleteCustomView(
                                                              _vm.customView
                                                                ._id,
                                                              "keywords"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "mdi-delete-outline"
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.customView._id != "0" &&
                                                _vm.customViews.find(function (
                                                  el
                                                ) {
                                                  return (
                                                    el._id == _vm.customView._id
                                                  )
                                                }).PinnedStatus != true
                                                  ? _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: {
                                                          bottom: "",
                                                          color: "primarydark",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                var attrs =
                                                                  ref.attrs
                                                                return [
                                                                  _c(
                                                                    "v-icon",
                                                                    _vm._g(
                                                                      _vm._b(
                                                                        {
                                                                          staticClass:
                                                                            "ml-2 primary--text",
                                                                          on: {
                                                                            click: function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.pinnedCustomView(
                                                                                true
                                                                              )
                                                                            },
                                                                          },
                                                                        },
                                                                        "v-icon",
                                                                        attrs,
                                                                        false
                                                                      ),
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-pin-outline"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          2813756920
                                                        ),
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v("Pin View"),
                                                        ]),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.customView._id != "0" &&
                                                _vm.customViews.find(function (
                                                  el
                                                ) {
                                                  return (
                                                    el._id == _vm.customView._id
                                                  )
                                                }).PinnedStatus == true
                                                  ? _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: {
                                                          bottom: "",
                                                          color: "primarydark",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                var attrs =
                                                                  ref.attrs
                                                                return [
                                                                  _c(
                                                                    "v-icon",
                                                                    _vm._g(
                                                                      _vm._b(
                                                                        {
                                                                          staticClass:
                                                                            "ml-2 primary--text",
                                                                          on: {
                                                                            click: function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.pinnedCustomView(
                                                                                false
                                                                              )
                                                                            },
                                                                          },
                                                                        },
                                                                        "v-icon",
                                                                        attrs,
                                                                        false
                                                                      ),
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-pin-off-outline"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          1877317105
                                                        ),
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v("Unpin View"),
                                                        ]),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.editMode
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "box-header chart-header-area edit-field d-flex align-baseline pl-6 mt-3",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "max-275" },
                                                  [
                                                    _c("v-text-field", {
                                                      staticClass: "max-275",
                                                      class: {
                                                        "error--text":
                                                          _vm.errorEditViewMessage,
                                                      },
                                                      attrs: {
                                                        outlined: "",
                                                        dense: "",
                                                        placeholder: "value",
                                                      },
                                                      on: {
                                                        input:
                                                          _vm.validateInput,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.customView
                                                            .CustomView,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.customView,
                                                            "CustomView",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "customView.CustomView",
                                                      },
                                                    }),
                                                    _vm.errorEditViewMessage
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "error--text text-caption ml-3 v-messages__message",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.errorEditViewMessage
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: {
                                                      bottom: "",
                                                      color: "primarydark",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function (ref) {
                                                            var on = ref.on
                                                            var attrs =
                                                              ref.attrs
                                                            return [
                                                              _c(
                                                                "v-icon",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      staticClass:
                                                                        "ml-2 primary--text",
                                                                      class: {
                                                                        "disabled-icon":
                                                                          _vm.errorEditViewMessage,
                                                                      },
                                                                      on: {
                                                                        click: function (
                                                                          $event
                                                                        ) {
                                                                          _vm.errorEditViewMessage
                                                                            ? null
                                                                            : _vm.updateCustomView()
                                                                        },
                                                                      },
                                                                    },
                                                                    "v-icon",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _vm._v(
                                                                    "mdi-checkbox-marked-circle"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      2788561454
                                                    ),
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v("Confirm"),
                                                    ]),
                                                  ]
                                                ),
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: {
                                                      bottom: "",
                                                      color: "primarydark",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function (ref) {
                                                            var on = ref.on
                                                            var attrs =
                                                              ref.attrs
                                                            return [
                                                              _c(
                                                                "v-icon",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      staticClass:
                                                                        "ml-2 primary--text",
                                                                      on: {
                                                                        click:
                                                                          _vm.cancelEditView,
                                                                      },
                                                                    },
                                                                    "v-icon",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _vm._v(
                                                                    "mdi-close-circle"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      2384401514
                                                    ),
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v("Cancel"),
                                                    ]),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                    !_vm.editMode
                                      ? _c(
                                          "v-col",
                                          {
                                            staticClass:
                                              "mt-2 d-flex justify-end align-center",
                                            attrs: {
                                              cols: "12",
                                              lg: "7",
                                              md: "6",
                                              sm: "4",
                                            },
                                          },
                                          [
                                            _vm.customView.CustomView !=
                                            "Default View"
                                              ? _c(
                                                  "v-btn",
                                                  {
                                                    staticClass: " ",
                                                    attrs: {
                                                      color: "primary",
                                                      dark: "",
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.updateCustomView,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { staticClass: "mr-1" },
                                                      [
                                                        _vm._v(
                                                          "mdi-arrow-right"
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" Update View "),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  class: [
                                                    "ml-3",
                                                    _vm.isSuperAdmin
                                                      ? "primary"
                                                      : _vm.permissions.includes(
                                                          _vm
                                                            .SubscriptionPermissionsEnums
                                                            .CUSTOMVIEWS
                                                        )
                                                      ? "primary"
                                                      : "disabled-button",
                                                  ],
                                                  attrs: {
                                                    dark: "",
                                                    position: "relative",
                                                  },
                                                },
                                                _vm.isSuperAdmin
                                                  ? {
                                                      click: function () {
                                                        return _vm.openViewForm()
                                                      },
                                                    }
                                                  : _vm.permissions.includes(
                                                      _vm
                                                        .SubscriptionPermissionsEnums
                                                        .CUSTOMVIEWS
                                                    ) == false
                                                  ? {
                                                      click: function () {
                                                        return _vm.openPermissionPopup()
                                                      },
                                                    }
                                                  : {
                                                      click: function () {
                                                        return _vm.openViewForm()
                                                      },
                                                    }
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  { staticClass: "mr-1" },
                                                  [_vm._v("mdi-plus")]
                                                ),
                                                _vm._v(" Add View "),
                                              ],
                                              1
                                            ),
                                            _vm.showViewForm
                                              ? [
                                                  _c(
                                                    "v-card",
                                                    {
                                                      staticClass:
                                                        "mx-auto d-flex align-baseline px-6 py-5 add-view",
                                                      attrs: { outlined: "" },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "field add-view-field",
                                                        },
                                                        [
                                                          _c("v-text-field", {
                                                            staticClass:
                                                              "w-100",
                                                            class: {
                                                              "error--text":
                                                                _vm.errorViewMessage,
                                                            },
                                                            attrs: {
                                                              required: "",
                                                              outlined: "",
                                                              dense: "",
                                                              placeholder:
                                                                "Enter View name",
                                                            },
                                                            on: {
                                                              input:
                                                                _vm.validateInput,
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.newCustomView,
                                                              callback: function (
                                                                $$v
                                                              ) {
                                                                _vm.newCustomView = $$v
                                                              },
                                                              expression:
                                                                "newCustomView",
                                                            },
                                                          }),
                                                          _vm.errorViewMessage
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "error--text text-caption ml-3 v-messages__message",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.errorViewMessage
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-card-actions",
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                color: _vm.newCustomView
                                                                  ? "primary"
                                                                  : "grey",
                                                                disabled:
                                                                  !_vm.newCustomView ||
                                                                  !_vm.isInputValid,
                                                              },
                                                              on: {
                                                                click: function (
                                                                  $event
                                                                ) {
                                                                  return _vm.saveCustomView(
                                                                    "keywords"
                                                                  )
                                                                },
                                                              },
                                                            },
                                                            [_vm._v(" Save ")]
                                                          ),
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                color: "grey",
                                                                depressed: "",
                                                                outlined: "",
                                                              },
                                                              on: {
                                                                click: function (
                                                                  $event
                                                                ) {
                                                                  _vm.showViewForm = false
                                                                },
                                                              },
                                                            },
                                                            [_vm._v(" Cancel ")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              : _vm._e(),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-card",
                                  { attrs: { flat: "" } },
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              lg: "6",
                                              md: "12",
                                              sm: "12",
                                            },
                                          },
                                          [
                                            _c(
                                              "KeepAlive",
                                              { attrs: { max: "1" } },
                                              [
                                                _vm.currentTab ===
                                                "keywords_tab"
                                                  ? _c("Chart", {
                                                      attrs: {
                                                        timeLineEntries:
                                                          _vm.timeLineEntries,
                                                        chartdata:
                                                          _vm.keywordsChartData,
                                                        options:
                                                          _vm.chartOptions,
                                                        tabInfo: "keywords",
                                                        strategySelection:
                                                          _vm.selectedStatestrategy,
                                                        customView:
                                                          _vm.customView._id,
                                                        granularity:
                                                          _vm.chartGranularity,
                                                        loading:
                                                          _vm.$apollo.queries
                                                            .keywordsGraph
                                                            .loading,
                                                      },
                                                      on: {
                                                        granularityChanged:
                                                          _vm.handleGranularityChange,
                                                        persistDatasets:
                                                          _vm.persistDatasets,
                                                        customViews:
                                                          _vm.saveCustomViews,
                                                        granularityWithTab:
                                                          _vm.saveGranularityToCustomView,
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              lg: "6",
                                              md: "12",
                                              sm: "12",
                                            },
                                          },
                                          [
                                            _c(
                                              "KeepAlive",
                                              { attrs: { max: "1" } },
                                              [
                                                _vm.currentTab ===
                                                "keywords_tab"
                                                  ? _c("TotalSummaryKeywords", {
                                                      attrs: {
                                                        tab: _vm.tab,
                                                        values:
                                                          _vm.keywordsTotalSummaryData,
                                                        rowItems:
                                                          (_vm.keywordReport &&
                                                            _vm.keywordReport
                                                              .row) ||
                                                          [],
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "actions-col pt-5" },
                                      [
                                        _c(
                                          "v-item-group",
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c("v-col", {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "6",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("SelectAdGroup", {
                                      attrs: {
                                        settings: _vm.selectAdgroupForKeywords,
                                      },
                                      on: {
                                        onAdgroupSelection: function ($event) {
                                          return _vm.adgroupsSelectedForKeyword(
                                            $event
                                          )
                                        },
                                      },
                                    }),
                                    _c("AddKeywords", {
                                      attrs: {
                                        adgroupDetails: _vm.adgroupMetadata,
                                      },
                                      on: {
                                        keywordAdded: function ($event) {
                                          return _vm.onUpdate()
                                        },
                                        onClickChange: function ($event) {
                                          return _vm.openSelectAdgroupForKeywords(
                                            $event
                                          )
                                        },
                                      },
                                    }),
                                    _c("DeleteKeyword", {
                                      attrs: {
                                        keywordDetailsForDeletion:
                                          _vm.keywordDeletionMetadata,
                                      },
                                      on: {
                                        onDeleteKeyword: function ($event) {
                                          return _vm.onUpdate()
                                        },
                                      },
                                    }),
                                    _c("EditBidAmount", {
                                      attrs: {
                                        keywordDetails: _vm.keywordBidData,
                                      },
                                      on: {
                                        bidUpdated: function ($event) {
                                          return _vm.onUpdate()
                                        },
                                      },
                                    }),
                                    _c(
                                      "v-row",
                                      {
                                        staticClass:
                                          "d-flex align-center mt-8 mb-4 custom-tab-outer",
                                      },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              lg: "9",
                                              md: "12",
                                              sm: "12",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-tabs",
                                              {
                                                staticClass: "custom-tab",
                                                attrs: {
                                                  "center-active": "",
                                                  "show-arrows": "",
                                                  "background-color":
                                                    "transparent",
                                                },
                                                model: {
                                                  value: _vm.keywordListingTab,
                                                  callback: function ($$v) {
                                                    _vm.keywordListingTab = $$v
                                                  },
                                                  expression:
                                                    "keywordListingTab",
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-tab",
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "subTabs.live"
                                                          ) +
                                                            " " +
                                                            _vm.$t(
                                                              "subTabs.keywords"
                                                            )
                                                        ) +
                                                        " "
                                                    ),
                                                    _c(
                                                      "v-avatar",
                                                      {
                                                        staticClass: "mx-3",
                                                        attrs: {
                                                          color: "primary",
                                                          size: "26",
                                                        },
                                                      },
                                                      [
                                                        !_vm.$apollo.queries
                                                          .targetingKeywordReportsLive
                                                          .loading
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "white--text caption",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.filteredKeywordReportsCount
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm.$apollo.queries
                                                          .targetingKeywordReportsLive
                                                          .loading
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "white--text caption",
                                                              },
                                                              [_vm._v("...")]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-tab",
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "subTabs.paused"
                                                          ) +
                                                            " " +
                                                            _vm.$t(
                                                              "subTabs.keywords"
                                                            )
                                                        ) +
                                                        " "
                                                    ),
                                                    _c(
                                                      "v-avatar",
                                                      {
                                                        staticClass: "mx-3",
                                                        attrs: {
                                                          color: "primary",
                                                          size: "26",
                                                        },
                                                      },
                                                      [
                                                        !_vm.$apollo.queries
                                                          .targetingKeywordReportsPaused
                                                          .loading
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "white--text caption",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.disabledKeywordReportsCount
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm.$apollo.queries
                                                          .targetingKeywordReportsPaused
                                                          .loading
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "white--text caption",
                                                              },
                                                              [_vm._v("...")]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-tab",
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "subTabs.deleted"
                                                          ) +
                                                            " " +
                                                            _vm.$t(
                                                              "subTabs.keywords"
                                                            )
                                                        ) +
                                                        " "
                                                    ),
                                                    _c(
                                                      "v-avatar",
                                                      {
                                                        staticClass: "mx-3",
                                                        attrs: {
                                                          color: "primary",
                                                          size: "26",
                                                        },
                                                      },
                                                      [
                                                        !_vm.$apollo.queries
                                                          .targetingKeywordReportsDeleted
                                                          .loading
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "white--text caption",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.deletedKeywordReportsCount
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm.$apollo.queries
                                                          .targetingKeywordReportsDeleted
                                                          .loading
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "white--text caption",
                                                              },
                                                              [_vm._v("...")]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-tab",
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "subTabs.all"
                                                          ) +
                                                            " " +
                                                            _vm.$t(
                                                              "subTabs.keywords"
                                                            )
                                                        ) +
                                                        " "
                                                    ),
                                                    _c(
                                                      "v-avatar",
                                                      {
                                                        staticClass: "mx-3",
                                                        attrs: {
                                                          color: "primary",
                                                          size: "26",
                                                        },
                                                      },
                                                      [
                                                        !_vm.$apollo.queries
                                                          .targetingKeywordReportsAll
                                                          .loading
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "white--text caption",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.allKeywordReportsCount
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm.$apollo.queries
                                                          .targetingKeywordReportsAll
                                                          .loading
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "white--text caption",
                                                              },
                                                              [_vm._v("...")]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "d-flex justify-end",
                                            attrs: {
                                              cols: "12",
                                              lg: "3",
                                              md: "12",
                                              sm: "12",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "mr-1",
                                                attrs: {
                                                  depressed: "",
                                                  color: "primary",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.openKBBU()
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { small: "" } },
                                                  [_vm._v("mdi-plus")]
                                                ),
                                                _vm._v(" Bulk Bid Update "),
                                              ],
                                              1
                                            ),
                                            !_vm.selectedAdGroup
                                              ? _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    {
                                                      staticClass:
                                                        "min-width-156",
                                                      attrs: {
                                                        depressed: "",
                                                        color: "primary",
                                                      },
                                                    },
                                                    !_vm.isReadOnly()
                                                      ? {
                                                          click: function () {
                                                            return _vm.openSelectAdgroupForKeywords()
                                                          },
                                                        }
                                                      : {
                                                          click: function () {
                                                            return _vm.openPermissionReadOnlyPopup()
                                                          },
                                                        }
                                                  ),
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v("mdi-plus"),
                                                    ]),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t("popups.add") +
                                                            " " +
                                                            _vm.$t(
                                                              "popups.keywords"
                                                            )
                                                        ) +
                                                        " "
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.selectedAdGroup
                                              ? _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    {
                                                      attrs: {
                                                        depressed: "",
                                                        color: "primary",
                                                        disabled:
                                                          _vm.selectedCampaignType ==
                                                          "DISPLAY",
                                                      },
                                                    },
                                                    !_vm.isReadOnly()
                                                      ? {
                                                          click: function () {
                                                            return _vm.addKeyToFiltAdgroup()
                                                          },
                                                        }
                                                      : {
                                                          click: function () {
                                                            return _vm.openPermissionReadOnlyPopup()
                                                          },
                                                        }
                                                  ),
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { small: "" } },
                                                      [_vm._v("mdi-plus")]
                                                    ),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t("popups.add") +
                                                            " " +
                                                            _vm.$t(
                                                              "popups.keywords"
                                                            ) +
                                                            " to filtered Adgroup"
                                                        ) +
                                                        " "
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.selectedCampaignType ==
                                            "DISPLAY"
                                              ? _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: {
                                                      bottom: "",
                                                      color: "primarydark",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function (ref) {
                                                            var on = ref.on
                                                            var attrs =
                                                              ref.attrs
                                                            return [
                                                              _c(
                                                                "v-icon",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      staticClass:
                                                                        "ml-2 greyxdark--text",
                                                                      attrs: {
                                                                        small:
                                                                          "",
                                                                      },
                                                                    },
                                                                    "v-icon",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _vm._v(
                                                                    " mdi-help-circle-outline "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      1262346950
                                                    ),
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        "Keywords are not available in Search Tab / Today's Tab / Product Page campaigns"
                                                      ),
                                                    ]),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-tabs-items",
                                      {
                                        staticClass: "show-full-content",
                                        model: {
                                          value: _vm.keywordListingTab,
                                          callback: function ($$v) {
                                            _vm.keywordListingTab = $$v
                                          },
                                          expression: "keywordListingTab",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-tab-item",
                                          [
                                            _c(
                                              "KeepAlive",
                                              { attrs: { max: "1" } },
                                              [
                                                _c("DataTable", {
                                                  ref: "livekeywordDatatable",
                                                  attrs: {
                                                    loading:
                                                      _vm.$apollo.queries
                                                        .targetingKeywordReportsLive
                                                        .loading,
                                                    items:
                                                      (_vm.keywordReport &&
                                                        _vm.keywordReport
                                                          .row) ||
                                                      [],
                                                    headers: _vm.keyheaders,
                                                    tab:
                                                      "Live-Targeting-Keywords",
                                                    tabInfo: "livekeyword",
                                                    tabindex: _vm.tab,
                                                    customView:
                                                      _vm.customView._id,
                                                    customTab: "keywords",
                                                    itemsLength:
                                                      _vm.filteredKeywordReportsCount,
                                                    sortBy:
                                                      _vm.LiveKeywordSortBy,
                                                    sortDesc:
                                                      _vm.LiveKeywordsSortDesc,
                                                  },
                                                  on: {
                                                    persistDatatable:
                                                      _vm.persistDatatable,
                                                    customViewsTable:
                                                      _vm.saveCustomTableViews,
                                                    pageChange: function (
                                                      $event
                                                    ) {
                                                      return _vm.fetchMoreKeywordsLive(
                                                        $event
                                                      )
                                                    },
                                                    liveKeywordSearchCols: function (
                                                      $event
                                                    ) {
                                                      return _vm.searchKeywordsLive(
                                                        $event
                                                      )
                                                    },
                                                    downloadReport:
                                                      _vm.downloadReport,
                                                  },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key:
                                                        "item.keywordMetadata.text",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          item.keywordMetadata
                                                            .text === "null"
                                                            ? _c(
                                                                "v-tooltip",
                                                                {
                                                                  attrs: {
                                                                    bottom: "",
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "activator",
                                                                        fn: function (
                                                                          ref
                                                                        ) {
                                                                          var on =
                                                                            ref.on
                                                                          var attrs =
                                                                            ref.attrs
                                                                          return [
                                                                            _c(
                                                                              "p",
                                                                              _vm._g(
                                                                                _vm._b(
                                                                                  {},
                                                                                  "p",
                                                                                  attrs,
                                                                                  false
                                                                                ),
                                                                                on
                                                                              ),
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    item
                                                                                      .keywordMetadata
                                                                                      .text
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "This row represents the number of acquisitions reported by Apple which did not attribute to a specific keyword"
                                                                    ),
                                                                  ]),
                                                                ]
                                                              )
                                                            : _c("p", [
                                                                _c(
                                                                  "a",
                                                                  {
                                                                    on: {
                                                                      click: function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.keywordFilters(
                                                                          item.keywordMetadata,
                                                                          item.metadata
                                                                        )
                                                                      },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item
                                                                          .keywordMetadata
                                                                          .text
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.keywordMetadata.status",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          item.campaignMetadata
                                                            .deleted &&
                                                          item.keywordMetadata
                                                            .text != "null"
                                                            ? _c("v-switch", {
                                                                staticClass:
                                                                  "borderedSwitch table-switch",
                                                                attrs: {
                                                                  color:
                                                                    "primary",
                                                                  "true-value":
                                                                    "ACTIVE",
                                                                  "false-value":
                                                                    "PAUSED",
                                                                  inset: "",
                                                                  outlined: "",
                                                                  disabled: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    item
                                                                      .keywordMetadata
                                                                      .status,
                                                                  callback: function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item.keywordMetadata,
                                                                      "status",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "item.keywordMetadata.status",
                                                                },
                                                              })
                                                            : _vm._e(),
                                                          !item.campaignMetadata
                                                            .deleted &&
                                                          item.keywordMetadata
                                                            .text != "null"
                                                            ? _c(
                                                                "v-switch",
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "borderedSwitch table-switch",
                                                                    attrs: {
                                                                      color:
                                                                        "primary",
                                                                      "true-value":
                                                                        "ACTIVE",
                                                                      "false-value":
                                                                        "PAUSED",
                                                                      inset: "",
                                                                      outlined:
                                                                        "",
                                                                      readonly:
                                                                        "",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        item
                                                                          .keywordMetadata
                                                                          .status,
                                                                      callback: function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          item.keywordMetadata,
                                                                          "status",
                                                                          $$v
                                                                        )
                                                                      },
                                                                      expression:
                                                                        "item.keywordMetadata.status",
                                                                    },
                                                                  },
                                                                  !_vm.isReadOnly()
                                                                    ? {
                                                                        click: function () {
                                                                          return _vm.askConfirm(
                                                                            item
                                                                              .keywordMetadata
                                                                              .status ==
                                                                              "ACTIVE"
                                                                              ? "PAUSED"
                                                                              : "ACTIVE",
                                                                            item,
                                                                            "keyword"
                                                                          )
                                                                        },
                                                                      }
                                                                    : {
                                                                        click: function () {
                                                                          return _vm.openPermissionReadOnlyPopup()
                                                                        },
                                                                      }
                                                                )
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.keywordMetadata.bidAmount.amount",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          item.keywordMetadata
                                                            .text != "null"
                                                            ? _c(
                                                                "v-row",
                                                                {
                                                                  staticClass:
                                                                    "bidAmount-row d-flex justify-end",
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      staticClass:
                                                                        "no-m-no-p no-cols mr-1",
                                                                      attrs: {
                                                                        cols:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c("p", [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.getCurrencyIcon(
                                                                                item
                                                                                  .orgMetadata
                                                                                  .currency
                                                                              ) +
                                                                                "" +
                                                                                _vm.numDecimalFormat(
                                                                                  item
                                                                                    .keywordMetadata
                                                                                    .bidAmount
                                                                                    .amount
                                                                                )
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]),
                                                                    ]
                                                                  ),
                                                                  item.dirty
                                                                    ? _c(
                                                                        "v-tooltip",
                                                                        {
                                                                          attrs: {
                                                                            bottom:
                                                                              "",
                                                                          },
                                                                          scopedSlots: _vm._u(
                                                                            [
                                                                              {
                                                                                key:
                                                                                  "activator",
                                                                                fn: function (
                                                                                  ref
                                                                                ) {
                                                                                  var on =
                                                                                    ref.on
                                                                                  var attrs =
                                                                                    ref.attrs
                                                                                  return [
                                                                                    _c(
                                                                                      "v-col",
                                                                                      _vm._g(
                                                                                        _vm._b(
                                                                                          {
                                                                                            staticClass:
                                                                                              "no-m-no-p",
                                                                                            attrs: {
                                                                                              cols:
                                                                                                "3",
                                                                                            },
                                                                                          },
                                                                                          "v-col",
                                                                                          attrs,
                                                                                          false
                                                                                        ),
                                                                                        on
                                                                                      ),
                                                                                      [
                                                                                        !item
                                                                                          .campaignMetadata
                                                                                          .deleted
                                                                                          ? _c(
                                                                                              "v-btn",
                                                                                              _vm._g(
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "ml-2 pa-0",
                                                                                                  attrs: {
                                                                                                    "max-width":
                                                                                                      "15",
                                                                                                    "min-width":
                                                                                                      "10",
                                                                                                    height:
                                                                                                      "20",
                                                                                                    text:
                                                                                                      "",
                                                                                                    depressed:
                                                                                                      "",
                                                                                                  },
                                                                                                },
                                                                                                !_vm.isReadOnly()
                                                                                                  ? {
                                                                                                      click: function () {
                                                                                                        return _vm.openEditBidAmount(
                                                                                                          item
                                                                                                        )
                                                                                                      },
                                                                                                    }
                                                                                                  : {
                                                                                                      click: function () {
                                                                                                        return _vm.openPermissionReadOnlyPopup()
                                                                                                      },
                                                                                                    }
                                                                                              ),
                                                                                              [
                                                                                                _c(
                                                                                                  "v-icon",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "mr-0",
                                                                                                    attrs: {
                                                                                                      color:
                                                                                                        "secondary",
                                                                                                    },
                                                                                                  },
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "mdi-pencil-outline"
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                              ],
                                                                                              1
                                                                                            )
                                                                                          : _vm._e(),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                  ]
                                                                                },
                                                                              },
                                                                            ],
                                                                            null,
                                                                            true
                                                                          ),
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "A bid update request is already in queue"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _c(
                                                                        "v-tooltip",
                                                                        {
                                                                          attrs: {
                                                                            bottom:
                                                                              "",
                                                                          },
                                                                          scopedSlots: _vm._u(
                                                                            [
                                                                              {
                                                                                key:
                                                                                  "activator",
                                                                                fn: function (
                                                                                  ref
                                                                                ) {
                                                                                  var on =
                                                                                    ref.on
                                                                                  var attrs =
                                                                                    ref.attrs
                                                                                  return [
                                                                                    _c(
                                                                                      "v-col",
                                                                                      _vm._g(
                                                                                        _vm._b(
                                                                                          {
                                                                                            staticClass:
                                                                                              "no-m-no-p",
                                                                                            attrs: {
                                                                                              cols:
                                                                                                "3",
                                                                                            },
                                                                                          },
                                                                                          "v-col",
                                                                                          attrs,
                                                                                          false
                                                                                        ),
                                                                                        on
                                                                                      ),
                                                                                      [
                                                                                        !item
                                                                                          .campaignMetadata
                                                                                          .deleted
                                                                                          ? _c(
                                                                                              "v-btn",
                                                                                              _vm._g(
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "ml-2 pa-0",
                                                                                                  attrs: {
                                                                                                    "max-width":
                                                                                                      "15",
                                                                                                    "min-width":
                                                                                                      "10",
                                                                                                    height:
                                                                                                      "20",
                                                                                                    text:
                                                                                                      "",
                                                                                                    depressed:
                                                                                                      "",
                                                                                                  },
                                                                                                },
                                                                                                !_vm.isReadOnly()
                                                                                                  ? {
                                                                                                      click: function () {
                                                                                                        return _vm.openEditBidAmount(
                                                                                                          item
                                                                                                        )
                                                                                                      },
                                                                                                    }
                                                                                                  : {
                                                                                                      click: function () {
                                                                                                        return _vm.openPermissionReadOnlyPopup()
                                                                                                      },
                                                                                                    }
                                                                                              ),
                                                                                              [
                                                                                                _c(
                                                                                                  "v-icon",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "mr-0",
                                                                                                    attrs: {
                                                                                                      color:
                                                                                                        "primary",
                                                                                                    },
                                                                                                  },
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "mdi-pencil-outline"
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                              ],
                                                                                              1
                                                                                            )
                                                                                          : _vm._e(),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                  ]
                                                                                },
                                                                              },
                                                                            ],
                                                                            null,
                                                                            true
                                                                          ),
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "Click to edit this keyword bid"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.campaignMetadata.countriesOrRegions",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return _vm._l(
                                                          item.campaignMetadata
                                                            .countriesOrRegions,
                                                          function (country) {
                                                            return _c(
                                                              "p",
                                                              {
                                                                key:
                                                                  item
                                                                    .campaignMetadata
                                                                    .countriesOrRegions,
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      country
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        )
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.keywordMetadata.deleted",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          item.keywordMetadata
                                                            .deleted &&
                                                          item.keywordMetadata
                                                            .text != "null"
                                                            ? _c(
                                                                "v-chip",
                                                                {
                                                                  staticClass:
                                                                    "ma-2",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "negativeHeaders.deleted"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          !item.keywordMetadata
                                                            .deleted &&
                                                          item.keywordMetadata
                                                            .text != "null"
                                                            ? _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "mx-2",
                                                                    attrs: {
                                                                      icon: "",
                                                                      dark: "",
                                                                      elevation:
                                                                        "0",
                                                                      small: "",
                                                                      color:
                                                                        "primary",
                                                                    },
                                                                  },
                                                                  !_vm.isReadOnly()
                                                                    ? {
                                                                        click: function () {
                                                                          return _vm.openDeleteKeywordDialog(
                                                                            item
                                                                          )
                                                                        },
                                                                      }
                                                                    : {
                                                                        click: function () {
                                                                          return _vm.openPermissionReadOnlyPopup()
                                                                        },
                                                                      }
                                                                ),
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        dark:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-delete-outline"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.keywordMetadata.matchType",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          item.keywordMetadata
                                                            .matchType != null
                                                            ? _c("div", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.keywordMetadata.matchType
                                                                        .charAt(
                                                                          0
                                                                        )
                                                                        .toUpperCase() +
                                                                        item.keywordMetadata.matchType
                                                                          .slice(
                                                                            1
                                                                          )
                                                                          .toLowerCase()
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcGoalsRevenue",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcGoalsRevenue(
                                                                    item,
                                                                    "keywordAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "item.calcGoalRoas",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.calcGoalRoas(
                                                                  item,
                                                                  "keywordAttributionData"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcEventCount",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.calcEventCount(
                                                                  item,
                                                                  "keywordAttributionData"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcCostPerGoal",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcCostPerGoal(
                                                                    item,
                                                                    "keywordAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcRevenuePerGoal",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcRevenuePerGoal(
                                                                    item,
                                                                    "keywordAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "item.calcARPU",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcARPU(
                                                                    item,
                                                                    "keywordAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "item.calcAMPU",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcAMPU(
                                                                    item,
                                                                    "keywordAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcEventCountSec",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.calcEventCountSec(
                                                                  item,
                                                                  "keywordAttributionData"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcCostPerGoalSec",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcCostPerGoalSec(
                                                                    item,
                                                                    "keywordAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.insights.bidRecommendation.bidMin.amount",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          item.insights
                                                            .bidRecommendation
                                                            .bidMin != null
                                                            ? _c("div", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.getCurrencyIcon(
                                                                        item
                                                                          .orgMetadata
                                                                          .currency
                                                                      ) +
                                                                        "" +
                                                                        item
                                                                          .insights
                                                                          .bidRecommendation
                                                                          .bidMin
                                                                          .amount
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.insights.bidRecommendation.bidMax.amount",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          item.insights
                                                            .bidRecommendation
                                                            .bidMax != null
                                                            ? _c("div", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.getCurrencyIcon(
                                                                        item
                                                                          .orgMetadata
                                                                          .currency
                                                                      ) +
                                                                        "" +
                                                                        item
                                                                          .insights
                                                                          .bidRecommendation
                                                                          .bidMax
                                                                          .amount
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                  ]),
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-tab-item",
                                          [
                                            _c(
                                              "KeepAlive",
                                              { attrs: { max: "1" } },
                                              [
                                                _c("DataTable", {
                                                  ref: "pausedKeywordDatatable",
                                                  attrs: {
                                                    loading:
                                                      _vm.$apollo.queries
                                                        .targetingKeywordReportsPaused
                                                        .loading,
                                                    items:
                                                      (_vm.disabledKeywordReports &&
                                                        _vm
                                                          .disabledKeywordReports
                                                          .row) ||
                                                      [],
                                                    headers: _vm.keyheaders,
                                                    tab:
                                                      "Disabled-Targeting-Keywords",
                                                    tabInfo: "pausedkeyword",
                                                    tabindex: _vm.tab,
                                                    customView:
                                                      _vm.customView._id,
                                                    customTab: "keywords",
                                                    itemsLength:
                                                      _vm.disabledKeywordReportsCount,
                                                    sortBy:
                                                      _vm.PausedKeywordSortBy,
                                                    sortDesc:
                                                      _vm.PausedKeywordsSortDesc,
                                                  },
                                                  on: {
                                                    persistDatatable:
                                                      _vm.persistDatatable,
                                                    customViewsTable:
                                                      _vm.saveCustomTableViews,
                                                    pageChange: function (
                                                      $event
                                                    ) {
                                                      return _vm.fetchMoreKeywordsPaused(
                                                        $event
                                                      )
                                                    },
                                                    pausedKeywordSearchCols: function (
                                                      $event
                                                    ) {
                                                      return _vm.searchKeywordsPaused(
                                                        $event
                                                      )
                                                    },
                                                    downloadReport:
                                                      _vm.downloadReport,
                                                  },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key:
                                                        "item.keywordMetadata.text",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          item.keywordMetadata
                                                            .text === "null"
                                                            ? _c(
                                                                "v-tooltip",
                                                                {
                                                                  attrs: {
                                                                    bottom: "",
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "activator",
                                                                        fn: function (
                                                                          ref
                                                                        ) {
                                                                          var on =
                                                                            ref.on
                                                                          var attrs =
                                                                            ref.attrs
                                                                          return [
                                                                            _c(
                                                                              "p",
                                                                              _vm._g(
                                                                                _vm._b(
                                                                                  {},
                                                                                  "p",
                                                                                  attrs,
                                                                                  false
                                                                                ),
                                                                                on
                                                                              ),
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    item
                                                                                      .keywordMetadata
                                                                                      .text
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "This row represents the number of acquisitions reported by Apple which did not attribute to a specific keyword"
                                                                    ),
                                                                  ]),
                                                                ]
                                                              )
                                                            : _c("p", [
                                                                _c(
                                                                  "a",
                                                                  {
                                                                    on: {
                                                                      click: function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.keywordFilters(
                                                                          item.keywordMetadata,
                                                                          item.metadata
                                                                        )
                                                                      },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item
                                                                          .keywordMetadata
                                                                          .text
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.keywordMetadata.status",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          item.campaignMetadata
                                                            .status ==
                                                            "ENABLED" &&
                                                          item.keywordMetadata
                                                            .text != "null"
                                                            ? _c(
                                                                "v-switch",
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "borderedSwitch table-switch",
                                                                    attrs: {
                                                                      color:
                                                                        "primary",
                                                                      "true-value":
                                                                        "ACTIVE",
                                                                      "false-value":
                                                                        "PAUSED",
                                                                      inset: "",
                                                                      outlined:
                                                                        "",
                                                                      readonly:
                                                                        "",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        item
                                                                          .keywordMetadata
                                                                          .status,
                                                                      callback: function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          item.keywordMetadata,
                                                                          "status",
                                                                          $$v
                                                                        )
                                                                      },
                                                                      expression:
                                                                        "item.keywordMetadata.status",
                                                                    },
                                                                  },
                                                                  !_vm.isReadOnly()
                                                                    ? {
                                                                        click: function () {
                                                                          return _vm.askConfirm(
                                                                            item
                                                                              .keywordMetadata
                                                                              .status ==
                                                                              "ACTIVE"
                                                                              ? "PAUSED"
                                                                              : "ACTIVE",
                                                                            item,
                                                                            "keyword"
                                                                          )
                                                                        },
                                                                      }
                                                                    : {
                                                                        click: function () {
                                                                          return _vm.openPermissionReadOnlyPopup()
                                                                        },
                                                                      }
                                                                )
                                                              )
                                                            : _vm._e(),
                                                          item.campaignMetadata
                                                            .status ==
                                                            "PAUSED" &&
                                                          item.keywordMetadata
                                                            .text != "null"
                                                            ? _c(
                                                                "p",
                                                                {
                                                                  staticClass:
                                                                    "primary--text",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Campaign is disabled "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.keywordMetadata.bidAmount.amount",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          item.keywordMetadata
                                                            .text != "null"
                                                            ? _c(
                                                                "v-row",
                                                                {
                                                                  staticClass:
                                                                    "d-flex justify-end",
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      staticClass:
                                                                        "no-m-no-p no-cols mr-1",
                                                                      attrs: {
                                                                        cols:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c("p", [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.getCurrencyIcon(
                                                                                item
                                                                                  .orgMetadata
                                                                                  .currency
                                                                              ) +
                                                                                "" +
                                                                                _vm.numDecimalFormat(
                                                                                  item
                                                                                    .keywordMetadata
                                                                                    .bidAmount
                                                                                    .amount
                                                                                )
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]),
                                                                    ]
                                                                  ),
                                                                  !item
                                                                    .campaignMetadata
                                                                    .deleted
                                                                    ? _c(
                                                                        "v-btn",
                                                                        _vm._g(
                                                                          {
                                                                            staticClass:
                                                                              "ml-2 pa-0",
                                                                            attrs: {
                                                                              "max-width":
                                                                                "15",
                                                                              "min-width":
                                                                                "10",
                                                                              height:
                                                                                "20",
                                                                              text:
                                                                                "",
                                                                              depressed:
                                                                                "",
                                                                            },
                                                                          },
                                                                          !_vm.isReadOnly()
                                                                            ? {
                                                                                click: function () {
                                                                                  return _vm.openEditBidAmount(
                                                                                    item
                                                                                  )
                                                                                },
                                                                              }
                                                                            : {
                                                                                click: function () {
                                                                                  return _vm.openPermissionReadOnlyPopup()
                                                                                },
                                                                              }
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              staticClass:
                                                                                "mr-0",
                                                                              attrs: {
                                                                                color:
                                                                                  "primary",
                                                                              },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "mdi-pencil-outline"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.campaignMetadata.countriesOrRegions",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return _vm._l(
                                                          item.campaignMetadata
                                                            .countriesOrRegions,
                                                          function (country) {
                                                            return _c("p", [
                                                              _vm._v(
                                                                _vm._s(country)
                                                              ),
                                                            ])
                                                          }
                                                        )
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.keywordMetadata.deleted",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          item.keywordMetadata
                                                            .deleted &&
                                                          item.keywordMetadata
                                                            .text != "null"
                                                            ? _c(
                                                                "v-chip",
                                                                {
                                                                  staticClass:
                                                                    "ma-2",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "negativeHeaders.deleted"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          !item.keywordMetadata
                                                            .deleted &&
                                                          item.keywordMetadata
                                                            .text != "null"
                                                            ? _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "mx-2",
                                                                    attrs: {
                                                                      icon: "",
                                                                      dark: "",
                                                                      elevation:
                                                                        "0",
                                                                      small: "",
                                                                      color:
                                                                        "primary",
                                                                    },
                                                                  },
                                                                  !_vm.isReadOnly()
                                                                    ? {
                                                                        click: function () {
                                                                          return _vm.openDeleteKeywordDialog(
                                                                            item
                                                                          )
                                                                        },
                                                                      }
                                                                    : {
                                                                        click: function () {
                                                                          return _vm.openPermissionReadOnlyPopup()
                                                                        },
                                                                      }
                                                                ),
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        dark:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-delete-outline"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.keywordMetadata.matchType",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          item.keywordMetadata
                                                            .matchType != null
                                                            ? _c("div", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.keywordMetadata.matchType
                                                                        .charAt(
                                                                          0
                                                                        )
                                                                        .toUpperCase() +
                                                                        item.keywordMetadata.matchType
                                                                          .slice(
                                                                            1
                                                                          )
                                                                          .toLowerCase()
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcGoalsRevenue",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcGoalsRevenue(
                                                                    item,
                                                                    "keywordAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "item.calcGoalRoas",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.calcGoalRoas(
                                                                  item,
                                                                  "keywordAttributionData"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcEventCount",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.calcEventCount(
                                                                  item,
                                                                  "keywordAttributionData"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcCostPerGoal",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcCostPerGoal(
                                                                    item,
                                                                    "keywordAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcRevenuePerGoal",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcRevenuePerGoal(
                                                                    item,
                                                                    "keywordAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "item.calcARPU",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcARPU(
                                                                    item,
                                                                    "keywordAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "item.calcAMPU",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcAMPU(
                                                                    item,
                                                                    "keywordAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcEventCountSec",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.calcEventCountSec(
                                                                  item,
                                                                  "keywordAttributionData"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcCostPerGoalSec",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcCostPerGoalSec(
                                                                    item,
                                                                    "keywordAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.insights.bidRecommendation.bidMin.amount",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          item.insights
                                                            .bidRecommendation
                                                            .bidMin != null
                                                            ? _c("div", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.getCurrencyIcon(
                                                                        item
                                                                          .orgMetadata
                                                                          .currency
                                                                      ) +
                                                                        "" +
                                                                        item
                                                                          .insights
                                                                          .bidRecommendation
                                                                          .bidMin
                                                                          .amount
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.insights.bidRecommendation.bidMax.amount",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          item.insights
                                                            .bidRecommendation
                                                            .bidMax != null
                                                            ? _c("div", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.getCurrencyIcon(
                                                                        item
                                                                          .orgMetadata
                                                                          .currency
                                                                      ) +
                                                                        "" +
                                                                        item
                                                                          .insights
                                                                          .bidRecommendation
                                                                          .bidMax
                                                                          .amount
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                  ]),
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-tab-item",
                                          [
                                            _c(
                                              "KeepAlive",
                                              { attrs: { max: "1" } },
                                              [
                                                _c("DataTable", {
                                                  ref:
                                                    "deletedKeywordDatatable",
                                                  attrs: {
                                                    loading:
                                                      _vm.$apollo.queries
                                                        .targetingKeywordReportsDeleted
                                                        .loading,
                                                    items:
                                                      (_vm.deletedKeywordReports &&
                                                        _vm
                                                          .deletedKeywordReports
                                                          .row) ||
                                                      [],
                                                    headers: _vm.keyheaders,
                                                    tab:
                                                      "Deleted-Targeting-Keywords",
                                                    tabInfo: "deletedkeyword",
                                                    tabindex: _vm.tab,
                                                    customView:
                                                      _vm.customView._id,
                                                    customTab: "keywords",
                                                    itemsLength:
                                                      _vm.deletedKeywordReportsCount,
                                                    sortBy:
                                                      _vm.DeletedKeywordSortBy,
                                                    sortDesc:
                                                      _vm.DeletedKeywordsSortDesc,
                                                  },
                                                  on: {
                                                    persistDatatable:
                                                      _vm.persistDatatable,
                                                    customViewsTable:
                                                      _vm.saveCustomTableViews,
                                                    pageChange: function (
                                                      $event
                                                    ) {
                                                      return _vm.fetchMoreKeywordsDeleted(
                                                        $event
                                                      )
                                                    },
                                                    deletedKeywordSearchCols: function (
                                                      $event
                                                    ) {
                                                      return _vm.searchKeywordsDeleted(
                                                        $event
                                                      )
                                                    },
                                                    downloadReport:
                                                      _vm.downloadReport,
                                                  },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key:
                                                        "item.keywordMetadata.text",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          item.keywordMetadata
                                                            .text === "null"
                                                            ? _c(
                                                                "v-tooltip",
                                                                {
                                                                  attrs: {
                                                                    bottom: "",
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "activator",
                                                                        fn: function (
                                                                          ref
                                                                        ) {
                                                                          var on =
                                                                            ref.on
                                                                          var attrs =
                                                                            ref.attrs
                                                                          return [
                                                                            _c(
                                                                              "p",
                                                                              _vm._g(
                                                                                _vm._b(
                                                                                  {},
                                                                                  "p",
                                                                                  attrs,
                                                                                  false
                                                                                ),
                                                                                on
                                                                              ),
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    item
                                                                                      .keywordMetadata
                                                                                      .text
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "This row represents the number of acquisitions reported by Apple which did not attribute to a specific keyword"
                                                                    ),
                                                                  ]),
                                                                ]
                                                              )
                                                            : _c("p", [
                                                                _c(
                                                                  "a",
                                                                  {
                                                                    on: {
                                                                      click: function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.keywordFilters(
                                                                          item.keywordMetadata,
                                                                          item.metadata
                                                                        )
                                                                      },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item
                                                                          .keywordMetadata
                                                                          .text
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.keywordMetadata.status",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          item.keywordMetadata
                                                            .text != "null"
                                                            ? _c("v-switch", {
                                                                staticClass:
                                                                  "borderedSwitch table-switch",
                                                                attrs: {
                                                                  color:
                                                                    "primary",
                                                                  "true-value":
                                                                    "ACTIVE",
                                                                  "false-value":
                                                                    "PAUSED",
                                                                  inset: "",
                                                                  outlined: "",
                                                                  disabled: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    item
                                                                      .keywordMetadata
                                                                      .status,
                                                                  callback: function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item.keywordMetadata,
                                                                      "status",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "item.keywordMetadata.status",
                                                                },
                                                              })
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.campaignMetadata.countriesOrRegions",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return _vm._l(
                                                          item.campaignMetadata
                                                            .countriesOrRegions,
                                                          function (country) {
                                                            return _c("p", [
                                                              _vm._v(
                                                                _vm._s(country)
                                                              ),
                                                            ])
                                                          }
                                                        )
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.keywordMetadata.deleted",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          item.keywordMetadata
                                                            .deleted &&
                                                          item.keywordMetadata
                                                            .text != "null"
                                                            ? _c(
                                                                "v-chip",
                                                                {
                                                                  staticClass:
                                                                    "ma-2",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "negativeHeaders.deleted"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.keywordMetadata.matchType",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          item.keywordMetadata
                                                            .matchType != null
                                                            ? _c("div", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.keywordMetadata.matchType
                                                                        .charAt(
                                                                          0
                                                                        )
                                                                        .toUpperCase() +
                                                                        item.keywordMetadata.matchType
                                                                          .slice(
                                                                            1
                                                                          )
                                                                          .toLowerCase()
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcGoalsRevenue",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcGoalsRevenue(
                                                                    item,
                                                                    "keywordAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "item.calcGoalRoas",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.calcGoalRoas(
                                                                  item,
                                                                  "keywordAttributionData"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcEventCount",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.calcEventCount(
                                                                  item,
                                                                  "keywordAttributionData"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcCostPerGoal",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcCostPerGoal(
                                                                    item,
                                                                    "keywordAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcRevenuePerGoal",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcRevenuePerGoal(
                                                                    item,
                                                                    "keywordAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "item.calcARPU",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcARPU(
                                                                    item,
                                                                    "keywordAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "item.calcAMPU",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcAMPU(
                                                                    item,
                                                                    "keywordAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcEventCountSec",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.calcEventCountSec(
                                                                  item,
                                                                  "keywordAttributionData"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcCostPerGoalSec",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcCostPerGoalSec(
                                                                    item,
                                                                    "keywordAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.keywordMetadata.bidAmount.amount",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          item.keywordMetadata
                                                            .text != "null"
                                                            ? _c("p", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.getCurrencyIcon(
                                                                        item
                                                                          .orgMetadata
                                                                          .currency
                                                                      ) +
                                                                        "" +
                                                                        Number(
                                                                          item
                                                                            .keywordMetadata
                                                                            .bidAmount
                                                                            .amount
                                                                        )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.insights.bidRecommendation.bidMin.amount",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          item.insights
                                                            .bidRecommendation
                                                            .bidMin != null
                                                            ? _c("div", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.getCurrencyIcon(
                                                                        item
                                                                          .orgMetadata
                                                                          .currency
                                                                      ) +
                                                                        "" +
                                                                        item
                                                                          .insights
                                                                          .bidRecommendation
                                                                          .bidMin
                                                                          .amount
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.insights.bidRecommendation.bidMax.amount",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          item.insights
                                                            .bidRecommendation
                                                            .bidMax != null
                                                            ? _c("div", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.getCurrencyIcon(
                                                                        item
                                                                          .orgMetadata
                                                                          .currency
                                                                      ) +
                                                                        "" +
                                                                        item
                                                                          .insights
                                                                          .bidRecommendation
                                                                          .bidMax
                                                                          .amount
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                  ]),
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-tab-item",
                                          [
                                            _c(
                                              "KeepAlive",
                                              { attrs: { max: "1" } },
                                              [
                                                _c("DataTable", {
                                                  ref: "allKeywordDatatable",
                                                  attrs: {
                                                    loading:
                                                      _vm.$apollo.queries
                                                        .targetingKeywordReportsAll
                                                        .loading,
                                                    items:
                                                      (_vm.allKeywordReports &&
                                                        _vm.allKeywordReports
                                                          .row) ||
                                                      [],
                                                    headers: _vm.keyheaders,
                                                    tab:
                                                      "All-Targeting-Keywords",
                                                    tabInfo: "allkeyword",
                                                    tabindex: _vm.tab,
                                                    customView:
                                                      _vm.customView._id,
                                                    customTab: "keywords",
                                                    itemsLength:
                                                      _vm.allKeywordReportsCount,
                                                    sortBy:
                                                      _vm.AllKeywordSortBy,
                                                    sortDesc:
                                                      _vm.AllKeywordsSortDesc,
                                                  },
                                                  on: {
                                                    persistDatatable:
                                                      _vm.persistDatatable,
                                                    customViewsTable:
                                                      _vm.saveCustomTableViews,
                                                    pageChange: function (
                                                      $event
                                                    ) {
                                                      return _vm.fetchMoreKeywordsAll(
                                                        $event
                                                      )
                                                    },
                                                    allKeywordSearchCols: function (
                                                      $event
                                                    ) {
                                                      return _vm.searchKeywordsAll(
                                                        $event
                                                      )
                                                    },
                                                    downloadReport:
                                                      _vm.downloadReport,
                                                  },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key:
                                                        "item.keywordMetadata.text",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          item.keywordMetadata
                                                            .text === "null"
                                                            ? _c(
                                                                "v-tooltip",
                                                                {
                                                                  attrs: {
                                                                    bottom: "",
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "activator",
                                                                        fn: function (
                                                                          ref
                                                                        ) {
                                                                          var on =
                                                                            ref.on
                                                                          var attrs =
                                                                            ref.attrs
                                                                          return [
                                                                            _c(
                                                                              "p",
                                                                              _vm._g(
                                                                                _vm._b(
                                                                                  {},
                                                                                  "p",
                                                                                  attrs,
                                                                                  false
                                                                                ),
                                                                                on
                                                                              ),
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    item
                                                                                      .keywordMetadata
                                                                                      .text
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "This row represents the number of acquisitions reported by Apple which did not attribute to a specific keyword"
                                                                    ),
                                                                  ]),
                                                                ]
                                                              )
                                                            : _c("p", [
                                                                _c(
                                                                  "a",
                                                                  {
                                                                    on: {
                                                                      click: function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.keywordFilters(
                                                                          item.keywordMetadata,
                                                                          item.metadata
                                                                        )
                                                                      },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item
                                                                          .keywordMetadata
                                                                          .text
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.keywordMetadata.status",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          item.campaignMetadata
                                                            .deleted ||
                                                          (item.keywordMetadata
                                                            .deleted &&
                                                            item.keywordMetadata
                                                              .text != "null")
                                                            ? _c("v-switch", {
                                                                staticClass:
                                                                  "borderedSwitch table-switch",
                                                                attrs: {
                                                                  color:
                                                                    "primary",
                                                                  "true-value":
                                                                    "ACTIVE",
                                                                  "false-value":
                                                                    "PAUSED",
                                                                  inset: "",
                                                                  outlined: "",
                                                                  disabled: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    item
                                                                      .keywordMetadata
                                                                      .status,
                                                                  callback: function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item.keywordMetadata,
                                                                      "status",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "item.keywordMetadata.status",
                                                                },
                                                              })
                                                            : _vm._e(),
                                                          !item.campaignMetadata
                                                            .deleted &&
                                                          !item.keywordMetadata
                                                            .deleted &&
                                                          item.keywordMetadata
                                                            .text != "null"
                                                            ? _c(
                                                                "v-switch",
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "borderedSwitch table-switch",
                                                                    attrs: {
                                                                      color:
                                                                        "primary",
                                                                      "true-value":
                                                                        "ACTIVE",
                                                                      "false-value":
                                                                        "PAUSED",
                                                                      inset: "",
                                                                      outlined:
                                                                        "",
                                                                      readonly:
                                                                        "",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        item
                                                                          .keywordMetadata
                                                                          .status,
                                                                      callback: function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          item.keywordMetadata,
                                                                          "status",
                                                                          $$v
                                                                        )
                                                                      },
                                                                      expression:
                                                                        "item.keywordMetadata.status",
                                                                    },
                                                                  },
                                                                  !_vm.isReadOnly()
                                                                    ? {
                                                                        click: function () {
                                                                          return _vm.askConfirm(
                                                                            item
                                                                              .keywordMetadata
                                                                              .status ==
                                                                              "ACTIVE"
                                                                              ? "PAUSED"
                                                                              : "ACTIVE",
                                                                            item,
                                                                            "keyword"
                                                                          )
                                                                        },
                                                                      }
                                                                    : {
                                                                        click: function () {
                                                                          return _vm.openPermissionReadOnlyPopup()
                                                                        },
                                                                      }
                                                                )
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.keywordMetadata.bidAmount.amount",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          item.keywordMetadata
                                                            .text != "null"
                                                            ? _c(
                                                                "v-row",
                                                                {
                                                                  staticClass:
                                                                    "bidAmount-row d-flex justify-end",
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      staticClass:
                                                                        "no-m-no-p no-cols mr-1",
                                                                      attrs: {
                                                                        cols:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c("p", [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.getCurrencyIcon(
                                                                                item
                                                                                  .orgMetadata
                                                                                  .currency
                                                                              ) +
                                                                                "" +
                                                                                Number(
                                                                                  item
                                                                                    .keywordMetadata
                                                                                    .bidAmount
                                                                                    .amount
                                                                                )
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      staticClass:
                                                                        "no-m-no-p",
                                                                      attrs: {
                                                                        cols:
                                                                          "3",
                                                                      },
                                                                    },
                                                                    [
                                                                      !item
                                                                        .campaignMetadata
                                                                        .deleted &&
                                                                      !item
                                                                        .keywordMetadata
                                                                        .deleted
                                                                        ? _c(
                                                                            "v-btn",
                                                                            _vm._g(
                                                                              {
                                                                                staticClass:
                                                                                  "ml-2 pa-0",
                                                                                attrs: {
                                                                                  "max-width":
                                                                                    "15",
                                                                                  "min-width":
                                                                                    "10",
                                                                                  height:
                                                                                    "20",
                                                                                  text:
                                                                                    "",
                                                                                  depressed:
                                                                                    "",
                                                                                },
                                                                              },
                                                                              !_vm.isReadOnly()
                                                                                ? {
                                                                                    click: function () {
                                                                                      return _vm.openEditBidAmount(
                                                                                        item
                                                                                      )
                                                                                    },
                                                                                  }
                                                                                : {
                                                                                    click: function () {
                                                                                      return _vm.openPermissionReadOnlyPopup()
                                                                                    },
                                                                                  }
                                                                            ),
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  staticClass:
                                                                                    "mr-2",
                                                                                  attrs: {
                                                                                    color:
                                                                                      "primary",
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "mdi-pencil-outline"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _vm._e(),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.campaignMetadata.countriesOrRegions",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return _vm._l(
                                                          item.campaignMetadata
                                                            .countriesOrRegions,
                                                          function (country) {
                                                            return _c(
                                                              "p",
                                                              {
                                                                key:
                                                                  item
                                                                    .campaignMetadata
                                                                    .countriesOrRegions,
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      country
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        )
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.keywordMetadata.deleted",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          item.keywordMetadata
                                                            .deleted &&
                                                          item.keywordMetadata
                                                            .text != "null"
                                                            ? _c(
                                                                "v-chip",
                                                                {
                                                                  staticClass:
                                                                    "ma-2",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "negativeHeaders.deleted"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          !item.keywordMetadata
                                                            .deleted &&
                                                          item.keywordMetadata
                                                            .text != "null"
                                                            ? _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "mx-2",
                                                                    attrs: {
                                                                      icon: "",
                                                                      dark: "",
                                                                      elevation:
                                                                        "0",
                                                                      small: "",
                                                                      color:
                                                                        "primary",
                                                                    },
                                                                  },
                                                                  !_vm.isReadOnly()
                                                                    ? {
                                                                        click: function () {
                                                                          return _vm.openDeleteKeywordDialog(
                                                                            item
                                                                          )
                                                                        },
                                                                      }
                                                                    : {
                                                                        click: function () {
                                                                          return _vm.openPermissionReadOnlyPopup()
                                                                        },
                                                                      }
                                                                ),
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        dark:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-delete-outline"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.keywordMetadata.matchType",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          item.keywordMetadata
                                                            .matchType != null
                                                            ? _c("div", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.keywordMetadata.matchType
                                                                        .charAt(
                                                                          0
                                                                        )
                                                                        .toUpperCase() +
                                                                        item.keywordMetadata.matchType
                                                                          .slice(
                                                                            1
                                                                          )
                                                                          .toLowerCase()
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcGoalsRevenue",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcGoalsRevenue(
                                                                    item,
                                                                    "keywordAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "item.calcGoalRoas",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.calcGoalRoas(
                                                                  item,
                                                                  "keywordAttributionData"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcEventCount",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.calcEventCount(
                                                                  item,
                                                                  "keywordAttributionData"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcCostPerGoal",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcCostPerGoal(
                                                                    item,
                                                                    "keywordAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcRevenuePerGoal",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcRevenuePerGoal(
                                                                    item,
                                                                    "keywordAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "item.calcARPU",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcARPU(
                                                                    item,
                                                                    "keywordAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "item.calcAMPU",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcAMPU(
                                                                    item,
                                                                    "keywordAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcEventCountSec",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.calcEventCountSec(
                                                                  item,
                                                                  "keywordAttributionData"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcCostPerGoalSec",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcCostPerGoalSec(
                                                                    item,
                                                                    "keywordAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.insights.bidRecommendation.bidMin.amount",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          item.insights
                                                            .bidRecommendation
                                                            .bidMin != null
                                                            ? _c("div", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.getCurrencyIcon(
                                                                        item
                                                                          .orgMetadata
                                                                          .currency
                                                                      ) +
                                                                        "" +
                                                                        item
                                                                          .insights
                                                                          .bidRecommendation
                                                                          .bidMin
                                                                          .amount
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.insights.bidRecommendation.bidMax.amount",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          item.insights
                                                            .bidRecommendation
                                                            .bidMax != null
                                                            ? _c("div", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.getCurrencyIcon(
                                                                        item
                                                                          .orgMetadata
                                                                          .currency
                                                                      ) +
                                                                        "" +
                                                                        item
                                                                          .insights
                                                                          .bidRecommendation
                                                                          .bidMax
                                                                          .amount
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                  ]),
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("EditAd", {
                              attrs: { adsData: _vm.adsMetadata },
                              on: {
                                adsUpdated: function ($event) {
                                  return _vm.onUpdate()
                                },
                              },
                            }),
                            _c("DeleteAd", {
                              attrs: {
                                adsDetailsForDeletion: _vm.adsDeletionMetadata,
                              },
                              on: {
                                onDeleteAd: function ($event) {
                                  return _vm.onUpdate()
                                },
                              },
                            }),
                            _c(
                              "v-tab-item",
                              [
                                _c(
                                  "v-row",
                                  { staticClass: "d-flex align-start" },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        attrs: {
                                          cols: "12",
                                          lg: "5",
                                          md: "6",
                                          sm: "8",
                                        },
                                      },
                                      [
                                        !_vm.editMode
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "box-header left-0 chart-header-area d-flex align-baseline pl-6 mb-4 max-400",
                                              },
                                              [
                                                _c("v-select", {
                                                  staticClass: "max-275",
                                                  attrs: {
                                                    "item-text": "CustomView",
                                                    "item-value": "_id",
                                                    items:
                                                      _vm.customViews || [],
                                                    attach: "",
                                                    "menu-props": "auto",
                                                    label: "Select",
                                                    "hide-details": "",
                                                    "single-line": "",
                                                    "return-object": "",
                                                    disabled: _vm.isSuperAdmin
                                                      ? false
                                                      : _vm.permissions.includes(
                                                          _vm
                                                            .SubscriptionPermissionsEnums
                                                            .CUSTOMVIEWS
                                                        )
                                                      ? false
                                                      : true,
                                                  },
                                                  on: {
                                                    change:
                                                      _vm.changeCustomView,
                                                  },
                                                  model: {
                                                    value: _vm.customView,
                                                    callback: function ($$v) {
                                                      _vm.customView = $$v
                                                    },
                                                    expression: "customView",
                                                  },
                                                }),
                                                _vm.customView._id != "0"
                                                  ? _c(
                                                      "v-icon",
                                                      {
                                                        staticClass:
                                                          "ml-2 primary--text",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            _vm.editMode = true
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "mdi-pencil-outline"
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.customView._id != "0"
                                                  ? _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "ml-2",
                                                        attrs: {
                                                          color: "primary",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.deleteCustomView(
                                                              _vm.customView
                                                                ._id,
                                                              "ads"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "mdi-delete-outline"
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.customView._id != "0" &&
                                                _vm.customViews.find(function (
                                                  el
                                                ) {
                                                  return (
                                                    el._id == _vm.customView._id
                                                  )
                                                }).PinnedStatus != true
                                                  ? _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: {
                                                          bottom: "",
                                                          color: "primarydark",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                var attrs =
                                                                  ref.attrs
                                                                return [
                                                                  _c(
                                                                    "v-icon",
                                                                    _vm._g(
                                                                      _vm._b(
                                                                        {
                                                                          staticClass:
                                                                            "ml-2 primary--text",
                                                                          on: {
                                                                            click: function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.pinnedCustomView(
                                                                                true
                                                                              )
                                                                            },
                                                                          },
                                                                        },
                                                                        "v-icon",
                                                                        attrs,
                                                                        false
                                                                      ),
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-pin-outline"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          2813756920
                                                        ),
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v("Pin View"),
                                                        ]),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.customView._id != "0" &&
                                                _vm.customViews.find(function (
                                                  el
                                                ) {
                                                  return (
                                                    el._id == _vm.customView._id
                                                  )
                                                }).PinnedStatus == true
                                                  ? _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: {
                                                          bottom: "",
                                                          color: "primarydark",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                var attrs =
                                                                  ref.attrs
                                                                return [
                                                                  _c(
                                                                    "v-icon",
                                                                    _vm._g(
                                                                      _vm._b(
                                                                        {
                                                                          staticClass:
                                                                            "ml-2 primary--text",
                                                                          on: {
                                                                            click: function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.pinnedCustomView(
                                                                                false
                                                                              )
                                                                            },
                                                                          },
                                                                        },
                                                                        "v-icon",
                                                                        attrs,
                                                                        false
                                                                      ),
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-pin-off-outline"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          1877317105
                                                        ),
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v("Unpin View"),
                                                        ]),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.editMode
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "box-header chart-header-area edit-field d-flex align-baseline pl-6 mt-3",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "max-275" },
                                                  [
                                                    _c("v-text-field", {
                                                      staticClass: "max-275",
                                                      class: {
                                                        "error--text":
                                                          _vm.errorEditViewMessage,
                                                      },
                                                      attrs: {
                                                        outlined: "",
                                                        dense: "",
                                                        placeholder: "value",
                                                      },
                                                      on: {
                                                        input:
                                                          _vm.validateInput,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.customView
                                                            .CustomView,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.customView,
                                                            "CustomView",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "customView.CustomView",
                                                      },
                                                    }),
                                                    _vm.errorEditViewMessage
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "error--text text-caption ml-3 v-messages__message",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.errorEditViewMessage
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: {
                                                      bottom: "",
                                                      color: "primarydark",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function (ref) {
                                                            var on = ref.on
                                                            var attrs =
                                                              ref.attrs
                                                            return [
                                                              _c(
                                                                "v-icon",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      staticClass:
                                                                        "ml-2 primary--text",
                                                                      class: {
                                                                        "disabled-icon":
                                                                          _vm.errorEditViewMessage,
                                                                      },
                                                                      on: {
                                                                        click: function (
                                                                          $event
                                                                        ) {
                                                                          _vm.errorEditViewMessage
                                                                            ? null
                                                                            : _vm.updateCustomView()
                                                                        },
                                                                      },
                                                                    },
                                                                    "v-icon",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _vm._v(
                                                                    "mdi-checkbox-marked-circle"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      2788561454
                                                    ),
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v("Confirm"),
                                                    ]),
                                                  ]
                                                ),
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: {
                                                      bottom: "",
                                                      color: "primarydark",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function (ref) {
                                                            var on = ref.on
                                                            var attrs =
                                                              ref.attrs
                                                            return [
                                                              _c(
                                                                "v-icon",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      staticClass:
                                                                        "ml-2 primary--text",
                                                                      on: {
                                                                        click:
                                                                          _vm.cancelEditView,
                                                                      },
                                                                    },
                                                                    "v-icon",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _vm._v(
                                                                    "mdi-close-circle"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      2384401514
                                                    ),
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v("Cancel"),
                                                    ]),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                    !_vm.editMode
                                      ? _c(
                                          "v-col",
                                          {
                                            staticClass:
                                              "mt-2 d-flex justify-end align-center",
                                            attrs: {
                                              cols: "12",
                                              lg: "7",
                                              md: "6",
                                              sm: "4",
                                            },
                                          },
                                          [
                                            _vm.customView.CustomView !=
                                            "Default View"
                                              ? _c(
                                                  "v-btn",
                                                  {
                                                    staticClass: " ",
                                                    attrs: {
                                                      color: "primary",
                                                      dark: "",
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.updateCustomView,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { staticClass: "mr-1" },
                                                      [
                                                        _vm._v(
                                                          "mdi-arrow-right"
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" Update View "),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  class: [
                                                    "ml-3",
                                                    _vm.isSuperAdmin
                                                      ? "primary"
                                                      : _vm.permissions.includes(
                                                          _vm
                                                            .SubscriptionPermissionsEnums
                                                            .CUSTOMVIEWS
                                                        )
                                                      ? "primary"
                                                      : "disabled-button",
                                                  ],
                                                  attrs: {
                                                    dark: "",
                                                    position: "relative",
                                                  },
                                                },
                                                _vm.isSuperAdmin
                                                  ? {
                                                      click: function () {
                                                        return _vm.openViewForm()
                                                      },
                                                    }
                                                  : _vm.permissions.includes(
                                                      _vm
                                                        .SubscriptionPermissionsEnums
                                                        .CUSTOMVIEWS
                                                    ) == false
                                                  ? {
                                                      click: function () {
                                                        return _vm.openPermissionPopup()
                                                      },
                                                    }
                                                  : {
                                                      click: function () {
                                                        return _vm.openViewForm()
                                                      },
                                                    }
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  { staticClass: "mr-1" },
                                                  [_vm._v("mdi-plus")]
                                                ),
                                                _vm._v(" Add View "),
                                              ],
                                              1
                                            ),
                                            _vm.showViewForm
                                              ? [
                                                  _c(
                                                    "v-card",
                                                    {
                                                      staticClass:
                                                        "mx-auto d-flex align-baseline px-6 py-5 add-view",
                                                      attrs: { outlined: "" },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "field add-view-field",
                                                        },
                                                        [
                                                          _c("v-text-field", {
                                                            staticClass:
                                                              "w-100",
                                                            class: {
                                                              "error--text":
                                                                _vm.errorViewMessage,
                                                            },
                                                            attrs: {
                                                              required: "",
                                                              outlined: "",
                                                              dense: "",
                                                              placeholder:
                                                                "Enter View name",
                                                            },
                                                            on: {
                                                              input:
                                                                _vm.validateInput,
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.newCustomView,
                                                              callback: function (
                                                                $$v
                                                              ) {
                                                                _vm.newCustomView = $$v
                                                              },
                                                              expression:
                                                                "newCustomView",
                                                            },
                                                          }),
                                                          _vm.errorViewMessage
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "error--text text-caption ml-3 v-messages__message",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.errorViewMessage
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-card-actions",
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                color: _vm.newCustomView
                                                                  ? "primary"
                                                                  : "grey",
                                                                disabled:
                                                                  !_vm.newCustomView ||
                                                                  !_vm.isInputValid,
                                                              },
                                                              on: {
                                                                click: function (
                                                                  $event
                                                                ) {
                                                                  return _vm.saveCustomView(
                                                                    "ads"
                                                                  )
                                                                },
                                                              },
                                                            },
                                                            [_vm._v(" Save ")]
                                                          ),
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                color: "grey",
                                                                depressed: "",
                                                                outlined: "",
                                                              },
                                                              on: {
                                                                click: function (
                                                                  $event
                                                                ) {
                                                                  _vm.showViewForm = false
                                                                },
                                                              },
                                                            },
                                                            [_vm._v(" Cancel ")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              : _vm._e(),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-card",
                                  { attrs: { flat: "" } },
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              lg: "6",
                                              md: "12",
                                              sm: "12",
                                            },
                                          },
                                          [
                                            _c(
                                              "KeepAlive",
                                              { attrs: { max: "1" } },
                                              [
                                                _vm.currentTab ===
                                                "product_page_tab"
                                                  ? _c("Chart", {
                                                      attrs: {
                                                        timeLineEntries:
                                                          _vm.timeLineEntries,
                                                        chartdata:
                                                          _vm.productPagesChartData,
                                                        options:
                                                          _vm.chartOptions,
                                                        tabInfo: "ads",
                                                        customView:
                                                          _vm.customView._id,
                                                        granularity:
                                                          _vm.chartGranularity,
                                                        loading:
                                                          _vm.$apollo.queries
                                                            .productPagesGraph
                                                            .loading,
                                                      },
                                                      on: {
                                                        granularityChanged:
                                                          _vm.handleGranularityChange,
                                                        persistDatasets:
                                                          _vm.persistDatasets,
                                                        customViews:
                                                          _vm.saveCustomViews,
                                                        granularityWithTab:
                                                          _vm.saveGranularityToCustomView,
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              lg: "6",
                                              md: "12",
                                              sm: "12",
                                            },
                                          },
                                          [
                                            _c(
                                              "KeepAlive",
                                              { attrs: { max: "1" } },
                                              [
                                                _vm.currentTab ===
                                                "product_page_tab"
                                                  ? _c("TotalSummaryAds", {
                                                      attrs: {
                                                        tab: _vm.tab,
                                                        values:
                                                          _vm.adsTotalSummary,
                                                        rowItems:
                                                          (_vm.productPageReport &&
                                                            _vm
                                                              .productPageReport
                                                              .row) ||
                                                          [],
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "actions-col pt-5" },
                                      [
                                        _c(
                                          "v-item-group",
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c("v-col", {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "6",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-center mt-8 mb-4 custom-tab-outer",
                                      },
                                      [
                                        _c(
                                          "v-tabs",
                                          {
                                            staticClass: "custom-tab",
                                            attrs: {
                                              "center-active": "",
                                              "show-arrows": "",
                                              "background-color": "transparent",
                                            },
                                            model: {
                                              value: _vm.adListingTab,
                                              callback: function ($$v) {
                                                _vm.adListingTab = $$v
                                              },
                                              expression: "adListingTab",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-tab",
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("subTabs.live") +
                                                        " " +
                                                        _vm.$t("tabs.ad")
                                                    ) +
                                                    " "
                                                ),
                                                _c(
                                                  "v-avatar",
                                                  {
                                                    staticClass: "mx-3",
                                                    attrs: {
                                                      color: "primary",
                                                      size: "26",
                                                    },
                                                  },
                                                  [
                                                    !_vm.$apollo.queries
                                                      .productPageReportsLive
                                                      .loading
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "white--text caption",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.productPageReportCount
                                                              )
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm.$apollo.queries
                                                      .productPageReportsLive
                                                      .loading
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "white--text caption",
                                                          },
                                                          [_vm._v("...")]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-tab",
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("subTabs.paused") +
                                                        " " +
                                                        _vm.$t("tabs.ad")
                                                    ) +
                                                    " "
                                                ),
                                                _c(
                                                  "v-avatar",
                                                  {
                                                    staticClass: "mx-3",
                                                    attrs: {
                                                      color: "primary",
                                                      size: "26",
                                                    },
                                                  },
                                                  [
                                                    !_vm.$apollo.queries
                                                      .productPageReportsPaused
                                                      .loading
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "white--text caption",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.adPauseReportCount
                                                              )
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm.$apollo.queries
                                                      .productPageReportsPaused
                                                      .loading
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "white--text caption",
                                                          },
                                                          [_vm._v("...")]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-tab",
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "subTabs.deleted"
                                                      ) +
                                                        " " +
                                                        _vm.$t("tabs.ad")
                                                    ) +
                                                    " "
                                                ),
                                                _c(
                                                  "v-avatar",
                                                  {
                                                    staticClass: "mx-3",
                                                    attrs: {
                                                      color: "primary",
                                                      size: "26",
                                                    },
                                                  },
                                                  [
                                                    !_vm.$apollo.queries
                                                      .productPageReportsDeleted
                                                      .loading
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "white--text caption",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.adDeletedReportCount
                                                              )
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm.$apollo.queries
                                                      .productPageReportsDeleted
                                                      .loading
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "white--text caption",
                                                          },
                                                          [_vm._v("...")]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-tab",
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("subTabs.all") +
                                                        " " +
                                                        _vm.$t("tabs.ad")
                                                    ) +
                                                    " "
                                                ),
                                                _c(
                                                  "v-avatar",
                                                  {
                                                    staticClass: "mx-3",
                                                    attrs: {
                                                      color: "primary",
                                                      size: "26",
                                                    },
                                                  },
                                                  [
                                                    !_vm.$apollo.queries
                                                      .productPageReportsAll
                                                      .loading
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "white--text caption",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.adAllReportCount
                                                              )
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm.$apollo.queries
                                                      .productPageReportsAll
                                                      .loading
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "white--text caption",
                                                          },
                                                          [_vm._v("...")]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-tabs-items",
                                      {
                                        staticClass: "show-full-content",
                                        model: {
                                          value: _vm.adListingTab,
                                          callback: function ($$v) {
                                            _vm.adListingTab = $$v
                                          },
                                          expression: "adListingTab",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-tab-item",
                                          [
                                            _c(
                                              "KeepAlive",
                                              { attrs: { max: "1" } },
                                              [
                                                _c("DataTable", {
                                                  ref: "adsDatatable",
                                                  attrs: {
                                                    loading:
                                                      _vm.$apollo.queries
                                                        .productPageReportsLive
                                                        .loading,
                                                    items:
                                                      (_vm.productPageReport &&
                                                        _vm.productPageReport
                                                          .row) ||
                                                      [],
                                                    headers: _vm.adsHeaders,
                                                    tab: "liveads",
                                                    tabInfo: "ads",
                                                    tabindex: _vm.tab,
                                                    customView:
                                                      _vm.customView._id,
                                                    customTab: "ads",
                                                    itemsLength:
                                                      _vm.productPageReportCount,
                                                    sortBy: _vm.LiveAdsSortBy,
                                                    sortDesc:
                                                      _vm.LiveAdsSortDesc,
                                                  },
                                                  on: {
                                                    persistDatatable:
                                                      _vm.persistDatatable,
                                                    customViewsTable:
                                                      _vm.saveCustomTableViews,
                                                    pageChange: function (
                                                      $event
                                                    ) {
                                                      return _vm.fetchRowsPerPageAds(
                                                        $event
                                                      )
                                                    },
                                                    adsSearchLiveCols: function (
                                                      $event
                                                    ) {
                                                      return _vm.searchLiveAds(
                                                        $event
                                                      )
                                                    },
                                                    downloadReport:
                                                      _vm.downloadReport,
                                                  },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key:
                                                        "item.adsMetadata.deleted",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex align-center justify-end",
                                                            },
                                                            [
                                                              item.adsMetadata &&
                                                              item.adsMetadata
                                                                .deleted ==
                                                                false
                                                                ? _c(
                                                                    "v-icon",
                                                                    _vm._g(
                                                                      {
                                                                        staticClass:
                                                                          "mr-2",
                                                                        attrs: {
                                                                          color:
                                                                            "primary",
                                                                          disabled:
                                                                            item.adTypeRow ===
                                                                            "DEFAULT",
                                                                        },
                                                                      },
                                                                      !_vm.isReadOnly()
                                                                        ? {
                                                                            click: function () {
                                                                              return _vm.openEditAdWizard(
                                                                                item
                                                                              )
                                                                            },
                                                                          }
                                                                        : {
                                                                            click: function () {
                                                                              return _vm.openPermissionReadOnlyPopup()
                                                                            },
                                                                          }
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-pencil-outline"
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              item.adsMetadata &&
                                                              item.adsMetadata
                                                                .deleted ==
                                                                false
                                                                ? _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        staticClass:
                                                                          "ml-2",
                                                                        attrs: {
                                                                          icon:
                                                                            "",
                                                                          dark:
                                                                            "",
                                                                          elevation:
                                                                            "0",
                                                                          small:
                                                                            "",
                                                                          color:
                                                                            "primary",
                                                                          disabled:
                                                                            item.adTypeRow ===
                                                                            "DEFAULT",
                                                                        },
                                                                      },
                                                                      !_vm.isReadOnly()
                                                                        ? {
                                                                            click: function () {
                                                                              return _vm.openDeleteAdDialog(
                                                                                item
                                                                              )
                                                                            },
                                                                          }
                                                                        : {
                                                                            click: function () {
                                                                              return _vm.openPermissionReadOnlyPopup()
                                                                            },
                                                                          }
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            dark:
                                                                              "",
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-delete-outline"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcGoalsRevenue",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcGoalsRevenue(
                                                                    item,
                                                                    "productPageAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "item.calcGoalRoas",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.calcGoalRoas(
                                                                  item,
                                                                  "productPageAttributionData"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcEventCount",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.calcEventCount(
                                                                  item,
                                                                  "productPageAttributionData"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcCostPerGoal",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcCostPerGoal(
                                                                    item,
                                                                    "productPageAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcRevenuePerGoal",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcRevenuePerGoal(
                                                                    item,
                                                                    "productPageAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "item.calcARPU",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcARPU(
                                                                    item,
                                                                    "productPageAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "item.calcAMPU",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcAMPU(
                                                                    item,
                                                                    "productPageAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcEventCountSec",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.calcEventCountSec(
                                                                  item,
                                                                  "productPageAttributionData"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcCostPerGoalSec",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcCostPerGoalSec(
                                                                    item,
                                                                    "productPageAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ]),
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-tab-item",
                                          [
                                            _c(
                                              "KeepAlive",
                                              { attrs: { max: "1" } },
                                              [
                                                _c("DataTable", {
                                                  ref: "pausedAdsDatatable",
                                                  attrs: {
                                                    loading:
                                                      _vm.$apollo.queries
                                                        .productPageReportsPaused
                                                        .loading,
                                                    items:
                                                      (_vm.adPauseReport &&
                                                        _vm.adPauseReport
                                                          .row) ||
                                                      [],
                                                    headers: _vm.adsHeaders,
                                                    tab: "pausedads",
                                                    tabInfo: "pausedads",
                                                    tabindex: _vm.tab,
                                                    customView:
                                                      _vm.customView._id,
                                                    customTab: "ads",
                                                    itemsLength:
                                                      _vm.adPauseReportCount,
                                                    sortBy: _vm.PausedAdsSortBy,
                                                    sortDesc:
                                                      _vm.PausedAdsSortDesc,
                                                  },
                                                  on: {
                                                    persistDatatable:
                                                      _vm.persistDatatable,
                                                    customViewsTable:
                                                      _vm.saveCustomTableViews,
                                                    pageChange: function (
                                                      $event
                                                    ) {
                                                      return _vm.fetchRowsAdsPaused(
                                                        $event
                                                      )
                                                    },
                                                    adsdSearchPausedCols: function (
                                                      $event
                                                    ) {
                                                      return _vm.searchPausedAds(
                                                        $event
                                                      )
                                                    },
                                                    downloadReport:
                                                      _vm.downloadReport,
                                                  },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key:
                                                        "item.adsMetadata.deleted",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex align-center justify-end",
                                                            },
                                                            [
                                                              item.adsMetadata &&
                                                              item.adsMetadata
                                                                .deleted ==
                                                                false
                                                                ? _c(
                                                                    "v-icon",
                                                                    _vm._g(
                                                                      {
                                                                        staticClass:
                                                                          "mr-2",
                                                                        attrs: {
                                                                          color:
                                                                            "primary",
                                                                          disabled:
                                                                            item.adTypeRow ===
                                                                            "DEFAULT",
                                                                        },
                                                                      },
                                                                      !_vm.isReadOnly()
                                                                        ? {
                                                                            click: function () {
                                                                              return _vm.openEditAdWizard(
                                                                                item
                                                                              )
                                                                            },
                                                                          }
                                                                        : {
                                                                            click: function () {
                                                                              return _vm.openPermissionReadOnlyPopup()
                                                                            },
                                                                          }
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-pencil-outline"
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              item.adsMetadata &&
                                                              item.adsMetadata
                                                                .deleted ==
                                                                false
                                                                ? _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        staticClass:
                                                                          "ml-2",
                                                                        attrs: {
                                                                          icon:
                                                                            "",
                                                                          dark:
                                                                            "",
                                                                          elevation:
                                                                            "0",
                                                                          small:
                                                                            "",
                                                                          color:
                                                                            "primary",
                                                                          disabled:
                                                                            item.adTypeRow ===
                                                                            "DEFAULT",
                                                                        },
                                                                      },
                                                                      !_vm.isReadOnly()
                                                                        ? {
                                                                            click: function () {
                                                                              return _vm.openDeleteAdDialog(
                                                                                item
                                                                              )
                                                                            },
                                                                          }
                                                                        : {
                                                                            click: function () {
                                                                              return _vm.openPermissionReadOnlyPopup()
                                                                            },
                                                                          }
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            dark:
                                                                              "",
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-delete-outline"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcGoalsRevenue",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcGoalsRevenue(
                                                                    item,
                                                                    "productPageAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "item.calcGoalRoas",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.calcGoalRoas(
                                                                  item,
                                                                  "productPageAttributionData"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcEventCount",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.calcEventCount(
                                                                  item,
                                                                  "productPageAttributionData"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcCostPerGoal",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcCostPerGoal(
                                                                    item,
                                                                    "productPageAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcRevenuePerGoal",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcRevenuePerGoal(
                                                                    item,
                                                                    "productPageAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "item.calcARPU",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcARPU(
                                                                    item,
                                                                    "productPageAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "item.calcAMPU",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcAMPU(
                                                                    item,
                                                                    "productPageAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcEventCountSec",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.calcEventCountSec(
                                                                  item,
                                                                  "productPageAttributionData"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcCostPerGoalSec",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcCostPerGoalSec(
                                                                    item,
                                                                    "productPageAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ]),
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-tab-item",
                                          [
                                            _c(
                                              "KeepAlive",
                                              { attrs: { max: "1" } },
                                              [
                                                _c("DataTable", {
                                                  ref: "deletedAdsDatatable",
                                                  attrs: {
                                                    loading:
                                                      _vm.$apollo.queries
                                                        .productPageReportsDeleted
                                                        .loading,
                                                    items:
                                                      (_vm.adDeletedReport &&
                                                        _vm.adDeletedReport
                                                          .row) ||
                                                      [],
                                                    headers: _vm.adsHeaders,
                                                    tab: "deletedads",
                                                    tabInfo: "deletedads",
                                                    tabindex: _vm.tab,
                                                    customView:
                                                      _vm.customView._id,
                                                    customTab: "ads",
                                                    itemsLength:
                                                      _vm.adDeletedReportCount,
                                                    sortBy:
                                                      _vm.DeletedAdsSortBy,
                                                    sortDesc:
                                                      _vm.DeletedAdsSortDesc,
                                                  },
                                                  on: {
                                                    persistDatatable:
                                                      _vm.persistDatatable,
                                                    customViewsTable:
                                                      _vm.saveCustomTableViews,
                                                    pageChange: function (
                                                      $event
                                                    ) {
                                                      return _vm.fetchRowsAdDeleted(
                                                        $event
                                                      )
                                                    },
                                                    adsdSearchDeletedCols: function (
                                                      $event
                                                    ) {
                                                      return _vm.searchDeletedAds(
                                                        $event
                                                      )
                                                    },
                                                    downloadReport:
                                                      _vm.downloadReport,
                                                  },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key:
                                                        "item.adsMetadata.deleted",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          item.adsMetadata &&
                                                          item.adsMetadata
                                                            .deleted == false
                                                            ? _c(
                                                                "v-icon",
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "mr-2",
                                                                    attrs: {
                                                                      color:
                                                                        "primary",
                                                                      disabled:
                                                                        item.adTypeRow ===
                                                                        "DEFAULT",
                                                                    },
                                                                  },
                                                                  !_vm.isReadOnly()
                                                                    ? {
                                                                        click: function () {
                                                                          return _vm.openEditAdWizard(
                                                                            item
                                                                          )
                                                                        },
                                                                      }
                                                                    : {
                                                                        click: function () {
                                                                          return _vm.openPermissionReadOnlyPopup()
                                                                        },
                                                                      }
                                                                ),
                                                                [
                                                                  _vm._v(
                                                                    "mdi-pencil-outline"
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          item.adsMetadata &&
                                                          item.adsMetadata
                                                            .deleted == false
                                                            ? _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "ml-2",
                                                                    attrs: {
                                                                      icon: "",
                                                                      dark: "",
                                                                      elevation:
                                                                        "0",
                                                                      small: "",
                                                                      color:
                                                                        "primary",
                                                                      disabled:
                                                                        item.adTypeRow ===
                                                                        "DEFAULT",
                                                                    },
                                                                  },
                                                                  !_vm.isReadOnly()
                                                                    ? {
                                                                        click: function () {
                                                                          return _vm.openDeleteAdDialog(
                                                                            item
                                                                          )
                                                                        },
                                                                      }
                                                                    : {
                                                                        click: function () {
                                                                          return _vm.openPermissionReadOnlyPopup()
                                                                        },
                                                                      }
                                                                ),
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        dark:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-delete-outline"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcGoalsRevenue",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcGoalsRevenue(
                                                                    item,
                                                                    "productPageAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "item.calcGoalRoas",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.calcGoalRoas(
                                                                  item,
                                                                  "productPageAttributionData"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcEventCount",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.calcEventCount(
                                                                  item,
                                                                  "productPageAttributionData"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcCostPerGoal",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcCostPerGoal(
                                                                    item,
                                                                    "productPageAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcRevenuePerGoal",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcRevenuePerGoal(
                                                                    item,
                                                                    "productPageAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "item.calcARPU",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcARPU(
                                                                    item,
                                                                    "productPageAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "item.calcAMPU",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcAMPU(
                                                                    item,
                                                                    "productPageAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcEventCountSec",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.calcEventCountSec(
                                                                  item,
                                                                  "productPageAttributionData"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcCostPerGoalSec",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcCostPerGoalSec(
                                                                    item,
                                                                    "productPageAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ]),
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-tab-item",
                                          [
                                            _c(
                                              "KeepAlive",
                                              { attrs: { max: "1" } },
                                              [
                                                _c("DataTable", {
                                                  ref: "allAdsDatatable",
                                                  attrs: {
                                                    loading:
                                                      _vm.$apollo.queries
                                                        .productPageReportsAll
                                                        .loading,
                                                    items:
                                                      (_vm.adAllReport &&
                                                        _vm.adAllReport.row) ||
                                                      [],
                                                    headers: _vm.adsHeaders,
                                                    tab: "allads",
                                                    tabInfo: "allads",
                                                    tabindex: _vm.tab,
                                                    customView:
                                                      _vm.customView._id,
                                                    customTab: "ads",
                                                    itemsLength:
                                                      _vm.adAllReportCount,
                                                    sortBy: _vm.AllAdsSortBy,
                                                    sortDesc:
                                                      _vm.AllAdsSortDesc,
                                                  },
                                                  on: {
                                                    persistDatatable:
                                                      _vm.persistDatatable,
                                                    customViewsTable:
                                                      _vm.saveCustomTableViews,
                                                    pageChange: function (
                                                      $event
                                                    ) {
                                                      return _vm.fetchRowsAdAll(
                                                        $event
                                                      )
                                                    },
                                                    adsdSearchAllCols: function (
                                                      $event
                                                    ) {
                                                      return _vm.searchAllAds(
                                                        $event
                                                      )
                                                    },
                                                    downloadReport:
                                                      _vm.downloadReport,
                                                  },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key:
                                                        "item.adsMetadata.deleted",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex align-center justify-end",
                                                            },
                                                            [
                                                              item.adsMetadata &&
                                                              item.adsMetadata
                                                                .deleted ==
                                                                false
                                                                ? _c(
                                                                    "v-icon",
                                                                    _vm._g(
                                                                      {
                                                                        staticClass:
                                                                          "mr-2",
                                                                        attrs: {
                                                                          color:
                                                                            "primary",
                                                                          disabled:
                                                                            item.adTypeRow ===
                                                                            "DEFAULT",
                                                                        },
                                                                      },
                                                                      !_vm.isReadOnly()
                                                                        ? {
                                                                            click: function () {
                                                                              return _vm.openEditAdWizard(
                                                                                item
                                                                              )
                                                                            },
                                                                          }
                                                                        : {
                                                                            click: function () {
                                                                              return _vm.openPermissionReadOnlyPopup()
                                                                            },
                                                                          }
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-pencil-outline"
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              item.adsMetadata &&
                                                              item.adsMetadata
                                                                .deleted ==
                                                                false
                                                                ? _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        staticClass:
                                                                          "ml-2",
                                                                        attrs: {
                                                                          icon:
                                                                            "",
                                                                          dark:
                                                                            "",
                                                                          elevation:
                                                                            "0",
                                                                          small:
                                                                            "",
                                                                          color:
                                                                            "primary",
                                                                          disabled:
                                                                            item.adTypeRow ===
                                                                            "DEFAULT",
                                                                        },
                                                                      },
                                                                      !_vm.isReadOnly()
                                                                        ? {
                                                                            click: function () {
                                                                              return _vm.openDeleteAdDialog(
                                                                                item
                                                                              )
                                                                            },
                                                                          }
                                                                        : {
                                                                            click: function () {
                                                                              return _vm.openPermissionReadOnlyPopup()
                                                                            },
                                                                          }
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            dark:
                                                                              "",
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-delete-outline"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcGoalsRevenue",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcGoalsRevenue(
                                                                    item,
                                                                    "productPageAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "item.calcGoalRoas",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.calcGoalRoas(
                                                                  item,
                                                                  "productPageAttributionData"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcEventCount",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.calcEventCount(
                                                                  item,
                                                                  "productPageAttributionData"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcCostPerGoal",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcCostPerGoal(
                                                                    item,
                                                                    "productPageAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcRevenuePerGoal",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcRevenuePerGoal(
                                                                    item,
                                                                    "productPageAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "item.calcARPU",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcARPU(
                                                                    item,
                                                                    "productPageAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "item.calcAMPU",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcAMPU(
                                                                    item,
                                                                    "productPageAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcEventCountSec",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.calcEventCountSec(
                                                                  item,
                                                                  "productPageAttributionData"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.calcCostPerGoalSec",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCurrencyIcon(
                                                                  item
                                                                    .orgMetadata
                                                                    .currency
                                                                ) +
                                                                  "" +
                                                                  _vm.calcCostPerGoalSec(
                                                                    item,
                                                                    "productPageAttributionData"
                                                                  )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ]),
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-tab-item",
                              [
                                _c(
                                  "v-row",
                                  { staticClass: "d-flex align-start" },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        attrs: {
                                          cols: "12",
                                          lg: "5",
                                          md: "6",
                                          sm: "8",
                                        },
                                      },
                                      [
                                        !_vm.editMode
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "box-header left-0 chart-header-area d-flex align-baseline pl-6 mb-4 max-400",
                                              },
                                              [
                                                _c("v-select", {
                                                  staticClass: "max-275",
                                                  attrs: {
                                                    "item-text": "CustomView",
                                                    "item-value": "_id",
                                                    items:
                                                      _vm.customViews || [],
                                                    attach: "",
                                                    "menu-props": "auto",
                                                    label: "Select",
                                                    "hide-details": "",
                                                    "single-line": "",
                                                    "return-object": "",
                                                    disabled: _vm.isSuperAdmin
                                                      ? false
                                                      : _vm.permissions.includes(
                                                          _vm
                                                            .SubscriptionPermissionsEnums
                                                            .CUSTOMVIEWS
                                                        )
                                                      ? false
                                                      : true,
                                                  },
                                                  on: {
                                                    change:
                                                      _vm.changeCustomView,
                                                  },
                                                  model: {
                                                    value: _vm.customView,
                                                    callback: function ($$v) {
                                                      _vm.customView = $$v
                                                    },
                                                    expression: "customView",
                                                  },
                                                }),
                                                _vm.customView._id != "0"
                                                  ? _c(
                                                      "v-icon",
                                                      {
                                                        staticClass:
                                                          "ml-2 primary--text",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            _vm.editMode = true
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "mdi-pencil-outline"
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.customView._id != "0"
                                                  ? _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "ml-2",
                                                        attrs: {
                                                          color: "primary",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.deleteCustomView(
                                                              _vm.customView
                                                                ._id,
                                                              "searchterm"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "mdi-delete-outline"
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.customView._id != "0" &&
                                                _vm.customViews.find(function (
                                                  el
                                                ) {
                                                  return (
                                                    el._id == _vm.customView._id
                                                  )
                                                }).PinnedStatus != true
                                                  ? _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: {
                                                          bottom: "",
                                                          color: "primarydark",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                var attrs =
                                                                  ref.attrs
                                                                return [
                                                                  _c(
                                                                    "v-icon",
                                                                    _vm._g(
                                                                      _vm._b(
                                                                        {
                                                                          staticClass:
                                                                            "ml-2 primary--text",
                                                                          on: {
                                                                            click: function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.pinnedCustomView(
                                                                                true
                                                                              )
                                                                            },
                                                                          },
                                                                        },
                                                                        "v-icon",
                                                                        attrs,
                                                                        false
                                                                      ),
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-pin-outline"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          2813756920
                                                        ),
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v("Pin View"),
                                                        ]),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.customView._id != "0" &&
                                                _vm.customViews.find(function (
                                                  el
                                                ) {
                                                  return (
                                                    el._id == _vm.customView._id
                                                  )
                                                }).PinnedStatus == true
                                                  ? _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: {
                                                          bottom: "",
                                                          color: "primarydark",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                var attrs =
                                                                  ref.attrs
                                                                return [
                                                                  _c(
                                                                    "v-icon",
                                                                    _vm._g(
                                                                      _vm._b(
                                                                        {
                                                                          staticClass:
                                                                            "ml-2 primary--text",
                                                                          on: {
                                                                            click: function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.pinnedCustomView(
                                                                                false
                                                                              )
                                                                            },
                                                                          },
                                                                        },
                                                                        "v-icon",
                                                                        attrs,
                                                                        false
                                                                      ),
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-pin-off-outline"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          1877317105
                                                        ),
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v("Unpin View"),
                                                        ]),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.editMode
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "box-header chart-header-area edit-field d-flex align-baseline pl-6 mt-3",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "max-275" },
                                                  [
                                                    _c("v-text-field", {
                                                      staticClass: "max-275",
                                                      class: {
                                                        "error--text":
                                                          _vm.errorEditViewMessage,
                                                      },
                                                      attrs: {
                                                        outlined: "",
                                                        dense: "",
                                                        placeholder: "value",
                                                      },
                                                      on: {
                                                        input:
                                                          _vm.validateInput,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.customView
                                                            .CustomView,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.customView,
                                                            "CustomView",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "customView.CustomView",
                                                      },
                                                    }),
                                                    _vm.errorEditViewMessage
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "error--text text-caption ml-3 v-messages__message",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.errorEditViewMessage
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: {
                                                      bottom: "",
                                                      color: "primarydark",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function (ref) {
                                                            var on = ref.on
                                                            var attrs =
                                                              ref.attrs
                                                            return [
                                                              _c(
                                                                "v-icon",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      staticClass:
                                                                        "ml-2 primary--text",
                                                                      class: {
                                                                        "disabled-icon":
                                                                          _vm.errorEditViewMessage,
                                                                      },
                                                                      on: {
                                                                        click: function (
                                                                          $event
                                                                        ) {
                                                                          _vm.errorEditViewMessage
                                                                            ? null
                                                                            : _vm.updateCustomView()
                                                                        },
                                                                      },
                                                                    },
                                                                    "v-icon",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _vm._v(
                                                                    "mdi-checkbox-marked-circle"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      2788561454
                                                    ),
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v("Confirm"),
                                                    ]),
                                                  ]
                                                ),
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: {
                                                      bottom: "",
                                                      color: "primarydark",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function (ref) {
                                                            var on = ref.on
                                                            var attrs =
                                                              ref.attrs
                                                            return [
                                                              _c(
                                                                "v-icon",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      directives: [
                                                                        {
                                                                          name:
                                                                            "icon",
                                                                          rawName:
                                                                            "v-icon",
                                                                        },
                                                                      ],
                                                                      staticClass:
                                                                        "ml-2 primary--text",
                                                                      on: {
                                                                        click:
                                                                          _vm.cancelEditView,
                                                                      },
                                                                    },
                                                                    "v-icon",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _vm._v(
                                                                    "mdi-close-circle"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      2818181131
                                                    ),
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v("Cancel"),
                                                    ]),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                    !_vm.editMode
                                      ? _c(
                                          "v-col",
                                          {
                                            staticClass:
                                              "mt-2 d-flex justify-end align-center",
                                            attrs: {
                                              cols: "12",
                                              lg: "7",
                                              md: "6",
                                              sm: "4",
                                            },
                                          },
                                          [
                                            _vm.customView.CustomView !=
                                            "Default View"
                                              ? _c(
                                                  "v-btn",
                                                  {
                                                    staticClass: " ",
                                                    attrs: {
                                                      color: "primary",
                                                      dark: "",
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.updateCustomView,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { staticClass: "mr-1" },
                                                      [
                                                        _vm._v(
                                                          "mdi-arrow-right"
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" Update View "),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  class: [
                                                    "ml-3",
                                                    _vm.isSuperAdmin
                                                      ? "primary"
                                                      : _vm.permissions.includes(
                                                          _vm
                                                            .SubscriptionPermissionsEnums
                                                            .CUSTOMVIEWS
                                                        )
                                                      ? "primary"
                                                      : "disabled-button",
                                                  ],
                                                  attrs: {
                                                    dark: "",
                                                    position: "relative",
                                                  },
                                                },
                                                _vm.isSuperAdmin
                                                  ? {
                                                      click: function () {
                                                        return _vm.openViewForm()
                                                      },
                                                    }
                                                  : _vm.permissions.includes(
                                                      _vm
                                                        .SubscriptionPermissionsEnums
                                                        .CUSTOMVIEWS
                                                    ) == false
                                                  ? {
                                                      click: function () {
                                                        return _vm.openPermissionPopup()
                                                      },
                                                    }
                                                  : {
                                                      click: function () {
                                                        return _vm.openViewForm()
                                                      },
                                                    }
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  { staticClass: "mr-1" },
                                                  [_vm._v("mdi-plus")]
                                                ),
                                                _vm._v(" Add View "),
                                              ],
                                              1
                                            ),
                                            _vm.showViewForm
                                              ? [
                                                  _c(
                                                    "v-card",
                                                    {
                                                      staticClass:
                                                        "mx-auto d-flex align-baseline px-6 py-5 add-view",
                                                      attrs: { outlined: "" },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "field add-view-field",
                                                        },
                                                        [
                                                          _c("v-text-field", {
                                                            staticClass:
                                                              "w-100",
                                                            class: {
                                                              "error--text":
                                                                _vm.errorViewMessage,
                                                            },
                                                            attrs: {
                                                              required: "",
                                                              outlined: "",
                                                              dense: "",
                                                              placeholder:
                                                                "Enter View name",
                                                            },
                                                            on: {
                                                              input:
                                                                _vm.validateInput,
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.newCustomView,
                                                              callback: function (
                                                                $$v
                                                              ) {
                                                                _vm.newCustomView = $$v
                                                              },
                                                              expression:
                                                                "newCustomView",
                                                            },
                                                          }),
                                                          _vm.errorViewMessage
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "error--text text-caption ml-3 v-messages__message",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.errorViewMessage
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-card-actions",
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                color: _vm.newCustomView
                                                                  ? "primary"
                                                                  : "grey",
                                                                disabled:
                                                                  !_vm.newCustomView ||
                                                                  !_vm.isInputValid,
                                                              },
                                                              on: {
                                                                click: function (
                                                                  $event
                                                                ) {
                                                                  return _vm.saveCustomView(
                                                                    "searchterm"
                                                                  )
                                                                },
                                                              },
                                                            },
                                                            [_vm._v(" Save ")]
                                                          ),
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                color: "grey",
                                                                depressed: "",
                                                                outlined: "",
                                                              },
                                                              on: {
                                                                click: function (
                                                                  $event
                                                                ) {
                                                                  _vm.showViewForm = false
                                                                },
                                                              },
                                                            },
                                                            [_vm._v(" Cancel ")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              : _vm._e(),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-card",
                                  { attrs: { flat: "" } },
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              lg: "6",
                                              md: "12",
                                              sm: "12",
                                            },
                                          },
                                          [
                                            _c(
                                              "KeepAlive",
                                              { attrs: { max: "1" } },
                                              [
                                                _vm.currentTab ===
                                                "search_terms_tab"
                                                  ? _c("Chart", {
                                                      attrs: {
                                                        timeLineEntries:
                                                          _vm.timeLineEntries,
                                                        chartdata:
                                                          _vm.searchTermsChartData,
                                                        options:
                                                          _vm.chartOptions,
                                                        tabInfo: "searchterm",
                                                        customView:
                                                          _vm.customView._id,
                                                        granularity:
                                                          _vm.chartGranularity,
                                                        loading:
                                                          _vm.$apollo.queries
                                                            .searchTermsGraph
                                                            .loading,
                                                      },
                                                      on: {
                                                        granularityChanged:
                                                          _vm.handleGranularityChange,
                                                        persistDatasets:
                                                          _vm.persistDatasets,
                                                        customViews:
                                                          _vm.saveCustomViews,
                                                        granularityWithTab:
                                                          _vm.saveGranularityToCustomView,
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              lg: "6",
                                              md: "12",
                                              sm: "12",
                                            },
                                          },
                                          [
                                            _c(
                                              "KeepAlive",
                                              { attrs: { max: "1" } },
                                              [
                                                _vm.currentTab ===
                                                "search_terms_tab"
                                                  ? _c("TotalSummary", {
                                                      attrs: {
                                                        tab: _vm.tab,
                                                        values:
                                                          (_vm.searchTermsReport &&
                                                            _vm
                                                              .searchTermsReport
                                                              .grandTotals &&
                                                            _vm
                                                              .searchTermsReport
                                                              .grandTotals
                                                              .total) ||
                                                          _vm.searchTermsReports,
                                                        rowItems:
                                                          (_vm.searchTermsReport &&
                                                            _vm
                                                              .searchTermsReport
                                                              .row) ||
                                                          [],
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "actions-col pt-5" },
                                      [
                                        _c(
                                          "v-item-group",
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c("v-col", {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "6",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "KeepAlive",
                                      { attrs: { max: "1" } },
                                      [
                                        _c("DataTable", {
                                          ref: "searchtermDatatable",
                                          attrs: {
                                            loading:
                                              _vm.$apollo.queries
                                                .searchTermsReports.loading,
                                            items:
                                              (_vm.filteredSearchTermsReports &&
                                                _vm.filteredSearchTermsReports
                                                  .row) ||
                                              [],
                                            headers: _vm.searchTermsHeader,
                                            tab: "Search-Terms",
                                            tabInfo: "searchterm",
                                            tabindex: _vm.tab,
                                            customView: _vm.customView._id,
                                            customTab: "searchterm",
                                            itemsLength:
                                              _vm.productSearchTermCount,
                                            sortBy:
                                              _vm.searchTermsReportsSortBy,
                                            sortDesc:
                                              _vm.searchTermsReportsSortDesc,
                                          },
                                          on: {
                                            persistDatatable:
                                              _vm.persistDatatable,
                                            customViewsTable:
                                              _vm.saveCustomTableViews,
                                            searchSearchTermCols: function (
                                              $event
                                            ) {
                                              return _vm.fetchSearchTerms(
                                                $event
                                              )
                                            },
                                            pageChange: function ($event) {
                                              return _vm.fetchMoreSearchTerms(
                                                $event
                                              )
                                            },
                                            downloadReport: _vm.downloadReport,
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "item.calcGoalsRevenue",
                                              fn: function (ref) {
                                                var item = ref.item
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.getCurrencyIcon(
                                                          item.orgMetadata
                                                            .currency
                                                        ) +
                                                          "" +
                                                          _vm.calcGoalsRevenue(
                                                            item,
                                                            "searchTermAttributionData"
                                                          )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                            },
                                            {
                                              key: "item.calcGoalRoas",
                                              fn: function (ref) {
                                                var item = ref.item
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.calcGoalRoas(
                                                          item,
                                                          "searchTermAttributionData"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                            },
                                            {
                                              key: "item.calcEventCount",
                                              fn: function (ref) {
                                                var item = ref.item
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.calcEventCount(
                                                          item,
                                                          "searchTermAttributionData"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                            },
                                            {
                                              key: "item.calcCostPerGoal",
                                              fn: function (ref) {
                                                var item = ref.item
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.getCurrencyIcon(
                                                          item.orgMetadata
                                                            .currency
                                                        ) +
                                                          "" +
                                                          _vm.calcCostPerGoal(
                                                            item,
                                                            "searchTermAttributionData"
                                                          )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                            },
                                            {
                                              key: "item.calcRevenuePerGoal",
                                              fn: function (ref) {
                                                var item = ref.item
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.getCurrencyIcon(
                                                          item.orgMetadata
                                                            .currency
                                                        ) +
                                                          "" +
                                                          _vm.calcRevenuePerGoal(
                                                            item,
                                                            "searchTermAttributionData"
                                                          )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                            },
                                            {
                                              key: "item.calcARPU",
                                              fn: function (ref) {
                                                var item = ref.item
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.getCurrencyIcon(
                                                          item.orgMetadata
                                                            .currency
                                                        ) +
                                                          "" +
                                                          _vm.calcARPU(
                                                            item,
                                                            "searchTermAttributionData"
                                                          )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                            },
                                            {
                                              key: "item.calcAMPU",
                                              fn: function (ref) {
                                                var item = ref.item
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.getCurrencyIcon(
                                                          item.orgMetadata
                                                            .currency
                                                        ) +
                                                          "" +
                                                          _vm.calcAMPU(
                                                            item,
                                                            "searchTermAttributionData"
                                                          )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                            },
                                            {
                                              key: "item.calcEventCountSec",
                                              fn: function (ref) {
                                                var item = ref.item
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.calcEventCountSec(
                                                          item,
                                                          "searchTermAttributionData"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                            },
                                            {
                                              key: "item.calcCostPerGoalSec",
                                              fn: function (ref) {
                                                var item = ref.item
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.getCurrencyIcon(
                                                          item.orgMetadata
                                                            .currency
                                                        ) +
                                                          "" +
                                                          _vm.calcCostPerGoalSec(
                                                            item,
                                                            "searchTermAttributionData"
                                                          )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-tab-item",
                              [
                                _c(
                                  "v-row",
                                  { staticClass: "d-flex align-start" },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        attrs: {
                                          cols: "12",
                                          lg: "5",
                                          md: "6",
                                          sm: "8",
                                        },
                                      },
                                      [
                                        !_vm.editMode
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "box-header left-0 chart-header-area d-flex align-baseline pl-6 mb-4 max-400",
                                              },
                                              [
                                                _c("v-select", {
                                                  staticClass: "max-275",
                                                  attrs: {
                                                    "item-text": "CustomView",
                                                    "item-value": "_id",
                                                    items:
                                                      _vm.customViews || [],
                                                    attach: "",
                                                    "menu-props": "auto",
                                                    label: "Select",
                                                    "hide-details": "",
                                                    "single-line": "",
                                                    "return-object": "",
                                                    disabled: _vm.isSuperAdmin
                                                      ? false
                                                      : _vm.permissions.includes(
                                                          _vm
                                                            .SubscriptionPermissionsEnums
                                                            .CUSTOMVIEWS
                                                        )
                                                      ? false
                                                      : true,
                                                  },
                                                  on: {
                                                    change:
                                                      _vm.changeCustomView,
                                                  },
                                                  model: {
                                                    value: _vm.customView,
                                                    callback: function ($$v) {
                                                      _vm.customView = $$v
                                                    },
                                                    expression: "customView",
                                                  },
                                                }),
                                                _vm.customView._id != "0"
                                                  ? _c(
                                                      "v-icon",
                                                      {
                                                        staticClass:
                                                          "ml-2 primary--text",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            _vm.editMode = true
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "mdi-pencil-outline"
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.customView._id != "0"
                                                  ? _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "ml-2",
                                                        attrs: {
                                                          color: "primary",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.deleteCustomView(
                                                              _vm.customView
                                                                ._id,
                                                              "negativekeywords"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "mdi-delete-outline"
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.customView._id != "0" &&
                                                _vm.customViews.find(function (
                                                  el
                                                ) {
                                                  return (
                                                    el._id == _vm.customView._id
                                                  )
                                                }).PinnedStatus != true
                                                  ? _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: {
                                                          bottom: "",
                                                          color: "primarydark",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                var attrs =
                                                                  ref.attrs
                                                                return [
                                                                  _c(
                                                                    "v-icon",
                                                                    _vm._g(
                                                                      _vm._b(
                                                                        {
                                                                          staticClass:
                                                                            "ml-2 primary--text",
                                                                          on: {
                                                                            click: function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.pinnedCustomView(
                                                                                true
                                                                              )
                                                                            },
                                                                          },
                                                                        },
                                                                        "v-icon",
                                                                        attrs,
                                                                        false
                                                                      ),
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-pin-outline"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          2813756920
                                                        ),
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v("Pin View"),
                                                        ]),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.customView._id != "0" &&
                                                _vm.customViews.find(function (
                                                  el
                                                ) {
                                                  return (
                                                    el._id == _vm.customView._id
                                                  )
                                                }).PinnedStatus == true
                                                  ? _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: {
                                                          bottom: "",
                                                          color: "primarydark",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                var attrs =
                                                                  ref.attrs
                                                                return [
                                                                  _c(
                                                                    "v-icon",
                                                                    _vm._g(
                                                                      _vm._b(
                                                                        {
                                                                          staticClass:
                                                                            "ml-2 primary--text",
                                                                          on: {
                                                                            click: function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.pinnedCustomView(
                                                                                false
                                                                              )
                                                                            },
                                                                          },
                                                                        },
                                                                        "v-icon",
                                                                        attrs,
                                                                        false
                                                                      ),
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-pin-off-outline"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          1877317105
                                                        ),
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v("Unpin View"),
                                                        ]),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.editMode
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "box-header chart-header-area edit-field d-flex align-baseline pl-6 mt-3",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "max-275" },
                                                  [
                                                    _c("v-text-field", {
                                                      staticClass: "max-275",
                                                      class: {
                                                        "error--text":
                                                          _vm.errorEditViewMessage,
                                                      },
                                                      attrs: {
                                                        outlined: "",
                                                        dense: "",
                                                        placeholder: "value",
                                                      },
                                                      on: {
                                                        input:
                                                          _vm.validateInput,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.customView
                                                            .CustomView,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.customView,
                                                            "CustomView",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "customView.CustomView",
                                                      },
                                                    }),
                                                    _vm.errorEditViewMessage
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "error--text text-caption ml-3 v-messages__message",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.errorEditViewMessage
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: {
                                                      bottom: "",
                                                      color: "primarydark",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function (ref) {
                                                            var on = ref.on
                                                            var attrs =
                                                              ref.attrs
                                                            return [
                                                              _c(
                                                                "v-icon",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      staticClass:
                                                                        "ml-2 primary--text",
                                                                      class: {
                                                                        "disabled-icon":
                                                                          _vm.errorEditViewMessage,
                                                                      },
                                                                      on: {
                                                                        click: function (
                                                                          $event
                                                                        ) {
                                                                          _vm.errorEditViewMessage
                                                                            ? null
                                                                            : _vm.updateCustomView()
                                                                        },
                                                                      },
                                                                    },
                                                                    "v-icon",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _vm._v(
                                                                    "mdi-checkbox-marked-circle"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      2788561454
                                                    ),
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v("Confirm"),
                                                    ]),
                                                  ]
                                                ),
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: {
                                                      bottom: "",
                                                      color: "primarydark",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function (ref) {
                                                            var on = ref.on
                                                            var attrs =
                                                              ref.attrs
                                                            return [
                                                              _c(
                                                                "v-icon",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      staticClass:
                                                                        "ml-2 primary--text",
                                                                      on: {
                                                                        click:
                                                                          _vm.cancelEditView,
                                                                      },
                                                                    },
                                                                    "v-icon",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _vm._v(
                                                                    "mdi-close-circle"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      2384401514
                                                    ),
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v("Cancel"),
                                                    ]),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                    !_vm.editMode
                                      ? _c(
                                          "v-col",
                                          {
                                            staticClass:
                                              "mt-2 d-flex justify-end align-center",
                                            attrs: {
                                              cols: "12",
                                              lg: "7",
                                              md: "6",
                                              sm: "4",
                                            },
                                          },
                                          [
                                            _vm.customView.CustomView !=
                                            "Default View"
                                              ? _c(
                                                  "v-btn",
                                                  {
                                                    staticClass: " ",
                                                    attrs: {
                                                      color: "primary",
                                                      dark: "",
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.updateCustomView,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { staticClass: "mr-1" },
                                                      [
                                                        _vm._v(
                                                          "mdi-arrow-right"
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" Update View "),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  class: [
                                                    "ml-3",
                                                    _vm.isSuperAdmin
                                                      ? "primary"
                                                      : _vm.permissions.includes(
                                                          _vm
                                                            .SubscriptionPermissionsEnums
                                                            .CUSTOMVIEWS
                                                        )
                                                      ? "primary"
                                                      : "disabled-button",
                                                  ],
                                                  attrs: {
                                                    dark: "",
                                                    position: "relative",
                                                  },
                                                },
                                                _vm.isSuperAdmin
                                                  ? {
                                                      click: function () {
                                                        return _vm.openViewForm()
                                                      },
                                                    }
                                                  : _vm.permissions.includes(
                                                      _vm
                                                        .SubscriptionPermissionsEnums
                                                        .CUSTOMVIEWS
                                                    ) == false
                                                  ? {
                                                      click: function () {
                                                        return _vm.openPermissionPopup()
                                                      },
                                                    }
                                                  : {
                                                      click: function () {
                                                        return _vm.openViewForm()
                                                      },
                                                    }
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  { staticClass: "mr-1" },
                                                  [_vm._v("mdi-plus")]
                                                ),
                                                _vm._v(" Add View "),
                                              ],
                                              1
                                            ),
                                            _vm.showViewForm
                                              ? [
                                                  _c(
                                                    "v-card",
                                                    {
                                                      staticClass:
                                                        "mx-auto d-flex align-baseline px-6 py-5 add-view",
                                                      attrs: { outlined: "" },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "field add-view-field",
                                                        },
                                                        [
                                                          _c("v-text-field", {
                                                            staticClass:
                                                              "w-100",
                                                            class: {
                                                              "error--text":
                                                                _vm.errorViewMessage,
                                                            },
                                                            attrs: {
                                                              required: "",
                                                              outlined: "",
                                                              dense: "",
                                                              placeholder:
                                                                "Enter View name",
                                                            },
                                                            on: {
                                                              input:
                                                                _vm.validateInput,
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.newCustomView,
                                                              callback: function (
                                                                $$v
                                                              ) {
                                                                _vm.newCustomView = $$v
                                                              },
                                                              expression:
                                                                "newCustomView",
                                                            },
                                                          }),
                                                          _vm.errorViewMessage
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "error--text text-caption ml-3 v-messages__message",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.errorViewMessage
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-card-actions",
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                color: _vm.newCustomView
                                                                  ? "primary"
                                                                  : "grey",
                                                                disabled:
                                                                  !_vm.newCustomView ||
                                                                  !_vm.isInputValid,
                                                              },
                                                              on: {
                                                                click: function (
                                                                  $event
                                                                ) {
                                                                  return _vm.saveCustomView(
                                                                    "negativekeywords"
                                                                  )
                                                                },
                                                              },
                                                            },
                                                            [_vm._v(" Save ")]
                                                          ),
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                color: "grey",
                                                                depressed: "",
                                                                outlined: "",
                                                              },
                                                              on: {
                                                                click: function (
                                                                  $event
                                                                ) {
                                                                  _vm.showViewForm = false
                                                                },
                                                              },
                                                            },
                                                            [_vm._v(" Cancel ")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              : _vm._e(),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-card",
                                  { attrs: { flat: "" } },
                                  [
                                    _c("AddNegativeKeywords", {
                                      attrs: {
                                        openNegativeKeyword:
                                          _vm.openAddNegativeKeywords,
                                      },
                                      on: {
                                        onAdd: function ($event) {
                                          return _vm.onUpdate()
                                        },
                                      },
                                    }),
                                    _c("DeleteNegKey", {
                                      attrs: {
                                        negKeyDeletionData:
                                          _vm.negKeyDeletionData,
                                      },
                                      on: {
                                        onDeletion: function ($event) {
                                          return _vm.onDeletingNegKey()
                                        },
                                      },
                                    }),
                                    _c(
                                      "v-row",
                                      {
                                        staticClass:
                                          "d-flex align-center mb-4 pt-2 custom-tab-outer",
                                      },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              lg: "9",
                                              md: "9",
                                              sm: "8",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-tabs",
                                              {
                                                staticClass: "custom-tab",
                                                attrs: {
                                                  "center-active": "",
                                                  "show-arrows": "",
                                                  "background-color":
                                                    "transparent",
                                                },
                                                model: {
                                                  value: _vm.negKeyListingTab,
                                                  callback: function ($$v) {
                                                    _vm.negKeyListingTab = $$v
                                                  },
                                                  expression:
                                                    "negKeyListingTab",
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-tab",
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "subTabs.live"
                                                          ) +
                                                            " " +
                                                            _vm.$t(
                                                              "subTabs.negKey"
                                                            )
                                                        ) +
                                                        " "
                                                    ),
                                                    _c(
                                                      "v-avatar",
                                                      {
                                                        staticClass: "mx-3",
                                                        attrs: {
                                                          color: "primary",
                                                          size: "26",
                                                        },
                                                      },
                                                      [
                                                        !_vm.$apollo.queries
                                                          .negativeKeywordReportsLive
                                                          .loading
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "white--text caption",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.filteredNegKeywordReportsCount
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm.$apollo.queries
                                                          .negativeKeywordReportsLive
                                                          .loading
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "white--text caption",
                                                              },
                                                              [_vm._v("...")]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-tab",
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "subTabs.paused"
                                                          ) +
                                                            " " +
                                                            _vm.$t(
                                                              "subTabs.negKey"
                                                            )
                                                        ) +
                                                        " "
                                                    ),
                                                    _c(
                                                      "v-avatar",
                                                      {
                                                        staticClass: "mx-3",
                                                        attrs: {
                                                          color: "primary",
                                                          size: "26",
                                                        },
                                                      },
                                                      [
                                                        !_vm.$apollo.queries
                                                          .negativeKeywordReportsPaused
                                                          .loading
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "white--text caption",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.disabledNegKeywordReportsCount
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm.$apollo.queries
                                                          .negativeKeywordReportsPaused
                                                          .loading
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "white--text caption",
                                                              },
                                                              [_vm._v("...")]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-tab",
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "subTabs.deleted"
                                                          ) +
                                                            " " +
                                                            _vm.$t(
                                                              "subTabs.negKey"
                                                            )
                                                        ) +
                                                        " "
                                                    ),
                                                    _c(
                                                      "v-avatar",
                                                      {
                                                        staticClass: "mx-3",
                                                        attrs: {
                                                          color: "primary",
                                                          size: "26",
                                                        },
                                                      },
                                                      [
                                                        !_vm.$apollo.queries
                                                          .negativeKeywordReportsDeleted
                                                          .loading
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "white--text caption",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.deletedNegKeywordReportsCount
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm.$apollo.queries
                                                          .negativeKeywordReportsDeleted
                                                          .loading
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "white--text caption",
                                                              },
                                                              [_vm._v("...")]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-tab",
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "subTabs.all"
                                                          ) +
                                                            " " +
                                                            _vm.$t(
                                                              "subTabs.negKey"
                                                            )
                                                        ) +
                                                        " "
                                                    ),
                                                    _c(
                                                      "v-avatar",
                                                      {
                                                        staticClass: "mx-3",
                                                        attrs: {
                                                          color: "primary",
                                                          size: "26",
                                                        },
                                                      },
                                                      [
                                                        !_vm.$apollo.queries
                                                          .negativeKeywordReportsAll
                                                          .loading
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "white--text caption",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.allNegKeywordReportsCount
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm.$apollo.queries
                                                          .negativeKeywordReportsAll
                                                          .loading
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "white--text caption",
                                                              },
                                                              [_vm._v("...")]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "d-flex justify-end",
                                            attrs: {
                                              cols: "12",
                                              lg: "3",
                                              md: "3",
                                              sm: "4",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  staticClass:
                                                    "w-sm-100 min-width-156",
                                                  attrs: {
                                                    depressed: "",
                                                    dense: "",
                                                    color: "primary",
                                                  },
                                                },
                                                !_vm.isReadOnly()
                                                  ? {
                                                      click: function () {
                                                        return _vm.openAddNegativeKeyword()
                                                      },
                                                    }
                                                  : {
                                                      click: function () {
                                                        return _vm.openPermissionReadOnlyPopup()
                                                      },
                                                    }
                                              ),
                                              [
                                                _c("v-icon", [
                                                  _vm._v("mdi-plus"),
                                                ]),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("popups.add") +
                                                        " " +
                                                        _vm.$t("popups.negKey")
                                                    ) +
                                                    " "
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-tabs-items",
                                      {
                                        staticClass: "show-full-content",
                                        model: {
                                          value: _vm.negKeyListingTab,
                                          callback: function ($$v) {
                                            _vm.negKeyListingTab = $$v
                                          },
                                          expression: "negKeyListingTab",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-tab-item",
                                          [
                                            _c(
                                              "KeepAlive",
                                              { attrs: { max: "1" } },
                                              [
                                                _c("DataTable", {
                                                  ref: "livenegativeDatatable",
                                                  attrs: {
                                                    loading:
                                                      _vm.$apollo.queries
                                                        .negativeKeywordReportsLive
                                                        .loading,
                                                    items:
                                                      (_vm.negKeywordReport &&
                                                        _vm.negKeywordReport
                                                          .row) ||
                                                      [],
                                                    headers:
                                                      _vm.negativeHeaders,
                                                    tab:
                                                      "Live-Negative-Keywords",
                                                    tabInfo:
                                                      "livenegativekeyword",
                                                    tabindex: _vm.tab,
                                                    customView:
                                                      _vm.customView._id,
                                                    customTab:
                                                      "negativekeywords",
                                                    itemsLength:
                                                      _vm.filteredNegKeywordReportsCount,
                                                    sortBy:
                                                      _vm.LiveNegtiveKeySortBy,
                                                    sortDesc:
                                                      _vm.LiveNegtiveKeySortDesc,
                                                  },
                                                  on: {
                                                    persistDatatable:
                                                      _vm.persistDatatable,
                                                    customViewsTable:
                                                      _vm.saveCustomTableViews,
                                                    pageChange: function (
                                                      $event
                                                    ) {
                                                      return _vm.fetchMoreNegativeKeywordsLive(
                                                        $event
                                                      )
                                                    },
                                                    negKeywordSearchLiveCols: function (
                                                      $event
                                                    ) {
                                                      return _vm.fetchSearchNegativekeywordsLive(
                                                        $event
                                                      )
                                                    },
                                                    downloadReport:
                                                      _vm.downloadReport,
                                                  },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key: "item.Action",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "mx-0",
                                                                attrs: {
                                                                  icon: "",
                                                                  dark: "",
                                                                  small: "",
                                                                  color:
                                                                    "primary",
                                                                },
                                                              },
                                                              !_vm.isReadOnly()
                                                                ? {
                                                                    click: function () {
                                                                      return _vm.openDeleteNegKeyDialog(
                                                                        item
                                                                      )
                                                                    },
                                                                  }
                                                                : {
                                                                    click: function () {
                                                                      return _vm.openPermissionReadOnlyPopup()
                                                                    },
                                                                  }
                                                            ),
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    dark: "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-delete-outline"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.modificationTime",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.modificationTime.substr(
                                                                  0,
                                                                  10
                                                                )
                                                              ) +
                                                              " " +
                                                              _vm._s(
                                                                item.modificationTime.substr(
                                                                  11,
                                                                  5
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ]),
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-tab-item",
                                          [
                                            _c(
                                              "KeepAlive",
                                              { attrs: { max: "1" } },
                                              [
                                                _c("DataTable", {
                                                  ref:
                                                    "pausedNegativeKeywordsDatatable",
                                                  attrs: {
                                                    loading:
                                                      _vm.$apollo.queries
                                                        .negativeKeywordReportsPaused
                                                        .loading,
                                                    items:
                                                      (_vm.disabledNegKeywordReports &&
                                                        _vm
                                                          .disabledNegKeywordReports
                                                          .row) ||
                                                      [],
                                                    headers:
                                                      _vm.negativeHeaders,
                                                    tab:
                                                      "Disabled-Negative-Keywords",
                                                    tabInfo:
                                                      "pausednegativekeyword",
                                                    tabindex: _vm.tab,
                                                    customView:
                                                      _vm.customView._id,
                                                    customTab:
                                                      "negativekeywords",
                                                    itemsLength:
                                                      _vm.disabledNegKeywordReportsCount,
                                                    sortBy:
                                                      _vm.PausedNegtiveKeySortBy,
                                                    sortDesc:
                                                      _vm.PausedNegtiveKeySortDesc,
                                                  },
                                                  on: {
                                                    persistDatatable:
                                                      _vm.persistDatatable,
                                                    customViewsTable:
                                                      _vm.saveCustomTableViews,
                                                    pageChange: function (
                                                      $event
                                                    ) {
                                                      return _vm.fetchMoreNegativeKeywordsPaused(
                                                        $event
                                                      )
                                                    },
                                                    negKeywordSearchPausedCols: function (
                                                      $event
                                                    ) {
                                                      return _vm.fetchSearchNegativekeywordsPaused(
                                                        $event
                                                      )
                                                    },
                                                    downloadReport:
                                                      _vm.downloadReport,
                                                  },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key: "item.Action",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "mx-0",
                                                                attrs: {
                                                                  icon: "",
                                                                  dark: "",
                                                                  small: "",
                                                                  color:
                                                                    "primary",
                                                                },
                                                              },
                                                              !_vm.isReadOnly()
                                                                ? {
                                                                    click: function () {
                                                                      return _vm.openDeleteNegKeyDialog(
                                                                        item
                                                                      )
                                                                    },
                                                                  }
                                                                : {
                                                                    click: function () {
                                                                      return _vm.openPermissionReadOnlyPopup()
                                                                    },
                                                                  }
                                                            ),
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    dark: "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-delete-outline"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.modificationTime",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.modificationTime.substr(
                                                                  0,
                                                                  10
                                                                )
                                                              ) +
                                                              " " +
                                                              _vm._s(
                                                                item.modificationTime.substr(
                                                                  11,
                                                                  5
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ]),
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-tab-item",
                                          [
                                            _c(
                                              "KeepAlive",
                                              { attrs: { max: "1" } },
                                              [
                                                _c("DataTable", {
                                                  ref:
                                                    "deletednegativeDatatable",
                                                  attrs: {
                                                    loading:
                                                      _vm.$apollo.queries
                                                        .negativeKeywordReportsDeleted
                                                        .loading,
                                                    items:
                                                      (_vm.deletedNegKeywordReports &&
                                                        _vm
                                                          .deletedNegKeywordReports
                                                          .row) ||
                                                      [],
                                                    headers:
                                                      _vm.negativeHeaders,
                                                    tab:
                                                      "Deleted-Negative-Keywords",
                                                    tabInfo:
                                                      "deletednegativekeyword",
                                                    tabindex: _vm.tab,
                                                    customView:
                                                      _vm.customView._id,
                                                    customTab:
                                                      "negativekeywords",
                                                    itemsLength:
                                                      _vm.deletedNegKeywordReportsCount,
                                                    sortBy:
                                                      _vm.DeletedNegtiveKeySortBy,
                                                    sortDesc:
                                                      _vm.DeletedNegtiveKeySortDesc,
                                                  },
                                                  on: {
                                                    persistDatatable:
                                                      _vm.persistDatatable,
                                                    customViewsTable:
                                                      _vm.saveCustomTableViews,
                                                    pageChange: function (
                                                      $event
                                                    ) {
                                                      return _vm.fetchMoreNegativeKeywordsDeleted(
                                                        $event
                                                      )
                                                    },
                                                    negKeywordSearchDeletedCols: function (
                                                      $event
                                                    ) {
                                                      return _vm.fetchSearchNegativekeywordsDeleted(
                                                        $event
                                                      )
                                                    },
                                                    downloadReport:
                                                      _vm.downloadReport,
                                                  },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key: "item.Action",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _c(
                                                            "v-chip",
                                                            {
                                                              staticClass:
                                                                "ma-0",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "negativeHeaders.deleted"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.modificationTime",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm._f(
                                                                "localTime"
                                                              )(
                                                                item.modificationTime
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ]),
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-tab-item",
                                          [
                                            _c(
                                              "KeepAlive",
                                              { attrs: { max: "1" } },
                                              [
                                                _c("DataTable", {
                                                  ref: "allnegativeDatatable",
                                                  attrs: {
                                                    loading:
                                                      _vm.$apollo.queries
                                                        .negativeKeywordReportsAll
                                                        .loading,
                                                    items:
                                                      (_vm.allNegKeywordReports &&
                                                        _vm.allNegKeywordReports
                                                          .row) ||
                                                      [],
                                                    headers:
                                                      _vm.negativeHeaders,
                                                    tab:
                                                      "All-Negative-Keywords",
                                                    tabInfo:
                                                      "allnegativekeyword",
                                                    tabindex: _vm.tab,
                                                    customView:
                                                      _vm.customView._id,
                                                    customTab:
                                                      "negativekeywords",
                                                    itemsLength:
                                                      _vm.allNegKeywordReportsCount,
                                                    sortBy:
                                                      _vm.AllNegtiveKeySortBy,
                                                    sortDesc:
                                                      _vm.AllNegtiveKeySortDesc,
                                                  },
                                                  on: {
                                                    persistDatatable:
                                                      _vm.persistDatatable,
                                                    customViewsTable:
                                                      _vm.saveCustomTableViews,
                                                    pageChange: function (
                                                      $event
                                                    ) {
                                                      return _vm.fetchMoreNegativeKeywordsAll(
                                                        $event
                                                      )
                                                    },
                                                    negKeywordSearchAllCols: function (
                                                      $event
                                                    ) {
                                                      return _vm.fetchSearchNegativekeywordsAll(
                                                        $event
                                                      )
                                                    },
                                                    downloadReport:
                                                      _vm.downloadReport,
                                                  },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key: "item.Action",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "mx-0",
                                                                attrs: {
                                                                  icon: "",
                                                                  dark: "",
                                                                  small: "",
                                                                  color:
                                                                    "primary",
                                                                },
                                                              },
                                                              !_vm.isReadOnly()
                                                                ? {
                                                                    click: function () {
                                                                      return _vm.openDeleteNegKeyDialog(
                                                                        item
                                                                      )
                                                                    },
                                                                  }
                                                                : {
                                                                    click: function () {
                                                                      return _vm.openPermissionReadOnlyPopup()
                                                                    },
                                                                  }
                                                            ),
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    dark: "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-delete-outline"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key:
                                                        "item.modificationTime",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.modificationTime.substr(
                                                                  0,
                                                                  10
                                                                )
                                                              ) +
                                                              " " +
                                                              _vm._s(
                                                                item.modificationTime.substr(
                                                                  11,
                                                                  5
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ]),
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "KeepAlive",
        { attrs: { max: "1" } },
        [
          _c("PermissionPopup", {
            attrs: { permissionMetadata: _vm.permissionMetadata },
          }),
        ],
        1
      ),
      _c(
        "KeepAlive",
        { attrs: { max: "1" } },
        [
          _c("PermissionReadOnlyPopup", {
            attrs: { permissionMetadata: _vm.permissionReadOnlyMetadata },
          }),
        ],
        1
      ),
      _c(
        "KeepAlive",
        { attrs: { max: "1" } },
        [
          _c("DecisionAlert", {
            attrs: {
              DecisionAlert: _vm.decisionAlertFlag,
              DecisionAlertTitle: _vm.decisionAlertTitle,
              DecisionAlertMessage: _vm.decisionAlertMessage,
              DecisionAlertData: _vm.decisionAlertData,
              DecisionAlertIcon: 3,
            },
            on: {
              emitDecisionAlertCancelClicked: _vm.clearDecisionModel,
              emitDecisionAlertOkClicked: function ($event) {
                return _vm.decisionOkAction($event)
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }