















































































































































































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { controllers } from 'chart.js';
import * as _ from 'lodash';
import { conformsTo } from 'lodash';
import { COUNTRIES_FOR_APP } from 'src/utils/apollo-queries';
import Vue from 'vue';
import { UPDATE_CAMPAIGN } from '../../utils/apollo-mutations';
import { COUNTRIES } from '../../utils/constants';

export default Vue.extend({
  name: 'EditCampaign',
  data() {
    return {
      saving: false,
      show: false,
      adamId: null,
      Countries: COUNTRIES,
      appCountries: [],
      valid: false,
      campaignDetails: {
        name: null,
        countriesOrRegions: [],
        budgetAmount: {
          amount: null,
        },
        appName: null
      },
      dailyBudgetAmount: null,
      dailyBudgetCurrency: null,
      // nameRules: [(v) => !!v || this.$t('createCampaignRules.campRequired')],
      // countryRules: [(v) => !!(v && v.length) || this.$t('createCampaignRules.selectCountry')],
      // budgetRules: [(v) => !!v || this.$t('createCampaignRules.budgetRequired')],
    };
  },

  computed: {
    nameRules: {
      get() {
        return [
          (v) => !!v || this.$t('createCampaignRules.campRequired'),
          (v) => (v && v.length <=199) || this.$t('createCampaignRules.campNameSize')
        ]
      }
    },

    countryRules: {
      get() {
        return [(v) => !!(v && v.length) || this.$t('createCampaignRules.selectCountry')]
      }
    },

    budgetRules: {
      get() {
        return [(v) => !!v || this.$t('createCampaignRules.budgetRequired')]
      }
    },
  },
  props: {
    campaignData: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  apollo: {
    countriesForApp: {
      query: COUNTRIES_FOR_APP,
      variables() {
        return {
          adamId: this.adamId,
        };
      },
      skip() {
        return !this.adamId;
      },
      update(data) {
        this.setCountries(data.countriesForApp);
      },
    },
  },

  watch: {
    campaignData: function (data1) {
      const data = _.cloneDeep(data1)
      this.show = true;
      this.adamId = data.app.adamId;

      this.campaignDetails = _.cloneDeep({
        name: data.name,
        countriesOrRegions: data.countriesOrRegions,
        appName: data.app.appName,
        budgetAmount: {amount: null},
      });

      if (data.dailyBudgetAmount == null) {
        this.dailyBudgetAmount = null;
      } else {
        this.dailyBudgetAmount = data.dailyBudgetAmount.amount;
        this.dailyBudgetCurrency = data.dailyBudgetAmount.currency;
      }
      if (data.budgetAmount == null) {
        this.campaignDetails.budgetAmount.amount = null
      }
      else {
       this.campaignDetails.budgetAmount = data.budgetAmount
      }

    },
  },
  methods: {
    async updateCampaign() {
      this.saving = true;
      const campaignData = {
        name: this.campaignDetails.name,
        countriesOrRegions: this.campaignDetails.countriesOrRegions,
        // budgetAmount: this.campaignDetails.budgetAmount,
        ...(this.campaignDetails.budgetAmount && this.campaignDetails.budgetAmount.amount && {
          budgetAmount:{
            amount: this.campaignDetails.budgetAmount.amount,
            currency: this.campaignDetails.budgetAmount.currency || this.dailyBudgetCurrency
          }
        }),
        ...(this.campaignDetails.budgetAmount && !this.campaignDetails.budgetAmount.amount && {
          budgetAmount:null
        }),
        ...(this.dailyBudgetAmount && {
          dailyBudgetAmount: {
            amount: this.dailyBudgetAmount,
            currency: this.dailyBudgetCurrency || this.campaignDetails.budgetAmount.currency,
          },
        }),
        ...(!this.dailyBudgetAmount && {
          dailyBudgetAmount: null,
        }),
      };
      const campaignRequest = {
        // should be true if countriesOrRegions is changed
        // clearGeoTargetingOnCountryOrRegionChange: !_.isEqual(
        //   this.campaignDetails.countriesOrRegions.sort(),
        //   this.campaignData.countriesOrRegions.sort()
        // ),
        campaign: campaignData,
      };

      const campaignId = this.campaignData.campaignId;
      const orgId = this.campaignData.orgId;

      try {
        const result = await this.$apollo.mutate({
          mutation: UPDATE_CAMPAIGN,
          variables: {
            orgId,
            campaignId,
            campaignRequest,
          },
        });

        if (result.data.updateCampaign.searchAdsErrors) {
          this.$notify.error(result.data.updateCampaign.searchAdsErrors);
        } else {
          this.$notify.success(this.$t('successMsg.campaignUpdate'));
          this.$emit('campaignUpdated');
          this.close();
        }
      } catch {}

      this.saving = false;
    },

    close() {
      this.show = false;
      this.campaignDetails = {
        name: null,
        countriesOrRegions: [],
        budgetAmount: {
          amount: null,
        },
        dailyBudgetAmount: {
          amount: null,
        },
      };
      this.$refs.editCampform.reset();
    },

    setCountries(data) {
      if(data[0]?.countryOrRegionCodes.length > 0) {
        const Countries = data[0].countryOrRegionCodes;
        for (const countryCode of Countries) {
          for (const country of this.Countries) {
            if (country.Code == countryCode) {
              this.appCountries.push(country);
            }
          }
        }
      } else {
        this.appCountries = this.Countries
      }
    },

  
  },
  mounted() {
      this.$root.$on('locale-changed', (l) => {
      //this.$refs.formStep1.resetValidation()
      this.$refs.editCampform.validate();
    });
    },
    beforeDestroy(){
    this.$root.$off('locale-changed');
    }
});
