var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "550", scrollable: "" },
          model: {
            value: _vm.AddnKeysShow,
            callback: function ($$v) {
              _vm.AddnKeysShow = $$v
            },
            expression: "AddnKeysShow",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  staticClass:
                    "headline d-flex justify-space-between px-5 py-3 v-modal-header",
                },
                [
                  _c("h5", { staticClass: "text-h6" }, [
                    _vm._v(" " + _vm._s(_vm.$t("addnKeys.addNegKeywords"))),
                  ]),
                  _c(
                    "v-icon",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.close()
                        },
                      },
                    },
                    [_vm._v("mdi-close")]
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "pa-3" },
                [
                  _c(
                    "div",
                    { staticClass: "pagination d-flex justify-end pa-2" },
                    [
                      _c(
                        "span",
                        { staticClass: "pagination-total primary--text" },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("addnKeys.total")) +
                              " " +
                              _vm._s(_vm.keywords.length)
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "v-card",
                    {
                      staticClass: "pa-2 rounded-0 border-x-0",
                      staticStyle: { border: "none" },
                      attrs: { outlined: "", height: "58" },
                    },
                    [
                      _c("div", { staticClass: "addkeword style-2" }, [
                        _c(
                          "div",
                          { staticClass: "d-flex" },
                          [
                            _c("v-text-field", {
                              staticClass:
                                "rounded-0 rounded-l-lg no-field-details mb-0",
                              attrs: {
                                placeholder:
                                  "Add one or add multiple comma separated",
                                required: "",
                                outlined: "",
                                dense: "",
                              },
                              model: {
                                value: _vm.keywordInput,
                                callback: function ($$v) {
                                  _vm.keywordInput = $$v
                                },
                                expression: "keywordInput",
                              },
                            }),
                            _c(
                              "v-btn",
                              {
                                staticClass:
                                  "rounded-0 rounded-r-lg border-l-0 ml-3",
                                attrs: {
                                  width: "36",
                                  height: "40",
                                  depressed: "",
                                  outlined: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.addKeyword()
                                  },
                                },
                              },
                              [
                                _c("span", { staticClass: "primary--text" }, [
                                  _vm._v(_vm._s(_vm.$t("addnKeys.add"))),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "keword-lists pa-2 v-max-height-300" },
                    _vm._l(_vm.keywords, function (keyword) {
                      return _c(
                        "v-row",
                        { key: keyword.text },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0  pl-0 word-nowrap",
                              attrs: { lg: "4", md: "5", sm: "5" },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "icon-hover primary--text my-0 ",
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteKeyword(keyword.text)
                                    },
                                  },
                                },
                                [_vm._v("mdi-delete-outline")]
                              ),
                              _vm._v(" " + _vm._s(keyword.text) + " "),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0 pt-3 pr-0",
                              attrs: { lg: "8", md: "7", sm: "7" },
                            },
                            [
                              _c("v-select", {
                                staticClass: "no-field-details",
                                attrs: {
                                  items: _vm.matchTypes,
                                  label: _vm.$t("labels.matchType"),
                                  required: "",
                                  outlined: "",
                                  dense: "",
                                },
                                model: {
                                  value: keyword.matchType,
                                  callback: function ($$v) {
                                    _vm.$set(keyword, "matchType", $$v)
                                  },
                                  expression: "keyword.matchType",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "grey", text: "" },
                      on: { click: _vm.emitAddnKeysCancelClicked },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("addnKeys.cancel")) + " ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        text: "",
                        disabled: !_vm.keywords.length,
                      },
                      on: { click: _vm.emitAddnKeysOkClicked },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("addnKeys.save")) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }