import { gql } from 'graphql-tag';

export const ADS_MANAGER_AD_GROUP_ALL = gql`
  query adGroupReportsAll(
    $filter: Filters!
    $offset: Float!
    $limit: Float!
    $sortBy: String!
    $sortDesc: Boolean!
    $adGroupSearch: adGroupSearchReq!
    $getGoals: Boolean!
  ) {
    adGroupReportsAll(
      filter: $filter
      offset: $offset
      limit: $limit
      sortBy: $sortBy
      sortDesc: $sortDesc
      adGroupSearch: $adGroupSearch
    ) {
      adgroupcounts {
        alladgroupcount
        liveadgroupcount
        pausedadgroupcount
        deletedadgroupcount
      }
      filterCount
      grandTotals {
        total {
          installs
          newDownloads
          redownloads
          ttr
          taps
          impressions
          localSpend {
            amount
          }
        }
      }
      row {
        total {
          localSpend {
            amount
          }
          avgCPA {
            amount
          }
          avgCPT {
            amount
          }
          avgCPM {
            amount
          }
          impressions
          taps
          installs
          ttr
          conversionRate
          newDownloads
          redownloads
          latOnInstalls
          latOffInstalls
        }
        metadata {
          # campaignStatus,
          # displayStatus,
          app {
            adamId
            appName
          }
          campaignId
          orgId
          adGroupId
          # campaignName,
          # countriesOrRegions
          adGroupDisplayStatus
        }
        orgMetadata {
          orgName
          orgId
          currency
        }
        campaignMetadata {
          id
          name
          adamId
          status
          countriesOrRegions
          deleted
          adChannelType
          supplySources
        }
        adgroupAttributionData(filter: $filter, getGoals: $getGoals) {
          successMessage
          content
          secondaryContent
        }
        adgroupMetadata {
          id
          name
          orgId
          campaignId
          status
          deleted
          displayStatus
          startTime
          endTime
          automatedKeywordsOptIn
          defaultBidAmount {
            currency
            amount
          }
          cpaGoal {
            currency
            amount
          }
          targetingDimensions {
            adminArea {
              included
            }
            appDownloaders {
              included
              excluded
              value
            }
            appCategories {
              included
              excluded
            }
            country {
              included
            }
            deviceClass {
              included
            }
            gender {
              included
            }
            locality {
              included
            }
            populateLocality {
              displayName
              entity
              id
            }
            daypart {
              userTime {
                included
              }
            }
            age {
              included {
                minAge
                maxAge
              }
            }
          }
        }
      }
    }
  }
`;
export const ADS_MANAGER_AD_GROUP_FILTER_ALL = gql`
  query adGroupReportsAll(
    $filter: Filters!
    $offset: Float!
    $limit: Float!
    $sortBy: String!
    $sortDesc: Boolean!
    $adGroupSearch: adGroupSearchReq!
  ) {
    adgroupReportsForFilter: adGroupReportsAll(
      filter: $filter
      offset: $offset
      limit: $limit
      sortBy: $sortBy
      sortDesc: $sortDesc
      adGroupSearch: $adGroupSearch
    ) {
      adgroupcounts {
        alladgroupcount
        liveadgroupcount
        pausedadgroupcount
        deletedadgroupcount
      }
      row {
        adgroupMetadata {
          id
          name
        }
      }
    }
  }
`;
export const ADS_MANAGER_AD_GROUP_LIVE = gql`
  query adGroupReportsLive(
    $filter: Filters!
    $offset: Float!
    $limit: Float!
    $sortBy: String!
    $sortDesc: Boolean!
    $adGroupSearch: adGroupSearchReq!
    $getGoals: Boolean!
  ) {
    adGroupReportsLive(
      filter: $filter
      offset: $offset
      limit: $limit
      sortBy: $sortBy
      sortDesc: $sortDesc
      adGroupSearch: $adGroupSearch
    ) {
      adgroupcounts {
        alladgroupcount
        liveadgroupcount
        pausedadgroupcount
        deletedadgroupcount
      }
      filterCount
      grandTotals {
        total {
          installs
          newDownloads
          redownloads
          ttr
          taps
          impressions
          localSpend {
            amount
          }
        }
      }
      row {
        total {
          localSpend {
            amount
          }
          avgCPA {
            amount
          }
          avgCPT {
            amount
          }
          avgCPM {
            amount
          }
          impressions
          taps
          installs
          ttr
          conversionRate
          newDownloads
          redownloads
          latOnInstalls
          latOffInstalls
        }
        metadata {
          # campaignStatus,
          # displayStatus,
          app {
            adamId
            appName
          }
          campaignId
          orgId
          adGroupId
          # campaignName,
          # countriesOrRegions
          adGroupDisplayStatus
        }
        orgMetadata {
          orgName
          orgId
          currency
        }
        campaignMetadata {
          id
          name
          adamId
          status
          countriesOrRegions
          deleted
          adChannelType
          supplySources
        }
        adgroupAttributionData(filter: $filter, getGoals: $getGoals) {
          successMessage
          content
          secondaryContent
        }
        adgroupMetadata {
          id
          name
          orgId
          campaignId
          status
          deleted
          displayStatus
          startTime
          endTime
          automatedKeywordsOptIn
          defaultBidAmount {
            currency
            amount
          }
          cpaGoal {
            currency
            amount
          }
          targetingDimensions {
            adminArea {
              included
            }
            appDownloaders {
              included
              excluded
              value
            }
            appCategories {
              included
              excluded
            }
            country {
              included
            }
            deviceClass {
              included
            }
            gender {
              included
            }
            locality {
              included
            }
            populateLocality {
              displayName
              entity
              id
            }
            daypart {
              userTime {
                included
              }
            }
            age {
              included {
                minAge
                maxAge
              }
            }
          }
        }
      }
    }
  }
`;
export const ADS_MANAGER_AD_GROUP_FILTER_LIVE = gql`
  query adGroupReportsLive(
    $filter: Filters!
    $offset: Float!
    $limit: Float!
    $sortBy: String!
    $sortDesc: Boolean!
    $adGroupSearch: adGroupSearchReq!
  ) {
    adgroupReportsForFilter: adGroupReportsLive(
      filter: $filter
      offset: $offset
      limit: $limit
      sortBy: $sortBy
      sortDesc: $sortDesc
      adGroupSearch: $adGroupSearch
    ) {
      adgroupcounts {
        alladgroupcount
        liveadgroupcount
        pausedadgroupcount
        deletedadgroupcount
      }
      row {
        adgroupMetadata {
          id
          name
        }
      }
    }
  }
`;

export const ADS_MANAGER_AD_GROUP_PAUSED = gql`
  query adGroupReportsPaused(
    $filter: Filters!
    $offset: Float!
    $limit: Float!
    $sortBy: String!
    $sortDesc: Boolean!
    $adGroupSearch: adGroupSearchReq!
    $getGoals: Boolean!
  ) {
    adGroupReportsPaused(
      filter: $filter
      offset: $offset
      limit: $limit
      sortBy: $sortBy
      sortDesc: $sortDesc
      adGroupSearch: $adGroupSearch
    ) {
      adgroupcounts {
        alladgroupcount
        liveadgroupcount
        pausedadgroupcount
        deletedadgroupcount
      }
      filterCount
      grandTotals {
        total {
          installs
          newDownloads
          redownloads
          ttr
          taps
          impressions
          localSpend {
            amount
          }
        }
      }
      row {
        total {
          localSpend {
            amount
          }
          avgCPA {
            amount
          }
          avgCPT {
            amount
          }
          avgCPM {
            amount
          }
          impressions
          taps
          installs
          ttr
          conversionRate
          newDownloads
          redownloads
          latOnInstalls
          latOffInstalls
        }
        metadata {
          # campaignStatus,
          # displayStatus,
          app {
            adamId
            appName
          }
          campaignId
          orgId
          adGroupId
          # campaignName,
          # countriesOrRegions
          adGroupDisplayStatus
        }
        orgMetadata {
          orgName
          orgId
          currency
        }
        campaignMetadata {
          id
          name
          adamId
          status
          countriesOrRegions
          deleted
          adChannelType
          supplySources
        }
        adgroupAttributionData(filter: $filter, getGoals: $getGoals) {
          successMessage
          content
          secondaryContent
        }
        adgroupMetadata {
          id
          name
          orgId
          campaignId
          status
          deleted
          displayStatus
          startTime
          endTime
          automatedKeywordsOptIn
          defaultBidAmount {
            currency
            amount
          }
          cpaGoal {
            currency
            amount
          }
          targetingDimensions {
            adminArea {
              included
            }
            appDownloaders {
              included
              excluded
              value
            }
            appCategories {
              included
              excluded
            }
            country {
              included
            }
            deviceClass {
              included
            }
            gender {
              included
            }
            locality {
              included
            }
            populateLocality {
              displayName
              entity
              id
            }
            daypart {
              userTime {
                included
              }
            }
            age {
              included {
                minAge
                maxAge
              }
            }
          }
        }
      }
    }
  }
`;

export const ADS_MANAGER_AD_GROUP_FILTER_PAUSED = gql`
  query adGroupReportsPaused(
    $filter: Filters!
    $offset: Float!
    $limit: Float!
    $sortBy: String!
    $sortDesc: Boolean!
    $adGroupSearch: adGroupSearchReq!
  ) {
    adgroupReportsForFilter: adGroupReportsPaused(
      filter: $filter
      offset: $offset
      limit: $limit
      sortBy: $sortBy
      sortDesc: $sortDesc
      adGroupSearch: $adGroupSearch
    ) {
      adgroupcounts {
        alladgroupcount
        liveadgroupcount
        pausedadgroupcount
        deletedadgroupcount
      }
      row {
        adgroupMetadata {
          id
          name
        }
      }
    }
  }
`;
export const ADS_MANAGER_AD_GROUP_DELETED = gql`
  query adGroupReportsDeleted(
    $filter: Filters!
    $offset: Float!
    $limit: Float!
    $sortBy: String!
    $sortDesc: Boolean!
    $adGroupSearch: adGroupSearchReq!
    $getGoals: Boolean!
  ) {
    adGroupReportsDeleted(
      filter: $filter
      offset: $offset
      limit: $limit
      sortBy: $sortBy
      sortDesc: $sortDesc
      adGroupSearch: $adGroupSearch
    ) {
      adgroupcounts {
        alladgroupcount
        liveadgroupcount
        pausedadgroupcount
        deletedadgroupcount
      }
      filterCount
      grandTotals {
        total {
          installs
          newDownloads
          redownloads
          ttr
          taps
          impressions
          localSpend {
            amount
          }
        }
      }
      row {
        total {
          localSpend {
            amount
          }
          avgCPA {
            amount
          }
          avgCPT {
            amount
          }
          avgCPM {
            amount
          }
          impressions
          taps
          installs
          ttr
          conversionRate
          newDownloads
          redownloads
          latOnInstalls
          latOffInstalls
        }
        metadata {
          # campaignStatus,
          # displayStatus,
          app {
            adamId
            appName
          }
          campaignId
          orgId
          adGroupId
          # campaignName,
          # countriesOrRegions
          adGroupDisplayStatus
        }
        orgMetadata {
          orgName
          orgId
          currency
        }
        campaignMetadata {
          id
          name
          adamId
          status
          countriesOrRegions
          deleted
          adChannelType
          supplySources
        }
        adgroupAttributionData(filter: $filter, getGoals: $getGoals) {
          successMessage
          content
          secondaryContent
        }
        adgroupMetadata {
          id
          name
          orgId
          campaignId
          status
          deleted
          displayStatus
          startTime
          endTime
          automatedKeywordsOptIn
          defaultBidAmount {
            currency
            amount
          }
          cpaGoal {
            currency
            amount
          }
          targetingDimensions {
            adminArea {
              included
            }
            appDownloaders {
              included
              excluded
              value
            }
            appCategories {
              included
              excluded
            }
            country {
              included
            }
            deviceClass {
              included
            }
            gender {
              included
            }
            locality {
              included
            }
            populateLocality {
              displayName
              entity
              id
            }
            daypart {
              userTime {
                included
              }
            }
            age {
              included {
                minAge
                maxAge
              }
            }
          }
        }
      }
    }
  }
`;

export const ADS_MANAGER_AD_GROUP_FILTER_DELETED = gql`
  query adGroupReportsDeleted(
    $filter: Filters!
    $offset: Float!
    $limit: Float!
    $sortBy: String!
    $sortDesc: Boolean!
    $adGroupDeleteSearch: adGroupSearchReq!
  ) {
    adgroupReportsForFilter: adGroupReportsDeleted(
      filter: $filter
      offset: $offset
      limit: $limit
      sortBy: $sortBy
      sortDesc: $sortDesc
      adGroupDeleteSearch: $adGroupDeleteSearch
    ) {
      adgroupcounts {
        alladgroupcount
        liveadgroupcount
        pausedadgroupcount
        deletedadgroupcount
      }
      row {
        adgroupMetadata {
          id
          name
        }
      }
    }
  }
`;
export const ADGROUPS_TOTAL_SUMMARY = gql`
  query adgroupsTotalSummary($filter: Filters!) {
    adgroupsTotalSummary(filter: $filter) {
      installs
      newDownloads
      redownloads
      ttr
      taps
      impressions
      localSpend {
        amount
      }
    }
  }
`;
