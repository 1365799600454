








































































































































































































































































































































































































































































































































































































































































































































































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import * as _ from 'lodash';
import Vue from 'vue';
import { COUNTRIES } from 'src/utils/constants';
import { UPDATE_ADGROUP } from 'src/utils/apollo-mutations';
import { CustomerTypes, Genders } from 'src/utils/enums';
import { LSS } from '@core/services';
import { APPS_BY_ORG } from 'src/utils/apollo-queries';
import { CampaignsService } from '@services/campaigns.service';

export default Vue.extend({
  name: 'EditAdgroup',
  data() {
    return {
      appCategory:{categoryName: 'All categories', value:0},
      appCategories:[
        {categoryName: 'All categories', value:0},
        {categoryName: 'Similar categories', value:1},
        {categoryName: 'Other categories', value:2},
      ],
      supplySources:[],
      adChannelType:'',
      show: false,
      dialog2: false,
      loading: false,
      savingUpdates: false,
      checkbox: false,
      selectedCustomerType: CustomerTypes.ALL,
      selectedLocalities: [],
      countries: COUNTRIES,
      adgroupDetails: {
        name: null,
        defaultBidAmount: {
          amount: null,
        },
        cpaGoal: {
          amount: null,
        },
        age: {
          included: {
            minAge: null,
            maxAge: null,
          },
        },
        startTime: null,
        endTime: null,
        automatedKeywordsOptIn: null,
        targetingDimensions: {
          adminArea: {
            included: null,
          },
          gender: {
            included: null,
          },
          locality: {
            included: null,
          },
          deviceClass: {
            included: null,
          },
          appDownloaders: {
            included: null,
            excluded: null,
          },
          country: {
            included: null,
          },
          daypart: {
            userTime: {
              included: null,
            },
          },
        },
      },
      cpaGoalAmount: null,
      cpaGoalCurrency: null,
      country: null,
      selectedGender: [null],
      selectedDeviceClass: null,
      selectedMinAge: null,
      selectedMaxAge: null,
      selectedStartTime: null,
      selectedEndTime: null,
      searchLoc: null,
      locations: [],
      displayStatus: null,
      tabs: null,
      items: ['Edit'],
      datePickerProps: {
        min: new Date().toISOString(),
      },

      onClickShow: false,
      Devices: [
        { text: 'IPHONE', value: 'IPHONE' },
        { text: 'IPAD', value: 'IPAD' },
      ],

      ageRange: false,
      ageRangeValue: [this.selectedMinAge, this.selectedMaxAge],

      //Validation
      valid: true,
      // nameRules: [(v) => !!v || 'Adgroup Name is Required'],
      // amountRules: [
      //   (v) => !!v || 'Default Max CPT Bid is required',
      //   (v) => !!/^[0.1-9]*$/gi.test(v) || 'Please enter a valid CPT Bid!',
      // ],
    };
  },

  props: {
    adgroupData: {
      type: Object,
      default() {
        return;
      },
    },
  },

  computed: {
    nameRules: {
      get() {
        return [
          (v) => !!v || this.$t('createCampaignRules.groupRequired'),
          (v) => (v && v.length <=199) || this.$t('createCampaignRules.campNameSize')
        ]
      }
    },

    amountRules: {
      get() {
        return [
        (v) => !!v || this.$t('createCampaignRules.defMaxCptRequired'),
        (v) => !!/^[0.1-9]*$/gi.test(v) || this.$t('createCampaignRules.validCptBid'),
      ]
      }
    },

    customerTypes: {
      cache: false,
      get() {
        return [
          {
            text: this.$t('createCampaign.allUsers'),
            value: CustomerTypes.ALL,
          },
          {
            text: this.$t('createCampaign.newUsers'),
            value: CustomerTypes.NEW,
          },
          {
            text: this.$t('createCampaign.appUsers'),
            value: CustomerTypes.OTHER_APPS,
          },
          {
            text: this.$t('createCampaign.existingUsers'),
            value: CustomerTypes.EXISITING,
          },
        ];
      },
    },

    gender: {
      cache: false,
      get() {
        return [
          { text: this.$t('createCampaign.all'), value: Genders.ALL },
          { text: this.$t('createCampaign.male'), value: Genders.MALE },
          { text: this.$t('createCampaign.female'), value: Genders.FEMALE },
        ];
      },
    },
  },

  watch: {
    adgroupData:  async function (data) {
      this.show = true;
      this.displayStatus = data.displayStatus;
      if (data.targetingDimensions.country == null) {
        this.country = null;
      } else {
        this.country = data.targetingDimensions.country.included;
      }

      if ( data.targetingDimensions?.appCategories && data.targetingDimensions?.appCategories.included.includes(100) ) {
        this.appCategory={categoryName: 'Similar categories', value:1}
      } 
      else if(data.targetingDimensions?.appCategories && data.targetingDimensions?.appCategories.excluded.includes(100) ){
        this.appCategory={categoryName: 'Other categories', value:2}
      }
      else{
        this.appCategory={categoryName: 'All categories', value:0}
      }

      this.supplySources = data.supplySources
      this.adChannelType = data.adChannelType
      if (data.targetingDimensions.gender == null) {
        this.selectedGender = Genders.ALL;
      } else {
        this.selectedGender = data.targetingDimensions.gender.included[0];
      }

      if (data.targetingDimensions.deviceClass == null) {
        this.selectedDeviceClass = null;
      } else {
        this.selectedDeviceClass =
          data.targetingDimensions.deviceClass.included;
      }

      if (data.targetingDimensions.age == null) {
        this.ageRange = false;
      } else {
        this.ageRange = true;
        this.selectedMinAge = data.targetingDimensions.age.included[0].minAge;
        this.selectedMaxAge = data.targetingDimensions.age.included[0].maxAge;
        this.ageRangeValue = [this.selectedMinAge, this.selectedMaxAge];
      }

      if (data.endTime == null) {
        this.selectedEndTime = null;
      } else {
        this.selectedEndTime = new Date(data.endTime);
      }

      if (data.startTime == null) {
        this.selectedStartTime = null;
      } else {
        this.selectedStartTime = new Date(data.startTime);
      }

      if (data.cpaGoal == null) {
        this.cpaGoalAmount = null;
      } else {
        this.cpaGoalAmount = data.cpaGoal.amount;
        this.cpaGoalCurrency = data.cpaGoal.currency;
      }

      /* if(data.targetingDimensions.populateLocality) {
        this.locations = data.targetingDimensions.populateLocality
        this.selectedLocalities = data.targetingDimensions.populateLocality
      } */
      if(data.targetingDimensions.locality) {
        try{
          this.locations = await CampaignsService.getLocations(
            this.adgroupData.orgId,
            data.targetingDimensions.locality.included
          );
          this.selectedLocalities = this.locations
        }
        catch(error){
          this.locations = []
          this.selectedLocalities = []
        }
        
      }

      if(data.targetingDimensions.appDownloaders) {
        if(data.targetingDimensions.appDownloaders?.value) {
          this.selectedCustomerType = data.targetingDimensions.appDownloaders?.value
        }
        else {
          const appDownloaders = data.targetingDimensions.appDownloaders
          const adamId = data.adamId
          if(appDownloaders.excluded != null && appDownloaders.included != null) {
            this.selectedCustomerType = CustomerTypes.ALL
          }
          else if(appDownloaders.excluded != null) {
            this.selectedCustomerType = CustomerTypes.NEW
          }
          else if(appDownloaders.included != null && appDownloaders.included[0] == adamId) {
            this.selectedCustomerType = CustomerTypes.EXISITING
          }
          else if(appDownloaders.included != null) {
            this.selectedCustomerType = CustomerTypes.OTHER_APPS
          }
        }
      }

      this.adgroupDetails = _.cloneDeep({
        name: data.name,
        defaultBidAmount: {
          amount: data.defaultBidAmount.amount,
          currency: data.defaultBidAmount.currency,
        },
        // cpaGoal: {
        //   amount: data.cpaGoal.amount,
        //   currency: data.cpaGoal.currency,
        // },

        startTime: data.startTime,
        endTime: data.endTime,
        automatedKeywordsOptIn: data.automatedKeywordsOptIn,
        targetingDimensions: data.targetingDimensions,
      });
    },

    searchLoc(query: string): void {
      query &&
        query.length > 2 &&
        this.searchLocations(query, this.adgroupData.countriesOrRegions[0]);
    },
  },

  methods: {
    async updateAdgroup() {
      this.savingUpdates = true;
      const orgId = this.adgroupData.orgId;
      const adamId = this.adgroupData.adamId;
      const allOwnedApps = this.apps.map((app) => app.adamId);
      const adgroupRequest = {
        name: this.adgroupDetails.name,
        automatedKeywordsOptIn: this.adgroupDetails.automatedKeywordsOptIn,
        defaultBidAmount: this.adgroupDetails.defaultBidAmount,
        // Using conditional expression to set cpaGoal appropriately
        cpaGoal: this.cpaGoalAmount ? {
          amount: this.cpaGoalAmount,
          currency: this.adgroupDetails.defaultBidAmount.currency,
        } : null,
        ...(this.selectedEndTime && { endTime: this.selectedEndTime }),
        targetingDimensions: {
          deviceClass: {
            included: this.selectedDeviceClass,
          },
          ...((this.ageRange && {
            age: {
              included: [
                {
                  minAge: this.ageRangeValue[0],
                  maxAge: this.ageRangeValue[1],
                },
              ],
            },
          }) || {
            age: null,
          }),
          // gender criteria
          ...((this.selectedGender && {
            gender: {
              included: [this.selectedGender],
            },
          }) || {
            gender: null,
          }),
          // TODO
          adminArea: null,
          daypart: null,
          ...((this.selectedLocalities &&
            this.selectedLocalities.length && {
              locality: {
                included: this.selectedLocalities.map(e => e.id),
              },
            }) || {
            locality: null,
          }),
          // App downloader criteria (customer type drop-down)
          ...(this.selectedCustomerType === CustomerTypes.ALL && {
            appDownloaders: null,
          }),
          ...(this.selectedCustomerType === CustomerTypes.NEW && {
            appDownloaders: {
              excluded: [adamId.toString()],
            },
          }),
          ...(this.selectedCustomerType === CustomerTypes.EXISITING && {
            appDownloaders: {
              included: [adamId.toString()],
            },
          }),
          ...(this.selectedCustomerType === CustomerTypes.OTHER_APPS && {
            appDownloaders: {
              included: [...allOwnedApps.map((adamId) => adamId.toString())],
            },
          }),
        },
      };

      if (this.supplySources.includes('APPSTORE_PRODUCT_PAGES_BROWSE')){
        if(this.appCategory.value == 1){
            adgroupRequest.targetingDimensions['appCategories'] = {
                            "included": [100], "excluded": []
                        }
        }
        else if(this.appCategory.value == 2){
            adgroupRequest.targetingDimensions['appCategories'] = {
                            "included": [], "excluded": [100]
                        }
        }
        else{
          delete adgroupRequest.targetingDimensions.appCategories
        }
        
      }
      const campaignId = this.adgroupData.campaignId;
      const adgroupId = this.adgroupData.adgroupId;
      const appDownloadersValue = this.selectedCustomerType
      const selectedLocalities = this.selectedLocalities
      try {
        const result = await this.$apollo.mutate({
          mutation: UPDATE_ADGROUP,
          variables: {
            orgId,
            campaignId,
            adgroupId,
            adgroupRequest,
            appDownloadersValue,
            selectedLocalities
          },
        });
        this.savingUpdates = false;

        if (result.data.updateAdGroup.searchAdsErrors) {
          this.$notify.error(result.data.updateAdGroup.searchAdsErrors);
        } else {
          this.$emit('adgroupUpdated');
          this.$notify.success(this.$t('successMsg.adgroupUpdatedMsg'));
          this.close();
        }
      } catch (error) {
        this.savingUpdates = false;
      }
    },

    async searchLocations(locationName: string, countrycode: string) {
      this.loading = true;
      this.locations = await CampaignsService.searchedLocations(
        this.adgroupData.orgId,
        locationName,
        countrycode
      );
      this.loading = false;
    },

    close() {
      this.show = false;
      this.dialog2 = false;
      if (this.$refs.addAdGroupForm1) {
        this.$refs.addAdGroupForm1.reset();
      }     
    },

    mounted() {
      this.$root.$on('locale-changed', (l) => {
      //this.$refs.formStep1.resetValidation()
      this.$refs.addAdGroupForm1.validate();  
    });
    }
  },

  apollo: {
    apps: {
      query: APPS_BY_ORG,
      variables() {
        return {
          orgId: this.adgroupData && this.adgroupData.orgId,
        };
      },
      skip() {
        return !(this.adgroupData && this.adgroupData.orgId);
      },
    },
  },
});
