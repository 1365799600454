


























































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { controllers } from 'chart.js';
import * as _ from 'lodash';
import { conformsTo } from 'lodash';
import Vue from 'vue';
import { UPDATE_AD } from '../../utils/apollo-mutations';
export default Vue.extend({
  name: 'EditAd',
  data() {
    return {
      saving: false,
      show: false,
      valid: false,
      adsDetails: {
        name: null,
       },
      };
  },
  computed: {
    nameRules: {
      get() {
        return [
          (v) => !!v || this.$t('createAdRules.adRequired'),
          (v) => (v && v.length <=199) || this.$t('createAdRules.adNameSize')
        ]
      }
    },
  },
  props: {
    adsData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    adsData: function (data) {
      console.log(data);
      this.show = true;
      this.adsDetails = _.cloneDeep({
      name: data.name,
     });
    },
  },
  methods: {
    async updateAd() {
      this.saving = true;
      const campaignId = this.adsData.campaignId;
      const orgId = this.adsData.orgId;
      const adgroupId = this.adsData.adgroupId;
      const adId = this.adsData.id;
      try {
        const result = await this.$apollo.mutate({
          mutation: UPDATE_AD,
          variables: {
            orgId,
            campaignId,
            adgroupId,
            adId,
            adRequest : {
              name: this.adsDetails.name,
            },
          },
        });
      if (result.data.updateAd.searchAdsErrors) {
          this.$notify.error(result.data.updateAd.searchAdsErrors);
        } else {
          this.$notify.success(this.$t('successMsg.adUpdate'));
          this.$emit('adsUpdated');
          this.close();
        }
      } catch {}
        this.saving = false;
    },
    close() {
      this.show = false;
      this.adsDetails = {
        name: null
       };
    },
    mounted() {
      this.$root.$on('locale-changed', (l) => {
      //this.$refs.formStep1.resetValidation()
      this.$refs.editAdform.validate();
    });
    }
  }
});
