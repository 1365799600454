





































































  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-nocheck
import moment from 'moment';
import Vue from 'vue';
import { DEFAULT_GRANULARITY } from '@shared/config';
import LineChart from '../chartjs/LineChart.vue';
import { LSS } from '@core/services';
import ChartPopup from './ChartPopup.vue';
import { numberFormat } from 'src/utils/util';
import myIMg from '../../assets/images/primary-diamond.png';
import XLSX from 'xlsx';
export default Vue.extend({
  name: 'Chart',
  components: {
    LineChart,
    ChartPopup
  },
  props: {
    chartdata: {
      type: Object,
      default() { return { labels: [], datasets: [] } }
    },
    options: {
      type: Object,
      default() { return {}; }
    },
    timeLineEntries: {
      type: Array,
      default() { return [] }
    },
    tabInfo: String,
    customView: String,
    strategySelection: String | Number,
    granularity: {
      type: String,
      default:DEFAULT_GRANULARITY
    },
    loading: Boolean
  },
  data() {
    return {
      localGranularity: this.granularity,
      chartGoalAttr:["Goal Revenue","Goal Event Count","Cost Per Goal","Goal ROAS","Revenue Per Goal","Goal ARPU","Goal AMPU", "Secondary Goal Event Count", "Secondary Cost Per Goal"],
      visible:false,
      oldData: null,
      selectedDatasets: [],
      datasetsList: [],
      linechartData: { labels: [], datasets: [], datasetsList:[]},
      selectedSetNamesToPersist: [],
      defaultDataset : ['Installs', 'Spend']
    };
  },
  computed: {
    granularityList: {
      cache: false,
      get() {
        return [
          {
            text: this.$t('dropdowns.daily'),
            value: 'DAILY'
          },
          {
            text: this.$t('dropdowns.weekly'),
            value: 'WEEKLY'
          },
          {
            text: this.$t('dropdowns.monthly'),
            value: 'MONTHLY'
          },
        ];
      }
    },
  },
  watch: {
    selectedDatasets(v){
      const datasets = v.map(e=>e.label)
      let getGoals = false
      getGoals = this.chartGoalAttr.some(el=> datasets.includes(el)) 
      this.$root.$emit('update-chart-api', {getGoals:getGoals, tabInfo: this.tabInfo})
    },

    chartdata: {
      handler(data){
      var diamondPng = new Image();
      diamondPng.src = myIMg;
      if(this.timeLineEntries.length) {
        let obj = {
          borderColor: "#ff9800",
          backgroundColor: "#ffc166",
          borderWidth: 1,
          pointStyle: 'rectRot',
          pointRadius: 8,
          pointHoverRadius: 8,
          fill: false,
          label: "Strategy Timeline",
          yAxisID: "Strategy Timeline",
          data: this.populateStrategyTimelineData(this.timeLineEntries, data.labels),
          // timelineData: ['test','test','test','test','test','test','test','test','test','test','test','test','test']
        }
        data.datasets = data.datasets.filter(ds => ds.label !== 'Strategy Timeline');
        data.datasets.push(obj);
      }
        
      const compare = _.cloneDeep(data);

      delete compare.time;

      const local = _.cloneDeep({
        ...data,
          datasets: data.datasets.map(dataset => {
          return {
            ...dataset,
           // data: dataset.data.map(item => numberFormat(item, true))
           data: dataset.data.map(item => item)
          }
        })
      });
      compare.datasets = compare.datasets.map(set => Object.assign({ data: set.data }));    

      if(!_.isEqual(this.oldData, compare)) {

        const stindex = this.defaultDataset.indexOf('Strategy Timeline');
        if (stindex > -1) {
          this.defaultDataset.splice(stindex, 1);
        }  
        if(this.strategySelection) {
          this.defaultDataset.push('Strategy Timeline', 'Goal Event Count');
        }
        
        this.oldData = compare;
        //this.datasetsList = local.datasets;
        this.datasetsList = local.datasets.filter((set)=>set.label!="LAT Off Installs" && set.label!="LAT On Installs" );

        //to remove attribution datasets from accounts tab
        if(this.tabInfo == 'accounts' || this.tabInfo == 'apps') {
          this.datasetsList = this.datasetsList.slice(0,11)
        }
        if(LSS.chartDatasets){
          this.setDataset();
        }
        // if(this.customView && this.customView!='0'){
          this.setCustomView();
        // }
        // this.selectedDatasets = this.selectedSetNamesToPersist.length ? this.datasetsList.filter((set) => this.selectedSetNamesToPersist.includes(set.label)) : this.datasetsList.slice(0, 1);
        this.selectedDatasets = this.selectedSetNamesToPersist.length ? this.datasetsList.filter((set) => this.selectedSetNamesToPersist.includes(set.label) || set.label == 'Strategy Timeline') : this.datasetsList.filter((set) => this.defaultDataset.includes(set.label));     
        this.setYaxis(this.selectedDatasets)
        this.linechartData = { labels: local.labels, datasets: this.selectedDatasets ,datasetsList:this.datasetsList};
      } else {
        if (this.linechartData) {
          this.setYaxis(this.selectedDatasets)
          this.linechartData = Object.assign({}, {...this.linechartData}, { time: Date.now() });
        }
      }

      this.datasetsList = this.datasetsList.filter((set)=>set.label != "Strategy Timeline");
    },
    immediate:true
    },
    customView:function(data) {
      this.setCustomView()
      this.selectedDatasets = this.selectedSetNamesToPersist.length ? this.datasetsList.filter((set) => this.selectedSetNamesToPersist.includes(set.label)) : this.datasetsList.filter((set) => this.defaultDataset.includes(set.label));
      this.setYaxis(this.selectedDatasets)
      this.linechartData = { labels: this.chartdata.labels, datasets: this.selectedDatasets,datasetsList:this.datasetsList };
    }
  },
  methods: {
    populateStrategyTimelineData(timeLineEntries, labels) {
      const data = [];
      for (const label of labels) {
        
        const match = timeLineEntries.find(entry => {
          const createdOn = moment(entry.createdOn);
          const date = createdOn.format('YYYY-MM-DD');
          const weekNumber = createdOn.isoWeek().toString() + '-' + createdOn.format('YYYY');
          const month = createdOn.format('MMMM') + '-' + createdOn.format('YYYY');
          
          if (label === date || label === weekNumber || label === month) {
            return true;
          } else {
            return false;
          }
        })
        
        if (match) {
          data.push(0)
        } else {
          data.push(undefined);
        }
      }
      
      return data;
    },
    
    limiter(e) {
      e = e.filter(set => set.label !== 'Strategy Timeline')
      if(e.length > 5) {
        this.$notify.error("You can select upto 5 datasets only")
        this.selectedDatasets.pop()
      }
    },

    downloadChartData() {
      let createXLSLFormatObj = [];
      let newXlsHeader = [];
      
      this.datasetsList.map((dataset) => {
        newXlsHeader.push(dataset.label);
      });

      newXlsHeader.unshift('Date');

      createXLSLFormatObj.push(newXlsHeader);
      this.chartdata.labels.forEach((value, idx) => {
        let innerRowData = [value];
       
        this.datasetsList.map((val) => {
          let fieldValue = val.data[idx];
          innerRowData.push(fieldValue);
        });

        createXLSLFormatObj.push(innerRowData);
      });

      let fileName = this.tabInfo + '-chart.csv';
      let ws_name = this.sheetName;
      let wb = XLSX.utils.book_new(),
      ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);
      XLSX.utils.book_append_sheet(wb, ws, ws_name);
      XLSX.writeFile(wb, fileName);
      this.$notify.success("Chart data downloaded successfully")
    },

    makePopupVisible(){
      this.visible=true;
      
    },
    setYaxis(datasets){
      let yAxes = [];
      datasets.map((data)=>{
        yAxes.push({
          type: 'linear',
          position: 'left',
          ticks: {
            beginAtZero: true
          },
          gridLines: {
            display: true,
            color: data.borderColor,
            drawOnChartArea:false
          },
          //max: Math.max(data.data)
          id:data.label
        })
      })
      this.options['scales'] = {
        yAxes: yAxes
      }
      
      const that = this;
      
      this.options['tooltips'] = {
        enabled: false,
        callbacks: {
          label: ((tooltipItem, data) => {
            let label = data.datasets[tooltipItem.datasetIndex].label || '';
            
            if (label === 'Strategy Timeline' && that.timeLineEntries.length) {
              const value = tooltipItem.xLabel;
              
              const entries = that.timeLineEntries.filter(entry => {
                const createdOn = moment(entry.createdOn);
                const date = createdOn.format('YYYY-MM-DD');
                const weekNumber = createdOn.isoWeek().toString() + '-' + createdOn.format('YYYY');
                const month = createdOn.format('MMMM') + '-' + createdOn.format('YYYY');
                
                if (value === date || value === weekNumber || value === month) {
                  return true;
                } else {
                  return false;
                }
              })
              
              const descArray = entries.filter(e => e.description).map(e => e.description.length > 50 ? e.description.slice(0, 49) + "…" : e.description);
              
              if (!descArray.length) return '';

              let text = `<ul>`;
              descArray.forEach(d => text += `<li>&#x2022; ${d}</li>`);
              text += `</ul>`;
              
              return text;
            }

            if (label) {
                label += ': ';
            }
            label += tooltipItem.yLabel;
            return label;
        })
        },
        custom: function(tooltipModel) {
                // Tooltip Element
                var tooltipEl = document.getElementById('chartjs-tooltip');

                // Create element on first render
                if (!tooltipEl) {
                    tooltipEl = document.createElement('div');
                    tooltipEl.id = 'chartjs-tooltip';
                    tooltipEl.innerHTML = '<table></table>';
                    document.body.appendChild(tooltipEl);
                }

                // Hide if no tooltip
                if (tooltipModel.opacity === 0) {
                    tooltipEl.style.opacity = 0;
                    return;
                }

                // Set caret Position
                tooltipEl.classList.remove('above', 'below', 'no-transform');
                if (tooltipModel.yAlign) {
                    tooltipEl.classList.add(tooltipModel.yAlign);
                } else {
                    tooltipEl.classList.add('no-transform');
                }

                function getBody(bodyItem) {
                    return bodyItem.lines;
                }

                // Set Text
                if (tooltipModel.body) {
                    var titleLines = tooltipModel.title || [];
                    var bodyLines = tooltipModel.body.map(getBody);

                    var innerHtml = '<thead>';

                    titleLines.forEach(function(title) {
                        innerHtml += '<tr><th>' + title + '</th></tr>';
                    });
                    innerHtml += '</thead><tbody>';

                    bodyLines.forEach(function(body, i) {
                        var colors = tooltipModel.labelColors[i];
                        var style = 'background:' + colors.backgroundColor;
                        style += '; border-color:' + colors.borderColor;
                        style += '; border-width: 2px';
                        var span = '<span style="' + style + '"></span>';
                        innerHtml += '<tr><td>' + span + body + '</td></tr>';
                    });
                    innerHtml += '</tbody>';

                    var tableRoot = tooltipEl.querySelector('table');
                    tableRoot.innerHTML = innerHtml;
                }

                // `this` will be the overall tooltip
                var position = this._chart.canvas.getBoundingClientRect();

                // Display, position, and set styles for font
                tooltipEl.style.backgroundColor = tooltipModel.backgroundColor;
                tooltipEl.style.color = tooltipModel.bodyFontColor;
                tooltipEl.style.borderRadius = '7px';
                tooltipEl.style.opacity = 0.9;
                tooltipEl.style.position = 'absolute';
                tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
                tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
                tooltipEl.style.pointerEvents = 'none';
            }
      }
    },
    setDataset(){
      return
      switch (this.tabInfo){
        case "accounts":{
          const presets = LSS.chartDatasets;
          if(presets.accounts){
            this.selectedSetNamesToPersist = presets.accounts.split(",");
          }
          break;
        }
        case "apps":{
          const presets = LSS.chartDatasets;
          if(presets.apps){
            this.selectedSetNamesToPersist = presets.apps.split(",");
          }
          break;
        }

        case "campaign":
          {const presets = LSS.chartDatasets;
          if(presets.campaign){
            this.selectedSetNamesToPersist = presets.campaign.split(",");
          }
          break;}
        case "adgroup":{
          const presets = LSS.chartDatasets;
          if(presets.adgroup){
            this.selectedSetNamesToPersist = presets.adgroup.split(",");
          }
          break;}
        case "keyword":{
          const presets = LSS.chartDatasets;
          if(presets.keyword){
            this.selectedSetNamesToPersist = presets.keyword.split(",");
          }
          break;}
        case "creativeset":{
          const presets = LSS.chartDatasets;
          if(presets.creativeset){
            this.selectedSetNamesToPersist = presets.creativeset.split(",");
          }
          break;}
        case "ads":{
          const presets = LSS.chartDatasets;
          if(presets.ads){
            this.selectedSetNamesToPersist = presets.ads.split(",");
          }
          break;}
        case "searchterm":{
          const presets = LSS.chartDatasets;
          if(presets.searchterm){
            this.selectedSetNamesToPersist = presets.searchterm.split(",");
          }
          break;}
        case "negativekeyword":{
          const presets = LSS.chartDatasets;
          if(presets.negativekeyword){
            this.selectedSetNamesToPersist = presets.negativekeyword.split(",");
          }
          break;}
      }
    },

    setCustomView(){
      switch (this.tabInfo){
        case "accounts":{
          const presets = LSS.customViews;
          const preset = presets?.find(o=>o._id == this.customView && o.tab == 'accounts')
          if(preset && preset.ChartDatasets){
            this.selectedSetNamesToPersist = preset.ChartDatasets.split(",");
          }
          else if(presets?.find(o=>o._id == '1' && o.tab == 'accounts') && presets?.find(o=>o._id == '1' && o.tab == 'accounts').ChartDatasets){
            this.selectedSetNamesToPersist = presets?.find(o=>o._id == '1' && o.tab == 'accounts').ChartDatasets.split(",");
          }
          else {
            this.selectedSetNamesToPersist = []
          }
          break;
        }
        case "apps":{
          const presets = LSS.customViews;
          const preset = presets?.find(o=>o._id == this.customView && o.tab == 'apps')
          if(preset && preset.ChartDatasets){
            this.selectedSetNamesToPersist = preset.ChartDatasets.split(",");
          }
          else if(presets?.find(o=>o._id == '1' && o.tab == 'apps') && presets?.find(o=>o._id == '1' && o.tab == 'apps').ChartDatasets){
            this.selectedSetNamesToPersist = presets?.find(o=>o._id == '1' && o.tab == 'apps').ChartDatasets.split(",");
          }else {
            this.selectedSetNamesToPersist = []
          }
          break;
        }

        case "campaigns":{
          const presets = LSS.customViews;
          const preset = presets?.find(o=>o._id == this.customView && o.tab == 'campaigns')
          if(preset && preset.ChartDatasets){
            this.selectedSetNamesToPersist = preset.ChartDatasets.split(",");
          }
          else if(presets?.find(o=>o._id == '1' && o.tab == 'campaigns') && presets?.find(o=>o._id == '1' && o.tab == 'campaigns').ChartDatasets){
            this.selectedSetNamesToPersist = presets?.find(o=>o._id == '1' && o.tab == 'campaigns').ChartDatasets.split(",");
          }else {
            this.selectedSetNamesToPersist = []
          }
          break;}
        case "adgroups":{
          const presets = LSS.customViews;
          const preset = presets?.find(o=>o._id == this.customView && o.tab == 'adgroups')
          if(preset && preset.ChartDatasets){
            this.selectedSetNamesToPersist = preset.ChartDatasets.split(",");
          }
          else if(presets?.find(o=>o._id == '1' && o.tab == 'adgroups') && presets?.find(o=>o._id == '1' && o.tab == 'adgroups').ChartDatasets){
            this.selectedSetNamesToPersist = presets?.find(o=>o._id == '1' && o.tab == 'adgroups').ChartDatasets.split(",");
          }
          else {
            this.selectedSetNamesToPersist = []
          }
          break;}
        case "keywords":{
          const presets = LSS.customViews;
          const preset = presets?.find(o=>o._id == this.customView && o.tab == 'keywords')
          if(preset && preset.ChartDatasets){
            this.selectedSetNamesToPersist = preset.ChartDatasets.split(",");
          }
          else if(presets?.find(o=>o._id == '1' && o.tab == 'keywords') && presets?.find(o=>o._id == '1' && o.tab == 'keywords').ChartDatasets){
            this.selectedSetNamesToPersist = presets?.find(o=>o._id == '1' && o.tab == 'keywords').ChartDatasets.split(",");
          }
          else {
            this.selectedSetNamesToPersist = []
          }
          break;}
        case "ads":{
          const presets = LSS.customViews;
          const preset = presets?.find(o=>o._id == this.customView && o.tab == 'ads')
          if(preset && preset.ChartDatasets){
            this.selectedSetNamesToPersist = preset.ChartDatasets.split(",");
          }
          else if(presets?.find(o=>o._id == '1' && o.tab == 'ads') && presets?.find(o=>o._id == '1' && o.tab == 'ads').ChartDatasets){
            this.selectedSetNamesToPersist = presets?.find(o=>o._id == '1' && o.tab == 'ads').ChartDatasets.split(",");
          }
          else {
            this.selectedSetNamesToPersist = []
          }
          break;}
        case "searchterm":{
          const presets = LSS.customViews;
          const preset = presets?.find(o=>o._id == this.customView && o.tab == 'searchterm')
          if(preset && preset.ChartDatasets){
            this.selectedSetNamesToPersist = preset.ChartDatasets.split(",");
          }
           else if(presets?.find(o=>o._id == '1' && o.tab == 'searchterm') && presets?.find(o=>o._id == '1' && o.tab == 'searchterm').ChartDatasets){
            this.selectedSetNamesToPersist = presets?.find(o=>o._id == '1' && o.tab == 'searchterm').ChartDatasets.split(",");
          }
          else {
            this.selectedSetNamesToPersist = []
          }
          break;}
        case "negativekeywords":{
          const presets = LSS.customViews;
          const preset = presets?.find(o=>o._id == this.customView && o.tab == 'negativekeywords')
          if(preset && preset.ChartDatasets){
            this.selectedSetNamesToPersist = presets.ChartDatasets.split(",");
          }
           else if(presets?.find(o=>o._id == '1' && o.tab == 'negativekeywords') && presets?.find(o=>o._id == '1' && o.tab == 'negativekeywords').ChartDatasets){
            this.selectedSetNamesToPersist = presets?.find(o=>o._id == '1' && o.tab == 'negativekeywords').ChartDatasets.split(",");
          }
          else {
            this.selectedSetNamesToPersist = []
          }
          break;}
      }
    },

    removeChipByLabel(label: string) {
      this.selectedDatasets = this.selectedDatasets.filter((item: { label: string }) => item.label !== label);
      this.changeSelectedDatasets();
    },

    changeSelectedDatasets(): void {
      this.selectedSetNamesToPersist = this.selectedDatasets.map((set) => set.label);
      this.setYaxis(this.selectedDatasets);
      this.linechartData = { labels: this.chartdata.labels, datasets: this.selectedDatasets,datasetsList:this.datasetsList };

      const persistDataset = {
        tabInfo : this.tabInfo,
        selectedSetNamesToPersist:this.selectedSetNamesToPersist
      }
     this.$emit('persistDatasets',persistDataset)
     this.$emit('customViews',persistDataset)
    },
    // emitGranularityChange(): void {
    //   this.$emit('granularityChanged', this.granularity);
    //   this.$emit('granularityWithTab',{tabInfo: this.tabInfo, granularity:this.granularity});
    // },
    emitGranularityChange() {
      this.$emit('granularityChanged', this.localGranularity);
      this.$emit('granularityWithTab', { tabInfo: this.tabInfo, granularity: this.localGranularity });
    },
    hidePopup(chartPopUp:object):void{
      if(chartPopUp[0]?.visible===false){
           this.visible=false;
       }else{
 
        this.selectedDatasets = chartPopUp[0]?.chartdata.datasets;
        this.changeSelectedDatasets();
        
       }
    
    }
  }
});
