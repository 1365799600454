var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", width: "600px", scrollable: "" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "dialogAdKeyword-card" },
        [
          _c(
            "v-card-title",
            {
              staticClass:
                "headline d-flex justify-space-between px-5 py-3 v-modal-header sticky-header",
            },
            [
              _c("h5", { staticClass: "text-h6" }, [
                _vm._v(_vm._s(_vm.$t("addKeywords.h6AddKeywords"))),
              ]),
              _c(
                "v-icon",
                {
                  staticClass: "black--text",
                  on: {
                    click: function ($event) {
                      return _vm.close()
                    },
                  },
                },
                [_vm._v("mdi-close")]
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "px-5", staticStyle: { "max-height": "680px" } },
            [
              _c(
                "v-container",
                { staticClass: "stepper-template pa-0", attrs: { fluid: "" } },
                [
                  [
                    _c(
                      "div",
                      { staticClass: "my-0 pa-3" },
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "d-flex algin-center",
                                attrs: { cols: "12", sm: "12" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "font-weight-medium subtitle-2 d-flex align-center",
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("addKeywords.h4Camp"))
                                      ),
                                    ]),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.totalCampaigns) +
                                          " " +
                                          _vm._s(
                                            _vm.$t("addKeywords.selected")
                                          ) +
                                          " "
                                      ),
                                    ]),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          "x-small": "",
                                          depressed: "",
                                          text: "",
                                          disabled: _vm.saving,
                                          color: "primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.change()
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("addKeywords.change"))
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c("v-divider", {
                                  staticClass: " mx-3",
                                  attrs: { vertical: "" },
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "font-weight-medium subtitle-2 d-flex align-center ",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("addKeywords.h4Adgroups")
                                        ) +
                                        " "
                                    ),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.totalAdgroups) +
                                          " " +
                                          _vm._s(_vm.$t("addKeywords.selected"))
                                      ),
                                    ]),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          "x-small": "",
                                          depressed: "",
                                          text: "",
                                          disabled: _vm.saving,
                                          color: "primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.change()
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("addKeywords.change"))
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { staticClass: "pb-0", attrs: { cols: "12" } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "max-bid d-flex" },
                                  [
                                    _c("v-select", {
                                      staticClass: "mt-2",
                                      attrs: {
                                        items: _vm.maxCptBidOptions,
                                        label: "Max CPT Bid",
                                        required: "",
                                        outlined: "",
                                        dense: "",
                                      },
                                      model: {
                                        value: _vm.maxCptBid,
                                        callback: function ($$v) {
                                          _vm.maxCptBid = $$v
                                        },
                                        expression: "maxCptBid",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12" } },
                              [
                                _c(
                                  "v-card",
                                  {
                                    staticClass: "ad-groupKeywordStep",
                                    attrs: { elevation: "0" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "text-left body-2" },
                                      [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href:
                                                "data:text/csv;base64,bmFtZSxhbW91bnQsbWF0Y2gsY3VycmVuY3kKa3csMC4xLEVYQUNULFVTRAo=",
                                              download:
                                                "targeting-keyword-example.csv",
                                            },
                                          },
                                          [_vm._v("Example Sheet")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: {
                                          bottom: "",
                                          color: "primarydark",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "activator",
                                            fn: function (ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  "div",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        staticClass:
                                                          "upload-file text-center  ad-card-hover",
                                                      },
                                                      "div",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c("v-file-input", {
                                                      attrs: {
                                                        "hide-input": "",
                                                        solo: "",
                                                        accept:
                                                          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.csv",
                                                      },
                                                      on: {
                                                        change: _vm.tKeysChange,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.targetingKeywordsFile,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.targetingKeywordsFile = $$v
                                                        },
                                                        expression:
                                                          "targetingKeywordsFile",
                                                      },
                                                    }),
                                                    _c("p", [
                                                      _c(
                                                        "span",
                                                        { staticClass: " " },
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v(
                                                              "mdi-upload"
                                                            ),
                                                          ]),
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "createCampaign.upload"
                                                              )
                                                            )
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "createCampaign.suppFormat"
                                              )
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          { staticClass: "mt-0" },
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "adkeywordsRightcol",
                                attrs: { cols: "12", sm: "12" },
                              },
                              [
                                _c(
                                  "v-form",
                                  {
                                    ref: "form",
                                    attrs: {
                                      role: "form",
                                      "lazy-validation": "",
                                    },
                                    on: {
                                      submit: function ($event) {
                                        $event.preventDefault()
                                      },
                                    },
                                    model: {
                                      value: _vm.valid,
                                      callback: function ($$v) {
                                        _vm.valid = $$v
                                      },
                                      expression: "valid",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-card",
                                      {
                                        staticClass: "adkeywordsCard",
                                        attrs: { outlined: "", height: "100%" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex pa-2 justify-space-between",
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "addKeywords.addedKeywords"
                                                  )
                                                )
                                              ),
                                            ]),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  " primary--text float-right position-relative",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("addKeywords.total")
                                                  ) +
                                                    " " +
                                                    _vm._s(_vm.keywords.length)
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "v-card",
                                          {
                                            staticClass:
                                              "pa-2 pt-0 rounded-0 border-x-0 ",
                                            staticStyle: { border: "none" },
                                            attrs: {
                                              outlined: "",
                                              height: "58",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "addkeword" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "d-flex" },
                                                  [
                                                    _c("v-text-field", {
                                                      staticClass:
                                                        "rounded-0 rounded-l-lg mb-0",
                                                      attrs: {
                                                        required: "",
                                                        outlined: "",
                                                        dense: "",
                                                        rules: _vm.keyRules,
                                                        maxlength: 80,
                                                      },
                                                      model: {
                                                        value: _vm.keywordInput,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.keywordInput = $$v
                                                        },
                                                        expression:
                                                          "keywordInput",
                                                      },
                                                    }),
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        staticClass:
                                                          "rounded-0 rounded-r-lg border-l-0 ml-3",
                                                        attrs: {
                                                          disabled:
                                                            _vm.keywordInput ==
                                                            "",
                                                          width: "36",
                                                          height: "40",
                                                          depressed: "",
                                                          outlined: "",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.addKeyword()
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "primary--text",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "addKeywords.add"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "keword-lists pa-3",
                                            staticStyle: {
                                              "overflow-y": "scroll",
                                              "max-height": "300px",
                                            },
                                          },
                                          _vm._l(
                                            _vm.keywords,
                                            function (keyword) {
                                              return _c(
                                                "v-row",
                                                {
                                                  key: keyword["text"],
                                                  staticClass:
                                                    "d-flex align-baseline",
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "py-0 pt-3",
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "4",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticClass:
                                                            "icon-hover primary--text my-0 ",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.deleteKeyword(
                                                                keyword["text"]
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "mdi-delete-outline"
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            keyword["text"]
                                                          ) +
                                                          " "
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "py-0 pt-3",
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "4",
                                                      },
                                                    },
                                                    [
                                                      _c("v-select", {
                                                        staticClass:
                                                          "no-field-details",
                                                        attrs: {
                                                          items: _vm.matchTypes,
                                                          label: "Match Type",
                                                          required: "",
                                                          outlined: "",
                                                          dense: "",
                                                        },
                                                        model: {
                                                          value:
                                                            keyword[
                                                              "matchType"
                                                            ],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              keyword,
                                                              "matchType",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "keyword['matchType']",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "py-0 pt-3",
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "4",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "field",
                                                        },
                                                        [
                                                          _c("v-text-field", {
                                                            attrs: {
                                                              type: "text",
                                                              required: "",
                                                              outlined: "",
                                                              dense: "",
                                                              "prepend-inner-icon":
                                                                "mdi-currency-usd",
                                                              rules:
                                                                _vm.amountRules,
                                                            },
                                                            model: {
                                                              value:
                                                                keyword[
                                                                  "bidAmount"
                                                                ]["amount"],
                                                              callback: function (
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  keyword[
                                                                    "bidAmount"
                                                                  ],
                                                                  "amount",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "keyword['bidAmount']['amount']",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "justify-end mt-0 pa-5 pt-0 sticky-footer" },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    disabled: _vm.saving,
                    width: "127",
                    color: "grey",
                    depressed: "",
                    outlined: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.close()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("addKeywords.cancel")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    disabled: _vm.saving || !this.keywords.length,
                    loading: _vm.saving,
                    width: "127",
                    color: "primary",
                    depressed: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.save()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("addKeywords.apply")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }