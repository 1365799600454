

































































































































































































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import * as _ from 'lodash';
import { APPSLIST_WITH_CAMPAIGNS } from '../../utils/apollo-queries';
import Vue from 'vue';
import * as _ from 'lodash';

export default Vue.extend({
  name: 'SelectCampaign',
  props: {
    openSelectCampaign: {
      // this object also includes now property which is current timestamp.
      // this is to trigger change detection even if values are unchanged in the passed object.
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },

  computed: {
    a: function (): string {
      return 'a';
    },
  },

  watch: {
    openSelectCampaign: function (data) {
      if (data.arIndex != null) {
        this.arIndex = data.arIndex;
      } else {
        this.arIndex = null;
      }
      this.show = true;
    },

    search() {
      this.filteredItems()
    }
  },

  data() {
    return {
      show: false,
      appsListWithCampaigns: null,
      finalAppsListWithCampaigns: null,
      listedCampaign: null,
      totalCampaigns: 0,
      totalSelectedCampaigns: 0,
      panel: [],
      valid: true,
      arIndex: null,
      search: '',
    };
  },

  methods: {
    deleteCamp(c) {
      this.appsListWithCampaigns.map((app) =>
        app.campaigns.map((listedCampaign) => {
          if (listedCampaign.id === c.id) listedCampaign.selected = false;
        })
      );
      this.oncheck(c);
    },
    listCampaign(campaign) {
      this.listedCampaign = campaign;
    },

    oncheck(): void {
      this.totalSelectedCampaigns = this.appsListWithCampaigns
        .map((app) =>
          app.campaigns.reduce(
            (p, currrent) => (currrent.selected === true ? 1 : 0 + p),
            0
          )
        )
        .reduce((p, c) => p + c);
    },

    clearAll() {
      this.totalSelectedCampaigns = 0;

      this.appsListWithCampaigns.map((app) =>
        app.campaigns.map((listedCampaign) => (listedCampaign.selected = false))
      );
    },

    save() {
      const toEmit = _.cloneDeep(this.appsListWithCampaigns);
      toEmit.forEach(
        (app) =>
          (app.campaigns = app.campaigns
            .filter((campaign) => campaign.selected)
            // need to omit property 'selected' since it's only used for binding in component's state
            .map((campaign) => _.omit(campaign, ['selected'])))
      );
      if (this.arIndex != null) {
        const toEmitWithIndex = {
          data: toEmit,
          index: this.arIndex,
        };
        this.$emit('onCampaignSelection', toEmitWithIndex);
      } else {
        this.$emit('onCampaignSelection', toEmit);
      }
      this.close();
    },

    filteredItems() {
      const toEmit = _.cloneDeep(this.finalAppsListWithCampaigns);
      if (this.search != '') {
        toEmit.forEach(
          (app) =>
            (app.campaigns = app.campaigns.filter((campaign) => 
              ( 
                campaign.name.toLowerCase().includes(this.search.toLowerCase())
              )
            ))
        );
      }
      this.appsListWithCampaigns = toEmit;
      this.totalCampaigns = this.appsListWithCampaigns.reduce(
          (p, c) => p + c.campaigns.length,
          0
      );
    },

    close() {
      this.show = false;
      this.arIndex = null;
      this.appsListWithCampaigns = null;
      this.listedCampaign = null;
      this.totalCampaigns = 0;
      this.totalSelectedCampaigns = 0;
      this.search = '';
    },

    expandAll() {
      this.panel = Array.from(Array(this.appsListWithCampaigns.length).keys());
    },

    collapseAll() {
      this.panel = [];
    },
  },

  apollo: {
    getAppsListWithCampaigns: {
      query: APPSLIST_WITH_CAMPAIGNS,
      variables: {
            at: Date.now(),
          },
      skip() {
        return !this.show;
      },
      update({ getAppsListWithCampaigns }) {
        this.finalAppsListWithCampaigns = _.cloneDeep(getAppsListWithCampaigns);
        this.appsListWithCampaigns = _.cloneDeep(getAppsListWithCampaigns);
        this.appsListWithCampaigns.forEach((app)=>{
              app.campaigns = app.campaigns.filter(el=>el.adChannelType=='SEARCH')
          })
        this.totalCampaigns = this.appsListWithCampaigns.reduce(
          (p, c) => p + c.campaigns.length,
          0
        );
      },
    },
  },
});
