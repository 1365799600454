



































































































































































































































































































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import * as _ from 'lodash';
import {
  ADD_NEGATIVE_KEYWORD_TO_ADGROUP,
  ADD_NEGATIVE_KEYWORD_TO_CAMPAIGN,
} from 'src/utils/apollo-mutations';
import Vue from 'vue';
import AdsMrSelectCampaign from '../campaign/AdsMrSelectCampaign.vue';
import SelectAdGroup from '../keywords/SelectAdGroup.vue';

export default Vue.extend({
  components: { AdsMrSelectCampaign, SelectAdGroup },
  name: 'AddNegativeKeywords',
  data() {
    return {
      step: 1,
      saving: false,
      selectionRadio: null,
      matchTypes: ['EXACT', 'BROAD'],

      negativeDialog: false,
      valid: false,
      negativeKeywordInput: '',
      negativeKeywords: [],

      selectedAdgroups: [],
      selectedCampaign: [],
      totalCampaigns: 0,
      totalAdgroups: 0,
      selectCampaign: null,
      selectAdgroupForKeywords: null,
      adgroupDetails: null,
      campaignDetails: null,

      keyRules: [
        (v) =>
          !this.negativeKeywords.find((kw) => kw.text === v) ||
          'Keyword already added',
      ],
    };
  },
  props: {
    openNegativeKeyword: {
      type: Object,
    },
  },
  watch: {
    openNegativeKeyword: function (data) {
      this.negativeDialog = true;
    },
  },
  methods: {
    openSelectCampaign() {
      this.selectCampaign = {
        now: Date.now(),
      };
    },

    openSelectAdgroup() {
      this.selectAdgroupForKeywords = {
        now: Date.now(),
      };
    },

    adgroupsSelectedForKeyword(appsWithCampaigns) {
      // getting adgroups
      this.step = 2;
      const adgroups = this.getNested(appsWithCampaigns.data, 'ADGROUP');
      this.adgroupDetails = { selectedAdgroups: adgroups, now: Date.now() };
      this.selectedAdgroups = this.adgroupDetails.selectedAdgroups;

      this.totalAdgroups = this.selectedAdgroups.length;
      this.totalCampaigns = _.uniq(
        this.selectedAdgroups.map((group) => group.campaignId)
      ).length;
      this.valid = true;
    },

    campaignSelectedForKeyword(appsWithCampaigns) {
      this.step = 2;
      //console.log(appsWithCampaigns)=
      const campaigns = this.getNested(appsWithCampaigns, 'CAMPAIGN');
      this.campaignDetails = { selectedCampaign: campaigns, now: Date.now() };
      this.selectedCampaign = this.campaignDetails.selectedCampaign;
      this.totalCampaigns = this.selectedCampaign.length;
    },

    close() {
      this.negativeDialog = false;
      this.selectionRadio = null;
      this.negativeKeywords = [];
      this.selectedAdgroups = [];
      this.selectedCampaign = [];
      this.totalAdgroups = 0;
      this.totalCampaigns = 0;
      this.step = 1;
    },

    addNegativeKeyword(): void {
      if (
        this.negativeKeywordInput &&
        !this.negativeKeywords.find(
          (kw) => kw.text === this.negativeKeywordInput
        )
      ) {
        this.negativeKeywords.push({
          text: this.negativeKeywordInput,
          matchType: 'EXACT',
        });

        this.negativeKeywordInput = '';

        if (this.selectedAdgroups.length > 0) {
          this.negativekeywordDetailsForAdgroup = {
            negativeKey: this.negativeKeywords,
            adgroups: this.adgroupDetails,
          };
        } else {
          this.negativekeywordDetailsForCampaign = {
            negativeKey: this.negativeKeywords,
            campaign: this.campaignDetails,
          };
        }
      }
    },

    deleteKeyword(negativeKeyword: string): void {
      this.negativeKeywords = this.negativeKeywords.filter(
        (kw) => kw.text !== negativeKeyword
      );
    },

    save() {
      this.saving = true;
      if (this.selectedAdgroups.length > 0) {
        this.addNegativeKeywordsToAdgroups();
      } else {
        this.addNegativeKeywordsToCampaigns();
      }
    },

    async addNegativeKeywordsToAdgroups() {
      const addNegativeKeywordsReq = {
        negativeKeywords: this.negativekeywordDetailsForAdgroup.negativeKey,
        adgroups: this.negativekeywordDetailsForAdgroup.adgroups
          .selectedAdgroups,
      };
      try {
        const result = await this.$apollo.mutate({
          mutation: ADD_NEGATIVE_KEYWORD_TO_ADGROUP,
          variables: {
            addNegativeKeywords: addNegativeKeywordsReq,
          },
        });

        if (result.data.addNegativeKeywordsInAdGroup.searchAdsErrors) {
          this.$notify.error(
            result.data.addNegativeKeywordsInAdGroup.searchAdsErrors
          );
        } else {
          this.$emit('onAdd');
          this.$notify.success(this.$t('successMsg.negaKeyAddedMsg'));
          this.close();
        }
      } catch {}

      this.saving = false;
    },

    async addNegativeKeywordsToCampaigns() {
      const addNegativeKeywordsReq = {
        negativeKeywords: this.negativekeywordDetailsForCampaign.negativeKey,
        campaign: this.negativekeywordDetailsForCampaign.campaign
          .selectedCampaign,
      };

      try {
        const result = await this.$apollo.mutate({
          mutation: ADD_NEGATIVE_KEYWORD_TO_CAMPAIGN,
          variables: {
            addNegativeKeywords: addNegativeKeywordsReq,
          },
        });

        if (result.data.addNegativeKeywordsInCampaign.searchAdsErrors) {
          this.$notify.error(
            result.data.addNegativeKeywordsInCampaign.searchAdsErrors
          );
        } else {
          this.$emit('onAdd');
          this.$notify.success(this.$t('successMsg.negKeyAddCamp'));
          this.close();
        }
      } catch {}

      this.saving = false;
    },

    change() {
      this.close();
      this.negativeDialog = true;
    },
    getNested(appsWithCampaigns, level: 'APP' | 'CAMPAIGN' | 'ADGROUP') {
      // returning nested data as requested (apps > campaigns > adgroups)
      return level === 'APP'
        ? appsWithCampaigns
        : [].concat(
            ...appsWithCampaigns.map((app) =>
              level === 'CAMPAIGN'
                ? app.campaigns
                : [].concat(
                    ...app.campaigns.map((campaign) => campaign.adgroups)
                  )
            )
          );
    },
  },
});
