





























// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { DELETE_AD } from 'src/utils/apollo-mutations';
import Vue from 'vue';
export default Vue.extend({
  props: {
    adsDetailsForDeletion: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      deleting: false,
    };
  },
   watch: {
    adsDetailsForDeletion() {
      this.dialog = true;
    },
   },
  methods: {
    async deleteAd() {
      this.deleting = true;
      try {
        const result = await this.$apollo.mutate({
          mutation: DELETE_AD,
          variables: {
            orgId: this.adsDetailsForDeletion.orgId,
            adId:this.adsDetailsForDeletion.id,
            adGroupId: this.adsDetailsForDeletion.adgroupId,
            campaignId: this.adsDetailsForDeletion.campaignId          
          },
        });
        if (result.data.deleteAd.data == 'deleted') {
          this.$notify.success(this.$t('successMsg.AdDeletedMsg'));
          this.$emit('onDeleteAd');
        } else if (result.data.deleteAd.searchAdsErrors) {          
            const deleteMsg = result.data.deleteAd.searchAdsErrors.search("Product Page is not Deleted");
            let displayDeleteMsg = result.data.deleteAd.searchAdsErrors;
          
            if(deleteMsg!=-1){
              displayDeleteMsg="Please delete the Product Page to delete this ad";
            }
            console.log(displayDeleteMsg);
            this.$notify.error(displayDeleteMsg);
        } else {
          this.$notify.error('Unable to delete this Ad');
          this.$emit('onDeleteAd');
        }
        this.deleting = false;
        this.dialog = false;
      } catch (error) {
        this.deleting = false;
        console.log(error);
      }
    },
  }
});
