import gql from 'graphql-tag';

export const ADS_MANAGER_NEG_KEYWORD_LIVE = gql`
  query negativeKeywordReportsLive(
    $filter: Filters!
    $offset: Float!
    $limit: Float!
    $sortBy: String!
    $sortDesc: Boolean!
    $negSearch: NegSearchReq!
  ) {
    negativeKeywordReportsLive(
      filter: $filter
      offset: $offset
      limit: $limit
      sortBy: $sortBy
      sortDesc: $sortDesc
      negSearch: $negSearch
    ) {
      row {
        adGroupId
        campaignId
        deleted
        id
        matchType
        modificationTime
        status
        text
        campaignMetadataNeg {
          name
        }
        adgroupMetadataNeg {
          name
        }
      }
      negCounts {
        allnegativekeyword
        livenegativekeyword
        pausednegativekeyword
        deletednegativekeyword
      }
    }
  }
`;
export const ADS_MANAGER_NEG_KEYWORD_ALL = gql`
  query negativeKeywordReportsAll(
    $filter: Filters!
    $offset: Float!
    $limit: Float!
    $sortBy: String!
    $sortDesc: Boolean!
    $negSearch: NegSearchReq!
  ) {
    negativeKeywordReportsAll(
      filter: $filter
      offset: $offset
      limit: $limit
      sortBy: $sortBy
      sortDesc: $sortDesc
      negSearch: $negSearch
    ) {
      row {
        adGroupId
        campaignId
        deleted
        id
        matchType
        modificationTime
        status
        text
        campaignMetadataNeg {
          name
        }
        adgroupMetadataNeg {
          name
        }
      }
      negCounts {
        allnegativekeyword
        livenegativekeyword
        pausednegativekeyword
        deletednegativekeyword
      }
    }
  }
`;
export const ADS_MANAGER_NEG_KEYWORD_PAUSED = gql`
  query negativeKeywordReportsPaused(
    $filter: Filters!
    $offset: Float!
    $limit: Float!
    $sortBy: String!
    $sortDesc: Boolean!
    $negSearch: NegSearchReq!
  ) {
    negativeKeywordReportsPaused(
      filter: $filter
      offset: $offset
      limit: $limit
      sortBy: $sortBy
      sortDesc: $sortDesc
      negSearch: $negSearch
    ) {
      row {
        adGroupId
        campaignId
        deleted
        id
        matchType
        modificationTime
        status
        text
        campaignMetadataNeg {
          name
        }
        adgroupMetadataNeg {
          name
        }
      }
      negCounts {
        allnegativekeyword
        livenegativekeyword
        pausednegativekeyword
        deletednegativekeyword
      }
    }
  }
`;
export const ADS_MANAGER_NEG_KEYWORD_DELETED = gql`
  query negativeKeywordReportsDeleted(
    $filter: Filters!
    $offset: Float!
    $limit: Float!
    $sortBy: String!
    $sortDesc: Boolean!
    $negSearch: NegSearchReq!
  ) {
    negativeKeywordReportsDeleted(
      filter: $filter
      offset: $offset
      limit: $limit
      sortBy: $sortBy
      sortDesc: $sortDesc
      negSearch: $negSearch
    ) {
      row {
        adGroupId
        campaignId
        deleted
        id
        matchType
        modificationTime
        status
        text
        campaignMetadataNeg {
          name
        }
        adgroupMetadataNeg {
          name
        }
      }
      negCounts {
        allnegativekeyword
        livenegativekeyword
        pausednegativekeyword
        deletednegativekeyword
      }
    }
  }
`;
