<template>
  <div class="text-center">
    <v-dialog
      v-model="AddnKeysShow"
      persistent
      max-width="550"
      scrollable
    >
      <v-card>
        <v-card-title class="headline d-flex justify-space-between px-5 py-3 v-modal-header"
          ><h5 class="text-h6"> {{$t('addnKeys.addNegKeywords')}}</h5>
          <v-icon @click="close()">mdi-close</v-icon>
        </v-card-title>

        <v-card-text class="pa-3">
          <div class="pagination d-flex justify-end pa-2">
            <span class="pagination-total primary--text"
              >{{$t('addnKeys.total')}} {{ keywords.length }}</span
            >
          </div>
          <v-card
            outlined
            class="pa-2 rounded-0 border-x-0" style="border:none"
            height="58"
          >
            <div class="addkeword style-2">
              <div class="d-flex">
                <v-text-field
                  class="rounded-0 rounded-l-lg no-field-details mb-0"
                  v-model="keywordInput"
                  placeholder="Add one or add multiple comma separated"
                  required
                  outlined
                  dense
                ></v-text-field>
                <v-btn
                  @click="addKeyword()"
                  width="36"
                  height="40"
                  class="rounded-0 rounded-r-lg border-l-0 ml-3"
                  depressed
                  outlined
                  ><span class="primary--text"
                    >{{$t('addnKeys.add')}}</span
                  ></v-btn
                >
              </div>
            </div>
          </v-card>
          <div class="keword-lists pa-2 v-max-height-300">
            <v-row
              v-for="keyword in keywords"
              :key="keyword.text"
            >
              <v-col lg="4" md="5" sm="5" class="py-0  pl-0 word-nowrap">
               <v-icon class="icon-hover primary--text my-0 " @click="deleteKeyword(keyword.text)"
                    >mdi-delete-outline</v-icon
                  >
                {{ keyword.text }}
              </v-col>
              <v-col lg="8" md="7" sm="7" class="py-0 pt-3 pr-0">
                <v-select
                  v-model="keyword.matchType"
                  class="no-field-details"
                  :items="matchTypes"
                  :label="$t('labels.matchType')"
                  required
                  outlined
                  dense
                ></v-select>
              </v-col>
            </v-row>
          </div>
          
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            text
            @click="emitAddnKeysCancelClicked"
          >
            {{$t('addnKeys.cancel')}}
          </v-btn>
          <v-btn
            color="primary"
            text
            :disabled="!keywords.length"
            @click="emitAddnKeysOkClicked"
          >
            {{$t('addnKeys.save')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name : 'AddNegKeyInAdgroup',
  computed: {
    matchTypes: {
      get() {
        return [this.$t('addKeys.matchExact'), this.$t('addKeys.matchBroad')];
      }
    }
  },
  data() {
    return {
      emitMsgOk : 'clicked Ok',
      emitMsgCancel : 'clicked Cancel',
      nKeys: [],
      AddnKeysShow: false,
      keywordInput: '',
      keywords: [],
    }
  },
  props:{
    AddnKeysData : {
      type: Object,
      default() {
        return;
      },
    },
  },
  watch: {
    AddnKeysData: function (data) {
      const final_data = Array.from(data.negKey);
      this.AddnKeysShow = true;
      this.keywords = final_data;
    },
  },
  methods: {
   close() {
     //console.log('close');
      this.AddnKeysShow = false;
      this.keywordInput = '';
      this.keywords = [];
    },

    emitAddnKeysOkClicked() {
      //console.log('emitAddnKeysOkClicked');
      this.$emit('emitAddnKeysOkClicked', this.keywords);
      this.close();
    },

    emitAddnKeysCancelClicked() {
      //console.log('emitAddnKeysCancelClicked');
      this.$emit('emitAddnKeysCancelClicked', this.emitMsgCancel);
      this.close();
    },

    addKeyword() {
      const keys = this.keywordInput.split(',');
      for (let index = 0; index < keys.length; index++) {
        const element = keys[index];
        //console.log(element);
        if (
          element &&
          !this.keywords.find((kw) => kw.text === element)
        ) {
          this.keywords.push({
            text: element,
            matchType: 'EXACT',
          });
        }
        this.keywordInput = '';
      }
    },

    deleteKeyword(keyword) {
      this.keywords = this.keywords.filter((kw) => kw.text !== keyword);
    },
  }
})
</script>

