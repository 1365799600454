var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "total-summary " }, [
    _c(
      "div",
      { staticClass: "total-summary-header text-center " },
      [
        _c(
          "v-subheader",
          { staticClass: "justify-center" },
          [
            _vm._v(" " + _vm._s(_vm.$t("labels.totalSummary")) + " "),
            _vm.tab == 4
              ? _c(
                  "v-tooltip",
                  {
                    attrs: {
                      bottom: "",
                      "max-width": "300",
                      color: "primarydark",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-icon",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass: "ml-2  greyxdark--text",
                                      attrs: { small: "" },
                                    },
                                    "v-icon",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [_vm._v("mdi-help-circle-outline")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4190419526
                    ),
                  },
                  [
                    _c("span", [
                      _vm._v(
                        "Keyword summary only includes the aggregation of search result keywords. It doesn't include the search tab information"
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
        _c("p", [
          _vm._v(
            " $" +
              _vm._s(_vm.convertToShortCurr(_vm.values.localSpend.amount)) +
              " "
          ),
          _c("br"),
          _c("span", [_vm._v(" " + _vm._s(_vm.$t("labels.spend")) + " ")]),
        ]),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "d-flex total-summary-content flex-wrap justify-center" },
      _vm._l(_vm.summaryItems, function (item, i) {
        return _c(
          "v-sheet",
          {
            key: i,
            staticClass:
              "justify-center d-flex flex-column font-weight-medium text-h5 col-tab",
            attrs: {
              color: "white",
              elevation: "0",
              height: "152",
              width: "33.3%",
            },
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "summary-inner-content d-flex align-center justify-content-center flex-column",
              },
              [
                _c(
                  "p",
                  {
                    staticClass:
                      "mb-0 text-with-info text-overflow-w margin-auto",
                  },
                  [
                    _c("span", {}, [
                      _vm._v(
                        _vm._s(item.currency ? "$" : "") +
                          _vm._s(
                            _vm.convertToShortCurr(_vm.getValue(item.key))
                          ) +
                          _vm._s(item.percentage ? "%" : "")
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "info-label" },
                  [
                    _c("span", [_vm._v(_vm._s(item.title))]),
                    _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "", color: "primarydark" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "v-icon",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass: " greyxdark--text mt-1",
                                          attrs: { small: "" },
                                        },
                                        "v-icon",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [_vm._v("mdi-help-circle-outline")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [_c("span", [_vm._v(_vm._s(item.tooltip))])]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]
        )
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }