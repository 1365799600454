import { gql } from 'graphql-tag';

export const ADS_MANAGER_AD_LIVE = gql`
  query productPageReportsLive(
    $filter: Filters!
    $offset: Float!
    $limit: Float!
    $sortBy: String!
    $sortDesc: Boolean!
    $adsSearch: adsSearchReq!
    $getGoals: Boolean!
  ) {
    productPageReportsLive(filter: $filter, offset: $offset, limit: $limit, sortBy: $sortBy, sortDesc: $sortDesc, adsSearch: $adsSearch) {
      totalRecords
      grandTotals {
        total {
          installs
          newDownloads
          redownloads
          ttr
          taps
          impressions
          localSpend {
            amount
          }
        }
      }
      row {
        adTypeRow
        total {
          localSpend {
            amount
          }
          avgCPA {
            amount
          }
          avgCPT {
            amount
          }
          avgCPM {
            amount
          }
          impressions
          taps
          installs
          ttr
          conversionRate
          newDownloads
          redownloads
        }
        metadata {
          adId
          adName
          status
          app {
            adamId
            appName
          }
        }
        adsMetadata {
          id
          creativeId
          name
          status
          campaignId
          adGroupId
          deleted
          creativeType
          orgId
        }
        adgroupMetadata {
          orgId
          id
          status
          name
        }
        orgMetadata {
          orgName
          currency
        }
        campaignMetadata {
          name
          adamId
          countriesOrRegions
          id
          status
          deleted
        }
        productPageAttributionData(filter: $filter, getGoals: $getGoals) {
          successMessage
          content
          secondaryContent
        }
      }
    }
  }
`;
export const ADS_MANAGER_AD_FILTER_LIVE = gql`
  query productPageReportsLive($filter: Filters!, $offset: Float!, $limit: Float!, $sortBy: String!, $sortDesc: Boolean!) {
    productPageReportsForFilter: productPageReportsLive(
      filter: $filter
      offset: $offset
      limit: $limit
      sortBy: $sortBy
      sortDesc: $sortDesc
    ) {
      totalRecords
      row {
        adsMetadata {
          id
          name
        }
      }
    }
  }
`;

export const ADS_MANAGER_AD_ALL = gql`
  query productPageReportsAll(
    $filter: Filters!
    $offset: Float!
    $limit: Float!
    $sortBy: String!
    $sortDesc: Boolean!
    $adsSearch: adsSearchReq!
    $getGoals: Boolean!
  ) {
    productPageReportsAll(filter: $filter, offset: $offset, limit: $limit, sortBy: $sortBy, sortDesc: $sortDesc, adsSearch: $adsSearch) {
      totalRecords
      grandTotals {
        total {
          installs
          newDownloads
          redownloads
          ttr
          taps
          impressions
          localSpend {
            amount
          }
        }
      }
      row {
        adTypeRow
        total {
          localSpend {
            amount
          }
          avgCPA {
            amount
          }
          avgCPT {
            amount
          }
          avgCPM {
            amount
          }
          impressions
          taps
          installs
          ttr
          conversionRate
          newDownloads
          redownloads
        }
        metadata {
          adId
          adName
          status
          app {
            adamId
            appName
          }
        }
        adsMetadata {
          id
          creativeId
          name
          status
          campaignId
          adGroupId
          deleted
          creativeType
          orgId
        }
        adgroupMetadata {
          orgId
          id
          status
          name
        }
        orgMetadata {
          orgName
          currency
        }
        campaignMetadata {
          name
          adamId
          countriesOrRegions
          id
          status
          deleted
        }
        productPageAttributionData(filter: $filter, getGoals: $getGoals) {
          successMessage
          content
          secondaryContent
        }
      }
    }
  }
`;
export const ADS_MANAGER_AD_FILTER_ALL = gql`
  query productPageReportsAll($filter: Filters!, $offset: Float!, $limit: Float!, $sortBy: String!, $sortDesc: Boolean!) {
    productPageReportsForFilter: productPageReportsAll(
      filter: $filter
      offset: $offset
      limit: $limit
      sortBy: $sortBy
      sortDesc: $sortDesc
    ) {
      totalRecords
      row {
        adsMetadata {
          id
          name
        }
      }
    }
  }
`;
export const ADS_MANAGER_AD_PAUSED = gql`
  query productPageReportsPaused(
    $filter: Filters!
    $offset: Float!
    $limit: Float!
    $sortBy: String!
    $sortDesc: Boolean!
    $adsSearch: adsSearchReq!
    $getGoals: Boolean!
  ) {
    productPageReportsPaused(filter: $filter, offset: $offset, limit: $limit, sortBy: $sortBy, sortDesc: $sortDesc, adsSearch: $adsSearch) {
      totalRecords
      grandTotals {
        total {
          installs
          newDownloads
          redownloads
          ttr
          taps
          impressions
          localSpend {
            amount
          }
        }
      }
      row {
        adTypeRow
        total {
          localSpend {
            amount
          }
          avgCPA {
            amount
          }
          avgCPT {
            amount
          }
          avgCPM {
            amount
          }
          impressions
          taps
          installs
          ttr
          conversionRate
          newDownloads
          redownloads
        }
        metadata {
          adId
          adName
          status
          app {
            adamId
            appName
          }
        }
        adsMetadata {
          id
          creativeId
          name
          status
          campaignId
          adGroupId
          deleted
          creativeType
          orgId
        }
        adgroupMetadata {
          orgId
          id
          status
          name
        }
        orgMetadata {
          orgName
          currency
        }
        campaignMetadata {
          name
          adamId
          countriesOrRegions
          id
          status
          deleted
        }
        productPageAttributionData(filter: $filter, getGoals: $getGoals) {
          successMessage
          content
          secondaryContent
        }
      }
    }
  }
`;
export const ADS_MANAGER_AD_FILTER_PAUSED = gql`
  query productPageReportsPaused($filter: Filters!, $offset: Float!, $limit: Float!, $sortBy: String!, $sortDesc: Boolean!) {
    productPageReportsForFilter: productPageReportsPaused(
      filter: $filter
      offset: $offset
      limit: $limit
      sortBy: $sortBy
      sortDesc: $sortDesc
    ) {
      totalRecords
      row {
        adsMetadata {
          id
          name
        }
      }
    }
  }
`;
export const ADS_MANAGER_AD_DELETED = gql`
  query productPageReportsDeleted(
    $filter: Filters!
    $offset: Float!
    $limit: Float!
    $sortBy: String!
    $sortDesc: Boolean!
    $adsSearch: adsSearchReq!
    $getGoals: Boolean!
  ) {
    productPageReportsDeleted(
      filter: $filter
      offset: $offset
      limit: $limit
      sortBy: $sortBy
      sortDesc: $sortDesc
      adsSearch: $adsSearch
    ) {
      totalRecords
      grandTotals {
        total {
          installs
          newDownloads
          redownloads
          ttr
          taps
          impressions
          localSpend {
            amount
          }
        }
      }
      row {
        adTypeRow
        total {
          localSpend {
            amount
          }
          avgCPA {
            amount
          }
          avgCPT {
            amount
          }
          avgCPM {
            amount
          }
          impressions
          taps
          installs
          ttr
          conversionRate
          newDownloads
          redownloads
        }
        metadata {
          adId
          adName
          status
          app {
            adamId
            appName
          }
        }
        adsMetadata {
          id
          creativeId
          name
          status
          campaignId
          adGroupId
          deleted
          creativeType
          orgId
        }
        adgroupMetadata {
          orgId
          id
          status
          name
        }
        orgMetadata {
          orgName
          currency
        }
        campaignMetadata {
          name
          adamId
          countriesOrRegions
          id
          status
          deleted
        }
        productPageAttributionData(filter: $filter, getGoals: $getGoals) {
          successMessage
          content
          secondaryContent
        }
      }
    }
  }
`;
export const ADS_MANAGER_AD_FILTER_DELETED = gql`
  query productPageReportsDeleted($filter: Filters!, $offset: Float!, $limit: Float!, $sortBy: String!, $sortDesc: Boolean!) {
    productPageReportsForFilter: productPageReportsDeleted(
      filter: $filter
      offset: $offset
      limit: $limit
      sortBy: $sortBy
      sortDesc: $sortDesc
    ) {
      totalRecords
      row {
        adsMetadata {
          id
          name
        }
      }
    }
  }
`;
